import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  Activity,
  ActivityActivityTypeEnum,
  Employee,
  Reminder
} from "$Generated/api";

import {
  Button,
  DataGridPro,
  GridColDef,
  GridRowClassNameParams,
  GridRenderCellParams,
  GridValueGetterParams
} from "$Imports/MaterialUIComponents";

import {
  AjaxActionIndicator,
  CardLinedHeader,
  DisplayFormattedDatetime,
  UserAccessControl,
  TextCellTruncated,
  AdvanceTextField,
  EditNoteModal
} from "$Imports/CommonComponents";

import {
  ICustomerDetailServiceInjectedProps,
  CustomerDetailService
} from "$State/CustomerDetailFreezerService";

import {
  IReminderServiceInjectedProps,
  ReminderService
} from "$State/ReminderFreezerService";

import {
  ActionMenu
} from "./ActionMenu";

import {
  AddEditActivityModal
} from "./AddEditActivityModal";

import {
  DATE_WITH_TIME_MERIDIAN_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  activityTypeTextMap
} from "$Utilities/enumUtil";


const styles: {
  notesContainer: string;
  tableRow: string;
  hideShow: string;
  addNoteContainer: string;
} = require("./Activities.scss");

interface IActivitiesCardBaseProps {
  customerId: number | undefined;
  isNotes?: boolean;
  salesReps: Employee[];
}

type IActivitiesCardProps = IActivitiesCardBaseProps
  & ICustomerDetailServiceInjectedProps
  & IReminderServiceInjectedProps;

  interface IActivitiesCardState {
    isOpen: boolean;
    editActivity: Activity;
  }

const dateColumn: GridColDef = {
  headerName: "Date",
  field: "createdOn",
  renderCell: (params: GridRenderCellParams<Date, Activity>) => <DisplayFormattedDatetime value={params.value} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} />,
  width: 161,
  disableColumnMenu: true
}

function createdByIdColumn(isNotes: boolean): GridColDef {
  return {
    headerName: isNotes ? "Author" : "User",
    field: "createdById",
    valueGetter: (params: GridValueGetterParams<number, Activity>) => params.row?.createdBy?.firstName + " " + params.row?.createdBy?.lastName,
    flex: 2,
    disableColumnMenu: true,
  }
}

function notesColumn(isNotes: boolean): GridColDef {
  return {
    headerName: isNotes ? "Note" : "Notes",
    field: "noteText",
    renderCell: (params: GridRenderCellParams<string, Activity>) => {
      let noteText = params.value ?? "";

      return (
        <TextCellTruncated text={noteText} />
      );
    },
    flex: 6.5,
    disableColumnMenu: true,
  }
}

class _ActivitiesCard extends React.Component<IActivitiesCardProps, IActivitiesCardState> {
  state: IActivitiesCardState = {
    isOpen: false,
    editActivity: {}
  };

  private readonly activityColumns: GridColDef[] = [
    dateColumn,
    createdByIdColumn(false),
    {
      headerName: "Type",
      field: "activityType",
      valueGetter: (params: GridValueGetterParams<ActivityActivityTypeEnum, Activity>) => activityTypeTextMap[params.value as ActivityActivityTypeEnum],
      flex: 3,
      disableColumnMenu: true,
    },
    notesColumn(false),
    {
      headerName: "",
      field: "actions",
      renderCell: (params: GridRenderCellParams<any, Activity>) => {
        if (params.row.activityType !== "CustomerToProspectHandoff") {
          return <ActionMenu
            note={params.row}
            onEditClick={this._toggleEditModal}
          />
        }
      },
      width: 56,
      resizable: false,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: styles.hideShow
    }
  ];

  private readonly notesColumns: GridColDef[] = [
    dateColumn,
    createdByIdColumn(true),
    notesColumn(true),
    {
      headerName: "",
      field: "actions",
      renderCell: (params: GridRenderCellParams<any, Activity>) => (
        <ActionMenu
          note={params.row}
          onEditClick={this._openEditModal}
          onDeleteClick={this._onDeleteClick}
        />
      ),
      width: 88,
      resizable: false,
      disableColumnMenu: true,
      sortable: false,
      cellClassName: styles.hideShow
    }
  ]

  @bind
  private _toggleEditModal(activity?: Activity) {
    if (activity) {
      if (!activity.customerId) {
        activity.customerId = this.props.customerId
      }
      this.setState({
        isOpen: true,
        editActivity: activity ?? {}
      });
    }
    else {
      this.setState({
        isOpen: false,
        editActivity: {}
      });
    }
  }

  @bind
  private _onAddActivityClick(): void {
    this._toggleEditModal({});
  }

  @bind
  private async _editActivity(activity?: Activity, reminder?: Reminder): Promise<void> {
    if (activity) {
      await this.props.customerDetailService.saveActivity(activity);
    }
    if (reminder) {
      await this.props.reminderService.createReminder(reminder);
      this.props.reminderService.fetchReminders(true);
    }

    this._toggleEditModal();
  }

  @bind
  private _onAddNoteTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.customerDetailService.addNoteTextChange(e.target.value);
  }

  @bind
  private _onAddNoteClick() {
    if (this.props.customerId) {
      this.props.customerDetailService.createNote(this.props.customerId);
    }
  }

  @bind
  private _onDeleteClick(note: Activity) {
    this.props.customerDetailService.deleteNote(note);
  }

  @bind
  private _openEditModal(note: Activity) {
    this.props.customerDetailService.openEditNoteModal(note);
  }

  @bind
  private _onEditNoteChange(note: Partial<Activity>) {
    this.props.customerDetailService.editNoteOnChange(note);
  }

  @bind
  private _saveEditNote() {
    this.props.customerDetailService.saveEditNote();
  }

  @bind
  private _cancelEditModal() {
    this.props.customerDetailService.cancelEditNoteModal();
  }

  render() {
    const {
      isNotes,
      salesReps
    } = this.props;

    const {
      isOpen,
      editActivity
    } = this.state;

    const {
      activitySaveResults,
      activities,
      allNotes,
      addNoteText,
      noteSaveResults,
      editNote
    } = this.props.customerDetailService.getState();

    return (
      <>
        <CardLinedHeader
          titleText={isNotes ? "Notes" : "Activity"}
          style={{ display: "flex", flexDirection: "column", flex: "0 0 auto" }}
          titleComponents={!isNotes && (
            <UserAccessControl roles={["note:create"]}>
              <Button
                color="primary"
                onClick={this._onAddActivityClick}
                style={{ marginBottom: "7px" }}
              >
                Add
              </Button>
            </UserAccessControl>
          )}
        >
          <AjaxActionIndicator state={isNotes ? [noteSaveResults] : [activitySaveResults]} />
          {isNotes &&
            <UserAccessControl roles={["note:create"]}>
              <div className={styles.addNoteContainer}>
                <AdvanceTextField
                  value={addNoteText}
                  onChange={this._onAddNoteTextChange}
                  placeholder="Write a new note"
                  inputProps={{ maxLength: 300 }}
                  multiline
                  fullWidth
                  style={{ marginRight: "5px" }}
                />
                <Button
                  color="primary"
                  onClick={this._onAddNoteClick}
                  disabled={!addNoteText || addNoteText.length > 300}
                >
                  Add
                </Button>
              </div>
            </UserAccessControl>
          }
          <div className={styles.notesContainer}>
            <DataGridPro
              rows={isNotes ? allNotes : activities}
              columns={isNotes ? this.notesColumns : this.activityColumns}
              density="compact"
              getRowClassName={(params: GridRowClassNameParams) => styles.tableRow}
              hideFooter
              disableSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: "createdOn", sort: "desc" }]
                }
              }}
            />
          </div>
        </CardLinedHeader>
        <AddEditActivityModal
          isOpen={isOpen}
          activity={editActivity}
          salesReps={salesReps}
          onClose={this._editActivity}
        />
        <EditNoteModal
          note={editNote}
          onChange={this._onEditNoteChange}
          onSave={this._saveEditNote}
          onCancel={this._cancelEditModal}
        />
      </>
    );
  }
}

export const ActivitiesCard = CustomerDetailService.inject(
  ReminderService.inject(
    _ActivitiesCard
));
