import {
  _,
  React,
  bind,
  NumberFormat,
  NumberFormatValues
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  Customer,
  CustomerBillingStatusEnum,
  Region
} from "$Generated/api";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  FormHelperText,
  SelectChangeEvent,
} from "$Imports/MaterialUIComponents";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  billingStatusEnumTextMap
} from "$Utilities/enumUtil";

import {
  qualifyZipPostalCodeInput
} from "$Shared/utilities/helpers";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import { ExpandMoreIcon } from "$Imports/MaterialUIIcons";
import { AdvanceTextField } from "$Imports/CommonComponents";

const styles: {
  accordion: string;
  templateContainer: string;
  noPaddingTop: string;
  textfield: string;
} = require("./BillingInformation.scss");

interface IBillingInformationProps {
  customer: Customer;
  regions: Region[];
  validationErrors: ValidationError | null;
  onBillingChange: (billingInfo: Partial<Customer>) => void;
}

const billingStatusArray: CustomerBillingStatusEnum[] = ["CreditConfirmed", "CODOnly"];

export class BillingInformation extends React.Component<IBillingInformationProps> {
  @bind
  private _onSelectChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.onBillingChange !== undefined) {
      this.props.onBillingChange({ [e.target.name]: e.target.value });
    }
  }

  @bind
  private _onCreditLimitChange(value?: NumberFormatValues) {
    if (this.props.onBillingChange !== undefined) {
      this.props.onBillingChange({ creditLimit: value?.floatValue });
    }
  }

  @bind
  private _onDueDaysChange(value?: NumberFormatValues) {
    if (this.props.onBillingChange !== undefined) {
      this.props.onBillingChange({ dueDays: value?.floatValue });
    }
  }

  @bind
  private _onHasBillingAddressChange(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    if (this.props.onBillingChange !== undefined) {
      this.props.onBillingChange({ [e.target.name]: checked });
    }
  }

  @bind
  private _onRegionChange(e: SelectChangeEvent): void {
    const regionId = Number(e.target.value);
    const region = _.find(this.props.regions, r => r.id === regionId);
    if (this.props.onBillingChange !== undefined) {
      this.props.onBillingChange({ billingRegionId: regionId, billingRegion: region });
    }
  }

  @bind
  private _onZipPostalCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const countryId = this.props.customer.billingRegion?.countryId;
    const isCanadian = countryId !== undefined ? countryId === 2 : undefined;
    const value = qualifyZipPostalCodeInput(e.target.value, isCanadian).replace(' ', '');
    if (this.props.onBillingChange !== undefined) {
      this.props.onBillingChange({ [e.target.name]: value });
    }
  }

  render() {
    const {
      customer,
      regions,
      validationErrors
    } = this.props;

    const isBillerUser = SharedSecurityContext.hasRole(["customer-credit-information:edit"]);
    const canUpdateBilling = isBillerUser && customer.isBillTo;
    const validationsParser = new ValidationErrorParser<Customer>(validationErrors);

    return (
      <Accordion
        disableGutters
        elevation={2}
        className={styles.accordion}
        sx={{
          '&:before': {
              display: 'none',
          }
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}><b>Billing Information</b></AccordionSummary>
        <AccordionDetails style={{ paddingTop: "0" }}>
          <div className={styles.templateContainer}>
            <Grid container direction="row" wrap="wrap" spacing={2}>
              <Grid item xs={4}>
                <FormControl fullWidth disabled={!canUpdateBilling} error={!validationsParser.isValid("billingStatus")}>
                  <InputLabel>Billing Status</InputLabel>
                  <Select
                    value={customer.billingStatus ?? ""}
                    name="billingStatus"
                    onChange={(event) => this._onSelectChange(event as React.ChangeEvent<HTMLInputElement>)}
                    renderValue={(selected: CustomerBillingStatusEnum) => {
                      if (customer.billingStatus === "CheckCredit") {
                        return <i>Check Credit</i>;
                      }
                      else {
                        const selectedStatus = _.find(billingStatusArray, s => s === selected);
                        return (
                          <span>{billingStatusEnumTextMap[selectedStatus as CustomerBillingStatusEnum]}</span>
                        );
                      }
                    }}
                  >
                    {
                      billingStatusArray.map((status, idx) => {
                        return (
                          <MenuItem key={idx} value={status}>{billingStatusEnumTextMap[status as CustomerBillingStatusEnum]}</MenuItem>
                        )
                      })
                    }
                  </Select>
                  {!validationsParser.isValid("billingStatus") && <FormHelperText>{validationsParser.validationMessage("billingStatus")}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <NumberFormat
                  allowNegative={false}
                  decimalSeparator="."
                  decimalScale={2}
                  thousandSeparator={true}
                  disabled={!canUpdateBilling}
                  prefix="$"
                  label="Credit Limit"
                  value={customer.creditLimit?.toFixed(2) ?? ""}
                  customInput={TextField}
                  onValueChange={this._onCreditLimitChange}
                  error={!validationsParser?.isValid("creditLimit")}
                  inputProps={{ maxLength: 13 }}
                  helperText={validationsParser?.validationMessage("creditLimit")}
                  className={styles.textfield}
                />
              </Grid>
              <Grid item xs={4}>
                <NumberFormat
                  allowNegative={false}
                  decimalScale={0}
                  thousandSeparator={true}
                  disabled={!canUpdateBilling}
                  label="Due Days"
                  value={customer.dueDays ?? ""}
                  customInput={TextField}
                  onValueChange={this._onDueDaysChange}
                  error={!validationsParser?.isValid("dueDays")}
                  helperText={validationsParser?.validationMessage("dueDays")}
                  inputProps={{ maxLength: 3 }}
                  className={styles.textfield}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  label="Customer has separate billing address"
                  control={(
                    <Checkbox
                      checked={customer.hasBillingAddress ?? false}
                      onChange={this._onHasBillingAddressChange}
                      name="hasBillingAddress"
                    />
                  )}
                />
                <FormHelperText error={customer.hasBillingAddress && !validationsParser.isValid("hasBillingAddress")}>{validationsParser.validationMessage("hasBillingAddress")}</FormHelperText>
              </Grid>
              <Grid item xs={8} className={styles.noPaddingTop}>
                <AdvanceTextField
                  label="Billing Address"
                  name="billingAddress1"
                  onChange={this._onSelectChange}
                  value={customer.billingAddress1 ?? ""}
                  error={!validationsParser.isValid("billingAddress1")}
                  helperText={validationsParser.validationMessage("billingAddress1")}
                  inputProps={{ maxLength: 40 }}
                  disabled={!customer.hasBillingAddress}
                  required={customer.hasBillingAddress}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} className={styles.noPaddingTop}>
                <AdvanceTextField
                  label="Billing Address line 2"
                  name="billingAddress2"
                  onChange={this._onSelectChange}
                  value={customer.billingAddress2 ?? ""}
                  error={!validationsParser.isValid("billingAddress2")}
                  helperText={validationsParser.validationMessage("billingAddress2")}
                  inputProps={{ maxLength: 40 }}
                  disabled={!customer.hasBillingAddress}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <AdvanceTextField
                  label="Billing City"
                  name="billingCity"
                  onChange={this._onSelectChange}
                  value={customer.billingCity ?? ""}
                  error={customer.hasBillingAddress && !validationsParser.isValid("billingCity")}
                  helperText={customer.hasBillingAddress && validationsParser.validationMessage("billingCity")}
                  inputProps={{ maxLength: 30 }}
                  disabled={!customer.hasBillingAddress}
                  required={customer.hasBillingAddress}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth disabled={!customer.hasBillingAddress} error={customer.hasBillingAddress && !validationsParser.isValid("billingRegionId")}>
                  <InputLabel>{`Billing Region${customer.hasBillingAddress ? ' *' : ''}`}</InputLabel>
                  <Select
                    value={customer.billingRegionId ?? ""}
                    name="billingRegionId"
                    onChange={(event) => this._onRegionChange(event as React.ChangeEvent<HTMLInputElement>)}
                    required={customer.hasBillingAddress}
                  >
                    {_.map(regions, (r, idx) =>
                      <MenuItem value={r.id} key={idx}>{r.regionName}</MenuItem>
                    )}
                  </Select>
                  {customer.hasBillingAddress && !validationsParser.isValid("billingRegionId") && <FormHelperText>{validationsParser.validationMessage("billingRegionId")}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Billing Postal Code"
                  name="billingZipPostalCode"
                  onChange={this._onZipPostalCodeChange}
                  value={customer.billingZipPostalCode ?? ""}
                  error={customer.hasBillingAddress && !validationsParser.isValid("billingZipPostalCode")}
                  helperText={customer.hasBillingAddress && validationsParser.validationMessage("billingZipPostalCode")}
                  disabled={!customer.hasBillingAddress}
                  required={customer.hasBillingAddress}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }
}