import {
  React,
  bind,
  Helpers,
  NumberFormat,
  NumberFormatValues
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  DeclaredValueApproval
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField
} from "$Imports/MaterialUIComponents";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

const styles: {
  modalContainer: string;
} = require("$Shared/styles/Modal.scss");

interface IEditDeclaredValueProps {
  isOpen: boolean;
  newRecord: DeclaredValueApproval;
  validationErrors: ValidationError | null;
  canSave: boolean;
  onSave: () => void;
  onCancel: () => void;
  onChange: (value: Partial<DeclaredValueApproval>) => void;
}

export class EditDeclaredValue extends React.PureComponent<IEditDeclaredValueProps> {

  @bind
  private _onSave() {
    this.props.onSave();
  }

  @bind
  private _onCancel() {
    this.props.onCancel();
  }

  @bind
  private _onValueChange(value: NumberFormatValues) {
    this.props.onChange({ declaredValue: !Helpers.isNullOrUndefined(value.floatValue) ? value.floatValue : undefined });
  }

  render() {
    const {
      isOpen,
      newRecord,
      validationErrors,
      canSave
    } = this.props;

    const validationsParser = new ValidationErrorParser<DeclaredValueApproval>(validationErrors);  

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>
          Edit Declared Value Approval Needed for {newRecord.company?.companyName}
        </DialogTitle>
        <DialogContent
          className={styles.modalContainer}
        >
          <NumberFormat
            required
            label="Declared Value"
            name="declaredValue"
            value={newRecord.declaredValue ?? ""}
            error={!validationsParser.isValid("declaredValue")}
            helperText={validationsParser.validationMessage("declaredValue")}
            prefix={"$"}
            thousandSeparator={","}
            decimalScale={0}
            fixedDecimalScale
            onValueChange={this._onValueChange}
            customInput={TextField}
            autoFocus
            onFocus={(e: React.FocusEvent<HTMLTextAreaElement>) => e.target.select()}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this._onSave}
            disabled={!canSave}
          >
            Save
          </Button>
          <Button
            onClick={this._onCancel}
            variant="outlined"
          >
            Cancel
          </Button>
          </DialogActions>
      </Dialog>
    );
  }
}
