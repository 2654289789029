import {
  React,
  Helpers,
  bind,
  _
} from "$Imports/Imports";

import {
  AdvanceTextField,
  AjaxActionIndicator
} from "$Imports/CommonComponents";

import {
  Question,
  Commodity,
  CommodityQuestion,
  QuestionQuestionTypeEnum
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  Switch,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SelectChangeEvent
} from "$Imports/MaterialUIComponents";

import {
  ModalState
} from "$State/QuestionFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

const styles: {
  modalContainer: string;
} = require("$Shared/styles/Modal.scss");

const gridStyles: {
  commoditiesGrid: string;
} = require("./UpchargeQuestions.scss");

interface IAddEditQuestionProps {
  modalState: ModalState;
  commodities: Commodity[];
  isFetching: boolean;
  onSave: () => void;
  onCancel: () => void;
  onChange: (change: Partial<Question>) => void;
  onChangeImmediateStart: (immediateStart: boolean) => void;
}

export class AddEditQuestion extends React.PureComponent<IAddEditQuestionProps> {

  @bind
  private _onTextChange(e: React.ChangeEvent<{ name: string; value: string; }>) {
    this.props.onChange({ questionText: e.target.value });
  }

  @bind
  private _onQuestionTypeChange(event: SelectChangeEvent, child: React.ReactNode) {
    this.props.onChange({ questionType: event.target.value as QuestionQuestionTypeEnum });
  }

  @bind
  private _onAnswerTypeChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.onChange({ isNa: checked });
  }

  @bind
  private _onIsActiveChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.onChange({isActive: checked})
  }

  private _onCommodityCheck = (value: number) => () => {
    const editedQuestion = this.props.modalState.editedQuestion;
    const editedCommodityQuestions: CommodityQuestion[] = editedQuestion?.commodityQuestions ?? []
    const commodityIndex = _.findIndex(editedCommodityQuestions, cQ => cQ.commodityId === value);

    const newChecked: CommodityQuestion[] = [...editedCommodityQuestions];

    if (commodityIndex === -1) {
      newChecked.push({questionId: editedQuestion?.id, commodityId: value});
    } else {
      newChecked.splice(commodityIndex, 1);
    }
    
    this.props.onChange({ commodityQuestions: newChecked });
  }

  render() {
    const {
      modalState,
      isFetching,
      commodities
    } = this.props;
    
    const validationsParser = new ValidationErrorParser<Question>(modalState.validationErrors);

    const commodityQuestions = this.props.modalState.editedQuestion?.commodityQuestions ?? [];

    return !Helpers.isNullOrUndefined(modalState.editedQuestion) ? (
      <>
        <Dialog
          open={modalState.formMode === "add" || modalState.formMode === "edit"}
          fullWidth={true}
          maxWidth="xs"
        >
          <AjaxActionIndicator
            showProgress={isFetching}
          />
          <DialogTitle>
            {modalState.formMode === "add" ? "Add Question" : "Edit Question"}
          </DialogTitle>
          <DialogContent
            className={styles.modalContainer}
          >
            <div style={{ padding: "6px" }}>
              <Grid container direction={"column"} spacing={3}>
                <Grid item>
                  <FormControlLabel
                      control={
                        (
                          <Switch
                            color="primary"
                            onChange={this._onIsActiveChange}
                            checked={modalState.editedQuestion?.isActive ?? true}
                          />
                        )
                      }
                      label="Active"
                      labelPlacement="start"
                      style={{ marginLeft: "0px" }}
                  />
                </Grid>
                <Grid item>
                  <AdvanceTextField
                    required
                    label="Question"
                    name="questionText"
                    value={modalState.editedQuestion?.questionText ?? ""}
                    onChange={this._onTextChange}
                    type="text"
                    fullWidth
                    error={modalState.formMode === "add" && !validationsParser.isValid("questionText")}
                    helperText={modalState.formMode === "add" && validationsParser.validationMessage("questionText")}
                  />
                </Grid>
                <Grid item>
                  <FormControl
                    error={!validationsParser.isValid("questionType")}
                  >
                    <InputLabel>Question Type *</InputLabel>
                    <Select
                      required
                      value={modalState.editedQuestion?.questionType !== undefined ? modalState.editedQuestion?.questionType : ""}
                      onChange={this._onQuestionTypeChange}
                      style={{ width: "195px" }}
                    >
                      <MenuItem value={"Commodity" as QuestionQuestionTypeEnum}>
                        Commodity
                      </MenuItem>
                      <MenuItem value={"Upcharge" as QuestionQuestionTypeEnum}>
                        Upcharge
                      </MenuItem>
                    </Select>
                    <FormHelperText>{validationsParser.validationMessage("questionType")}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl
                    error={!validationsParser.isValid("isNa")}
                  >
                    <FormControlLabel
                      label="Allow N/A response"
                      control={(
                        <Checkbox
                          checked={!!modalState.editedQuestion?.isNa}
                          onChange={this._onAnswerTypeChange}
                          name="isNa"
                        />
                      )}
                    />
                    <FormHelperText>{validationsParser.validationMessage("isNa")}</FormHelperText>
                  </FormControl>
                </Grid>
                {modalState.editedQuestion?.questionType === "Commodity" &&
                  <Grid item >
                    <div>
                      Assign to Commodities
                    </div>
                    <List className={gridStyles.commoditiesGrid}>
                      {commodities.length && _.map(commodities, (value: Commodity) => (
                        <ListItem key={value.id} button onClick={this._onCommodityCheck(value.id!)}>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={!!_.find(commodityQuestions, cQ => cQ.commodityId === value.id)}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': value.commodityName }}
                            />
                            <ListItemText id={value.commodityName} primary={value.commodityName} />
                          </ListItemIcon>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>}
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={isFetching}
              onClick={this.props.onSave}
            >
              Save
            </Button>
            <Button
              onClick={this.props.onCancel}
              disabled={isFetching}
              variant="outlined"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    ) : null;
  }
}