import {
  React,
  bind
} from "$Imports/Imports";

import {
  Customer,
} from "$Generated/api";

import {
  DialogActions,
  DialogContentText,
  DialogContent,
  Dialog,
  Button,
  DialogTitle
} from "$Imports/MaterialUIComponents";

interface IBillingConfirmationModalProps {
  isOpen: boolean;
  customer: Customer | undefined;
  disableButtons: boolean;
  onConfirmAcceptQuote: () => void;
  onCancel: () => void;
}

export class BillingConfirmationModal extends React.Component<IBillingConfirmationModalProps> {

  @bind
  private _onConfirm() {
    this.props.onConfirmAcceptQuote();
  }

  @bind
  private _onCancel(): void {
    this.props.onCancel();
  }

  render() {
    const {
      customer,
      disableButtons
    } = this.props;

    return (
      <Dialog
        open={this.props.isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>Billing Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The Bill-To customer for this quote ({customer?.customerName}) has not had a credit check.<br/>
            Do you want to proceed with accepting this quote?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this._onConfirm}
            disabled={disableButtons}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            onClick={this._onCancel}
            disabled={disableButtons}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
