import {
  React,
  bind,
  Helpers,
  NumberFormat,
  NumberFormatValues
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";
  
import {
  PerStopFee
} from "$Generated/api";
  
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField
} from "$Imports/MaterialUIComponents";
  
import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";
  
const styles: {
  modalContainer: string;
} = require("$Shared/styles/Modal.scss");

interface IEditPerStopFeeProps {
  isOpen: boolean;
  newRecord: PerStopFee;
  validationErrors: ValidationError | null;
  onSave: () => void;
  onCancel: () => void;
  onChange: (value: Partial<PerStopFee>) => void;
}

export class EditPerStopFee extends React.PureComponent<IEditPerStopFeeProps> {

  @bind
  private _onSave() {
    this.props.onSave();
  }

  @bind
  private _onCancel() {
    this.props.onCancel();
  }

  @bind
  private _onValueChange(value: NumberFormatValues) {
    this.props.onChange({ fee: !Helpers.isNullOrUndefined(value.floatValue) ? value.floatValue : undefined });
  }

  render() {
    const {
      isOpen,
      newRecord,
      validationErrors
    } = this.props;

    const validationsParser = new ValidationErrorParser<PerStopFee>(validationErrors);

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>
          Edit Per Stop Fee for {newRecord.company?.companyName}
        </DialogTitle>
        <DialogContent
          className={styles.modalContainer}
        >
          <NumberFormat
            required
            label="Per Stop Fee"
            name="fee"
            value={newRecord.fee ?? ""}
            error={!validationsParser.isValid("fee")}
            helperText={validationsParser.validationMessage("fee")}
            prefix={"$"}
            thousandSeparator={","}
            decimalScale={0}
            fixedDecimalScale
            onValueChange={this._onValueChange}
            customInput={TextField}
            autoFocus
            onFocus={(e: React.FocusEvent<HTMLTextAreaElement> ) => e.target.select()}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this._onSave}
          >
            Save
          </Button>
          <Button
            onClick={this._onCancel}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}