// extracted by mini-css-extract-plugin
var _1 = "ProspectDetailView__address__Nyf88";
var _2 = "ProspectDetailView__column__bn0NN";
var _3 = "ProspectDetailView__fieldLabel__X57Cb";
var _4 = "ProspectDetailView__fieldLabelOne__Xa3Zj";
var _5 = "ProspectDetailView__fieldLabelThree__XO5WB";
var _6 = "ProspectDetailView__fieldLabelTwo__W8cLu";
var _7 = "ProspectDetailView__fieldPair__Z8BOM";
var _8 = "ProspectDetailView__fieldPairIcon__oa3rJ";
var _9 = "ProspectDetailView__fieldValue__yrCho";
var _a = "ProspectDetailView__icon__mHCh_";
var _b = "ProspectDetailView__infoHeader__JNPbN";
var _c = "ProspectDetailView__infoIcon__KalnO";
var _d = "ProspectDetailView__mainContainer__XcVqy";
var _e = "ProspectDetailView__row__MoQIC";
var _f = "ProspectDetailView__rowColumn__Vl_PJ";
var _10 = "ProspectDetailView__website__rOwB0";
export { _1 as "address", _2 as "column", _3 as "fieldLabel", _4 as "fieldLabelOne", _5 as "fieldLabelThree", _6 as "fieldLabelTwo", _7 as "fieldPair", _8 as "fieldPairIcon", _9 as "fieldValue", _a as "icon", _b as "infoHeader", _c as "infoIcon", _d as "mainContainer", _e as "row", _f as "rowColumn", _10 as "website" }
