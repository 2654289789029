import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf
} from "$Shared/imports/Yup";

import {
  EquipmentType,
  EquipmentApiFactory,
  OverdimensionalRuleset
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

interface IEquipmentTypeServiceState {
  equipmentTypeFetchResults: IAjaxState<EquipmentType[]>;
  activeEquipmentTypes: EquipmentType[];
  equipmentTypeSaveResults: IAjaxState<EquipmentType>;
  overdimensionalRulesetFetchResults: IAjaxState<OverdimensionalRuleset[]>;
}

const InjectedPropName = "equipmentTypeService";

export const EMPTY_EQUIPMENT_TYPE: EquipmentType = {
  id: 0,
  overdimensionalRulesetId: undefined,
  isActive: true
};

export const EquipmentTypeValidationSchema: SchemaOf<NullableOptional<EquipmentType>> = yup.object({
  id: yup.number().notRequired(),
  name: yup.string()
    .required("Name is required")
    .max(100, "Name cannot be longer than 100 characters"),
  tmEquipmentCode: yup.string()
    .required("Equipment code is required")
    .max(10, "Equipment code cannot be longer than 10 characters"),
  overdimensionalRulesetId: yup.number()
    .required("Over-Dimensional Ruleset is required"),
  isActive: yup.boolean().notRequired(), // technically it is required but it's a boolean
  createdOn: yup.date().notRequired(),
  modifiedOn: yup.date().notRequired(),
  equipmentTypeValues: yup.array().notRequired(),
  quotes: yup.array().notRequired(),
  customerQuotes: yup.array().notRequired()
});

const initialState = {
  equipmentTypeFetchResults: managedAjaxUtil.createInitialState(),
  activeEquipmentTypes: [],
  equipmentTypeSaveResults: managedAjaxUtil.createInitialState(),
  overdimensionalRulesetFetchResults: managedAjaxUtil.createInitialState()
} as IEquipmentTypeServiceState;

class EquipmentTypeFreezerService extends FreezerService<IEquipmentTypeServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchEquipmentTypes(includeInactive: boolean = false, forceUpdate: boolean = false) {
    const {
      equipmentTypeFetchResults
    } = this.freezer.get();

    if (equipmentTypeFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "equipmentTypeFetchResults",
      params: {
        activeOnly: !includeInactive
      },
      onExecute: (apiOptions, params, options) => {
        const factory = EquipmentApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getEquipmentTypes(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch equipment types.");
      },
      onOk: (data: EquipmentType[]) => {
        var activeEquipmentTypes = data.filter(e => e.isActive);
        activeEquipmentTypes = _.sortBy(activeEquipmentTypes, e => e.name?.toLowerCase());
        this.freezer.get().set({ activeEquipmentTypes });
      }
    });
  }

  public saveEquipmentType(equipmentType: EquipmentType) {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "equipmentTypeSaveResults",
      params: {
        body: equipmentType
      },
      onExecute: (apiOptions, params, options) => {
        const factory = EquipmentApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);

        if (equipmentType.id) {
          return factory.updateEquipmentType(params);
        } else {
          return factory.addEquipmentType(params);
        }
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? "Failed to save equipment type.");
      }
    });
  }

  public fetchOverdimensionalRulesets() {
    const {
      overdimensionalRulesetFetchResults
    } = this.freezer.get();

    if (overdimensionalRulesetFetchResults.hasFetched) {
      return;
    }

    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "overdimensionalRulesetFetchResults",
      onExecute: (apiOptions, params, options) => {
        const factory = EquipmentApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getOverdimensionalRulesets(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch overdimensional rulesets.");
      }
    });
  }
}

export const EquipmentTypeService = new EquipmentTypeFreezerService();
export type IEquipmentTypeServiceInjectedProps = ReturnType<EquipmentTypeFreezerService["getPropsForInjection"]>;
