import {
  React,
  _
} from "$Imports/Imports";

import {
  ProspectDetailView
} from "./ProspectDetailView";

import { SelectedCompanyConsumer } from "$Providers/CompanyProvider";

interface IProspectDetailViewPageProps {
  customerId: number | undefined;
}

export class ProspectDetailViewPage extends React.Component<IProspectDetailViewPageProps> {
  render() {
    return (
      <SelectedCompanyConsumer>
        {(companyContext) => { 
          return (
            <ProspectDetailView companyId={companyContext.companyId} customerId={this.props.customerId}/>
          );
        }}
      </SelectedCompanyConsumer>
    );
  }
}