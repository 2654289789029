import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  Activity
} from "$Generated/api";

import {
  ConfirmDeleteModal,
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  Delete,
  Edit
} from "$Imports/MaterialUIIcons";

interface IActionMenuProps {
  note: Activity;
  onEditClick: (note: Activity) => void;
  onDeleteClick?: (note: Activity) => void;
}

interface IActionMenuState {
  deleteModalIsOpen: boolean;
}

export class ActionMenu extends React.Component<IActionMenuProps, IActionMenuState> {

  state: IActionMenuState = {
    deleteModalIsOpen: false
  }

  @bind
  private _onEditClick() {
    this.props.onEditClick(this.props.note);
  }

  @bind
  private _onDelete() {
    if (this.props.onDeleteClick) {
      this.props.onDeleteClick(this.props.note);
      this._onDeleteCancel();
    }
  }

  @bind
  private _onDeleteClick() {
    this.setState({
      deleteModalIsOpen: true
    });
  }

  @bind
  private _onDeleteCancel() {
    this.setState({
      deleteModalIsOpen: false
    });
  }

  render() {
    return (
      <>
        <UserAccessControl roles={["note:edit"]}>
          <IconButton size="small" onClick={this._onEditClick}>
            <Edit />
          </IconButton>
        </UserAccessControl>

        {this.props.onDeleteClick &&
          <UserAccessControl roles={["note:delete"]}>
            <IconButton size="small" onClick={this._onDeleteClick}>
              <Delete />
            </IconButton>
            <ConfirmDeleteModal
              isOpen={this.state.deleteModalIsOpen}
              onDelete={this._onDelete}
              onDeleteCancel={this._onDeleteCancel}
              deleteMessage={"Delete this note?"}
            />
          </UserAccessControl>
        }
      </>
    );
  }
}