import {
  React
} from "$Imports/Imports";

import {
  ActivityActivityTypeEnum,
  AuditCustomerAuditLog
} from "$Generated/api";

import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams
} from "$Imports/MaterialUIComponents";

import {
  CardLinedHeader,
  AjaxActionIndicator,
  DisplayFormattedDatetime,
  QuoteLink,
  TextCellTruncated
} from "$Imports/CommonComponents";

import {
  DATE_WITH_TIME_MERIDIAN_FORMAT
} from "$Shared/utilities/formatUtil";

import { activityTypeTextMap } from "$Utilities/enumUtil";

interface IAuditLogCardProps {
  auditLogs: AuditCustomerAuditLog[];
  isFetching: boolean;
}

interface IAuditLogCardState {
  detailAuditLog: AuditCustomerAuditLog | undefined;
  showDetails: boolean;
}

export class AuditLogCard extends React.Component<IAuditLogCardProps, IAuditLogCardState> {

  state: IAuditLogCardState = {
    detailAuditLog: undefined,
    showDetails: false
  }

  private readonly columns: GridColDef[] = [
    {
      headerName: "Date / Time",
      field: "datetime",
      flex: 1,
      valueGetter: (params: GridValueGetterParams<Date | undefined, AuditCustomerAuditLog>) => {
        return params.row.createdOn;
      },
      renderCell: (params: GridRenderCellParams<any, AuditCustomerAuditLog>) =>
        <TextCellTruncated text={<DisplayFormattedDatetime value={params.value} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} />} />
    },
    {
      headerName: "User",
      field: "user",
      flex: 1,
      valueGetter: (params: GridValueGetterParams<string, AuditCustomerAuditLog>) => {
        return params.row.employee?.firstName + " " + params.row.employee?.lastName;
      },
      renderCell: (params: GridRenderCellParams<string>) =>
        params.value && <TextCellTruncated text={params.value} />
    },
    {
      headerName: "Element",
      field: "element",
      flex: 1,
      valueGetter: (params: GridValueGetterParams<string, AuditCustomerAuditLog>) => {
        return params.row.log?.element;
      },
      renderCell: (params: GridRenderCellParams<string>) =>
        params.value && <TextCellTruncated text={params.value} />
    },
    {
      headerName: "Event Type",
      field: "eventType",
      flex: 1,
      valueGetter: (params: GridValueGetterParams<string, AuditCustomerAuditLog>) => {
        return params.row.log?.eventType;
      },
      renderCell: (params: GridRenderCellParams<string>) =>
        params.value && <TextCellTruncated text={params.value} />
    },
    {
      headerName: "Action",
      field: "activity",
      flex: 2,
      valueGetter: (params: GridValueGetterParams<string, AuditCustomerAuditLog>) => {
        let activity = params.row.log?.activity;
        if (params.row.log?.element === "Contacts" || params.row.log?.element === "Sales Opportunities" || params.row.log?.element === "Activity") {
          if (params.row.log.eventType === "Edit") {            
            const fieldChanges = params.row.log?.fieldChanges;
            const field = fieldChanges && fieldChanges.length > 0 ? fieldChanges[0].dataField : undefined;
            if (field) {
              activity += ` (field: ${field})`;
            }
          }
        }
        return activity;
      },
      renderCell: (params: GridRenderCellParams<string, AuditCustomerAuditLog>) => {
        let content: string | JSX.Element | undefined = params.value;
        let tooltip: string | JSX.Element | undefined = params.value;

        if (params.row.log?.element === "Quotes") {
          if (params.row.log.eventType === "Add") {
            // New quote *** created
            content = <>New quote&nbsp;<QuoteLink quoteId={params.row.log.recordId} quoteNumber={`Q${params.row.log.recordName}`} />&nbsp;created</>;
            tooltip = <>New quote&nbsp;<b>Q{params.row.log.recordName}</b>&nbsp;created</>;
          }
          else if (params.row.log.eventType === "Edit") {
            // Quote *** status changed
            content = <>Quote&nbsp;{<QuoteLink quoteId={params.row.log.recordId} quoteNumber={`Q${params.row.log.recordName}`} />}&nbsp;status changed</>;
            tooltip = <>Quote&nbsp;<b>Q{params.row.log.recordName}</b>&nbsp;status changed</>
          }
        }

        return content && <TextCellTruncated text={content} tooltip={tooltip} />;
      }
    },
    {
      headerName: "Previous Value",
      field: "previousValue",
      flex: 1,
      valueGetter: (params: GridValueGetterParams<any, AuditCustomerAuditLog>) => {
        if (params.row.log?.eventType === "Edit") {
          const fieldChanges = params.row.log?.fieldChanges;
          return fieldChanges && fieldChanges.length > 0
            ? fieldChanges[0].dataField === "Activity Type" ? activityTypeTextMap[fieldChanges[0].previousValue as ActivityActivityTypeEnum]
            : fieldChanges[0].previousValue : "";
        }
        return "";
      },
      renderCell: (params: GridRenderCellParams<any, AuditCustomerAuditLog>) => {
        const fieldChanges = params.row.log?.fieldChanges;
        return fieldChanges && fieldChanges.length > 0 && fieldChanges[0].dataField === "Activity Date/Time"
          ? <TextCellTruncated text={<DisplayFormattedDatetime value={new Date(params.value)} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} />} />
          : <TextCellTruncated text={params.value} />;
      }
    },
    {
      headerName: "New Value",
      field: "newValue",
      flex: 1,
      valueGetter: (params: GridValueGetterParams<any, AuditCustomerAuditLog>) => {
        if (params.row.log?.eventType === "Edit") {
          const fieldChanges = params.row.log?.fieldChanges;
          return fieldChanges && fieldChanges.length > 0
            ? fieldChanges[0].dataField === "Activity Type" ? activityTypeTextMap[fieldChanges[0].newValue as ActivityActivityTypeEnum]
            : fieldChanges[0].newValue : "";
        }
        return "";
      },
      renderCell: (params: GridRenderCellParams<any, AuditCustomerAuditLog>) => {
        const fieldChanges = params.row.log?.fieldChanges;
        return fieldChanges && fieldChanges.length > 0 && fieldChanges[0].dataField === "Activity Date/Time"
          ? <TextCellTruncated text={<DisplayFormattedDatetime value={new Date(params.value)} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} />} />
          : <TextCellTruncated text={params.value} />;
      }
    }
  ];

  render() {
    const {
      auditLogs,
      isFetching
    } = this.props;

    let rowIdCounter = 0;

    return (
      <CardLinedHeader titleText="Audit Log">
        <AjaxActionIndicator showProgress={isFetching} />
        <div style={{ height: "250px" }}>
          <DataGridPro
            rows={auditLogs}
            columns={this.columns}
            getRowId={(row) => {
              rowIdCounter++;
              return rowIdCounter;
            }}
            density="compact"
            hideFooter
            disableSelectionOnClick
            initialState={{
              sorting: {
                sortModel: [{ field: "datetime", sort: "desc" }]
              }
            }}
          />
        </div>
      </CardLinedHeader>
    )
  }
}