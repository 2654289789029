import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from "$Imports/MaterialUIComponents";

import {
  CustomerSource
} from "$Generated/api";

interface IAddSourceDetailModalProps {
  isOpen: boolean;
  customerSource?: CustomerSource;
  sourceDetails?: string;
  onSave: (sourceDetails: string) => void;
}

interface IAddSourceDetailModalState {
  sourceDetails: string | undefined;
  errorMsg: string | undefined;
}

export class AddSourceDetailModal extends React.Component<IAddSourceDetailModalProps, IAddSourceDetailModalState> {
  state: IAddSourceDetailModalState = {
    sourceDetails: this.props.sourceDetails,
    errorMsg: undefined
  };

  @bind
  private _onSave() {
    const { sourceDetails } = this.state;
    if (sourceDetails && sourceDetails.length > 0) {
      this.props.onSave(sourceDetails);
    }

    this.setState({
      sourceDetails: undefined,
      errorMsg: !sourceDetails || sourceDetails?.length === 0 ? "Details are required" : undefined
    });
  }

  @bind
  private _onTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ sourceDetails: e.target.value });
  }

  render() {
    const {
      isOpen,
      customerSource
    } = this.props;
    const {
      sourceDetails,
      errorMsg
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>Add Source Detail</DialogTitle>
        <DialogContent>
          <AdvanceTextField
            label={`${customerSource?.name} Details`}
            value={sourceDetails ?? ""}
            onChange={this._onTextChange}
            error={!!errorMsg}
            helperText={errorMsg}
            inputProps={{ maxLength: 50 }}
            multiline
            fullWidth
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this._onSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}