// extracted by mini-css-extract-plugin
var _1 = "CustomerDetailView__address__VhQeY";
var _2 = "CustomerDetailView__announcement__LYUj5";
var _3 = "CustomerDetailView__column__mcx_A";
var _4 = "CustomerDetailView__fieldBlock__l7eGu";
var _5 = "CustomerDetailView__fieldLabel__GoatZ";
var _6 = "CustomerDetailView__fieldPair__ySY9R";
var _7 = "CustomerDetailView__fieldValue__MPZ5u";
var _8 = "CustomerDetailView__icon__lONIT";
var _9 = "CustomerDetailView__infoContainer__c2KRZ";
var _a = "CustomerDetailView__infoHeader__OKzrd";
var _b = "CustomerDetailView__infoIcon___NL3k";
var _c = "CustomerDetailView__labelValueIconPair__nbWAc";
var _d = "CustomerDetailView__labelValuePair__LEvdV";
var _e = "CustomerDetailView__mainContainer__eaJQc";
var _f = "CustomerDetailView__subcolumn__uykm8";
export { _1 as "address", _2 as "announcement", _3 as "column", _4 as "fieldBlock", _5 as "fieldLabel", _6 as "fieldPair", _7 as "fieldValue", _8 as "icon", _9 as "infoContainer", _a as "infoHeader", _b as "infoIcon", _c as "labelValueIconPair", _d as "labelValuePair", _e as "mainContainer", _f as "subcolumn" }
