import {
  React,
  _,
  User
} from "$Shared/imports/Imports";

import {
  SvgIconProps
} from "$Shared/imports/MaterialUIStyles";

import {
  SalesPortalViewPage
} from "$Pages/SalesPortalView";

import {
  SalesRepHomeViewPage
} from "$Pages/SalesRepHomeView";

import {
  RateModelViewPage
} from "$Pages/RateModelView";

import {
  CapacityModelViewPage
} from "$Pages/CapacityModelView";

import {
  TarpRatesViewPage
} from "$Pages/TarpRatesView";

import {
  CommodityRatesViewPage
} from "$Pages/CommodityRatesView";

import {
  UpchargeQuestionsViewPage
} from "$Pages/UpchargeQuestionsView";

import {
  CommodityLookupViewPage
} from "$Pages/CommodityLookupView";

import {
  AccessorialChargeViewPage
} from "$Pages/AccessorialChargeView";

import {
  LoadingInstructionViewPage
} from "$Pages/LoadingInstructionView";

import {
  CustomerSourceViewPage
} from "$Pages/CustomerSourceView";

import {
  AuditViewPage
} from "$Pages/AuditView";

import {
  RateTesterViewPage
} from "$Pages/RateTesterView";

import {
  TerminalsViewPage
} from "$Pages/TerminalsView"

import {
  ZoneViewPage
} from "$Pages/ZoneView";

import {
  ZoneConfigurationViewPage
} from "$Pages/ZoneConfigurationView";

import {
  QuotesViewPage,
  QuickQuotesViewPage
} from "$Pages/QuotesView";

import {
  FuelSurchargeViewPage
} from "$Pages/FuelSurchargeView";

import {
  AutomaticUpchargesViewPage
} from "$Pages/AutomaticUpchargesView"

import {
  CustomersViewPage
} from "$Pages/CustomersView";

import {
  ProspectsViewPage
} from "$Pages/ProspectsView";

import {
  CustomerDetailViewPage
} from "$Pages/CustomerDetailViewPage";

import {
  ProspectDetailViewPage
} from "$Pages/ProspectDetailViewPage";

import {
  ConvertCustomerQuoteViewPage
} from "$Pages/ConvertCustomerQuoteViewPage";

import {
  LogisticsMarkupViewPage
} from "$Pages/LogisticsMarkupView";

import {
  DeclaredValueConfigViewPage
} from "$Pages/DeclaredValueConfigView";

import {
  PerStopFeeConfigViewPage
} from "$Pages/PerStopFeeConfigView";

import {
  EquipmentTypeViewPage
} from "$Pages/EquipmentTypeView";

import {
  TarpViewPage
} from "$Pages/TarpsView"

import {
  ApplicationSettingsViewPage
} from "$Pages/ApplicationSettingsView";

import {
  ISelectedCompanyContext
} from "$Providers/CompanyProvider";

import {
  CustomerQuotesListViewPage
} from "$Pages/CustomerQuotesListView";

import {
  CustomerQuoteViewPage
} from "$Pages/CustomerQuoteView";

import {
  EquipmentRatesViewPage
} from "$Pages/EquipmentRatesView";

import {
  LeadSourceViewPage
} from "$Pages/LeadSourceView";

import {
  DatAuditReportViewPage
} from "$Pages/DatAuditReportView";

import {
  AuditRateEngineViewPage
} from "$Pages/AuditRateEngineView";

import {
  CarrierManagementPage
} from "$Pages/CarrierManagementPage";

import {
  PrintQuoteView
} from "$Pages/PrintQuoteView";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings"

interface IMatchProps<P> {
  match?: {
    params?: P;
  };
}

export interface INavigationItem {
  url: string;
  redirectUrls?: string[];
  label: string;
  externalLink: boolean;
  enabled: boolean | (<T = User>(navItem: INavigationItem, securityContext: T) => boolean);
  hasAccess?: boolean | ((navItem: INavigationItem, securityContext: User | null, companyContext: ISelectedCompanyContext) => boolean);
  childNavigation?: INavigationItem[];
  icon?: React.ComponentType<SvgIconProps>;
  component?: React.ComponentClass | React.FunctionComponent;
  title?: string;
  isRoot: boolean;
  rootUrl: string;
  rootUrlLabel?: string;
  rolesToAccess?: string[];
  companiesToAccess?: string[];
  hideMainLayout?: boolean;
}

export interface ISideNavigationItem extends INavigationItem {
  rootUrlPageUrl: string;
}

function hasAccessByContext(navItem: INavigationItem, securityContext: User | null, companyContext: ISelectedCompanyContext): boolean {
  let canAccess = false;
  const roles = SharedSecurityContext.getRoles(securityContext);

  // any match, doesn't need to match every role
  if (roles && navItem.rolesToAccess) {
    var overlap = _.intersection(navItem.rolesToAccess, roles);
    canAccess = overlap.length > 0;
  }

  if (companyContext.companyKey && navItem.companiesToAccess) {
    canAccess = canAccess && _.includes(navItem.companiesToAccess, companyContext.companyKey);
  }

  return canAccess;
}

const salesPortalChildren: INavigationItem[] = [
  {
    url: "/salesportal",
    label: "SalesPortal",
    externalLink: false,
    component: SalesPortalViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["salesportal:page"],
    enabled: true,
    isRoot: false,
    rootUrl: "/quotes"
  },
  {
    url: "/salesportal/quote",
    label: "Quotes",
    externalLink: false,
    component: SalesPortalViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["salesportal:page"],
    enabled: true,
    isRoot: false,
    rootUrl: "/quotes"
  },
  {
    url: "/customer/:customerId",
    label: "Customer",
    externalLink: false,
    component: (props: React.PropsWithChildren<IMatchProps<{ customerId: number | undefined }>>) => {
      const componentProps: { customerId: number | undefined } = props.match && props.match.params ? props.match.params : { customerId: undefined };
      return React.createElement(CustomerDetailViewPage, componentProps);
    },
    hasAccess: hasAccessByContext,
    rolesToAccess: ["customer:page"],
    enabled: true,
    isRoot: false,
    rootUrl: "/customers"
  },
  {
    url: "/customer",
    label: "Customer",
    externalLink: false,
    component: (props: React.PropsWithChildren<IMatchProps<{ customerId: number | undefined }>>) => {
      const componentProps: { customerId: number | undefined } = props.match && props.match.params ? props.match.params : { customerId: undefined };
      return React.createElement(CustomerDetailViewPage, componentProps);
    },
    hasAccess: hasAccessByContext,
    rolesToAccess: ["customer:page"],
    enabled: true,
    isRoot: false,
    rootUrl: "/customers"
  },
  {
    url: "/prospect/:customerId",
    label: "Prospect",
    externalLink: false,
    component: (props: React.PropsWithChildren<IMatchProps<{ customerId: number | undefined }>>) => {
      const componentProps: { customerId: number | undefined } = props.match && props.match.params ? props.match.params : { customerId: undefined };
      return React.createElement(ProspectDetailViewPage, componentProps);
    },
    hasAccess: hasAccessByContext,
    rolesToAccess: ["prospects:page"],
    enabled: true,
    isRoot: false,
    rootUrl: "/customers"
  },
]

export const mainNavigation: INavigationItem[] = [
  {
    redirectUrls: ["/"],
    url: "/home",
    label: "Home",
    externalLink: false,
    component: SalesRepHomeViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["salesrep-home:page"],
    enabled: true,
    isRoot: true,
    rootUrl: "/home", // sure would be nice if root nav and side nav items were different things. Maybe with some nested structure?
    rootUrlLabel: "Home"
  },
  {
    url: "/quotes",
    label: "Full Quotes",
    externalLink: false,
    component: QuotesViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["quotes:page"],
    enabled: true,
    isRoot: true,
    rootUrl: "/quotes" || "/quickquotes" || "/portalquotes",
    rootUrlLabel: "Quotes"
  },
  {
    url: "/quickquotes",
    label: "Quick Quotes",
    externalLink: false,
    component: QuickQuotesViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["quick-quotes:page"],
    enabled: true,
    isRoot: false,
    rootUrl: "/quotes" || "/quickquotes" || "/portalquotes"
  },
  {
    url: "/portalquotes",
    label: "Portal Quotes",
    externalLink: false,
    component: CustomerQuotesListViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["portal-quotes:page"],
    enabled: true,
    isRoot: false,
    rootUrl: "/quotes" || "/quickquotes" || "/portalquotes"
  },
  {
    url: "/customers",
    label: "Customer Search",
    externalLink: false,
    component: CustomersViewPage,
    childNavigation: salesPortalChildren,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["customers:page"],
    enabled: true,
    isRoot: true,
    rootUrl: "/customers" || "/prospects",
    rootUrlLabel: "CRM"
  },
  {
    url: "/prospects",
    label: "Prospect Search",
    externalLink: false,
    component: ProspectsViewPage,
    childNavigation: salesPortalChildren,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["prospects:page"],
    enabled: true,
    isRoot: false,
    rootUrl: "/customers" || "/prospects",
    rootUrlLabel: "CRM"
  },
  {
    redirectUrls: ["/"],
    url: "/carrier_management",
    label: "Carrier Board",
    externalLink: false,
    component: CarrierManagementPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["quote-carrier:edit"],
    companiesToAccess: ["KL"],
    enabled: true,
    isRoot: true,
    rootUrl: "/carrier_management",
    rootUrlLabel: "Carrier Management",
    childNavigation: [
      {
        url: "/salesportal/quote/:quoteId/:viewOnly",
        label: "SalesPortal",
        externalLink: false,
        component: (props: React.PropsWithChildren<IMatchProps<{ quoteId: number | undefined, viewOnly: boolean | undefined}>>) => {
          const componentProps: { quoteId: number | undefined, viewOnly: boolean | undefined } = props.match && props.match.params
            ? { quoteId: props.match.params.quoteId, viewOnly: true }
            : { quoteId: undefined, viewOnly: true };
          return React.createElement(SalesPortalViewPage, componentProps);
        },
        hasAccess: hasAccessByContext,
        rolesToAccess: ["salesportal:page"],
        enabled: true,
        isRoot: false,
        rootUrl: "/carrier_management"
      },
    ]
  },
  {
    url: "/customerQuoteConvert/:quoteId",
    label: "Customer Quote Convert",
    externalLink: false,
    component: (props: React.PropsWithChildren<IMatchProps<{ quoteId: string }>>) => {
      const componentProps: { quoteId: string } = props.match && props.match.params ? props.match.params : { quoteId: "" };
      return React.createElement(ConvertCustomerQuoteViewPage, componentProps);
    },
    hasAccess: hasAccessByContext,
    rolesToAccess: ["customer-quote:convert"],
    enabled: true,
    isRoot: false,
    rootUrl: "/customerQuote",
    hideMainLayout: true
  },
  {
    url: "/customerQuote/:quoteId",
    label: "Customer Quote",
    externalLink: false,
    component: (props: React.PropsWithChildren<IMatchProps<{ quoteId: string }>>) => {
      const componentProps: { quoteId: string } = props.match && props.match.params ? props.match.params : { quoteId: "" };
      return React.createElement(CustomerQuoteViewPage, componentProps);
    },
    hasAccess: true,
    enabled: true,
    isRoot: true,
    rootUrl: "/customerQuote",
    hideMainLayout: true
  },
  // {
  //   url: "/report",
  //   label: "Quotes by SalesRep",
  //   externalLink: false,
  //   component: SalesRepViewPage,
  //   hasAccess: true,
  //   enabled: true,
  //   isRoot: true,
  //   rootUrl: "/report",
  //   rootUrlLabel: "Reports"
  // },
  {
    url: "/rate_engine",
    label: "Rate Model",
    externalLink: false,
    component: RateModelViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["rate-model:view", "capacity-model:view", "commodity-rate:view", "tarp-rate:view", "rate-tester:view", "zone-rate:view"],
    enabled: true,
    isRoot: true,
    rootUrl: "/rate_engine",
    rootUrlLabel: "Rate Engine"
  },
  // {
  //   url: "/company_quotes",
  //   label: "Quotes by Company",
  //   externalLink: false,
  //   component: CompanyQuotesViewPage,
  //   hasAccess: true,
  //   enabled: true,
  //   isRoot: false,
  //   rootUrl: "/report"
  // },
  {
    url: "/capacity_model",
    label: "Capacity Model",
    externalLink: false,
    component: CapacityModelViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["capacity-model:view"],
    enabled: true,
    isRoot: false,
    rootUrl: "/rate_engine"
  },
  {
    url: "/tarp_rates",
    label: "Tarp Rates",
    externalLink: false,
    component: TarpRatesViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["tarp-rate:view"],
    enabled: true,
    isRoot: false,
    rootUrl: "/rate_engine"
  },
  // {
  //   url: "/company_config",
  //   label: "Company",
  //   externalLink: false,
  //   component: CompanyConfigViewPage,
  //   hasAccess: true,
  //   enabled: true,
  //   isRoot: false,
  //   rootUrl: "/rate_engine"
  // },
  {
    url: "/commodity_rate",
    label: "Commodity Rates",
    externalLink: false,
    component: CommodityRatesViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["commodity-rate:view"],
    enabled: true,
    isRoot: false,
    rootUrl: "/rate_engine"
  },
  {
    url: "/zone_config",
    label: "Zone Rates",
    externalLink: false,
    component: ZoneConfigurationViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["zone-rate:view"],
    enabled: true,
    isRoot: false,
    rootUrl: "/rate_engine",
  },
  {
    url: "/admin",
    label: "Application Settings",
    externalLink: false,
    component: ApplicationSettingsViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["application-settings:edit", "questions:edit", "zone-setup:edit", "commodity-lookup:edit", "lead-source:edit", "yahara:dev"],
    enabled: true,
    isRoot: true,
    rootUrl: "/admin",
    rootUrlLabel: "Admin"
  },
  {
    url: "/declared_value",
    label: "Declared Value Approval",
    externalLink: false,
    component: DeclaredValueConfigViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["declared-value:create"],
    enabled: true,
    isRoot: false,
    rootUrl: "/admin"
  },
  {
    url: "/per_stop_fee",
    label: "Per Stop Fee",
    externalLink: false,
    component: PerStopFeeConfigViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["per-stop-fee:create"],
    enabled: true,
    isRoot: false,
    rootUrl: "/rate_engine"
  },
  {
    url: "/equipmentRates",
    label: "Equipment Rates",
    externalLink: false,
    component: EquipmentRatesViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["equipment-rates:edit"],
    enabled: true,
    isRoot: false,
    rootUrl: "/rate_engine"
  },
  {
    url: "/fuelSurcharge",
    label: "Fuel Surcharge",
    externalLink: false,
    component: FuelSurchargeViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["fuel-surcharge-setup:edit"],
    enabled: true,
    isRoot: false,
    rootUrl: "/rate_engine",
    rootUrlLabel: "Fuel Surcharge" //Admin
  },
  {
    url: "/automaticUpcharges",
    label: "Automatic Upcharges",
    externalLink: false,
    component: AutomaticUpchargesViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["automatic-upcharge:view"],
    enabled: true,
    isRoot: false,
    rootUrl: "/rate_engine",
    rootUrlLabel: "Automatic Upcharges" //Admin
  },
  {
    url: "/equipmentTypes",
    label: "Equipment",
    externalLink: false,
    component: EquipmentTypeViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["equipment-types:edit"],
    enabled: true,
    isRoot: false,
    rootUrl: "/admin"
  },
  {
    url: "/tarps",
    label: "Tarps",
    externalLink: false,
    component: TarpViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["tarps:edit"],
    enabled: true,
    isRoot: false,
    rootUrl: "/admin"
  },
  {
    url: "/questions",
    label: "Questions",
    externalLink: false,
    component: UpchargeQuestionsViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["questions:edit"],
    enabled: true,
    isRoot: false,
    rootUrl: "/admin"
  },
  {
    url: "/zone",
    label: "Zone Setup",
    externalLink: false,
    component: ZoneViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["zone-setup:edit"],
    enabled: true,
    isRoot: false,
    rootUrl: "/admin"
  },
  {
    url: "/terminals",
    label: "Terminals Setup",
    externalLink: false,
    component: TerminalsViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["terminals-setup:edit"],
    enabled: true,
    isRoot: false,
    rootUrl: "/admin"
  },
  {
    url: "/audit",
    label: "Login History",
    externalLink: false,
    component:   AuditViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["audit:view"],
    enabled: true,
    isRoot: false,
    rootUrl: "/admin"
  },
  {
    url: "/commodity_lookup",
    label: "Commodity Lookup",
    externalLink: false,
    component: CommodityLookupViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["commodity-lookup:edit"],
    enabled: true,
    isRoot: false,
    rootUrl: "/admin",
  },
  {
    url: "/accessorial_charges",
    label: "Accessorial Charges",
    externalLink: false,
    component: AccessorialChargeViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["accessorial-charge:edit"],
    enabled: true,
    isRoot: false,
    rootUrl: "/admin",
  },
  {
    url: "/loading_instructions",
    label: "Loading Instructions",
    externalLink: false,
    component: LoadingInstructionViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["loading-instruction:edit"],
    enabled: true,
    isRoot: false,
    rootUrl: "/admin",
  },
  {
    url: "/customer_sources",
    label: "Customer Sources",
    externalLink: false,
    component: CustomerSourceViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["customer-source:edit"],
    enabled: true,
    isRoot: false,
    rootUrl: "/admin",
  },
  {
    url: "/logistics_markup",
    label: "Logistics Markup",
    externalLink: false,
    component: LogisticsMarkupViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["logistics-markup:create"],
    companiesToAccess: ["KL"],
    enabled: true,
    isRoot: false,
    rootUrl: "/rate_engine",
  },
  {
    url: "/rate_tester",
    label: "Rate Tester",
    externalLink: false,
    component: RateTesterViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["rate-tester:view"],
    enabled: true,
    isRoot: false,
    rootUrl: "/rate_engine"
  },
  {
    url: "/dat_audit",
    label: "DAT Audit",
    externalLink: false,
    component: DatAuditReportViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["dat-audit:view"],
    enabled: true,
    isRoot: false,
    rootUrl: "/rate_engine"
  },
  {
    url: "/lead_sources",
    label: "Opportunity Lead Sources",
    externalLink: false,
    component: LeadSourceViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["lead-source:edit"],
    enabled: true,
    isRoot: false,
    rootUrl: "/admin"
  },
  {
    url: "/rate_engine_audit",
    label: "Rate Engine Audit Log",
    externalLink: false,
    component: AuditRateEngineViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["rate-engine-audit:view"],
    enabled: true,
    isRoot: false,
    rootUrl: "/admin"
  },
  {
    url: "/printQuote/:quoteId",
    label: "Print Quotes",
    externalLink: false,
    component: (props: React.PropsWithChildren<IMatchProps<{ quoteId: string }>>) => {
      const componentProps: { quoteId: string } = props.match && props.match.params ? props.match.params : { quoteId: "" };
      return React.createElement(PrintQuoteView, componentProps);
    },
    hasAccess: hasAccessByContext,
    rolesToAccess: ["quote:print"],
    enabled: true,
    isRoot: false,
    rootUrl: "/printQuote",
    hideMainLayout: true
  }
];
