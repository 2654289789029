import {
  React,
  bind,
  _,
  moment
} from "$Imports/Imports";

import {
  ICustomerDetailServiceInjectedProps,
  CustomerDetailService
} from "$State/CustomerDetailFreezerService";

import {
  IEmployeeServiceInjectedProps,
  EmployeeService
} from "$State/EmployeeFreezerService";

import {
  Grid,
  Icon,
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  Edit,
  Announcement,
  Info
} from "$Imports/MaterialUIIcons";

import {
  Activity,
  Customer,
  CustomerContact,
  Reminder
} from "$Generated/api";

import {
  AjaxActionIndicator,
  CardLinedHeader,
  directionType,
  ReminderEditModal,
  UserAccessControl,
  DisplayFormattedDatetime,
  CustomerContactModal,
  CustomerAlertModal,
  CustomerReminders,
  ContactsCard,
  AddEditCustomerModal,
  ActivitiesCard
} from "$Imports/CommonComponents";

import {
  EMPTY_REMINDER,
  IReminderServiceInjectedProps,
  ReminderService
} from "$State/ReminderFreezerService";

import {
  StateService,
  IStateServiceInjectedProps
} from "$State/RegionFreezerService";

import {
  ActivityCard
} from "./Activity/ActivityCard";

import {
  CustomersQuotes
} from "./Quotes/CustomersQuotes";

import {
  NotesCard
} from "./Notes/NotesCard"

import {
  OpportunitiesCard
} from "./Opportunities/OpportunitiesCard";

import {
  AuditLogCard
} from "./AuditLog/AuditLogCard";

import {
  CustomerPortalCommoditiesCard
} from "./CustomerPortalCommodities/CustomerPortalCommoditiesCard";

import {
  HoursOfOperation
} from "./HoursOfOperation";

import {
  LoadingUnloadingInstructions
} from "./LoadingUnloadingInstructions";

import {
  CommodityService,
  ICommodityServiceInjectedProps
} from "$State/CommodityFreezerService";

import {
  LoadingInstructionService,
  ILoadingInstructionServiceInjectedProps
} from "$State/LoadingInstructionFreezerService";

import {
  CustomerSourceService,
  ICustomerSourceServiceInjectedProps
} from "$State/CustomerSourceFreezerService";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  NavigationService
} from "$State/NavigationFreezerService";

import { getFormattedZipPostalCode } from "$Shared/utilities/helpers";

interface ICustomerDetailViewState {
  reminder: Reminder;
  isReminderModalOpen: boolean;
  isCustomerAlertModalOpen: boolean;
}

interface ICustomerDetailViewBaseProps {
  companyId: number | undefined;
  customerId: number | undefined;
}

type ICustomerDetailViewProps = ICustomerDetailViewBaseProps
  & ICustomerDetailServiceInjectedProps
  & IEmployeeServiceInjectedProps
  & IReminderServiceInjectedProps
  & IStateServiceInjectedProps
  & ICommodityServiceInjectedProps
  & ILoadingInstructionServiceInjectedProps
  & ICustomerSourceServiceInjectedProps;

const styles: {
  mainContainer: string;
  column: string;
  infoHeader: string;
  infoContainer: string;
  subcolumn: string;
  address: string;
  fieldBlock: string;
  labelValuePair: string;
  labelValueIconPair: string;
  fieldPair: string;
  fieldLabel: string;
  fieldValue: string;
  announcement: string;
  icon: string;
  infoIcon: string
} = require("./CustomerDetailView.scss");

class _CustomerDetailView extends React.Component<ICustomerDetailViewProps, ICustomerDetailViewState> {
  state: ICustomerDetailViewState = {
    reminder: { ...EMPTY_REMINDER },
    isReminderModalOpen: false,
    isCustomerAlertModalOpen: false
  };

  componentDidMount() {
    const { customerId } = this.props;

    if (customerId) {
      this.props.customerDetailService.fetchCustomerDetailData(customerId);
      this.props.customerDetailService.fetchCustomerContacts(customerId, true);
      this.props.customerDetailService.fetchActiveCustomerQuotes(customerId);
      this.props.customerDetailService.fetchCustomerQuotes(customerId, true);
      this.props.customerDetailService.fetchCustomerNotes(customerId, true);
      this.props.customerDetailService.fetchAuditLogs(customerId);
      if (SharedSecurityContext.hasRole(["commodity-exclusion:view"])) {
        this.props.customerDetailService.fetchCustomerCommodityExclusions(customerId, true);
      }
    }
    
    this.props.commodityService.fetchCommodities(this.props.companyId);
    this.props.employeeService.fetchSalesReps();
    this.props.customerSourceService.fetchCustomerSources(true, true);
    this.props.customerDetailService.fetchNameSuffixes();
    this.props.customerDetailService.fetchContactTypes();
    this.props.regionService.fetchStates();
    this.props.loadingInstructionService.fetchLoadingInstructions(true);
  }

  componentDidUpdate(prevProps: ICustomerDetailViewProps) {
    const { customerId } = this.props;

    if (customerId && customerId !== prevProps.customerId) {
      this.props.customerDetailService.fetchCustomerDetailData(customerId);
      this.props.customerDetailService.fetchCustomerContacts(customerId, true);
      this.props.customerDetailService.fetchActiveCustomerQuotes(customerId);
      this.props.customerDetailService.fetchCustomerQuotes(customerId, true);
      this.props.customerDetailService.fetchCustomerNotes(customerId, true);
      this.props.customerDetailService.fetchAuditLogs(customerId);
      if (SharedSecurityContext.hasRole(["commodity-exclusion:view"])) {
        this.props.customerDetailService.fetchCustomerCommodityExclusions(customerId, true);
      }
    }
    
    if (this.props.companyId !== prevProps.companyId) {
      this.props.commodityService.fetchCommodities(this.props.companyId);
    }
  }

  componentWillUnmount() {
    this.props.customerDetailService.clearFreezer();
  }

  @bind
  private _openDetailModal() {
    this.props.customerDetailService.openDetailModal();
  }

  @bind
  private async _saveCustomerDetails(customer: Customer, activity?: Activity) {
    await this.props.customerDetailService.saveCustomer(customer);
    if (activity) {
      await this.props.customerDetailService.saveActivity(activity);
      NavigationService.navigateTo(`/prospect/${customer.id}`);
    }
  }

  @bind
  private _closeDetailModal() {
    this.props.customerDetailService.closeDetailModal();
  }

  @bind
  private _addEditContact(contact?: CustomerContact) {
    this.props.customerDetailService.openCustomerContactModal(contact);
  }

  @bind
  private _setPrimaryContact(contact: CustomerContact) {
    this.props.customerDetailService.setPrimaryContact(contact);
  }

  @bind
  private _toggleShowInactiveContacts(showInactive: boolean) {
    this.props.customerDetailService.updateShowInactiveToggle(showInactive);
    const {customerDetailFetchResults} = this.props.customerDetailService.freezer.get();

    if (customerDetailFetchResults.data?.id){
      this.props.customerDetailService.fetchCustomerContacts(customerDetailFetchResults.data?.id, true, showInactive);
    }
  }
  
  @bind
  private _onContactChange(contact: Partial<CustomerContact>) {
    this.props.customerDetailService.contactOnChange(contact);
  }

  @bind
  private _onContactSave() {
    this.props.customerDetailService.saveCustomerContact();
  }

  @bind
  private _onContactSortChange(columnName: string | undefined, direction: directionType) {
    this.props.customerDetailService.setContactSortState({
      sortColumnName: columnName,
      sortDirection: direction,
    });
  }

  @bind
  private _closeContactModal() {
    this.props.customerDetailService.closeCustomerContactModal();
  }

  @bind
  private _toggleReminderModal(seed?: Reminder, customer?: Customer) {
    if (seed) {
      this.setState({
        isReminderModalOpen: true,
        reminder: {
          ...seed,
          customer: {
            linkId: customer?.id,
            linkName: customer?.customerName
          }
        }
      });
    }
    else {
      this.setState({
        isReminderModalOpen: false,
        reminder: { ...EMPTY_REMINDER }
      });
    }
  }

  @bind
  private openCustomerAlertModal(customerData: Customer) {
    if (customerData) {
      this.setState({
        isCustomerAlertModalOpen: true
      });
    }
  }

  @bind
  private closeCustomerAlertModal() {
    this.setState({
      isCustomerAlertModalOpen: false
    });
  }

  render() {
    const {
      reminder,
      isReminderModalOpen,
      isCustomerAlertModalOpen
    } = this.state;

    const {
      customerDetailFetchResults,
      contactsFetchResults,
      saveContactResults,
      saveCustomerResults,
      detailModalState,
      contactModalState,
      nameSuffixFetchResults,
      contactTypeFetchResults,
      contactSortState,
      showInactiveContacts,
      quoteFetchResults,
      activeQuoteFetchResults,
      auditLogFetchResults,
      auditLogRows
    } = this.props.customerDetailService.getState();

    const {
      customerId
    } = this.props;

    let customerData = customerDetailFetchResults.data ?? {};
    let contactData = contactsFetchResults.data ?? [];

    let suffixData = nameSuffixFetchResults.data ?? [];
    suffixData = _.orderBy(suffixData, s => s.suffixValue);
    let contactTypeData = contactTypeFetchResults.data ?? [];
    contactTypeData = _.orderBy(contactTypeData, c => c.type);

    const {
      salesRepFetchResults,
      activeSalesReps
    } = this.props.employeeService.getState();
    const salesReps = _.orderBy(activeSalesReps ?? [], s => s.lastName);

    const {
      customerSourceFetchResults,
      activeCustomerSources
    } = this.props.customerSourceService.getState();
    const customerSources = _.orderBy(activeCustomerSources ?? [], s => s.name);
    const customerSource = _.find(customerSourceFetchResults.data, s => s.id === customerData.customerSourceId);

    const {
      regionFetchResults
    } = this.props.regionService.getState();
    const regions = regionFetchResults.data ?? [];

    const {
      activeLoadingInstructions
    } = this.props.loadingInstructionService.getState();
    const loadingInstructions = activeLoadingInstructions ?? [];

    let customersQuotes = quoteFetchResults.data?.results ?? [];

    let dateDiff = "";
    let customerSince: moment.Moment | null = customerData.prospect?.startDate ? moment(customerData.prospect?.startDate) : customerData.customerSince ? moment(moment(customerData.customerSince).utc().format("YYYY-MM-DD")) : null;

    if (customerSince) {
      const diff = moment.duration(moment().diff(customerSince));
      dateDiff = `(${diff.years()} ${diff.years() === 1 ? "year" : "years"}, ${diff.months()} ${diff.months() === 1 ? "month" : "months"})`;
    }

    const canConvertToProspect = activeQuoteFetchResults.hasFetched ? !activeQuoteFetchResults.data : false;

    return (
      <Grid container className={styles.mainContainer}>
        <Grid container item xs={8} style={{ height: "fit-content", alignContent: "flex-start" }}> {/*height set to prevent extra space between cards*/}
          <Grid item xs={6} className={styles.column}>
            <CardLinedHeader
              titleText={
                <span className={styles.infoHeader}>
                  {customerData?.customerName}
                </span>
              }
              titleComponents={
                <UserAccessControl roles={["quote:create", "quote:edit"]}>
                  <IconButton onClick={this._openDetailModal} size="small">
                    <Edit fontSize="small" style={{ verticalAlign: "middle" }} />
                  </IconButton>
                </UserAccessControl>
              }
            >
              <AjaxActionIndicator state={[customerDetailFetchResults, saveCustomerResults, salesRepFetchResults, customerSourceFetchResults]} />
              <div className={styles.infoContainer}>
                <div className={styles.subcolumn}>
                  <div className={styles.address}>
                    <div>{customerData?.address1 ?? ""}</div>
                    <div>{customerData?.address2 ?? ""}</div>
                    <div>{`${customerData?.city ?? ""}, ${customerData?.region?.regionAbbreviation ?? ""}, ${getFormattedZipPostalCode(customerData) ?? ""}`}</div>
                  </div>

                  <div className={styles.fieldBlock}>
                    <div className={styles.fieldPair}>
                      <div className={styles.fieldLabel}>Phone:</div>
                      <div className={styles.fieldValue}>{customerData?.phoneNumber?.trim() || "N/A"}</div>
                    </div>

                    <div className={styles.fieldPair}>
                      <div className={styles.fieldLabel}>Cell:</div>
                      <div className={styles.fieldValue}>{customerData?.cellNumber?.trim() || "N/A"}</div>
                    </div>
                  </div>

                  <div style={{ marginTop: "5px" }}>
                    <a href={customerData?.website ?? ""} target="_blank">{customerData?.website ?? ""}</a>
                  </div>

                  <HoursOfOperation hours={customerData?.customerHours ?? []} />
                </div>

                <div className={styles.subcolumn}>
                  <div className={styles.labelValuePair}>Customer Code:<span>{customerData?.tmcustomerId ?? " "}</span></div>
                  <div className={styles.labelValuePair}>Sales Rep:<span>{customerData?.salesAgent ? customerData?.salesAgent?.firstName + " " + customerData?.salesAgent?.lastName : "Not Assigned"}</span></div>

                  <div className={styles.fieldBlock}>
                    <div className={styles.labelValuePair}>Customer Since:<span><DisplayFormattedDatetime value={customerSince?.toDate()} formatString={"MMMM YYYY"} /> {dateDiff}</span></div>
                    <div className={!!customerData.sourceDetails ? styles.labelValueIconPair : styles.labelValuePair}>Customer Source:<span>{customerSource?.name ?? "Unknown"}</span>
                      {customerData.sourceDetails &&
                        <Icon title={customerData.sourceDetails} className={styles.icon}>
                          <Info className={styles.infoIcon} />
                        </Icon>}
                    </div>
                  </div>

                  <div className={styles.fieldBlock}>
                    <div className={styles.fieldPair}>
                      <div className={styles.fieldLabel}>Caller?</div>
                      <div className={styles.fieldValue}>{customerData?.isCaller ? "Yes" : "No"}</div>
                    </div>
                    <div className={styles.fieldPair}>
                      <div className={styles.fieldLabel}>Shipper?</div>
                      <div className={styles.fieldValue}>{customerData?.isShipper ? "Yes" : "No"}</div>
                    </div>
                    <div className={styles.fieldPair}>
                      <div className={styles.fieldLabel}>Consignee?</div>
                      <div className={styles.fieldValue}>{customerData?.isConsignee ? "Yes" : "No"}</div>
                    </div>
                    <div className={styles.fieldPair} style={{marginTop:"0.5rem"}}>
                      <div className={styles.fieldLabel}>Alert:</div>
                      <div className={styles.fieldValue}>{customerData?.displayAlert ? 
                        <IconButton onClick={() => this.openCustomerAlertModal(customerData)} size="small">
                          <Announcement fontSize="small" className={styles.announcement} />
                        </IconButton> : "None"}
                      </div>
                    </div>
                    <LoadingUnloadingInstructions
                      loadingInstructions={loadingInstructions}
                      customerLoadingInstructions={customerData?.customerLoadingInstructions ?? []}
                    />
                  </div>
                </div>
              </div>
            </CardLinedHeader>

            <ContactsCard
              contacts={contactData}
              addEditContact={this._addEditContact}
              setPrimaryContact={this._setPrimaryContact}
              onShowInactive={this._toggleShowInactiveContacts}
              sortState={contactSortState}
              onSortChange={this._onContactSortChange}
              isFetching={contactsFetchResults.isFetching || saveContactResults.isFetching}
              showInactiveContacts={showInactiveContacts}
            />

            <CustomerAlertModal
              isOpen={isCustomerAlertModalOpen}
              customers={customerData ? [customerData] : []}
              onClose={this.closeCustomerAlertModal}
              customerType="Customer"
            />
          </Grid>

          <Grid item xs={6}>
            <ActivityCard
              customerId={customerId}
            />
          </Grid>

          <Grid item xs={12} className={styles.column}>
            <CustomersQuotes
              quotes={customersQuotes}
              customerId={customerId}
            />

            <OpportunitiesCard
              customer={customerData}
            />

            <AuditLogCard
              isFetching={auditLogFetchResults.isFetching}
              auditLogs={auditLogRows}
            />
          </Grid>
        </Grid>

        <Grid item xs={4} className={styles.column}>
          <CustomerReminders
            salesReps={salesReps}
            companyId={this.props.companyId}
            customer={customerData}
          />
          
          <ActivitiesCard
            customerId={this.props.customerId}
            salesReps={salesReps}
          />

          <NotesCard
            customerId={this.props.customerId}
            onCreateReminder={(seed) => this._toggleReminderModal(seed, customerData)}
          />

          {customerData.hasCustomerPortalAccess && 
            <UserAccessControl roles={["commodity-exclusion:view"]}>
              <CustomerPortalCommoditiesCard
                companyId={this.props.companyId}
                customerId={this.props.customerId}
              />
            </UserAccessControl>
          }
        </Grid>

        <AddEditCustomerModal
          isOpen={detailModalState.isOpen}
          customer={detailModalState.editCustomer ?? {}}
          isFetching={saveCustomerResults.isFetching}
          regions={regions}
          salesReps={salesReps}
          customerSources={customerSources}
          onSave={this._saveCustomerDetails}
          onCancel={this._closeDetailModal}
          canConvertToProspect={canConvertToProspect}
        />
        <CustomerContactModal
          isOpen={contactModalState.isOpen}
          contact={contactModalState.editContact}
          validationErrors={contactModalState.validationErrors}
          onSave={this._onContactSave}
          onCancel={this._closeContactModal}
          onChange={this._onContactChange}
          suffixes={suffixData}
          contactTypes={contactTypeData}
          isEmailRequired={contactModalState.isEmailRequired}
        />
        <ReminderEditModal
          isOpen={isReminderModalOpen}
          reminder={reminder}
          onClose={() => this._toggleReminderModal()}
          salesReps={salesReps}
          canSelectType
        />
      </Grid>
    )
  }
}

export const CustomerDetailView =
  ReminderService.inject(
    CustomerDetailService.inject(
      EmployeeService.inject(
        StateService.inject(
          CommodityService.inject(
            LoadingInstructionService.inject(
              CustomerSourceService.inject(
                _CustomerDetailView
  )))))));