import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  AddEditCommodity
} from "./AddEditCommodity";

import {
  UserAccessControl,
  AjaxActionIndicator
} from "$Imports/CommonComponents";

import {
  Card,
  CardActions,
  CardHeader,
  Button,
  FormControlLabel,
  Switch,
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams
} from "$Imports/MaterialUIComponents";

import {
  ICommodityServiceInjectedProps,
  CommodityService
} from "$State/CommodityFreezerService";

import {
  Commodity
} from "$Generated/api";

import {
  ActionMenu
} from "./ActionMenu";

const styles: {
  mainContainer: string;
  actionArea: string;
  cardStyle: string;
} = require("$Shared/styles/Modal.scss");

interface ICommodityLookupViewBaseProp {
  companyId: number | undefined;
}

type ICommodityLookupViewProp = ICommodityServiceInjectedProps
  & ICommodityLookupViewBaseProp;

class _CommodityLookupView extends React.Component<ICommodityLookupViewProp> {

  componentDidMount() {
    this.props.commodityService.fetchCommodities(this.props.companyId);
  }

  componentDidUpdate(prevProps: ICommodityLookupViewBaseProp) {
    if (this.props.companyId !== prevProps.companyId) {
      this.props.commodityService.fetchCommodities(this.props.companyId);
    }
  }

  @bind
  private _onAddClick() {
    this.props.commodityService.addCommodity();
  }

  @bind
  private _onCancel() {
    this.props.commodityService.clearEditAddForm();
  }

  @bind
  private _onTaskChanged(commodity: Partial<Commodity>) {
    this.props.commodityService.updateCommodity(commodity);
  }

  @bind
  private _onSave() {
    this.props.commodityService.saveCommodity(this.props.companyId);
  }

  @bind
  private _onMenuItemSelected(commodity: Commodity) {
    this.props.commodityService.editCommodity(commodity?.id);
  }

  @bind
  private _onActiveFilterChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.commodityService.setFilterActive(checked);
  }

  private readonly columns: GridColDef[] = [
    {
      field: "commodityName",
      headerName: "Name",
      valueGetter: (params: GridValueGetterParams<string, Commodity>) => params.value ?? "",
      flex: 2
    },
    {
      field: "tmCommodityId",
      headerName: "TM Commodity Id",
      valueGetter: (params: GridValueGetterParams<string, Commodity>) => params.value ?? "",
      flex: 2
    },
    {
      field: "isActive",
      valueGetter: (params: GridValueGetterParams<boolean, Commodity>) => params.value ? "True" : "False",
      headerName: "Active",
      flex: 1
    },
    {
      field: "isStackable",
      valueGetter: (params: GridValueGetterParams<boolean, Commodity>) => params.value ? "True" : "False",
      headerName: "Stackable",
      flex: 1
    },
    {
      field: "isSideBySide",
      valueGetter: (params: GridValueGetterParams<boolean, Commodity>) => params.value ? "True" : "False",
      headerName: "Side by Side",
      flex: 1
    },
    {
      headerName: "",
      field: "action",
      sortable: false,
      disableColumnMenu: true,
      width: 60,
      renderCell: (params: GridRenderCellParams<any, Commodity>) =>
        <ActionMenu
          commodity={params.row}
          onMenuItemClick={this._onMenuItemSelected}
        />
    }
  ];

  render() {
    const {
      editAddCommodity,
      editAddValidationErrors,
      formMode,
      commodityFetchResults,
      commodityAddResults,
      filterActive
    } = this.props.commodityService.getState();

    const commodityData = commodityFetchResults.data ?? [];
    const filteredData = filterActive ? _.filter(commodityData, x => x.isActive) as Commodity[] : commodityData;
    const isFetching: boolean = (formMode === "add" || formMode === "edit") ? commodityAddResults.isFetching : false;

    return (
      <div
        className={styles.mainContainer}
      >
        <Card
          className={styles.cardStyle}
        >
          <CardHeader
            title="Commodity Table"
          />

          <CardActions
            disableSpacing={true}
            className={styles.actionArea}
          >
            <FormControlLabel
              control={
                (
                  <Switch
                    color="primary"
                    checked={filterActive}
                    onChange={this._onActiveFilterChange}
                  />
                )
              }
              label="Only show active"
            />
            <UserAccessControl roles={["commodity-lookup:create"]}>
              <Button
                color="primary"
                onClick={this._onAddClick}
                disabled={isFetching}
              >
                Add
              </Button>
            </UserAccessControl>
          </CardActions>
          <AjaxActionIndicator
            state={commodityFetchResults}
          />
          <div style={{ margin: "0.5rem" }}>
            <DataGridPro
              columns={this.columns}
              rows={filteredData}
              initialState={{
                sorting: {
                  sortModel: [{ field: "commodityName", sort: "asc" }]
                }
              }}
              density="compact"
              disableSelectionOnClick
              hideFooter
              autoHeight
            />
          </div>
        </Card>
        <AddEditCommodity
          formMode={formMode}
          commodity={editAddCommodity}
          validationErrors={editAddValidationErrors}
          onCancel={this._onCancel}
          onSave={this._onSave}
          isFetching={isFetching}
          onTaskChange={this._onTaskChanged}
        />
      </div>
    );
  }
}

export const CommodityLookupView = CommodityService.inject(
  _CommodityLookupView
);
