import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  CustomerSearchResults,
  UserAccessControl,
  AddEditCustomerModal
} from "$Imports/CommonComponents";

import {
  Customer,
} from "$Generated/api";

import {
  Card,
  Button,
  CardHeader
} from "$Imports/MaterialUIComponents";

import {
  ICustomerServiceInjectedProps,
  CustomerService
} from "$State/CustomerFreezerService";

import {
  StateService,
  IStateServiceInjectedProps
} from "$State/RegionFreezerService";

import {
  EmployeeService,
  IEmployeeServiceInjectedProps
} from "$State/EmployeeFreezerService";

import {
  LoadingInstructionService,
  ILoadingInstructionServiceInjectedProps
} from "$State/LoadingInstructionFreezerService";

import {
  CustomerSourceService,
  ICustomerSourceServiceInjectedProps
} from "$State/CustomerSourceFreezerService";

import {
  NavigationService
} from "$State/NavigationFreezerService";

import {
  QuoteEntryService
} from "$State/QuoteEntryFreezerService";

const styles: {
  mainContainer: string;
  cardStyle: string;
} = require("./CustomersViewPage.scss");

interface ICustomersViewPageBaseProps { }

type ICustomersViewPageProps = ICustomersViewPageBaseProps
  & ICustomerServiceInjectedProps
  & IStateServiceInjectedProps
  & IEmployeeServiceInjectedProps
  & ILoadingInstructionServiceInjectedProps
  & ICustomerSourceServiceInjectedProps;

class _CustomersViewPage extends React.Component<ICustomersViewPageProps> {

  componentDidMount() {
    this.props.regionService.fetchStates();
    this.props.employeeService.fetchSalesReps();
    this.props.loadingInstructionService.fetchLoadingInstructions(true);
    this.props.customerSourceService.fetchCustomerSources(true, true);
  }

  @bind
  private _addCustomerClick() {
    this.props.customerService.openAddEditCustomerModal("CustomersViewPage", { isActive: true });
  }

  @bind
  private _cancelAdd() {
    this.props.customerService.closeAddEditModal();
  }

  @bind
  private _createQuoteForCustomer(customer: Customer) {
    QuoteEntryService.setSelectedCustomer(customer);
    QuoteEntryService.setQuoteType(true);
    NavigationService.navigateTo("/salesportal");
  }

  @bind
  private async _saveCustomer(customer: Customer) {
    await this.props.customerService.addCustomer(customer);

    const result = this.props.customerService.getState().saveCustomerResults;

    if (result?.data) {
      this.props.customerService.closeAddEditModal();
      this._goToCustomerDetails(result.data);
    }
  }
  
  @bind
  private async _onNewQuoteClick() {
    const { selectedRow } = this.props.customerService.getState();

    if (selectedRow == null) {
      return;
    }

    const isActiveCustomer = await this.props.customerService.customerIsActiveInTruckMate(selectedRow.tmcustomerId);
    if (isActiveCustomer) {
      this._createQuoteForCustomer(selectedRow);
    }
  }

  @bind
  private _goToCustomerDetails(customer: Customer) {
    if (customer.id) {
      NavigationService.navigateTo(`/customer/${customer.id}`);
    }
  }

  render() {
    const {
      customerModalIsOpen,
      addEditCustomer,
      saveCustomerResults,
      searchResults,
      selectedRow
    } = this.props.customerService.getState();

    const {
      regionFetchResults
    } = this.props.regionService.getState();
    const regionData = regionFetchResults.data ?? [];

    const {
      activeSalesReps
    } = this.props.employeeService.getState();    
    const salesReps = _.orderBy(activeSalesReps ?? [], s => s.lastName);

    const {
      activeCustomerSources
    } = this.props.customerSourceService.getState();
    const customerSources = _.orderBy(activeCustomerSources ?? [], s => s.name);

    return (
      <div className={styles.mainContainer}>
        <Card className={styles.cardStyle}>
          <CardHeader
            title="Customer Search"
          />
          <CustomerSearchResults
            searchBySalesRep
            actionButtons={
              <UserAccessControl roles={["quote:create"]}>
                {searchResults.hasFetched &&
                  <Button
                    color="primary"
                    onClick={this._addCustomerClick}
                  >
                    Add Customer
                  </Button>
                }
                <Button
                  color="primary"
                  disabled={!selectedRow || !selectedRow.isCaller}
                  onClick={this._onNewQuoteClick}
                >
                  New Quote
                </Button>
              </UserAccessControl>
            }
            onEnterPressWithSelectedRow={this._onNewQuoteClick}
            onDetailsClick={this._goToCustomerDetails}
          />
        </Card>
        <AddEditCustomerModal
          isOpen={customerModalIsOpen === "CustomersViewPage"}
          isFetching={saveCustomerResults.isFetching}
          customer={addEditCustomer ?? {}}
          regions={regionData}
          salesReps={salesReps}
          customerSources={customerSources}
          onSave={this._saveCustomer}
          onCancel={this._cancelAdd}
        />
      </div>
    );
  }
}

export const CustomersViewPage = CustomerService.inject(
  StateService.inject(
    EmployeeService.inject(
      LoadingInstructionService.inject(
        CustomerSourceService.inject(
          _CustomersViewPage
        )
      )
    )
  )
);