import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  AjaxActionIndicator,
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import {
  Card,
  CardActions,
  CardHeader,
  GridColDef,
  GridRenderCellParams,
  DataGridPro,
  GridSortModel
} from "$Imports/MaterialUIComponents";

import {
  SimplifiedQuote,
  Employee,
  SimplifiedCustomer,
  QuoteQuoteTypeEnum,
  CustomerEntityLink
} from "$Generated/api";

import {
  IQuoteServiceInjectedProps,
  QuoteService
} from "$State/QuoteFreezerService";

import {
  IEmployeeServiceInjectedProps,
  EmployeeService
} from "$State/EmployeeFreezerService";

import {
  CURRENCY_FORMAT,
} from "$Shared/utilities/formatUtil";

import {
  ActionMenu
} from "./ActionMenu";

import {
  QuoteSearchForm
} from "./QuoteSearchForm";

import {
  getDeliveryDateColumn,
  getFtKRatingVariableColumn,
  getQuoteDateColumn,
  milesCol,
  negotiatedRateCol,
  quoteNumberCol,
  salesRepCol,
  statusCol
} from "$Utilities/quoteColConstants";

import { getFormattedZipPostalCode } from "$Shared/utilities/helpers";

const styles: {
  mainContainer: string;
  cardStyle: string;
  resultsGrid: string;
  resultsMessage: string;
  resultCell: string;
} = require("./QuotesView.scss");

interface IQuotesViewBaseProps {
  companyId: number | undefined;
  quoteType: QuoteQuoteTypeEnum;
}

type IQuotesViewProps = IQuotesViewBaseProps
  & IQuoteServiceInjectedProps
  & IEmployeeServiceInjectedProps;

const actionCol: GridColDef = {
  headerName: "",
  field: "Action",
  renderCell: (params: GridRenderCellParams<any, SimplifiedQuote>) =>
    <ActionMenu quote={params.row} />,
  width: 160,
  sortable: false,
  disableColumnMenu: true
};

class _QuotesView extends React.Component<IQuotesViewProps> {
  private _columns: GridColDef[];
  
  componentDidMount() {
    const { quoteType } = this.props;
    const { searchCriteria } = this.props.quoteService.getState();
    // set to default criteria if search has not been previously set
    if (searchCriteria.userId === undefined || searchCriteria.quoteType !== quoteType) {
      this.props.quoteService.onResetSearchModel();
    }

    this.props.employeeService.fetchSalesReps();
    this.props.quoteService.fetchQuotes(this.props.companyId, true, quoteType);
  }

  componentDidUpdate(prevProps: IQuotesViewBaseProps) {
    const { quoteType } = this.props;
    if (this.props.companyId !== prevProps.companyId) {
      this.props.quoteService.clearFreezer();
      this.props.quoteService.onResetSearchModel();
      this.props.quoteService.fetchQuotes(this.props.companyId, true, quoteType);
    }
  }

  @bind
  private _getFullQuoteColumns(): GridColDef[] {
    return [
      quoteNumberCol,
      {
        headerName: "Customer Name",
        field: "customer",
        renderCell: (params: GridRenderCellParams<CustomerEntityLink>) => params.value?.linkName ?? "",
        flex: 1
      },
      getQuoteDateColumn(this.props.quoteType),
      negotiatedRateCol,
      getFtKRatingVariableColumn(this.props.quoteType),
      milesCol,
      getDeliveryDateColumn("Delivery Date", true),
      {
        headerName: "Origin Location",
        field: "originLocation",
        width: 130
      }, {
        headerName: "Destination Location",
        field: "destinationLocation",
        width: 130
      },
      salesRepCol,
      {
        headerName: "Reviewed By",
        field: "reviewedBy",
        renderCell: (params: GridRenderCellParams<Employee | undefined>) => params.value ? `${params.value.firstName} ${params.value.lastName}` : "N/A",
        width: 140
      }, {
        headerName: "FB#",
        field: "freightBillNumber",
        renderCell: (params: GridRenderCellParams<any, SimplifiedQuote>) => params.row.quoteStops?.length ?
          params.row.quoteStops[0].freightBillNumber
          : "",
        width: 120
      },
      statusCol,
      actionCol
    ];
  }

  @bind
  private _getQuickQuoteColumns(): GridColDef[] {
    return [
      quoteNumberCol,
      getQuoteDateColumn(this.props.quoteType),
      {
        headerName: "Calculated Rate",
        field: "calculatedRate",
        renderCell: (params: GridRenderCellParams<number | undefined>) =>
          params.row.currentRate !== null ? <DisplayFormattedNumber value={params.row.currentRate?.highRate} formatString={CURRENCY_FORMAT} /> : "--",
        width: 130
      },
      negotiatedRateCol,
      getFtKRatingVariableColumn(this.props.quoteType),
      milesCol,
      {
        headerName: "Shipper Zip",
        field: "shipperZip",
        renderCell: (params: GridRenderCellParams<any, SimplifiedQuote>) =>
          params.row.quoteStops?.[0].addresses && params.row.quoteStops?.[0].addresses.length > 0 ? `${getFormattedZipPostalCode(params.row.quoteStops?.[0].addresses?.[0])}` : "",
        width: 115
      }, {
        headerName: "Consignee Zip",
        field: "consigneeZip",
        renderCell: (params: GridRenderCellParams<any, SimplifiedQuote>) =>
          params.row.quoteStops?.[0].addresses && params.row.quoteStops?.[0].addresses.length > 0 ? `${getFormattedZipPostalCode(params.row.quoteStops?.[0].addresses?.[1])}` : "",
        width: 115
      },
      salesRepCol,
      statusCol,
      actionCol
    ];
  }

  constructor(props: IQuotesViewProps) {
    super(props);

    this._columns = props.quoteType === "Full" ? this._getFullQuoteColumns()
      : this._getQuickQuoteColumns();
  }

  @bind
  private _onSortChange(sortModel: GridSortModel) {
    const { quoteType } = this.props;
    this.props.quoteService.onSearchModelChanged({
      sortColumn: sortModel.length > 0 ? sortModel[0].field : "quoteDate",
      sortAscending: sortModel.length > 0 ? sortModel[0].sort === "asc" : false
    });

    this.props.quoteService.fetchQuotes(this.props.companyId, true, quoteType);
  }

  render() {
    const {
      companyId,
      quoteType
    } = this.props;

    const {
      quotesFetchResults,
      quickQuotesFetchResults,
      searchCriteria,
    } = this.props.quoteService.getState();

    const {
      salesRepFetchResults
    } = this.props.employeeService.getState();

    const quoteData = quoteType === "Full" ? quotesFetchResults.data?.results ?? [] : quickQuotesFetchResults.data?.results ?? [];

    const sortModel: GridSortModel = [{
      field: searchCriteria.sortColumn ?? "quoteDate",
      sort: searchCriteria.sortAscending ? "asc" : "desc"
    }];

    const fetchResults = quoteType === "Full" ? quotesFetchResults : quickQuotesFetchResults;

    return (
      <div className={styles.mainContainer}>
        <Card className={styles.cardStyle}>
          <CardHeader
            title={`${quoteType} Quotes`}
          />
          <CardActions disableSpacing>
            <QuoteSearchForm companyId={companyId} quoteType={quoteType} />
          </CardActions>
          <AjaxActionIndicator
            state={[fetchResults, salesRepFetchResults]}
          />
          <div className={styles.resultsGrid}>
            {(fetchResults.hasFetched && (fetchResults.data?.totalRecords ?? 0) > (fetchResults.data?.numberOfRecords ?? 0)) ? (
              <div className={styles.resultsMessage}>
                {fetchResults.data?.totalRecords} results found, {fetchResults.data?.numberOfRecords} shown - please refine your search.
              </div>
            ) : undefined}

            <DataGridPro
              columns={this._columns}
              rows={quoteData}
              classes={{
                cell: styles.resultCell
              }}
              density="compact"
              initialState={{
                sorting: { sortModel: sortModel }
              }}
              sortingMode="server"
              sortingOrder={["asc", "desc"]}
              getRowHeight={() => 64}
              onSortModelChange={this._onSortChange}
              disableSelectionOnClick
              hideFooter
            />
          </div>
        </Card>
      </div>
    );
  }
}

export const QuotesView =
  QuoteService.inject(
    EmployeeService.inject(
      _QuotesView
    )
  );
