import {
  React,
  bind,
  moment,
  _
} from "$Imports/Imports";

import {
  Tarp,
  TarpValue
} from "$Generated/api";

import {
  Card,
  CardActions,
  CardHeader,
  Button,
  FormControlLabel,
  Switch,
  DataGridPro,
  GridColDef,
  GridValueFormatterParams,
  GridRenderCellParams
} from "$Imports/MaterialUIComponents";

import {
  AjaxActionIndicator
} from "$Imports/CommonComponents";

import {
  ITarpsServiceInjectedProps,
  TarpsService
} from "$State/TarpsFreezerService";

import {
  AddEditTarpModal
} from "./AddEditTarpModal";

import {
  ActionMenu
} from "./ActionMenu";

import {
  CompanyEnum,
  COMPANY_MAP
} from "$Utilities/companyUtil";

const styles: {
  mainContainer: string;
  actionArea: string;
  cardStyle: string;
} = require("$Shared/styles/Modal.scss");

interface ITarpViewPageBaseProps {

}

export type FormModeType = "add" | "edit" | "none";

interface TarpViewPageState {
  openedAt: Date | undefined;
  tarp: Tarp | undefined;
  tarpValue: TarpValue | undefined;
  activeOnly: boolean;
  formMode: FormModeType;
}

type ITarpViewPageProps = ITarpViewPageBaseProps
  & ITarpsServiceInjectedProps;

class _TarpViewPage extends React.Component<ITarpViewPageProps, TarpViewPageState> {
  state: TarpViewPageState = {
    openedAt: undefined,
    tarp: undefined,
    tarpValue: undefined,
    activeOnly: true,
    formMode: "none"
  };

  private readonly columns: GridColDef[] = [
    {
      field: "tarpName",
      valueFormatter: (params: GridValueFormatterParams) => params.value,
      headerName: "Tarp Name",
      flex: 2
    },
    {
      field: "tarpDescription",
      valueFormatter: (params: GridValueFormatterParams) => params.value,
      headerName: "Description",
      flex: 4
    },
    {
      field: "companyId",
      valueFormatter: (params: GridValueFormatterParams) => params.value ? COMPANY_MAP[params.value as CompanyEnum].name : "Both",
      headerName: "Company",
      flex: 1
    },
    {
      field: "isActive",
      valueFormatter: (params: GridValueFormatterParams) => params.value ? "Yes" : "No",
      headerName: "Active",
      flex: 1
    },
    {
      field: "action",
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any, Tarp>) => (
        <ActionMenu
          tarp={params.row}
          onEdit={this._onEdit}
        />
      ),
      headerName: ""
    }
  ];

  componentDidMount() {
    this.props.tarpsService.fetchTarps(true);
  }

  @bind
  private _onAddClick() {
    var now = new Date();
    now.setSeconds(0);

    const tarpValue: TarpValue = {
      startDateTime: now,
      endDateTime: undefined
    }

    const blankTarp: Tarp = {
      companyId: undefined,
      isActive: true,
      tarpValues: [tarpValue]
    };
    
    this.setState({
      openedAt: now,
      tarp: blankTarp,
      tarpValue: tarpValue,
      formMode: "add"
    });
  }

  @bind
  private _onEdit(tarp: Tarp) {
    const now = new Date();
    now.setSeconds(0);

    const editTarp: Tarp = _.cloneDeep(tarp);
    const editTarpValue: TarpValue | undefined = tarp.tarpValues && tarp.tarpValues.length > 0 ? _.cloneDeep(tarp.tarpValues[0]) : undefined;
    var minStart = _.cloneDeep(now);
    if (editTarpValue && editTarpValue.startDateTime && moment(editTarpValue.startDateTime).isAfter(now)) {
      minStart = _.cloneDeep(editTarpValue.startDateTime);
    }
    if (editTarpValue) {
      editTarpValue.startDateTime = minStart;
    }

    this.setState({
      openedAt: now,
      tarp: editTarp,
      tarpValue: editTarpValue,
      formMode: "edit"
    });
  }

  @bind
  private _closeModal() {
    this.setState({
      formMode: "none",
      tarp: undefined,
      tarpValue: undefined
    });
  }

  @bind
  private _onActiveFilterChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.setState({activeOnly: checked});
  }

  @bind
  private async _onSave(editAddTarp: Tarp) {
    await this.props.tarpsService.saveTarp(this.state.formMode.toString(), editAddTarp);
    this._closeModal();
  }

  render() {
    const {
      tarpFetchResult,
      tarpSaveResult,
      tarpRateSaveResult
    } = this.props.tarpsService.getState();

    const {
      openedAt,
      activeOnly,
      formMode,
      tarp,
      tarpValue
    } = this.state;

    const tarps = tarpFetchResult.data ?? [];
    const tarpData = activeOnly ? _.filter(tarps, t => t.isActive) ?? [] : tarps ?? [];
    const isFetching: boolean = tarpFetchResult.isFetching || tarpSaveResult.isFetching || tarpRateSaveResult.isFetching;

    return (
      <div
        className={styles.mainContainer}
      >
        <Card
          className={styles.cardStyle}
        >
          <CardHeader title="Tarps" />
          <CardActions
            disableSpacing={true}
            className={styles.actionArea}
          >
            <FormControlLabel
              control={
                (
                  <Switch
                    color="primary"
                    checked={activeOnly}
                    onChange={this._onActiveFilterChange}
                  />
                )
              }
              label="Only show active"
            />
            <Button
              color="primary"
              onClick={this._onAddClick}
            >
              Add
            </Button>
          </CardActions>
          <AjaxActionIndicator
            state={[tarpSaveResult, tarpRateSaveResult, tarpFetchResult]}
          />
          <div style={{ margin: "0.5rem" }}>
            <DataGridPro
              columns={this.columns}
              rows={tarpData}
              density="compact"
              hideFooter
              disableSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: "tarpName", sort: "asc" }]
                }
              }}
              autoHeight
            />
          </div>
        </Card>
        <AddEditTarpModal
          openedAt={openedAt}
          formMode={formMode}
          tarp={tarp}
          tarpValue={tarpValue}
          tarps={tarps ?? []}
          isFetching={isFetching}
          canSave={!tarpSaveResult.isFetching && !tarpRateSaveResult.isFetching}
          onSave={this._onSave}
          onCancel={this._closeModal}
        />
      </div>
    );
  }
}

export const TarpViewPage = TarpsService.inject(
  _TarpViewPage
);
