import {
  _,
  React,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  Customer,
  CustomerContact,
  CustomerSource
} from "$Generated/api";

import {
  IconButton,
  Button,
  Link
} from "$Imports/MaterialUIComponents";

import {
  CardLinedHeader,
  UserAccessControl,
  CustomerContactModal,
  AddEditCustomerModal
} from "$Imports/CommonComponents";

import {
  Announcement,
  Edit,
  Launch
} from "$Imports/MaterialUIIcons";

import {
  StateService,
  IStateServiceInjectedProps
} from "$State/RegionFreezerService";

import {
  AlertCustomerType,
  IQuoteEntryServiceInjectedProps,
  QuoteEntryService
} from "$State/QuoteEntryFreezerService";

import {
  CustomerService,
  ICustomerServiceInjectedProps
} from "$State/CustomerFreezerService";

import {
  EmployeeService,
  IEmployeeServiceInjectedProps
} from "$State/EmployeeFreezerService";

import {
  ICustomerDetailServiceInjectedProps,
  CustomerDetailService,
  CustomerContactValidationSchema
} from "$State/CustomerDetailFreezerService";

import {
  ContactPersonDisplay
} from "./ContactPersonDisplay";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  AddressDisplay
} from "./QuoteStopEntryComponents/AddressDisplay";

import {
  SecurityContext
} from "$Utilities/Security/ApplicationSecuritySettings";

const styles: {
  titleContainer: string;
  alertIcon: string;
} = require("./CustomerCard.scss");

interface ICustomerCardState {
  addContactModalIsOpen: boolean;
  newContact: CustomerContact | undefined;
  contactValidationErrors: ValidationError | null;
}

interface ICustomerCardBaseProps {
  customerSources: CustomerSource[];
  onCopyClick: () => void;
  onCustomerAlertClick: (alertCustomers: Customer[], alertCustomerType: AlertCustomerType) => void;
}

type ICustomerCardProps = ICustomerCardBaseProps
  & IQuoteEntryServiceInjectedProps
  & IStateServiceInjectedProps
  & ICustomerServiceInjectedProps
  & IEmployeeServiceInjectedProps
  & ICustomerDetailServiceInjectedProps;

class _CustomerCard extends React.Component<ICustomerCardProps, ICustomerCardState> {
  state: ICustomerCardState = {
    addContactModalIsOpen: false,
    newContact: undefined,
    contactValidationErrors: null
  }

  async componentDidMount() {
    this.props.regionService.fetchStates();
    this.props.employeeService.fetchSalesReps();

    const { quote, selectedCustomer } = this.props.QuoteEntryService.getState();
    if ((!quote.status || quote.status === "Convert") && selectedCustomer?.displayAlert) {
      // always show caller alert on new quotes, or converting customer quotes
      this._openCallerAlertModal();
    }
  }

  @bind
  private _openEditModal() {
    const {
      selectedCustomer,
      viewOnly
    } = this.props.QuoteEntryService.getState();

    if (selectedCustomer && !viewOnly) {
      this.props.customerService.openAddEditCustomerModal("CustomerCard", selectedCustomer);
    }
  }

  @bind
  private _cancelEditModal() {
    this.props.customerService.closeAddEditModal();
  }

  @bind
  private async _saveCustomer(customer: Customer) {
    await this.props.customerService.updateSelectedCustomer(customer);

    const result = this.props.customerService.getState().saveCustomerResults;

    if (result?.data) {
      this.props.QuoteEntryService.setSelectedCustomer(result.data);
      this.props.QuoteEntryService.updateAddressInformation();
      this.props.customerService.closeAddEditModal();
    }
  }

  @bind
  private _openAddContactModal() {
    const {
      selectedCustomer
    } = this.props.QuoteEntryService.getState();

    this.setState({
      addContactModalIsOpen: true,
      contactValidationErrors: null,
      newContact: {
        isActive: true,
        customerId: selectedCustomer?.id
      }
    });
  }

  @bind
  private _closeAddContactModal() {
    this.setState({
      addContactModalIsOpen: false,
      contactValidationErrors: null,
      newContact: undefined
    });
  }

  @bind
  private async _saveContact() {
    const errors = await validateSchema(CustomerContactValidationSchema, this.state.newContact, {
      abortEarly: false,
      context: { emailRequired: true }
    });
    this.setState({ contactValidationErrors: errors });

    if (errors) {
      return;
    }

    if (this.state.newContact) {
      this.props.QuoteEntryService.saveNewContact(this.state.newContact, "Caller");
    }

    this._closeAddContactModal();
  }

  @bind
  private _addContactChange(contact: Partial<CustomerContact>) {
    this.setState((prev) => ({
      newContact: {
        ...prev.newContact,
        ...contact
      }
    }));
  }

  @bind
  private _openCallerAlertModal() {
    const {
      selectedCustomer
    } = this.props.QuoteEntryService.getState();

    if (selectedCustomer) {
      this.props.onCustomerAlertClick([selectedCustomer], "Caller");
    }
  }

  @bind
  private _onChangeContact(contactId: number | undefined) {
    this.props.QuoteEntryService.updateSelectedContact(contactId);
  }

  public render() {
    const {
      quote,
      selectedCustomer,
      viewOnly,
      editMode,
      selectedCustomerContactsResults
    } = this.props.QuoteEntryService.getState();
    const {
      regionFetchResults
    } = this.props.regionService.getState();
    const {
      customerModalIsOpen,
      addEditCustomer,
      saveCustomerResults
    } = this.props.customerService.getState();
    const {
      activeSalesReps
    } = this.props.employeeService.getState();
    const {
      nameSuffixFetchResults,
      contactTypeFetchResults
    } = this.props.customerDetailService.getState();

    let suffixData = nameSuffixFetchResults.data ?? [];
    suffixData = _.orderBy(suffixData, s => s.suffixValue);
    let contactTypeData = contactTypeFetchResults.data ?? [];
    contactTypeData = _.orderBy(contactTypeData, c => c.type);

    const regions = regionFetchResults.data ?? [];
    const salesReps = _.orderBy(activeSalesReps ?? [], s => s.lastName);

    const disableContact = editMode || quote.status === "Accepted" || quote.status === "Declined" || quote.status === "Expired" || quote.status === "AcceptanceRejected";
    const isCarrierRep = SecurityContext.isInGroup("/CarrierRep");

    const contacts = selectedCustomerContactsResults.data ?? [];
    const contactId = quote.customerContactId ?? contacts?.find(c => c.isPrimary)?.id;

    const HeaderComponents = () =>
      <div style={{ marginLeft: "auto", display: "flex" }}>
        {viewOnly &&
          <UserAccessControl roles={["quote:create", "quote:edit"]}>
            <Button
              color="primary"
              onClick={this.props.onCopyClick}
              style={{ marginBottom: "10px" }}
            >
              Copy
            </Button>
          </UserAccessControl>
        }
        <IconButton
          onClick={this._openEditModal}
          disabled={viewOnly}
          style={{ marginBottom: "-5px", paddingTop: "0px" }}
        >
          <Edit />
        </IconButton>
        {!isCarrierRep && <Link
          href={`/${selectedCustomer?.isProspect ? 'prospect' : 'customer'}/${selectedCustomer?.id}`}
          target="_blank"
          style={{ color: "inherit", display: "inline-flex" }}
        >
          <IconButton
            style={{ marginBottom: "-5px", paddingTop: "0px" }}
          >
            <Launch />
          </IconButton>
        </Link>}
      </div>

    return (
      <>
        <CardLinedHeader
          titleText={
            <div className={styles.titleContainer}>
              Caller Information
              {selectedCustomer?.displayAlert &&
                <IconButton
                  className={styles.alertIcon}
                  onClick={this._openCallerAlertModal}
                  size="small"
                >
                  <Announcement fontSize="inherit" />
                </IconButton>
              }
            </div>
          }
          titleComponents={<HeaderComponents />}
          style={{ margin: "10px 0 0 10px" }}
        >
          {selectedCustomer &&
            <div style={{ display: "flex" }}>
              <div style={{ marginLeft: "10px" }}>
                <AddressDisplay regions={regions} customer={selectedCustomer} place={undefined} />
              </div>
              <div style={{ marginLeft: "25px" }}>
                <ContactPersonDisplay
                  contactId={contactId}
                  contacts={contacts ?? []}
                  disableContact={disableContact}
                  viewOnly={viewOnly}
                  onChangeContact={this._onChangeContact}
                  customerType="Caller" />
              </div>
              <div style={{ marginLeft: "25px" }}>
                {(!viewOnly && !disableContact) && (
                  <UserAccessControl roles={["quote:create", "quote:edit"]}>
                    <Button onClick={this._openAddContactModal}>Add</Button>
                  </UserAccessControl>
                )}
              </div>
            </div>
          }
        </CardLinedHeader>
        <AddEditCustomerModal
          isOpen={customerModalIsOpen === "CustomerCard"}
          isFetching={saveCustomerResults.isFetching}
          customer={addEditCustomer ?? {}}
          regions={regions}
          salesReps={salesReps}
          customerSources={this.props.customerSources}
          onSave={this._saveCustomer}
          onCancel={this._cancelEditModal}
          disableCallerCheckbox
        />
        <CustomerContactModal
          isOpen={this.state.addContactModalIsOpen}
          contact={this.state.newContact}
          validationErrors={this.state.contactValidationErrors}
          onSave={this._saveContact}
          onCancel={this._closeAddContactModal}
          onChange={this._addContactChange}
          suffixes={suffixData}
          contactTypes={contactTypeData}
          isEmailRequired={true}
        />
      </>
    );
  }
}

export const CustomerCard = QuoteEntryService.inject(
  StateService.inject(
    CustomerService.inject(
      EmployeeService.inject(
        CustomerDetailService.inject(
          _CustomerCard
        )
      )
    )
  )
);