import {
  React,
  _,
  bind,
  Prompt,
  managedAjaxUtil
} from "$Imports/Imports";

import {
  ConfirmDeleteModal,
  ConfirmCancelModal,
  CardLinedHeader,
  UserAccessControl,
  CustomerAlertModal,
  NotificationModal,
  AddEditCustomerModal
} from "$Imports/CommonComponents";

import {
  Customer,
  Place,
  QuoteFreight
} from "$Generated/api";

import {
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps,
  CustomerType,
  ResponseType,
  AlertCustomerType
} from "$State/QuoteEntryFreezerService";

import {
  CommodityService,
  ICommodityServiceInjectedProps
} from "$State/CommodityFreezerService"

import {
  DeclaredValueService,
  IDeclaredValueServiceInjectedProps
} from "$State/DeclaredValueFreezerService";

import {
  CompanySelectService,
  ICompanySelectServiceInjectedProps
} from "$State/CompanySelectFreezerService";

import {
  EquipmentTypeService,
  IEquipmentTypeServiceInjectedProps
} from "$State/EquipmentTypeFreezerService";

import {
  StateService,
  IStateServiceInjectedProps
} from "$State/RegionFreezerService";

import {
  EmployeeService,
  IEmployeeServiceInjectedProps
} from "$State/EmployeeFreezerService";

import {
  LoadingInstructionService,
  ILoadingInstructionServiceInjectedProps
} from "$State/LoadingInstructionFreezerService";

import {
  AddEditQuoteCommodity
} from "./QuoteStopEntryComponents/AddEditQuoteCommodity";

import {
  NavigationService
} from "$State/NavigationFreezerService";

import {
  ApplicationSettingsService
} from "$State/ApplicationSettingsFreezerService";

import {
  CustomerService,
  ICustomerServiceInjectedProps
} from "$State/CustomerFreezerService";

import {
  ICustomerDetailServiceInjectedProps,
  CustomerDetailService
} from "$State/CustomerDetailFreezerService";

import {
  CustomerSourceService,
  ICustomerSourceServiceInjectedProps
} from "$State/CustomerSourceFreezerService";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  CityStateSearchModal
} from "./CityStateSearchModal";

import {
  CustomerCard
} from "./CustomerCard";

import {
  TabSection
} from "./TabSection";

import {
  CustomerSearchModal
} from "./CustomerSearchModal";

import {
  QuestionModal
} from "./QuestionModalComponents/QuestionModal";

import {
  QuoteStopEntry
} from "./QuoteStopEntryComponents/QuoteStopEntry";

import {
  Button
} from "$Imports/MaterialUIComponents";

import { getTrimmedZipPostalCode } from "$Shared/utilities/helpers";

const styles: {
  paper: string,
  mainContainer: string,
  addStopButton: string
} = require("./QuoteStopEntryComponents/QuoteStopEntry.scss");

interface IQuoteEntryBaseProps {
  companyId: number | undefined;
  themeColor: string;
}

interface IOwnState {
  isQuoteStopDeleteModalOpen: boolean;
  quoteStopDeleteIndex?: number;
  navigationModalIsOpen: boolean;
  navigationActionText?: string;
  nextLocation?: string;
  hasConfirmedNavigation: boolean;
  isLengthRestrictionModalOpen: boolean;
  notifyZipCode?: string;
  isCustomerAlertModalOpen: boolean;
  alertCustomerType?: AlertCustomerType;
  alertCustomers: Customer[];
}

export interface IQFRow extends QuoteFreight {
  rowIndex: number;
}

export function addRowIndexes(entries: QuoteFreight[]): IQFRow[] {
  return _.map(entries, (x, idx) => ({
    rowIndex: idx,
    ...x
  }));
}

type IQuoteEntryProps = IQuoteEntryBaseProps
  & IQuoteEntryServiceInjectedProps
  & ICommodityServiceInjectedProps
  & IDeclaredValueServiceInjectedProps
  & ICompanySelectServiceInjectedProps
  & ICustomerServiceInjectedProps
  & IEquipmentTypeServiceInjectedProps
  & IStateServiceInjectedProps
  & IEmployeeServiceInjectedProps
  & ICustomerDetailServiceInjectedProps
  & ILoadingInstructionServiceInjectedProps
  & ICustomerSourceServiceInjectedProps;

class _QuoteView extends React.Component<IQuoteEntryProps, IOwnState> {
  state: IOwnState = {
    isQuoteStopDeleteModalOpen: false,
    navigationModalIsOpen: false,
    nextLocation: "",
    hasConfirmedNavigation: false,
    isLengthRestrictionModalOpen: false,
    notifyZipCode: undefined,
    isCustomerAlertModalOpen: false,
    alertCustomerType: undefined,
    alertCustomers: []
  };

  async componentDidMount() {
    const url = window.location.pathname.split('/');

    const viewOnlyUser = !SharedSecurityContext.hasRole(["quote:create", "quote:edit"]);
    if (viewOnlyUser) {
      this.props.QuoteEntryService.freezer.get().set({
        viewOnly: true
      });
    }

    // workaround: force-resolve the intermittent race condition by also clearing save results on component mount
    // quoteSaveResult may resolve and set data _after_ the freezer clear on component unmount
    // only child components consume quoteSaveResults, and it does not need to persist outside this component's scope
    this.props.QuoteEntryService.freezer.get().set({
      quoteSaveResults: managedAjaxUtil.createInitialState()
    });

    this._fetchQuoteBuildingData();

    //specific quotes will have url form of /salesportal/quote/:id/:viewOnly
    // todo update routing, see KT-815
    const viewOnlyString = (url.length > 2) ? url[url.length - 1] : undefined;
    if (viewOnlyString !== undefined) {
      await this.props.QuoteEntryService.fetchQuoteData(+url[url.length - 2], viewOnlyString === "true");

      const { quote } = this.props.QuoteEntryService.getState();
      this.props.QuoteEntryService.fetchAccessorialChargeValues(quote.id, true);
      if (quote.quoteStops && quote.quoteStops.length) {
        const quoteStop = quote.quoteStops[0];
        const origAddress = _.find(quoteStop.addresses, a => (a.isCurrent === true) && (a.addressType === "Shipper"));
        const destAddress = _.find(quoteStop.addresses, a => (a.isCurrent === true) && (a.addressType === "Consignee"));

        if (origAddress && origAddress?.customerId) {
          await this.props.QuoteEntryService.getContactsForShipperConsignee(origAddress.customerId, "Shipper");
        }
        if (destAddress && destAddress?.customerId) {
          await this.props.QuoteEntryService.getContactsForShipperConsignee(destAddress.customerId, "Consignee");
        }
      }
      await this.props.QuoteEntryService.fetchDataChangeLogs(+url[url.length - 2]);
    }

    await ApplicationSettingsService.fetchApplicationSettings(true);
    this.props.QuoteEntryService.setQuoteMarketType();
    this.props.customerDetailService.fetchNameSuffixes();
    this.props.customerDetailService.fetchContactTypes();
    this.props.loadingInstructionService.fetchLoadingInstructions(true);
    this.props.customerSourceService.fetchCustomerSources(true, true);
  }

  componentWillUnmount() {
    this.props.QuoteEntryService.clearFreezer();
  }

  async componentDidUpdate(prevProps: IQuoteEntryBaseProps) {
    if (this.props.companyId !== prevProps.companyId) {
      await this.props.QuoteEntryService.fetchUpchargeTarpRates(this.props.companyId, true);
      this.props.commodityService.fetchCommodities(this.props.companyId, true);
    }
  }

  @bind
  private async _fetchQuoteBuildingData() {
    this.props.commodityService.fetchCommodities(this.props.companyId);
    this.props.QuoteEntryService.fetchCommodityQuestions();
    await this.props.QuoteEntryService.fetchUpchargeTarpRates(this.props.companyId, true);
    this.props.QuoteEntryService.fetchUpchargeQuestions();
    this.props.declaredValueService.fetchData();
    this.props.equipmentTypeService.fetchEquipmentTypes();
    this.props.regionService.fetchStates();
  }

  @bind
  private _onQuoteStopChange(index: number) {
    this.props.QuoteEntryService.updateCurrentQuoteStop(index);
  }

  @bind
  private _onAddStopClick() {
    this.props.QuoteEntryService.addNewQuoteStop();
  }

  @bind
  private _removeQuoteStop(quoteStopIndex: number) {
    this.props.QuoteEntryService.removeQuoteStop(quoteStopIndex);
    this._closeRemoveQuoteStopModal();
  }

  @bind
  private _closeRemoveQuoteStopModal() {
    this.setState({
      isQuoteStopDeleteModalOpen: false,
      quoteStopDeleteIndex: undefined
    });
  }

  @bind
  private _onNavigationConfirm() {
    this.setState({
      hasConfirmedNavigation: true,
      navigationModalIsOpen: false
    }, () => this.state.nextLocation && NavigationService.navigateTo(this.state.nextLocation));
  }

  @bind
  private _onNavigationCancel() {
    this.setState({
      navigationModalIsOpen: false
    });
  }

  @bind
  private _onCloseTabClick(quoteStopIndex: number) {
    this.setState({
      isQuoteStopDeleteModalOpen: true,
      quoteStopDeleteIndex: quoteStopIndex
    });
  }

  @bind
  private async _onCustomerSelect(customerType: CustomerType | undefined) {
    const customer = this.props.customerService.getState().selectedRow;

    if (customer) {
      const isActiveCustomer = await this.props.customerService.customerIsActiveInTruckMate(customer.tmcustomerId);
      if (isActiveCustomer) {
        await this.props.QuoteEntryService.updateSelectedCustomer(customer, customerType);
        const contactId = this.props.QuoteEntryService.getQuoteStopContactId(customer?.id, customerType);
        this.props.QuoteEntryService.updateAllQuoteStopsCustomerTypeContacts(customerType, contactId, customer?.id);
        this.props.QuoteEntryService.getContactsForShipperConsignee(customer.id, customerType);
        this._onCustomerModalClose();
        this._onZipCodeChange(getTrimmedZipPostalCode(customer.zipPostalCode));
        if (customer.displayAlert) {
          this._openCustomerAlertModal([customer], customerType ?? "Customer");
        }
      }
    }
  }

  @bind
  private _onZipCodeChange(zipcode: string | undefined) {
    const notifyUser = this._checkLocationsWithLengthRestrictions(zipcode);
    this.setState({
      isLengthRestrictionModalOpen: notifyUser,
      notifyZipCode: notifyUser ? zipcode : undefined
    });
  }

  @bind
  private _onCustomerLocationSelect(place: Place, customerType: CustomerType | undefined) {
    const zipCode = place.zipPostalCode;

    const {
      quote,
      currentQuoteStopIdx
    } = this.props.QuoteEntryService.freezer.get().toJS();

    var currentCustomer: Customer | undefined = undefined;
    if (quote.quoteStops) {
      currentCustomer = _.find(quote.quoteStops[currentQuoteStopIdx].addresses, adr => (adr.isCurrent === true) && (adr.addressType === customerType))?.customer;
    }

    if (currentCustomer && customerType) {
      this.props.QuoteEntryService.onCustomerClear(customerType);
    }

    if (zipCode) {
      // translate the customer type to an address type here
      if (customerType === "Shipper") {
        this.props.QuoteEntryService.onPlaceChanged(zipCode, "Shipper", place);
      } else if (customerType === "Consignee") {
        this.props.QuoteEntryService.onPlaceChanged(zipCode, "Consignee", place);
      }
    }

    this._onCustomerCityStateSearchClose();
  }

  @bind
  private _onCustomerCityStateSearchClose() {
    this.props.QuoteEntryService.onCustomerCityStateSearchClose();
  }

  @bind
  private _onCustomerModalClose() {
    this.props.QuoteEntryService.onCustomerSearchModalClose();
  }

  @bind
  private _displayNavigationModal(location: string, message: string) {
    this.setState({
      navigationModalIsOpen: true,
      navigationActionText: message,
      nextLocation: location
    });
  }

  @bind
  private _onAnswerChanged(questionId: number, answer: ResponseType) {
    this.props.QuoteEntryService.updateQuestionResponse(questionId, answer);
  }

  @bind
  private _onFlatUpchargeOtherRespChanged(answer: ResponseType) {
    this.props.QuoteEntryService.updateFlatUpchargeOtherResponse(answer);
  }

  @bind
  private _onUpchargeReasonChanged(response: string) {
    this.props.QuoteEntryService.updateUpchargeReason(response);
  }

  @bind
  private _closeQuestionModal() {
    this.props.QuoteEntryService.closeQuestionsModal();
  }

  @bind
  private async _onSaveAddCustomer(addEditCustomer: Customer) {
    // editing the caller
    if (addEditCustomer?.id) {
      await this.props.customerService.updateSelectedCustomer(addEditCustomer);

      const result = this.props.customerService.getState().saveCustomerResults;
      if (result?.data) {
        this.props.QuoteEntryService.setSelectedCustomer(result.data);
        this.props.customerService.closeAddEditModal();
      }
    }
    // adding a new shipper or consignee
    else {
      // creation of shipper / consignee customers requires an address from the quote workflow
      await this.props.customerService.addCustomer(addEditCustomer);

      const {
        customerSearchType
      } = this.props.QuoteEntryService.getState();

      const result = this.props.customerService.getState().saveCustomerResults;
      if (result?.data) {
        await this.props.QuoteEntryService.updateSelectedCustomer(result.data, customerSearchType);
        this.props.customerService.closeAddEditModal();
        this._onCustomerModalClose();
      }
    }
  }

  @bind
  private _onCancelAddCustomer() {
    this.props.customerService.closeAddEditModal();
  }

  @bind
  private _setQuoteType(isFullQuoteType: boolean) {
    this.props.QuoteEntryService.setQuoteType(isFullQuoteType)
  }

  @bind
  private async _createQuickQuote() {
    this.props.QuoteEntryService.createNewQuickQuote();
    this._fetchQuoteBuildingData();
    await ApplicationSettingsService.fetchApplicationSettings(true);
    this.props.QuoteEntryService.setQuoteMarketType();
  }

  @bind
  private _openCustomerAlertModal(alertCustomers: Customer[], alertCustomerType: AlertCustomerType) {
    this.setState({
      alertCustomers: alertCustomers,
      alertCustomerType: alertCustomerType,
      isCustomerAlertModalOpen: true
    });
  }

  @bind
  private _closeCustomerAlertModal() {
    this.setState({
      isCustomerAlertModalOpen: false
    });
  }

  @bind
  private async _onCopyClick() {
    await this.props.QuoteEntryService.copyCurrentQuote();

    const { quote } = this.props.QuoteEntryService.getState();

    // gather caller, shippers/consignees across all stops with an active alert
    const alertCustomers = [];
    if (quote.customer?.displayAlert) {
      alertCustomers.push(quote.customer);
    }

    for (let i = 0; i < (quote.quoteStops?.length ?? 0); i += 1) {
      _.forEach(quote.quoteStops![i].addresses, a => {
        if (!a.customerId || !a.isCurrent) {
          return;
        }

        if (a.customer?.displayAlert && a.customer?.alert) {
          alertCustomers.push(a.customer);
        }
      });
    }

    if (alertCustomers.length > 0) {
      this._openCustomerAlertModal(alertCustomers, "Customer");
    }
  }

  @bind
  private _onDismissLengthRestrictionModal() {
    this.setState({
      isLengthRestrictionModalOpen: false,
      notifyZipCode: undefined
    });
  }

  @bind
  public _checkLocationsWithLengthRestrictions(zipPostalCode: string | undefined): boolean {
    if (!zipPostalCode) {
      return false;
    }
    const restrictedZips = ApplicationSettingsService.getLocationsWithLengthRestrictionsSetting();
    const zipTest = zipPostalCode.substring(0,3);
    const warnUser = _.includes(restrictedZips, zipTest);
    return !!warnUser;
  }

  render() {
    const {
      quote,
      selectedCustomer,
      hasQuoteChanged,
      currentQuoteStopIdx,
      viewOnly,
      editMode,
      freightModalIsOpen,
      currentQuoteFreight,
      isCustomerSearchModalOpen,
      isCityStateSearchModalOpen,
      cityStateCustomerSearchType,
      customerSearchType,
      modalQuestionType,
      upchargeQuestionsFetchResults,
      upchargeResponses,
      questionModalIsOpen,
      freightTotalData,
      calculateRatingVariableResults
    } = this.props.QuoteEntryService.getState();
    const {
      activeCommodities,
      commodityFetchResults
    } = this.props.commodityService.getState();
    const {
      companyContext
    } = this.props.companySelectService.getState();
    const {
      selectedRow,
      addEditCustomer,
      customerModalIsOpen,
      saveCustomerResults,
      searchResults
    } = this.props.customerService.getState();
    const {
      activeEquipmentTypes,
      equipmentTypeFetchResults
    } = this.props.equipmentTypeService.getState();
    const {
      regionFetchResults
    } = this.props.regionService.getState();
    const {
      salesRepFetchResults
    } = this.props.employeeService.getState();
    const {
      activeCustomerSources
    } = this.props.customerSourceService.getState();
    const customerSources = _.orderBy(activeCustomerSources ?? [], s => s.name);

    const {
      navigationModalIsOpen,
      navigationActionText,
      isQuoteStopDeleteModalOpen,
      quoteStopDeleteIndex,
      hasConfirmedNavigation,
      isLengthRestrictionModalOpen,
      notifyZipCode,
      isCustomerAlertModalOpen,
      alertCustomerType,
      alertCustomers
    } = this.state;

    const upchargeQuestions = upchargeQuestionsFetchResults.data ?? [];

    const setQuoteTypeFull = !!selectedRow && quote.quoteType === "Full";
    if (!quote.quoteType) {
      this._setQuoteType(setQuoteTypeFull);
    }
    const titleText = quote.quoteType === "Full" ? "Quote Form" : "Quick Quote";
    const callerId = selectedCustomer?.isProspect ? selectedCustomer.id : undefined;

    return (
      <>
        <div className={styles.paper}>
          {quote.quoteType === "Full" &&
            <CustomerCard
              customerSources={customerSources}
              onCopyClick={this._onCopyClick}
              onCustomerAlertClick={this._openCustomerAlertModal}
            />
          }
          <CardLinedHeader
            titleText={titleText}
            className={styles.mainContainer}
            titleComponents={quote.quoteType !== "Full" ?
              <div style={{ marginBottom: "0.5rem" }}>
                <UserAccessControl roles={["quote:create"]}>
                  <Button
                    disabled={!quote.calculatedRates || hasQuoteChanged}
                    onClick={this._createQuickQuote}
                  >
                    New Quick Quote
                  </Button>
                </UserAccessControl>
              </div> : ""
            }>
            {quote.quoteType === "Full" &&
              <TabSection
                quote={quote}
                currentQuoteStopIdx={currentQuoteStopIdx}
                viewOnly={viewOnly}
                editMode={editMode}
                onTabChange={this._onQuoteStopChange}
                onAddTabClick={this._onAddStopClick}
                onCloseTabClick={this._onCloseTabClick} />
            }
            {_.map(quote.quoteStops, (qs, idx) =>
              <QuoteStopEntry
                companyId={this.props.companyId}
                themeColor={this.props.themeColor}
                currentQuoteStopIndex={currentQuoteStopIdx}
                thisQuoteStopIndex={idx}
                quoteStop={qs}
                key={idx}
                onCustomerAlertClick={this._openCustomerAlertModal}
                onZipCodeChange={this._onZipCodeChange}
              />)}
          </CardLinedHeader>
        </div>
        <AddEditQuoteCommodity
          freightTotalData={freightTotalData}
          isOpen={freightModalIsOpen}
          currentQuoteStopIndex={currentQuoteStopIdx}
          currentQuoteFreight={currentQuoteFreight}
          activeCommodities={activeCommodities}
          activeEquipmentTypes={activeEquipmentTypes}
          isFetching={commodityFetchResults.isFetching || equipmentTypeFetchResults.isFetching || calculateRatingVariableResults.isFetching}
          currentCompany={companyContext.companyKey}
          quote={quote}
        />
        <Prompt
          message={(location) => {
            const {
              forceNavigate,
              quote: { status },
              editMode,
              hasQuoteChanged,
              hasOtherInformationChanged,
              hasRateContactChanged
            } = this.props.QuoteEntryService.getState();
            if (!hasConfirmedNavigation && !forceNavigate) {
              let navigationMessage: string | undefined = undefined;

              if (!status) {
                // prior to initial quote save / rate
                navigationMessage = "before rating a quote will delete all entered data";
              } else if (editMode && status !== "ApprovalNeeded") {
                navigationMessage = "before saving quote edits will discard all changes"
              }
              else if (hasQuoteChanged || hasOtherInformationChanged || hasRateContactChanged) {
                navigationMessage = "will discard all unsaved changes"
              }

              if (navigationMessage) {
                this._displayNavigationModal(location.pathname, `Navigating away ${navigationMessage} - are you sure you want to navigate away?`);
                return false;
              }
            }

            return true;
          }}
        />
        <ConfirmDeleteModal
          isOpen={isQuoteStopDeleteModalOpen}
          onDelete={() => this._removeQuoteStop(quoteStopDeleteIndex!)}
          onDeleteCancel={this._closeRemoveQuoteStopModal}
          deleteMessage={"Are you sure you want to delete this stop?"}
        />
        <ConfirmCancelModal
          isOpen={navigationModalIsOpen}
          onCancelNo={this._onNavigationCancel}
          onCancelYes={this._onNavigationConfirm}
          message={navigationActionText}
        />
        <CustomerSearchModal
          isOpen={isCustomerSearchModalOpen}
          customerType={customerSearchType}
          selectedRow={selectedRow}
          canAdd={searchResults.hasFetched}
          onCustomerModalClose={this._onCustomerModalClose}
          onCustomerSelect={this._onCustomerSelect}
          addSeed={{ isActive: true }}
          callerId={callerId}
        />
        <AddEditCustomerModal
          isOpen={customerModalIsOpen === "CustomerSearchModal"}
          isFetching={saveCustomerResults.isFetching}
          customer={addEditCustomer ?? {}}
          regions={regionFetchResults?.data ?? []}
          salesReps={salesRepFetchResults?.data ?? []}
          customerSources={customerSources}
          disableShipperCheckbox={customerSearchType === "Shipper"}
          disableConsigneeCheckbox={customerSearchType === "Consignee"}
          onSave={this._onSaveAddCustomer}
          onCancel={this._onCancelAddCustomer}
        />
        <CityStateSearchModal
          isOpen={isCityStateSearchModalOpen}
          customerType={cityStateCustomerSearchType}
          onClose={this._onCustomerCityStateSearchClose}
          onLocationSelect={(place: Place) => this._onCustomerLocationSelect(place, cityStateCustomerSearchType)}
        />
        <QuestionModal
          questions={upchargeQuestions}
          questionType={modalQuestionType}
          answers={upchargeResponses}
          isOpen={questionModalIsOpen}
          viewOnly={viewOnly}
          quote={quote}
          setAnswer={this._onAnswerChanged}
          setOtherAnswer={this._onFlatUpchargeOtherRespChanged}
          setReason={this._onUpchargeReasonChanged}
          handleDone={this._closeQuestionModal}
        />
        <CustomerAlertModal
          isOpen={isCustomerAlertModalOpen}
          customers={alertCustomers}
          onClose={this._closeCustomerAlertModal}
          customerType={alertCustomerType}
        />
        <NotificationModal
          isOpen={isLengthRestrictionModalOpen}
          title={"Possible Length Restriction"}
          message={`Location ${notifyZipCode} may have a length restriction on the allowable trailers`}
          confirmButtonText="Dismiss"
          onConfirm={this._onDismissLengthRestrictionModal}
        />
      </>
    )
  }
}

export const QuoteView = QuoteEntryService.inject(
  CommodityService.inject(
    CompanySelectService.inject(
      DeclaredValueService.inject(
        CustomerService.inject(
          EquipmentTypeService.inject(
            StateService.inject(
              EmployeeService.inject(
                CustomerDetailService.inject(
                  LoadingInstructionService.inject(
                    CustomerSourceService.inject(
                      _QuoteView
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  )
);