import {
  _,
  React,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  Customer,
  CustomerHourDayOfWeekEnum,
  LoadingInstruction
} from "$Generated/api";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel
} from "$Imports/MaterialUIComponents";

import {
  appliesWhenTextMap,
} from "$Utilities/enumUtil";

import { ExpandMoreIcon } from "$Imports/MaterialUIIcons";

const styles: {
  accordion: string;
  instructionsTable: string;
} = require("./InstructionsTable.scss");

interface IInstructionsTableProps {
  customer: Customer;
  loadingInstructions: LoadingInstruction[];
  onInstructionsChange?: (loadingInstructionId: number, checked: boolean) => void;
}

interface IInstructionsTableState {
  confirmModalOpen: boolean;
  templateDay: CustomerHourDayOfWeekEnum | "";
  customer: Customer;
  validationErrors: ValidationError | null;
}

export class InstructionsTable extends React.Component<IInstructionsTableProps, IInstructionsTableState> {
  state: IInstructionsTableState = {
    confirmModalOpen: false,
    templateDay: "",
    customer: this.props.customer,
    validationErrors: null
  }

  componentDidUpdate(prev: IInstructionsTableProps) {
    const { customer } = this.props;
    if (customer !== prev.customer) {
      this.setState({
        customer: this.props.customer,
        validationErrors: null
      });
    }
  }

  @bind
  async _onInstructionCheckboxChange(loadingInstructionId: number | undefined, checked: boolean) {
    if (!loadingInstructionId || this.props.onInstructionsChange === undefined) {
      return;
    }

    if (checked === true) {
      await this.props.onInstructionsChange(loadingInstructionId, checked);
    }
    if (checked === false) {
      await this.props.onInstructionsChange(loadingInstructionId, checked);
    }
  }

  render() {
    const {
      loadingInstructions
    } = this.props;

    const {
      customer
    } = this.state;

    return (
      <Accordion
        disableGutters={false}
        elevation={2}
        className={styles.accordion}
        sx={{
          '&:before': {
              display: 'none',
          }
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}><b>Loading/Unloading Instructions</b></AccordionSummary>
        <AccordionDetails style={{ paddingTop: "0" }}>
          <table className={styles.instructionsTable}>
            <tbody>
              <tr>
                <th>Instruction</th>
                <th>Applies When</th>
              </tr>
              {
                _.map(loadingInstructions, (i, idx) => {
                  const isChecked: boolean = customer?.customerLoadingInstructions?.find(c => c.loadingInstructionId === i.id) ? true : false;
                  return <tr key={idx}>
                    <td>
                      <FormControlLabel
                        label={i.instruction ?? ""}
                        control={(
                          <Checkbox
                            checked={isChecked}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => this._onInstructionCheckboxChange(i.id, checked)}
                          />
                        )}
                      />
                    </td>
                    <td>{appliesWhenTextMap[i.appliesTo ?? ""]}</td>
                  </tr>
                })
              }
            </tbody>
          </table>
        </AccordionDetails>
      </Accordion>
    );
  }
}