import {
  React,
  Helpers,
  bind,
  _,
  NumberFormat,
  NumberFormatValues,
  numeral
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  AdvanceTextField,
  BinaryChoiceDialog
} from "$Imports/CommonComponents";

import {
  AccessorialCharge,
  AccessorialChargeValue
} from "$Generated/api";

import {
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps
} from "$State/QuoteEntryFreezerService";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  TextField
} from "$Imports/MaterialUIComponents";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  AccessorialChargeValueValidationSchema
} from "$State/AccessorialChargeValidationSchema";

import {
  CURRENCY_FORMAT
} from "$Shared/utilities/formatUtil";

const styles: {
  modalContainer: string;
} = require("$Shared/styles/Modal.scss");

interface IAddAccessorialChargeValueBaseProps {
  isOpen: boolean;
  quoteId: number | undefined;
  autoApprove: boolean;
  accessorialCharges: AccessorialCharge[];
  onClose: () => void;
}

interface IOwnState {
  accessorialChargeValue: AccessorialChargeValue;
  validationErrors: ValidationError | null;
  disableSave: boolean;
  confirmACModalIsOpen: boolean;
  confirmACMessage: string;
}

type IAddAccessorialChargeValueProps = IAddAccessorialChargeValueBaseProps
& IQuoteEntryServiceInjectedProps;

const EMPTY_ACCESSORIAL_CHARGE_VALUE: AccessorialChargeValue = {
  id: 0,
  quoteId: undefined,
  accessorialChargeId: undefined,
  amount: undefined,
  notes: undefined
};

class _AddAccessorialChargeValue extends React.PureComponent<IAddAccessorialChargeValueProps, IOwnState> {
  state: IOwnState = {
    accessorialChargeValue: { ...EMPTY_ACCESSORIAL_CHARGE_VALUE },
    validationErrors: null,
    disableSave: false,
    confirmACModalIsOpen: false,
    confirmACMessage: ""
  };

  @bind
  private async _onClose(shouldSave: boolean): Promise<void> {
    if(!shouldSave) {
      this.setState({
        accessorialChargeValue: EMPTY_ACCESSORIAL_CHARGE_VALUE,
        validationErrors: null,
        disableSave: false
      });
      this.props.onClose();
      return;
    }

    this.setState((prev) => ({
      accessorialChargeValue: {
        ...prev.accessorialChargeValue,
        quoteId: this.props.quoteId
      }
    }));
    
    const accessorialChargeValueErrors = await validateSchema(AccessorialChargeValueValidationSchema, this.state.accessorialChargeValue);
    this.setState({ validationErrors: accessorialChargeValueErrors });

    if (accessorialChargeValueErrors) {
      return;
    }
    
    this._openConfirmModal();
  }

  @bind
  private async _saveAndClose() {
    const { accessorialChargeValue } = this.state;
    
    try {
      await this.props.QuoteEntryService.saveAccessorialChargeValue(accessorialChargeValue);
      this.props.QuoteEntryService.fetchAccessorialChargeValues(this.props.quoteId, true);
      this.props.QuoteEntryService.fetchDataChangeLogs(this.props.quoteId);
      this.setState({
        accessorialChargeValue: EMPTY_ACCESSORIAL_CHARGE_VALUE,
        disableSave: false,
        confirmACModalIsOpen: false,
        confirmACMessage: ""
      });
      this.props.onClose();
    } catch (ex) {
      if (ex) {
        this.setState({
          disableSave: false
        });
      }
    }

  }

  @bind
  private _onAmountChange(value: NumberFormatValues) {
    const amount = !Helpers.isNullOrUndefined(value.floatValue) ? value.floatValue : undefined;
    const status = this.props.autoApprove ? "Approved" : amount ? amount > 0 ? "Approved" : "Pending" : undefined;
    
    this.setState((prev) => ({
      accessorialChargeValue: {
        ...prev.accessorialChargeValue,
        amount: amount,
        status: status
      }
    }));
  }

  @bind
  private _onChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState((prev) => ({
      accessorialChargeValue: {
        ...prev.accessorialChargeValue,
        [e.target.name]: e.target.value
      }
    }));
  }

  @bind
  private _openConfirmModal() {
    const { accessorialChargeValue } = this.state;
    const description = this.props.accessorialCharges.find(c => c.id === accessorialChargeValue.accessorialChargeId)?.description;
    this.setState({
      confirmACModalIsOpen: true,
      confirmACMessage: `Are you sure you want to add charge ${description} for ${numeral(accessorialChargeValue.amount).format(CURRENCY_FORMAT)} to this quote?`
    });
  }

  @bind
  private _closeConfirmModal() {
    this.setState({
      confirmACModalIsOpen: false,
      confirmACMessage: ""
    });
  }

  render() {
    const {
      isOpen,
      accessorialCharges
    } = this.props;

    const {
      accessorialChargeValue,
      disableSave,
      validationErrors,
      confirmACModalIsOpen,
      confirmACMessage
    } = this.state;
    
    const validationsParser = new ValidationErrorParser<AccessorialChargeValue>(validationErrors);

    return (
      <>
        <Dialog
          open={isOpen}
          fullWidth={true}
          maxWidth="xs"
        >
          <DialogTitle>
            Add Accessorial Charge
          </DialogTitle>
          <DialogContent
            className={styles.modalContainer}
          >
            <div style={{ padding: "6px" }}>
              <Grid container direction={"column"} spacing={3}>
                <Grid item>
                  <FormControl error={!validationsParser.isValid("accessorialChargeId")}>
                    <InputLabel>Charge Description *</InputLabel>
                    <Select
                      required
                      name="accessorialChargeId"
                      value={accessorialChargeValue.accessorialChargeId ?? ""}
                      onChange={(event) => this._onChange(event as React.ChangeEvent<HTMLInputElement>)}
                      style={{ width: "195px" }}
                    >
                      {_.map(accessorialCharges, (c, idx) =>
                        <MenuItem value={c.id} key={idx}>{c.description}</MenuItem>
                      )}
                    </Select>
                    {!validationsParser.isValid("accessorialChargeId") && <FormHelperText>{validationsParser.validationMessage("accessorialChargeId")}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item>
                <NumberFormat
                  required
                  label="Amount"
                  name="amount"
                  value={accessorialChargeValue.amount ?? ""}
                  error={!validationsParser.isValid("amount")}
                  helperText={validationsParser.validationMessage("amount")}
                  prefix={"$"}
                  thousandSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale
                  onValueChange={this._onAmountChange}
                  customInput={TextField}
                  onFocus={(e: React.FocusEvent<HTMLTextAreaElement> ) => e.target.select()}
                />
                </Grid>
                <Grid item>
                  <AdvanceTextField
                    label="Notes"
                    name="notes"
                    value={accessorialChargeValue.notes ?? ""}
                    onChange={this._onChange}
                    type="text"
                    inputProps={{ maxLength: 40 }}
                    fullWidth
                    error={!validationsParser.isValid("notes")}
                    helperText={validationsParser.validationMessage("notes")}
                  />
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => this._onClose(true)}
            disabled={disableSave}
          >
            Save
          </Button>
          <Button
            onClick={() => this._onClose(false)}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
        </Dialog>
        <BinaryChoiceDialog
          title="Confirm Accessorial Charge"
          isOpen={confirmACModalIsOpen}
          message={confirmACMessage}
          falseText="No"
          trueText="Yes"
          onClick={(value) => {
            if (value) {
              this._saveAndClose();
            } else {
              this._closeConfirmModal();
            }
          }}
        />
      </>
    );
  }
}

export const AddAccessorialChargeValue = QuoteEntryService.inject(_AddAccessorialChargeValue);