import {
  React,
  _,
  bind,
  moment
} from "$Imports/Imports";

import {
  Activity,
  ActivityActivityTypeEnum,
  Employee,
  Reminder
} from "$Generated/api";

import {
  AdvanceTextField,
  DateTimeInputField,
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  TextField
} from "$Imports/MaterialUIComponents";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  activityTypeTextMap
} from "$Utilities/enumUtil";

import {
  ActivityValidationSchema
} from "$State/CustomerDetailFreezerService";

import {
  ReminderValidationSchema
} from "$State/ReminderValidationSchema";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

interface IAddEditActivityModalState {
  activity: Activity;
  errors: ValidationError | null;
  disableSave: boolean;
  setReminder: boolean;
  reminder: Reminder | undefined;
  reminderErrors: ValidationError | null;
  activityTimeEmpty: boolean;
  reminderTimeEmpty: boolean;
  reminderType: ActivityActivityTypeEnum | undefined;
  reminderTypeError: boolean;
}

interface IAddEditActivityModalProps {
  isOpen: boolean;
  activity: Activity;
  salesReps: Employee[];
  onClose: (activity?: Activity, reminder?: Reminder) => void;
}

const initialActivity: Activity = {
  createdOn: undefined,
  activityType: undefined,
  noteText: "",
  isActive: true,
  isPinned: false,
};

const initialReminder: Reminder = {
  id: 0,
  createdOn: moment().toDate(),
  type: "Activity",
  text: "",
  isComplete: false,
  isActive: true
};

export class AddEditActivityModal extends React.Component<IAddEditActivityModalProps, IAddEditActivityModalState> {
  state: IAddEditActivityModalState = {
    activity: {
      ...initialActivity
    },
    errors: null,
    disableSave: false,
    setReminder: false,
    reminder: undefined,
    reminderErrors: null,
    activityTimeEmpty: false,
    reminderTimeEmpty: false,
    reminderType: undefined,
    reminderTypeError: false
  };

  componentDidUpdate(prev: IAddEditActivityModalProps) {
    if(prev.isOpen !== this.props.isOpen) {
      this.setState({
        disableSave: false,
        setReminder: false,
        activityTimeEmpty: false,
        reminderTimeEmpty: false,
        reminderType: undefined,
        reminderTypeError: false
      })
    }

    if (this.props.activity !== prev.activity) {
      this.setState({
        activity: {
          ...initialActivity,
          ...this.props.activity,
          createdOn: this.props.activity.createdOn ?? moment().toDate()
        },
        reminder: undefined,
        errors: null,
        reminderErrors: null,
        activityTimeEmpty: false,
        reminderTimeEmpty: false,
        reminderType: undefined,
        reminderTypeError: false
      });
    }
  }
  
  @bind
  private _onDueDateTimeChange(value: Date | null | undefined, timeEmpty: boolean = false): void {
    this.setState((prev) => ({
      activity: {
        ...prev.activity,
        createdOn: moment(value).toDate()
      },
      activityTimeEmpty: timeEmpty
    }));
  }
  
  @bind
  private _onReminderDueDateTimeChange(value: Date | null | undefined, timeEmpty: boolean = false): void {
    this.setState((prev) => ({
      reminder: {
        ...prev.reminder,
        dueDate: moment(value).toDate()
      },
      reminderTimeEmpty: timeEmpty
    }));
  }

  @bind
  private _onTypeChange(event: SelectChangeEvent): void {
    this.setState((prev) => ({
      activity: {
        ...prev.activity,
        activityType: event.target.value as ActivityActivityTypeEnum
      }
    }));
  }

  @bind
  private _onReminderTypeChange(event: SelectChangeEvent): void {
    this.setState((prev) => ({
      reminder: {
        ...prev.reminder,
        text: event.target.value !== "Other" ? activityTypeTextMap[event.target.value as ActivityActivityTypeEnum] : undefined
      },
      reminderType: event.target.value as ActivityActivityTypeEnum
    }));
  }

  @bind
  private _onTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState((prev) => ({
      activity: {
        ...prev.activity,
        noteText: event.target.value
      }
    }));
  }

  @bind
  private _onReminderTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState((prev) => ({
      reminder: {
        ...prev.reminder,
        text: event.target.value
      }
    }));
  }

  @bind
  private _onScheduleReminderChange(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    const { activity, reminder, setReminder } = this.state;
    if (!setReminder && !reminder?.createdById) {
      const userId = SharedSecurityContext.getUserId();
      const createdById = _.find(this.props.salesReps, (u) => u.userId === userId)?.id;

      this.setState({
        setReminder: true,
        reminder: {
          ...initialReminder,
          createdById: createdById,
          customer: {
            linkId: activity.customerId ?? undefined 
          }
        }
      });
    } else {
      this.setState((prev) => ({
        setReminder: !prev.setReminder,
        reminder: undefined,
        reminderErrors: null
      }));
    }
  }

  @bind
  private _onAssignedToChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState((prev) => ({
      reminder: {
        ...prev.reminder,
        createdById: Number(e.target.value)
      }
    }));
  }

  @bind
  private async _onClose(shouldSave: boolean): Promise<void> {
    if(!shouldSave) {
      this.props.onClose();
      return;
    }

    const { activity, setReminder, reminder, reminderType } = this.state;

    const errors = await validateSchema(ActivityValidationSchema, activity, {
      abortEarly: false,
      context: { timeEmpty: this.state.activityTimeEmpty }
    });
    const reminderErrors = setReminder ? await validateSchema(ReminderValidationSchema, reminder, {
      abortEarly: false,
      context: { timeEmpty: this.state.reminderTimeEmpty }
    }) : null;
    const reminderTypeError = setReminder ? !reminderType : false;
    this.setState({ errors: errors, reminderErrors: reminderErrors, reminderTypeError: reminderTypeError });
    
    if (errors || reminderErrors) {
      return;
    } else if (reminder && reminderType === "Other") {
      reminder.text = `Other - ${reminder.text}`;
    }

    this.setState({
      disableSave: true
    });

    this.props.onClose(activity, reminder);
  }

  render() {
    const {
      isOpen,
      salesReps
    } = this.props;

    const {
      activity: {
        id,
        createdOn,
        activityType,
        noteText
      },
      errors,
      disableSave,
      setReminder,
      reminder,
      reminderErrors,
      reminderType,
      reminderTypeError
    } = this.state;

    const validationParser = new ValidationErrorParser<Activity>(errors);
    const reminderValidationParser = new ValidationErrorParser<Reminder>(reminderErrors);
    
    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>{!id ? "Add Activity" : "Edit Activity"}</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <DateTimeInputField
                value={createdOn ?? null}
                onChange={this._onDueDateTimeChange}
                dateLabel="Date"
                timeLabel="Time *"
                error={!validationParser.isValid("createdOn")}
                helperText={validationParser.validationMessage("createdOn")}
                required
              />
            </Grid>
            <Grid item>
              <FormControl style={{ width: "11.875rem" }} error={!validationParser.isValid("activityType")}>
                <InputLabel>Type *</InputLabel>
                <Select
                  value={activityType}
                  onChange={this._onTypeChange}
                  error={!validationParser.isValid("activityType")}
                  required
                >
                  <MenuItem value="PhoneCallCold">{activityTypeTextMap["PhoneCallCold"]}</MenuItem>
                  <MenuItem value="PhoneCallFirstAppt">{activityTypeTextMap["PhoneCallFirstAppt"]}</MenuItem>
                  <MenuItem value="PhoneCallFollowup">{activityTypeTextMap["PhoneCallFollowup"]}</MenuItem>
                  <MenuItem value="InPersonColdCall">{activityTypeTextMap["InPersonColdCall"]}</MenuItem>
                  <MenuItem value="InPersonFirstAppt">{activityTypeTextMap["InPersonFirstAppt"]}</MenuItem>
                  <MenuItem value="InPersonFollowup">{activityTypeTextMap["InPersonFollowup"]}</MenuItem>
                  <MenuItem value="EmailColdCall">{activityTypeTextMap["EmailColdCall"]}</MenuItem>
                  <MenuItem value="EmailFollowup">{activityTypeTextMap["EmailFollowup"]}</MenuItem>
                  <MenuItem value="MarketingCampaign">{activityTypeTextMap["MarketingCampaign"]}</MenuItem>
                  <MenuItem value="NewAccountHandoff">{activityTypeTextMap["NewAccountHandoff"]}</MenuItem>
                  <MenuItem value="Proposal">{activityTypeTextMap["Proposal"]}</MenuItem>
                  <MenuItem value="Other">{activityTypeTextMap["Other"]}</MenuItem>
                </Select>
                {!validationParser.isValid("activityType") && <FormHelperText>{validationParser.validationMessage("activityType")}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item>
              <AdvanceTextField
                label="Notes"
                value={noteText ?? ""}
                onChange={this._onTextChange}
                maxRows={3}
                inputProps={{ maxLength: 300 }}
                error={!validationParser.isValid("noteText")}
                helperText={validationParser.validationMessage("noteText")}
                multiline
                fullWidth
                required
              />
            </Grid>
            {!id &&
              <Grid item>
                <FormControlLabel
                  label="Schedule to-do reminder"
                  control={(
                    <Checkbox
                      checked={setReminder}
                      onChange={this._onScheduleReminderChange}
                      name="isPrimary"
                    />
                  )}
                />
              </Grid>
            }
            {setReminder &&
              <>
                <Grid item>
                  <DateTimeInputField
                    value={reminder?.dueDate ?? null}
                    onChange={this._onReminderDueDateTimeChange}
                    dateLabel="Due Date"
                    timeLabel="Due Time *"
                    error={!reminderValidationParser.isValid("dueDate")}
                    helperText={reminderValidationParser.validationMessage("dueDate")}
                    required
                    disablePast
                  />
                </Grid>
                <UserAccessControl roles={["reminder:manage-others"]}>
                  <Grid item>
                    <FormControl 
                      style={{minWidth: "5rem"}} 
                      error={!reminderValidationParser.isValid("createdById")}
                      required
                    >
                      <InputLabel shrink>Assigned To</InputLabel>
                      <Select
                        value={reminder?.createdById}
                        onChange={(event) => this._onAssignedToChange(event as React.ChangeEvent<HTMLInputElement>)}
                      >
                        {salesReps.map((s, idx) => (
                          <MenuItem value={s.id} key={idx}>{`${s.firstName} ${s.lastName}`}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{reminderValidationParser.validationMessage("createdById")}</FormHelperText>
                    </FormControl>
                  </Grid>
                </UserAccessControl>
                <Grid item>
                  <FormControl style={{ width: "11.875rem" }} error={reminderTypeError}>
                    <InputLabel>Type *</InputLabel>
                    <Select
                      value={reminderType}
                      onChange={this._onReminderTypeChange}
                      required
                    >
                      <MenuItem value="PhoneCallCold">{activityTypeTextMap["PhoneCallCold"]}</MenuItem>
                      <MenuItem value="PhoneCallFirstAppt">{activityTypeTextMap["PhoneCallFirstAppt"]}</MenuItem>
                      <MenuItem value="PhoneCallFollowup">{activityTypeTextMap["PhoneCallFollowup"]}</MenuItem>
                      <MenuItem value="InPersonColdCall">{activityTypeTextMap["InPersonColdCall"]}</MenuItem>
                      <MenuItem value="InPersonFirstAppt">{activityTypeTextMap["InPersonFirstAppt"]}</MenuItem>
                      <MenuItem value="InPersonFollowup">{activityTypeTextMap["InPersonFollowup"]}</MenuItem>
                      <MenuItem value="EmailColdCall">{activityTypeTextMap["EmailColdCall"]}</MenuItem>
                      <MenuItem value="EmailFollowup">{activityTypeTextMap["EmailFollowup"]}</MenuItem>
                      <MenuItem value="MarketingCampaign">{activityTypeTextMap["MarketingCampaign"]}</MenuItem>
                      <MenuItem value="Proposal">{activityTypeTextMap["Proposal"]}</MenuItem>
                      <MenuItem value="NewAccountHandoff">{activityTypeTextMap["NewAccountHandoff"]}</MenuItem>
                      <MenuItem value="Other">{activityTypeTextMap["Other"]}</MenuItem>
                    </Select>
                    {reminderTypeError && <FormHelperText>{reminderTypeError ? "Type is required" : ""}</FormHelperText>}
                  </FormControl>
                </Grid>
                {reminderType === "Other" && 
                  <Grid item>
                    <TextField
                      label="Description"
                      value={reminder?.text}
                      onChange={this._onReminderTextChange}
                      maxRows={3}
                      inputProps={{
                        maxLength: 292
                      }}
                      error={!reminderValidationParser.isValid("text")}
                      helperText={reminderValidationParser.validationMessage("text")}
                      multiline
                      fullWidth
                      required
                    />
                  </Grid>}
              </>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => this._onClose(true)}
            disabled={disableSave}
          >
            Save
          </Button>
          <Button
            onClick={() => this._onClose(false)}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}