import {
  React,
  Helpers,
  bind,
  _,
  NumberFormat,
  NumberFormatValues
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  ZoneConfiguration,
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  TextField
} from "$Imports/MaterialUIComponents";

import {
  formModeType,
  IZoneRateConfig,
  ZoneRatesValidationSchema
} from "$State/ZoneConfigurationService";

import {
  AjaxActionIndicator,
  ConfirmCancelModal,
  DisplayFormattedNumber,
  PercentageInputField
} from "$Imports/CommonComponents";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

const styles: {
  container: string;
  defaults: string;
} = require("./EditZoneConfiguration.scss");

import { 
  ThemeOptions, 
  ThemeProvider, 
  createTheme 
} from "$Imports/MaterialUIStyles";

const tableCellTheme: ThemeOptions = {
  components: {
    MuiTableCell: {
      styleOverrides: {

        head: {
          borderBottom: "none"
        },
        body: {
          borderBottom: "none"
        }
      }
    }
  }
}

interface IEditZoneConfigurationProps {
  formMode: formModeType;
  zoneConfigData: ZoneConfiguration[] | null;
  validationErrors: ValidationError | null;
  dataChanged: () => any;
  isFetching?: boolean;
  onSave: () => void;
  onCancelClick: () => void;
  onZoneConfigsChanged: (index: number, zoneConfigs: Partial<ZoneConfiguration>) => void;
  defaultZoneRateConfig: IZoneRateConfig;
  defaultValidationErrors: ValidationError | null;
  onDefaultsChanged: (defaultValues: Partial<IZoneRateConfig>) => void;
  applyDefaults: () => void;
  onCancelYes: () => void;
  onCancelNo: () => void;
  cancelOpen: boolean;
}

export class EditZoneConfiguration extends React.Component<IEditZoneConfigurationProps> {

  static defaultProps: Partial<IEditZoneConfigurationProps> = {
    isFetching: false,
  };

  @bind
  private _onSubmit() {
    this.props.onSave();
  }

  @bind
  private _onDefaultLowPercentageChange(value?: number) {
    this.props.onDefaultsChanged({ lowRatePercentage: value });
  }

  @bind
  private _onDefaultMinimumPerLoadChange(value?: NumberFormatValues) {
    this.props.onDefaultsChanged({ minimumQuoteAmount: value?.floatValue });
  }

  @bind
  private async _applyDefaults() {
    this.props.applyDefaults();
  }

  @bind
  private _onLowPercentageChange(index: number, value?: number) {
    this.props.onZoneConfigsChanged(index, { lowRatePercentage: value });
  }

  @bind
  private _onMinimumPerLoadChange(index: number, value?: number) {
    this.props.onZoneConfigsChanged(index, { minimumQuoteAmount: value });
  }

  render() {
    const {
      formMode,
      zoneConfigData,
      validationErrors,
      isFetching,
      defaultZoneRateConfig,
      defaultValidationErrors
    } = this.props;

    const validationsParser = new ValidationErrorParser<ZoneConfiguration>(validationErrors);
    const defaultValidationsParser = new ValidationErrorParser<IZoneRateConfig>(defaultValidationErrors);

    return !Helpers.isNullOrUndefined(zoneConfigData) ? (
      <>
        <Dialog
          open={formMode === "edit"}
          maxWidth={"md"}
        >
          <AjaxActionIndicator
            showProgress={isFetching}
          />
          <DialogTitle>
            Edit Zone Rates
          </DialogTitle>
          <DialogContent className={styles.container}>
            <div className={styles.defaults}>
              <div>
                <span>Default Low Percentage</span>
                <br/>
                <PercentageInputField
                  decimalScale={1}
                  value={defaultZoneRateConfig.lowRatePercentage}
                  onPercentValueChange={this._onDefaultLowPercentageChange}
                  style={{ maxWidth: "100px" }}
                  error={!defaultValidationsParser.isValid("lowRatePercentage")}
                  helperText={defaultValidationsParser.validationMessage("lowRatePercentage")}
                />
              </div>
              <div>
                <span>Default Minimum $ per Load</span>
                <br/>
                <NumberFormat
                  prefix={'$'}
                  thousandSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale
                  allowNegative={false}
                  value={defaultZoneRateConfig.minimumQuoteAmount}
                  onValueChange={this._onDefaultMinimumPerLoadChange}
                  customInput={TextField}
                  style={{maxWidth: "100px"}}
                  error={!defaultValidationsParser.isValid("minimumQuoteAmount")}
                  helperText={defaultValidationsParser.validationMessage("minimumQuoteAmount")}
                />
              </div>
              <Button
                color="primary"
                onClick={this._applyDefaults}
              >
                Apply
              </Button>
            </div>
          <ThemeProvider 
              theme={(theme) => createTheme(
                tableCellTheme,
                theme
              )}
            >
              <Table>
                <TableHead>
                  <TableRow >
                    <TableCell >
                      Destination Zone
                    </TableCell>
                    <TableCell>
                      Current DAT RATE
                    </TableCell>
                    <TableCell>
                      Low Percentage
                    </TableCell>
                    <TableCell>
                      Minimum $ per Load
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(zoneConfigData, (d: ZoneConfiguration, dIdx: number) => (
                    <TableRow key={dIdx}>
                      <TableCell >
                        {d.destZone ? d.destZone.zoneName : ""}
                      </TableCell>
                      <TableCell >
                        <DisplayFormattedNumber value={d.currentDatRate} formatString={'$0,0.00'} emptyDisplay={'N/A'} />
                      </TableCell>
                      <TableCell>
                        <PercentageInputField
                          error={!validationsParser.isValid("lowRatePercentage", dIdx)}
                          helperText={validationsParser.validationMessage("lowRatePercentage", dIdx)}
                          decimalScale={1}
                          value={d.lowRatePercentage}
                          onPercentValueChange={(value?: number) => this._onLowPercentageChange(dIdx, value)}
                          style={{maxWidth: "100px"}}
                        />
                      </TableCell>
                      <TableCell>
                        <NumberFormat
                          error={!validationsParser.isValid("minimumQuoteAmount", dIdx)}
                          helperText={validationsParser.validationMessage("minimumQuoteAmount", dIdx)}
                          prefix={'$'}
                          thousandSeparator={","}
                          decimalScale={2}
                          fixedDecimalScale
                          allowNegative={false}
                          value={d.minimumQuoteAmount}
                          onValueChange={(value?: NumberFormatValues) => this._onMinimumPerLoadChange(dIdx, value?.floatValue)}
                          customInput={TextField}
                          style={{maxWidth: "100px"}}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ThemeProvider>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={isFetching}
              onClick={this._onSubmit}
            >
              Save
            </Button>
            <Button
              onClick={this.props.onCancelClick}
              disabled={isFetching}
              variant="outlined"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog >
        <ConfirmCancelModal
          isOpen={this.props.cancelOpen}
          onCancelNo={this.props.onCancelNo}
          onCancelYes={this.props.onCancelYes}
        />
      </>
    ) : null;
  }
}
