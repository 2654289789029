import {
  _,
  React,
  bind,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  Customer,
  CustomerHour,
  CustomerHourDayOfWeekEnum
} from "$Generated/api";

import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  TimePicker,
} from "$Imports/MaterialUIComponents";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  ErrorService
} from "$State/ErrorFreezerService";

import { ExpandMoreIcon } from "$Imports/MaterialUIIcons";
import AppConstants from "$Utilities/AppConstants";
import { CustomerHourValidationSchema } from "$State/CustomerFreezerService";

const styles: {
  accordion: string;
  templateContainer: string;
  hourRow: string;
  dayLabel: string;
  textbox: string;
  checkbox: string;
} = require("./CustomerHours.scss");

interface ICustomerHoursProps {
  customer: Customer;
  onHoursChange: (day: CustomerHourDayOfWeekEnum, hours: Partial<CustomerHour>) => void;
  onApplyTemplate: (customerHours: Partial<CustomerHour[]>) => void;
  validationErrors: ValidationError | null;
}

interface ICustomerHoursState {
  templateDay: CustomerHourDayOfWeekEnum | "";
}

export class CustomerHours extends React.Component<ICustomerHoursProps, ICustomerHoursState> {
  state: ICustomerHoursState = {
    templateDay: ""
  }

  @bind
  private _onTemplateDayChange(e: SelectChangeEvent<CustomerHourDayOfWeekEnum | "">) {
    this.setState({
      templateDay: e.target.value ? e.target.value as CustomerHourDayOfWeekEnum : ""
    });
  }

  @bind
  private async _applyTemplate(includeWeekends: boolean) {
    const {
      templateDay
    } = this.state;
    if (templateDay === "") return;

    const {
      customer
    } = this.props;
    const index = _.findIndex(customer.customerHours ?? [], h => h.dayOfWeek === templateDay);
    const currentDay = customer.customerHours?.[index];

    const currentDayErrors = await validateSchema(CustomerHourValidationSchema, currentDay, {
      context: {
        currentTime: moment(new Date()).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
      },
      abortEarly: false
    });

    if (!currentDayErrors && currentDay !== undefined && (currentDay.allDay || currentDay.closed || currentDay.openTime || currentDay.closeTime)) {
      const dayArray = includeWeekends ? AppConstants.DayOfWeekEnumArray : _.filter(AppConstants.DayOfWeekEnumArray, day => day !== "Saturday" && day !== "Sunday");
      const customerHours: CustomerHour[] = []; 
      _.forEach(dayArray, (day) => {
        customerHours.push({
          dayOfWeek: day,
          openTime: currentDay.openTime,
          closeTime: currentDay.closeTime,
          allDay: currentDay.allDay,
          closed: currentDay.closed
        });
      });
      this.props.onApplyTemplate(customerHours);
    }
    else {
      let errorMsg = '';
      _.forEach(currentDayErrors?.errors, e => {
        errorMsg = e && errorMsg === '' ? e : errorMsg;
      });
      ErrorService.pushErrorMessage(`Hours of operation not set for ${templateDay}${errorMsg !== '' ? ` (${errorMsg})` : ''}`);
    }
  }

  @bind
  private openTimeChange(dayOfWeek: CustomerHourDayOfWeekEnum, time: string | undefined, ) {
    if (this.props.onHoursChange !== undefined) {
      this.props.onHoursChange(dayOfWeek, { openTime: time });
    }
  }

  @bind
  private closeTimeChange(dayOfWeek: CustomerHourDayOfWeekEnum, time: string | undefined, ) {
    if (this.props.onHoursChange !== undefined) {
      this.props.onHoursChange(dayOfWeek, { closeTime: time });
    }
  }

  @bind
  private allDayChange(dayOfWeek: CustomerHourDayOfWeekEnum, check: boolean) {
    if (this.props.onHoursChange !== undefined) {
      if (check) {
        this.props.onHoursChange(dayOfWeek, {
          allDay: check,
          closed: false,
          openTime: "",
          closeTime: ""
        });
      }
      else {
        this.props.onHoursChange(dayOfWeek, { allDay: check });
      }
    }
  }

  @bind
  private isClosedChange(dayOfWeek: CustomerHourDayOfWeekEnum, check: boolean) {
    if (this.props.onHoursChange !== undefined) {
      if (check) {
        this.props.onHoursChange(dayOfWeek, {
          closed: check,
          allDay: false,
          openTime: "",
          closeTime: ""
        });
      }
      else {
        this.props.onHoursChange(dayOfWeek, { closed: check });
      }
    }
  }

  render() {
    const {
      customer,
      validationErrors
    } = this.props;

    const validationsParser = new ValidationErrorParser<Customer>(validationErrors);

    return (
      <Accordion
        disableGutters
        elevation={2}
        className={styles.accordion}
        sx={{
          '&:before': {
              display: 'none',
          }
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}><b>Hours of Operation</b></AccordionSummary>
        <AccordionDetails style={{ paddingTop: "0" }}>
          <div className={styles.templateContainer}>
            <FormControl style={{ width: "150px" }}>
              <InputLabel>Day of Week</InputLabel>
              <Select
                value={this.state.templateDay}
                name="dayOfWeek"
                onChange={this._onTemplateDayChange}
              >
                {_.map(AppConstants.DayOfWeekEnumArray, (day, idx) => <MenuItem value={day} key={idx}>{day}</MenuItem>)}
              </Select>
            </FormControl>
            <Button onClick={() => this._applyTemplate(false)}>Apply to Weekdays</Button>
            <Button onClick={() => this._applyTemplate(true)}>Apply to All</Button>
          </div>
          {
            // open/close times are converted moment <--> string
            _.map(AppConstants.DayOfWeekEnumArray, (day, idx) => {
              const index = _.findIndex(customer.customerHours ?? [], h => h.dayOfWeek === day);
              const currentDay = customer.customerHours?.[index];

              return (
                <div className={styles.hourRow} key={idx}>
                  <div className={styles.dayLabel}>{day}:</div>
                  <TimePicker
                    label="From"
                    value={currentDay?.openTime ? moment(currentDay.openTime, "HH:mm") : null}
                    onChange={(time: moment.Moment | null) => this.openTimeChange(day, time?.format("HH:mm"))}
                    disabled={currentDay?.allDay || currentDay?.closed}
                    ampm={true}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        className={styles.textbox}
                        error={!validationsParser.isValidDeep(`customerHours[${index}].openTime`)}
                        helperText={validationsParser.validationMessageDeep(`customerHours[${index}].openTime`)}
                      />
                    )}
                  />
                  <TimePicker
                    label="To"
                    value={currentDay?.closeTime ? moment(currentDay.closeTime, "HH:mm") : null}
                    onChange={(time: moment.Moment | null) => this.closeTimeChange(day, time?.format("HH:mm"))}
                    disabled={currentDay?.allDay || currentDay?.closed}
                    ampm={true}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        className={styles.textbox}
                        error={!validationsParser.isValidDeep(`customerHours[${index}].closeTime`)}
                        helperText={validationsParser.validationMessageDeep(`customerHours[${index}].closeTime`)}
                      />
                    )}
                  />
                  <div className={styles.checkbox}>
                    <FormControlLabel
                      label="All Day?"
                      control={(
                        <Checkbox
                          checked={currentDay?.allDay ?? false}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => this.allDayChange(day, checked)}
                          name="allDay"
                        />
                      )}
                    />
                  </div>
                  <div className={styles.checkbox}>
                    <FormControlLabel
                      label="Closed?"
                      control={(
                        <Checkbox
                          checked={currentDay?.closed ?? false}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => this.isClosedChange(day, checked)}
                          name="closed"
                        />
                      )}
                    />
                  </div>
                </div>
              );
            })
          }
        </AccordionDetails>
      </Accordion>
    );
  }
}