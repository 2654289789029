import {
  React,
  bind,
  _,
  NumberFormat,
  NumberFormatValues,
  Helpers
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  DialogActions,
  DialogTitle,
  DialogContent,
  Dialog,
  Button,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  SelectChangeEvent
} from "$Imports/MaterialUIComponents";

import {
  SecurityContext
} from "$Utilities/Security/ApplicationSecuritySettings";

import {
  QuoteCarrierFlattened,
  QuoteCarrierFlattenedCarrierStatusEnum,
  Vendor,
  Employee
} from "$Generated/api";

import {
  QuoteCarrierValidationSchema
} from "$State/CarrierFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  ErrorService
} from "$State/ErrorFreezerService";

const styles: {
  modalContainer: string;
} = require("./CarrierManagementPage.scss");

interface ICarrierInfoEditModalState {
  carrier: QuoteCarrierFlattened;
  validationErrors: ValidationError | null;
}

interface ICarrierInfoEditModalProps {
  isOpen: boolean;
  isSaving: boolean;
  currentUserId: number | undefined;
  carrier: QuoteCarrierFlattened | undefined;
  vendors: Vendor[];
  carrierReps: Employee[];
  onSave: (carrier: QuoteCarrierFlattened) => void;
  onCancel: () => void;
}

export class CarrierInfoEditModal extends React.PureComponent<ICarrierInfoEditModalProps, ICarrierInfoEditModalState> {

  state: ICarrierInfoEditModalState = {
    carrier: {},
    validationErrors: null
  };

  componentDidUpdate(prev: ICarrierInfoEditModalProps) {
    if (this.props.carrier && this.props.carrier !== prev.carrier) {
      this.setState({
        carrier: {...this.props.carrier}
      });
    }
  }

  @bind
  private async onSave() {
    const errors = await validateSchema(QuoteCarrierValidationSchema, this.state.carrier);
    this.setState({ validationErrors: errors });

    if (!errors) {
      this.props.onSave(this.state.carrier);
    }
  }

  @bind
  private onCancel() {
    this.setState({ validationErrors: null }, this.props.onCancel)
  }

  @bind
  private onMarketChange(value: string) {
    const isMarketPrimary = value === "true" ? true : false;
    this.setState((prev) => ({
      carrier: {
        ...prev.carrier,
        isMarketPrimary: isMarketPrimary
      }
    }));
  }

  @bind
  private onStatusChange(status: QuoteCarrierFlattenedCarrierStatusEnum) {
    let carrierRepId = this.state.carrier.carrierRepId;
    if (Helpers.isNullOrUndefined(carrierRepId) && status === "Assigned") {
      carrierRepId = this.props.currentUserId;
    }

    this.setState((prev) => ({
      carrier: {
        ...prev.carrier,
        carrierStatus: status,
        carrierRepId: carrierRepId
      }
    }));
  }

  @bind
  private onVendorChange(val: Vendor) {
    this.setState((prev) => ({
      carrier: {
        ...prev.carrier,
        vendor: val,
        vendorId: val?.id ?? undefined
      }
    }));
  }

  @bind
  private onCarrierRepChange(event: SelectChangeEvent<string | number | undefined>) {
    this.setState((prev) => ({
      carrier: {
        ...prev.carrier,
        carrierRepId: event.target.value ? event.target.value as number : undefined
      }
    }));
  }

  @bind
  private onCarrierRateChange(value?: NumberFormatValues) {
    this.setState((prev) => ({
      carrier: {
        ...prev.carrier,
        carrierRate: value?.floatValue
      }
    }));
  }

  @bind
  private onTripNumberChange(value?: NumberFormatValues) {
    this.setState((prev) => ({
      carrier: {
        ...prev.carrier,
        tripNumber: value?.floatValue
      }
    }));
  }

  @bind
  private removeInactiveCarrierRep() {
    this.setState((prev) => ({
      carrier: {
        ...prev.carrier,
        carrierRepId: undefined
      }
    }));
    ErrorService.pushErrorMessage("Inactive carrier rep removed.");
  }

  render() {
    const {
      isOpen,
      vendors,
      carrierReps,
      isSaving
    } = this.props;

    const {
      carrier,
      validationErrors
    } = this.state;

    const carrierRepInactive = carrierReps && carrier?.carrierRepId ? !_.find(carrierReps, (r) => r.id === carrier?.carrierRepId) : false;
    if (carrierRepInactive) {
      this.removeInactiveCarrierRep();
    }

    const validationsParser = new ValidationErrorParser<QuoteCarrierFlattened>(validationErrors);
    const fbNumber = carrier.freightBillNumber ?? "";

    const isAdminUser = SecurityContext.isInGroup("/Admin");
    const isManagerUser =  SecurityContext.isInGroup("/Manager");

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>
          Edit Carrier Information for {fbNumber}
        </DialogTitle>
        <DialogContent className={styles.modalContainer}>
          {(isAdminUser || isManagerUser) && <><FormControl style={{ width: "150px" }}>
            <InputLabel shrink>Market</InputLabel>
            <Select<Boolean>
              value={carrier?.isMarketPrimary}
              disabled={!(carrier?.carrierStatus === "Available" || carrier?.carrierStatus === "Assigned" || carrier?.carrierStatus === "Picked" || carrier?.carrierStatus === "OnHold")}
              onChange={(event) => this.onMarketChange(event.target.value as string)}
            >
              <MenuItem value="true">Primary</MenuItem>
              <MenuItem value="false">Secondary</MenuItem>
            </Select>
          </FormControl>
          <br /></>}
          <FormControl style={{ width: "150px" }} error={!validationsParser.isValid("carrierStatus")}>
            <InputLabel shrink>Carrier Status</InputLabel>
            <Select<QuoteCarrierFlattenedCarrierStatusEnum>
              value={carrier?.carrierStatus}
              onChange={(event) => this.onStatusChange(event.target.value as QuoteCarrierFlattenedCarrierStatusEnum)}
            >
              <MenuItem value="Assigned">Assigned</MenuItem>
              <MenuItem value="Available">Available</MenuItem>
              <MenuItem value="Delivered">Delivered</MenuItem>
              <MenuItem value="OnHold">On Hold</MenuItem>
              <MenuItem value="Picked">Picked</MenuItem>
            </Select>
            {!validationsParser.isValid("carrierStatus") && <FormHelperText>{validationsParser.validationMessage("carrierStatus")}</FormHelperText>}
          </FormControl>
          <br/>
          <FormControl style={{ width: "200px" }} error={!validationsParser.isValid("carrierRepId")}>
            <InputLabel>Carrier Rep</InputLabel>
            <Select
              value={carrier?.carrierRepId ?? ""}
              onChange={this.onCarrierRepChange}
            >
              <MenuItem value={""}>&nbsp;</MenuItem>
              {carrierReps.map((e, idx) =>
                <MenuItem value={e.id} key={idx}>
                  {`${e.firstName} ${e.lastName}`}
                </MenuItem>
              )}
            </Select>
            {!validationsParser.isValid("carrierRepId") && <FormHelperText>{validationsParser.validationMessage("carrierRepId")}</FormHelperText>}
          </FormControl>
          <Autocomplete<Vendor, false, true, false>
            options={vendors}
            autoHighlight
            onChange={(event, value) => { this.onVendorChange(value) }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.vendorName ?? ""}
            value={carrier?.vendor}
            renderInput={(params) =>
              <TextField
                {...params}
                fullWidth
                label="Carrier"
                error={!validationsParser.isValid("vendorId")}
                helperText={validationsParser.validationMessage("vendorId") || "Limit 100 displayed. Type to filter on vendor name."}
              />
            }
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.vendorName}
                </li>
              )
            }}
            filterOptions={(options, state) => {
              var filtered = _.take(_.filter(options, v => (v.vendorName ?? "").toLowerCase().includes(state.inputValue.toLowerCase())), 100)
              return filtered;
            }}
          />
          <NumberFormat
            allowNegative={false}
            decimalSeparator="."
            decimalScale={2}
            thousandSeparator={true}
            prefix="$"
            label="Carrier Rate"
            value={carrier?.carrierRate?.toFixed(2) ?? ""}
            customInput={TextField}
            onValueChange={this.onCarrierRateChange}
            error={!validationsParser.isValid("carrierRate")}
            helperText={validationsParser.validationMessage("carrierRate")}
          />
          <br/>
          <NumberFormat
            allowNegative={false}
            decimalScale={0}
            label="Trip Number"
            value={carrier?.tripNumber ?? ""}
            customInput={TextField}
            onValueChange={this.onTripNumberChange}
            error={!validationsParser.isValid("tripNumber")}
            helperText={validationsParser.validationMessage("tripNumber")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onSave} disabled={isSaving}>
            Save
          </Button>
          <Button onClick={this.onCancel} disabled={isSaving} variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}