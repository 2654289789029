import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  CreateReminderButton,
  UserAccessControl,
  NotificationModal,
  ConvertCustomerModal
} from "$Imports/CommonComponents";

import {
  Button
} from "$Imports/MaterialUIComponents";

import {
  Customer,
  CustomerConvertParams,
  Quote,
  QuoteStatusEnum,
  QuoteStop,
  Reminder
} from "$Generated/api"

import {
  SecurityContext,
  SalesPortalGroup
} from "$Utilities/Security/ApplicationSecuritySettings";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  IQuoteEntryServiceInjectedProps,
  QuoteEntryService
} from "$State/QuoteEntryFreezerService";

import {
  EMPTY_REMINDER
} from "$State/ReminderFreezerService";

import {
  CustomerService,
  ICustomerServiceInjectedProps
} from "$State/CustomerFreezerService";

import {
  EmployeeService,
  IEmployeeServiceInjectedProps
} from "$State/EmployeeFreezerService";

import {
  approvalReasonTextMap,
  quoteStatusTextMap
} from "$Utilities/enumUtil";

import {
  printQuote
} from "$Utilities/windowUtil";

import {
  ReasonModal
} from "./ReasonModal";

import {
  CancelQuoteModal
} from "./CancelQuoteModal";

import {
  RateDiscrepancyModal
} from "./RateDiscrepancyModal";

import {
  RateModelCard
} from "./RateModelCard";

import {
  QuoteInfoCard
} from "./QuoteInfoCard";

import {
  ContactInfoCard
} from "./ContactInfoCard";

import {
  QuoteCardSection
} from "./QuoteCardSection";

import {
  NegotiatedRateEntryCard
} from "./NegotiatedRateEntryCard";

import {
  OverdimensionalApprovalCard
} from "./OverdimensionalApprovalCard";

import {
  OtherInformationCard
} from "./OtherInformationCard";

import {
  QuoteApprovalsCard
} from "./QuoteApprovalsCard";

import {
  EditHistoryCard
} from "./EditHistoryComponents/EditHistoryCard";

import {
  AccessorialChargesCard
} from "./AccessorialChargesComponents/AccessorialChargesCard";

import { 
  NavigationService
} from "$State/NavigationFreezerService";

import { 
  CustomerSearchModal
} from "./CustomerSearchModal";

import {
  BillingConfirmationModal
} from "./BillingConfirmationModal";

const styles: {
  mainContainer: string;
  modelQuoteContainer: string;
} = require("./RateInfoSection.scss");

interface IRateInfoSectionBaseProps {
  companyId: number | undefined;
  tmCompanyId: number | undefined;
  onCreateReminder: (seed: Reminder) => void;
}

interface IOwnState {
  isDeclineModalOpen: boolean;
  isCustomerSearchModalOpen: boolean;
  isCancelQuoteModalOpen: boolean;
  isNotificationModalOpen: boolean;
  isConvertProspectModalOpen: boolean;
  isBillingConfirmationModalOpen: boolean;
  customerConvert: CustomerConvertParams | undefined;
}

type IRateInfoSectionProps = IRateInfoSectionBaseProps
  & IQuoteEntryServiceInjectedProps
  & ICustomerServiceInjectedProps
  & IEmployeeServiceInjectedProps;

class _RateInfoSection extends React.Component<IRateInfoSectionProps, IOwnState> {
  state: IOwnState = {
    isDeclineModalOpen: false,
    isCustomerSearchModalOpen: false,
    isCancelQuoteModalOpen: false,
    isNotificationModalOpen: false,
    isConvertProspectModalOpen: false,
    isBillingConfirmationModalOpen: false,
    customerConvert: undefined
  };

  async componentDidMount() {
    await this.props.QuoteEntryService.fetchSiteIds(this.props.tmCompanyId, true);
    await this.props.QuoteEntryService.fetchOpsCodes(this.props.tmCompanyId, true);
  }

  async componentDidUpdate(prevProps: IRateInfoSectionProps) {
    if (this.props.tmCompanyId !== prevProps.tmCompanyId) {
      await this.props.QuoteEntryService.fetchSiteIds(this.props.tmCompanyId, true);
      await this.props.QuoteEntryService.fetchOpsCodes(this.props.tmCompanyId, true);
    }
  }

  @bind
  private async _changeQuoteStatus(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const { quote } = this.props.QuoteEntryService.getState();
    const status = event.currentTarget.value as QuoteStatusEnum;

    if (quote.quoteType === "Quick" && status === "Pending" && quote.calculatedRates) {
      await this.props.QuoteEntryService.save("PendingResave");
    }
    else if (status === "Convert") {
      this.setState({ isCustomerSearchModalOpen: true });
    }
    else if (status === "Declined") {
      this.setState({ isDeclineModalOpen: true });
    }
    else if (status === "PendingResave") {
      this.props.QuoteEntryService.updateEditMode(true);
    }
    else if (status === "Canceled") {
      this.openCancelQuoteModal(quote);
    }
    else if (status === "Accepted" && quote.customer?.isProspect) {
      this.setState({ isConvertProspectModalOpen: true });
    }
    else if (status === "Accepted" && quote.billTo?.isBillTo && quote.billTo?.billingStatus === "CheckCredit") {
      this.setState({ isBillingConfirmationModalOpen: true });
    }
    else {
      this.props.QuoteEntryService.save(status);
    }
  }

  @bind
  private async _saveEdits() {
    const { quote } = this.props.QuoteEntryService.getState();
    await this.props.QuoteEntryService.save("PendingResave");
    this.props.QuoteEntryService.fetchDataChangeLogs(quote.id);
  }

  @bind
  private _printQuote() {
    const { quote } = this.props.QuoteEntryService.getState();

    if (quote.id) {
      printQuote(quote.id);
    }
  }

  @bind
  private _onReasonModalClose() {
    this.setState({
      isDeclineModalOpen: false
    });
  }

  @bind
  private async _onReasonSave(reason: string) {
    this.props.QuoteEntryService.updateQuote({ declineReasonText: reason });
    await this.props.QuoteEntryService.save("Declined");

    this._onReasonModalClose();
  }

  @bind
  private openCancelQuoteModal(quote: Quote) {
    if (quote.carrier?.vendorId) {
      this.setState({
        isNotificationModalOpen: true
      });
    } else {
      this.setState({
        isCancelQuoteModalOpen: true
      });
    }
  }

  @bind
  private async _onCancelQuoteConfirm(cancelQuote: Quote) {
    const cancelQuoteParams = {
      quoteId: cancelQuote.id,
      companyId: cancelQuote.companyId,
      cancellationReason: cancelQuote.cancellationReason,
      cancellationDetails: cancelQuote.cancellationDetails
    };
    await QuoteEntryService.cancelQuote(cancelQuoteParams);
    this._onCancelQuoteModalClose();
  }

  @bind
  private _onCancelQuoteModalClose() {
    this.setState({
      isCancelQuoteModalOpen: false
    });
  }

  @bind
  private _onConfirmNotification() {
    this.setState({
      isNotificationModalOpen: false
    });
  }

  @bind
  private async _onRateDiscrepancyModalClose(status: QuoteStatusEnum | "") {
    // unconditional close of modal if no status given
    if (status !== "") {
      const { quote } = this.props.QuoteEntryService.getState();

      if ((status === "AcceptanceRejected") || (status === "Declined")) {
        const approvalReasons = quote.approvalReasons?.map(x => approvalReasonTextMap[x.approvalNeededReasonId ?? ""]);

        this.props.QuoteEntryService.updateQuote({
          declineReasonText: approvalReasons?.join(", ")
        });
      }

      await this.props.QuoteEntryService.save(status);
    }

    this.props.QuoteEntryService.closeRateDiscrepancyModal();
  }

  @bind
  private _closeCustomerSearchModal() {
    this.setState({
      isCustomerSearchModalOpen: false
    });
  }

  @bind
  private _selectCustomerForConvert() {
    const { selectedRow } = this.props.customerService.getState();

    this._createQuoteForCustomer(selectedRow);
  }
  
  @bind
  private _getCurrentEmployeeId(): number | undefined {
    const userGuid = SharedSecurityContext.getUserId();

    return this.props.employeeService.getCurrentEmployeeId(userGuid);
  }

  @bind
  private async _createQuoteForCustomer(customer: Customer | null) {
    if (!customer) {
      return;
    }

    const isActiveCustomer = await this.props.customerService.customerIsActiveInTruckMate(customer.tmcustomerId);
    if (isActiveCustomer) {
      QuoteEntryService.setSelectedCustomer(customer);
      QuoteEntryService.setQuoteType(true);
      QuoteEntryService.prepForQuickQuoteConvert();
      NavigationService.navigateTo("/salesportal");
      
      // forceNavigate needs to be true for navigation without warning upon selecting a customer for quick quote conversion
      // after navigating to full quote, set forceNavigate to false to resume navigation warnings
      QuoteEntryService.freezer.get().set({
        forceNavigate: false
      });
    }

    this._closeCustomerSearchModal();
  }
  
  @bind
  private _onOtherInfoChanged(currentQuoteIdx: number, data: Partial<QuoteStop>)
  {
    this.props.QuoteEntryService.updateQuoteStop(currentQuoteIdx, data);
  }

  @bind
  private _onCancelConvertProspect() {
    this.setState({ isConvertProspectModalOpen: false, customerConvert: undefined });
  }

  @bind
  private async _onConfirmConvertProspect(customerConvert: CustomerConvertParams) {
    const { quote } = this.props.QuoteEntryService.getState();
    if (quote.customer?.billingStatus === "CheckCredit") {
      this.setState({
        isBillingConfirmationModalOpen: true,
        isConvertProspectModalOpen: false,
        customerConvert: customerConvert
      });
    } else {
      await this.props.QuoteEntryService.save("Accepted", undefined, undefined, customerConvert);
    }
  }

  @bind
  private _onCancelAcceptQuote() {
    this.setState({ isBillingConfirmationModalOpen: false });
  }

  @bind
  private async _onConfirmAcceptQuote() {
    await this.props.QuoteEntryService.save("Accepted", undefined, undefined, this.state.customerConvert);
  }

  render() {
    const {
      isApprovalNeededForLowRate,
      isApprovalNeededForDeclaredValue,
      quote,
      currentQuoteStopIdx,
      negotiatedQuoteDataEntry,
      viewOnly,
      isOverDimensional,
      hasQuoteChanged,
      isRateDiscrepancyModalOpen,
      rateEngineResult,
      editMode,
      dataChangeLogsFetchResults,
      editHistories,
      quoteSaveResults,
      accessorialChargeValueFetchResults
    } = this.props.QuoteEntryService.getState();

    const {
      searchResults,
      selectedRow
    } = this.props.customerService.getState();

    const {
      isDeclineModalOpen,
      isCustomerSearchModalOpen,
      isCancelQuoteModalOpen,
      isNotificationModalOpen,
      isConvertProspectModalOpen,
      isBillingConfirmationModalOpen
    } = this.state;

    const {
      onCreateReminder
    } = this.props;

    const displayEditHistories = _.filter(editHistories, h => !_.some(h.log?.relatedEntities, e => e.entityType === 'QuoteStop' && !_.some(quote.quoteStops, s => s.id === e.identifier)));
    
    const currentQuoteStop = quote.quoteStops ? quote.quoteStops[currentQuoteStopIdx] : undefined;
    const accessorialChargeValues = accessorialChargeValueFetchResults.data ?? [];
    const accessorialChargesValue = accessorialChargeValues.reduce((sum, el) => sum += el.status === "Approved" && el.amount ? el.amount : 0, 0);

    const isAdminUser = SecurityContext.isInGroup("/Admin");
    const isManagerUser = SecurityContext.isInGroup("/Manager");

    // approval needed detection:
    // - overdimensional and declared value are detected both before (rate variable calculation) and during (full rate calculation) save; adds to approval reasons on any save
    // - low negotiated rate can be detected after the first rate calculation (if not overdimensional); adds to approval reasons on "Request Approval"
    // - rate / mileage discrepancy are detected on an attempt to accept - adds to approval reasons immediately
    const isApprovalNeededForOverDimensional = isOverDimensional ? quote.approvalReasons &&
      !(quote.approvalReasons.find(x => x.approvalNeededReasonId === "OverDimensional" && x.approvalStatus === "Approved") 
      && !quote.approvalReasons.find(x => x.approvalNeededReasonId === "OverDimensional" && x.approvalStatus === "PendingApproval")) : 
      false;

    const needsApproval = ((quote.status === "InProgress" || editMode)
      && (isApprovalNeededForOverDimensional || isApprovalNeededForDeclaredValue || isApprovalNeededForLowRate))
      || (quote.approvalReasons && !!quote.approvalReasons.find(x => x.approvalStatus === "PendingApproval"));

    // current freezer flags - may not have been persisted (as quote approval reasons) yet
    const approvalReasons: string = [
      isOverDimensional ? approvalReasonTextMap["OverDimensional"] : "", // ID = 1
      !isOverDimensional && isApprovalNeededForLowRate ? approvalReasonTextMap["LowNegotiatedRate"] : "", // ID = 2  // overdimensional cannot be rated, therefore has no lower rate bound
      isApprovalNeededForDeclaredValue ? approvalReasonTextMap["DeclaredValue"] : ""  // ID = 3
    ]
      .filter(x => !!x)
      .join(", ");

    // reasons already persisted to quote
    const savedReasons = quote.approvalReasons?.map(x => approvalReasonTextMap[x.approvalNeededReasonId ?? ""])
      .join(", ");

    // rates are pre-ordered by creation date (desc) on quote retrieve
    const currentRate = (quote.calculatedRates && quote.calculatedRates.length > 0) ? quote.calculatedRates[0] : undefined;
    const previousRate = (quote.calculatedRates && quote.calculatedRates.length > 1) ? quote.calculatedRates[1] : undefined;

    const actionButtons = this.getActionButtons((isAdminUser || isManagerUser), needsApproval);

    // show reminder-create only on existing saved quotes
    const createReminderButton = quote.id ? (
      <UserAccessControl roles={["reminder:create"]}>
        <CreateReminderButton
          seed={{
            ...EMPTY_REMINDER,
            type: "Quote",
            customer: {
              linkId: quote.customerId,
              linkName: quote.customer?.customerName,
            },
            quote: {
              linkId: quote.id,
              linkName: quote.quoteNumber?.toString()
            }
          }}
          onClick={onCreateReminder}
        />
      </UserAccessControl>
    ) : undefined;

    const isOverDimensionalApprovalNeeded = !!quote.approvalReasons?.some(ar => ar.approvalNeededReasonId === "OverDimensional" && ar.approvalStatus === "PendingApproval");
    const adminOnApprovalNeededQuote = (isAdminUser || isManagerUser) && (quote.status === "ApprovalNeeded");

    const quickQuoteAddSeed: Partial<Customer> = {
      isCaller: true,
      isActive: true,
      salesAgentId: this._getCurrentEmployeeId()
    };

    const carrierName = quote?.carrier?.vendor?.vendorName ? quote?.carrier?.vendor?.vendorName : "";
    const prospectIsShipper = _.some(quote.quoteStops, q => _.some(q.addresses, a => a.addressType === "Shipper" && a.customerId === quote.customerId));
    const prospectIsConsignee = _.some(quote.quoteStops, q => _.some(q.addresses, a => a.addressType === "Consignee" && a.customerId === quote.customerId));

    return (
      <div className={styles.mainContainer}>
        <QuoteCardSection quote={quote} isOverDimensional={isOverDimensional} rateEngineResult={rateEngineResult} />

        <div className={styles.modelQuoteContainer}>
          {!isOverDimensional &&
            <RateModelCard currentRate={currentRate} accessorialChargesValue={accessorialChargesValue} actionButtons={actionButtons} />}

          {quote.quoteType === "Full" ? (isOverDimensionalApprovalNeeded && adminOnApprovalNeededQuote) ? <OverdimensionalApprovalCard quote={quote} negotiatedQuoteDataEntry={negotiatedQuoteDataEntry} approvalReasons={approvalReasons} createReminderButton={createReminderButton} actionButtons={actionButtons} />
            : adminOnApprovalNeededQuote ? <NegotiatedRateEntryCard quote={quote} approvalReasons={approvalReasons} hasQuoteChanged={hasQuoteChanged} editMode={editMode} createReminderButton={createReminderButton} actionButtons={actionButtons} />
            : viewOnly  ? <QuoteInfoCard quote={quote} approvalReasons={approvalReasons} accessorialChargesValue={accessorialChargesValue} needsApproval={needsApproval} savedReasons={savedReasons} createReminderButton={createReminderButton} actionButtons={actionButtons} />
            : <NegotiatedRateEntryCard quote={quote} approvalReasons={approvalReasons} hasQuoteChanged={hasQuoteChanged} editMode={editMode} createReminderButton={createReminderButton} actionButtons={actionButtons} />
            : ""}

          {quote.quoteType === "Full" ? 
            <>
            <OtherInformationCard
              quoteStop={currentQuoteStop}
              viewOnly={viewOnly}
              onChange={(data) => this._onOtherInfoChanged(currentQuoteStopIdx, data)}
            />
            <QuoteApprovalsCard approvals={quote.approvalReasons} hasQuoteChanged={hasQuoteChanged} />
            {quote.status === "Accepted" || quote.status === "Canceled" ? 
              <AccessorialChargesCard quoteId={quote.id} quoteStatus={quote.status} accessorialChargeValues={accessorialChargeValues ?? []} />
              : ""
            }
            <EditHistoryCard quoteStops={quote?.quoteStops ?? []} auditLogs={displayEditHistories} isFetching={dataChangeLogsFetchResults.isFetching} />
            </> : ""}

          {quote.quoteType === "Quick" && (quote.status === "Declined" || quote.status === "Converted") &&
          <QuoteInfoCard quote={quote} approvalReasons={approvalReasons} accessorialChargesValue={accessorialChargesValue} needsApproval={needsApproval} savedReasons={savedReasons} createReminderButton={createReminderButton} actionButtons={actionButtons} />}

          {quote.quoteType === "Quick" ? <ContactInfoCard quote={quote} viewOnly={viewOnly} hasQuoteChanged={hasQuoteChanged}/> : ""}
        </div>

        <ReasonModal
          isOpen={isDeclineModalOpen}
          onSave={this._onReasonSave}
          onCancel={this._onReasonModalClose}
        />

        <RateDiscrepancyModal
          isOpen={isRateDiscrepancyModalOpen}
          approvalReasons={quote.approvalReasons}
          quoteStops={quote.quoteStops}
          quoteStatus={quote.status}
          newRate={currentRate}
          previousRate={previousRate}
          negotiatedRate={quote.negotiatedRate}
          closeModal={this._onRateDiscrepancyModalClose}
        />

        <CustomerSearchModal
          isOpen={isCustomerSearchModalOpen}
          customerType="Caller"
          onCustomerModalClose={this._closeCustomerSearchModal}
          onCustomerSelect={this._selectCustomerForConvert}
          selectedRow={selectedRow}
          canAdd={searchResults.hasFetched}
          addSeed={quickQuoteAddSeed}
          canExpandSearch={false}
          hiddenColumns={["isCaller", "isShipper", "isConsignee"]}
          searchAll
        />

        <CancelQuoteModal
          quote={quote}
          isOpen={isCancelQuoteModalOpen}
          isSaving={quoteSaveResults.isFetching}
          onConfirm={this._onCancelQuoteConfirm}
          onClose={this._onCancelQuoteModalClose}
        />
        <NotificationModal
          isOpen={isNotificationModalOpen}
          onConfirm={this._onConfirmNotification}
          message={`Cannot cancel quote. Assigned carrier: ${carrierName}`}
        />
        <ConvertCustomerModal
          isOpen={isConvertProspectModalOpen}
          customer={quote.customer}
          onConfirmConvertToCustomer={this._onConfirmConvertProspect}
          onCancel={this._onCancelConvertProspect}
          prospectIsShipper={prospectIsShipper}
          prospectIsConsignee={prospectIsConsignee}
        />
        <BillingConfirmationModal
          isOpen={isBillingConfirmationModalOpen}
          customer={quote.billTo}
          disableButtons={quoteSaveResults.isFetching}
          onConfirmAcceptQuote={this._onConfirmAcceptQuote}
          onCancel={this._onCancelAcceptQuote}
        />
      </div>
    );
  }

  //#region privateFunctions
  private getActionButtons(isAuthorizedUser: boolean, needsApproval: boolean | undefined) {
    const { 
      quote,
      workflowState,
      viewOnly,
      applyTripFlagsResults,
      quoteGetResults,
      quoteSaveResults,
      calculateRatingVariableResults,
      hasQuoteChanged,
      editMode,
      isAdminCreatedApprovalReasons,
      hasOtherInformationChanged
    } = this.props.QuoteEntryService.getState();

    const quoteRoleGroup: SalesPortalGroup | undefined = SecurityContext.isInGroup("/Admin") || SecurityContext.isInGroup("/Manager") ? "/Admin"
      : SecurityContext.isInGroup("/SalesRep") ? "/SalesRep"
        : SecurityContext.isInGroup("/Biller") ? "/Biller"
          : SecurityContext.isInGroup("/ViewOnly") ? "/ViewOnly"
            : undefined;

    const allowEdit = !viewOnly
      || (["Pending", "PendingNeedsCustomers"].includes(quote.status ?? ""))
      || (isAuthorizedUser && ["Pending", "PendingNeedsCustomers", "ApprovalNeeded"].includes(quote.status ?? ""));

    const allowCancel = !editMode && quote.quoteType === "Full" && quote.status === "Accepted" && 
      (quoteRoleGroup === '/Admin' || quoteRoleGroup === '/SalesRep' || quoteRoleGroup === '/Biller');

    const isFetching = applyTripFlagsResults.isFetching || quoteGetResults.isFetching || quoteSaveResults.isFetching || calculateRatingVariableResults.isFetching;

    // create usable buttons based on permitted actions from workflow result
    let filteredActions: QuoteStatusEnum[] = [];
    if (!!quoteRoleGroup && workflowState) {
      const actions = workflowState.roleActions![quoteRoleGroup] as QuoteStatusEnum[];

      filteredActions = actions
        .filter(x => (x !== "InProgress") // handled by (re-)Calculate Rate
          && (x !== "AcceptanceRejected") // handled by rate discrepancy modal
          && (x !== "AdminRerate"));      // internal operation (itself triggered by an admin) triggers re-rate

      if (quote.quoteType === "Quick") {
        filteredActions = filteredActions.filter(x => (x !== "PendingResave"));
      }

      if (quote.quoteType === "Full") {
        filteredActions = filteredActions.filter(x => (x !== "Convert"));
      }

      if (!needsApproval || isAuthorizedUser) {
        // filter out "Request Approval" if no approval is actually needed (or an admin)
        filteredActions = filteredActions.filter(x => (x !== "ApprovalNeeded"));
      }

      if (needsApproval && isAuthorizedUser && isAdminCreatedApprovalReasons && quote.status !== "ApprovalNeeded") {
        // filter out "Approved" for admins' own quotes
        filteredActions = filteredActions.filter(x => (x !== "Approved"));
      }

      if (needsApproval && !isAuthorizedUser) {
        filteredActions = filteredActions.filter(x => (x !== "Accepted") && (x !== "Pending") && (x !== "Approved"));
      }

      if (editMode) {
        filteredActions = [];
      }
    }
    const buttonText = needsApproval && !isAuthorizedUser ? 'Approval Needed' : 'Save';

    // disable actions if no current rate exists
    const hasCurrentRate = (quote.calculatedRates?.findIndex(x => x.isCurrent) ?? -1) > -1;

    const actionButtons = (
      <>
        {editMode &&
          <UserAccessControl roles={["quote:create"]}>
          <Button color="primary" disabled={isFetching || hasQuoteChanged} onClick={this._saveEdits}>{buttonText}</Button>
        </UserAccessControl>
        }
        {allowCancel &&
          <UserAccessControl roles={["quote:edit"]}>
            <Button style={{backgroundColor:"#6C0505"}} value={"Canceled"} onClick={this._changeQuoteStatus}>Cancel</Button>
          </UserAccessControl>
        }
        {!editMode && hasCurrentRate && quote.quoteType !== "Quick" && _.includes(["Accepted", "Pending", "PendingNeedsCustomers"], quote.status) &&
          <UserAccessControl roles={["quote:print"]}>
            <Button color="primary" onClick={this._printQuote}>Print</Button>
          </UserAccessControl>
        }
        {filteredActions
          .map((x, idx) => (
            <Button
              key={idx}
              color="primary"
              disabled={isFetching || (viewOnly && !allowEdit) || (!viewOnly && hasQuoteChanged) || (!hasCurrentRate && (x !== "Declined") && (x !== "PendingResave")) || hasOtherInformationChanged}
              value={x}
              onClick={this._changeQuoteStatus}
            >
              {quoteStatusTextMap[x]}
            </Button>
          ))}
      </>
    );
    return actionButtons;
  }
  //#endregion
}

export const RateInfoSection = QuoteEntryService.inject(
  CustomerService.inject(
    EmployeeService.inject(
      _RateInfoSection
    )
  )
);