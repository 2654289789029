import {
  React,
  bind
} from "$Imports/Imports";

import {
  Activity,
  Customer,
  CustomerConvertParams,
  Employee
} from "$Generated/api";

import {
  DialogActions,
  DialogContentText,
  DialogContent,
  Dialog,
  Button,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Grid,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "$Imports/MaterialUIComponents";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import { AdvanceTextField } from "$Imports/CommonComponents";

import { ActivityValidationSchema } from "$State/CustomerDetailFreezerService";

interface IConvertCustomerModalProps {
  isOpen: boolean;
  customer: Customer | undefined;
  salesReps?: Employee[];
  prospectIsShipper?: boolean;
  prospectIsConsignee?: boolean;
  onConfirmConvertToCustomer?: (customer: CustomerConvertParams) => void;
  onConfirmConvertToProspect?: (asalesAgentId: number, ctivity: Activity) => void;
  onCancel: () => void;
  toProspect?: boolean;
}

interface IConvertCustomerModalState {
  customerConvert: CustomerConvertParams | undefined;
  salesAgentId: number | undefined;
  activity: Activity | undefined;
  salesAgentMissing: boolean;
  validationErrors: ValidationError | null;
}

const initialActivity: Activity = {
  createdOn: undefined,
  activityType: "CustomerToProspectHandoff",
  noteText: undefined,
  isActive: true,
  isPinned: false
};

export class ConvertCustomerModal extends React.Component<IConvertCustomerModalProps, IConvertCustomerModalState> {
  state: IConvertCustomerModalState = {
    customerConvert: this.props.toProspect ? undefined : {
      isConsignee: this.props.customer?.isConsignee || this.props.prospectIsConsignee,
      isShipper: this.props.customer?.isShipper || this.props.prospectIsShipper
    },
    salesAgentId: this.props.customer?.salesAgentId ?? undefined,
    activity: this.props.toProspect ? initialActivity : undefined,
    salesAgentMissing: false,
    validationErrors: null
  };

  componentDidUpdate(prev: IConvertCustomerModalProps) {
    const { customer, toProspect } = this.props;
    if (customer !== prev.customer) {
      this.setState({
        customerConvert: toProspect ? undefined : {
          isConsignee: this.props.customer?.isConsignee || this.props.prospectIsConsignee,
          isShipper: this.props.customer?.isShipper || this.props.prospectIsShipper
        },
        salesAgentId: this.props.customer?.salesAgentId,
        activity: this.props.toProspect ? {
          ...initialActivity,
          customerId: this.props.customer?.id
        } : undefined,
        validationErrors: null
      });
    }
  }

  @bind
  private _onCheckboxChange(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.setState((prev) => ({
      customerConvert: {
        ...prev.customerConvert,
        [e.target.name]: checked
      }
    }));
  }

  @bind
  private _onTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState((prev) => ({
      activity: {
        ...prev.activity,
        noteText: event.target.value
      }
    }));
  }

  @bind
  private _onSelectChange(event: SelectChangeEvent): void {
    this.setState({
      salesAgentId: event.target.value !== "" ? Number(event.target.value) : undefined
    });
  }

  @bind
  private async _onValidateActivity(activity: Activity | undefined): Promise<boolean> {
    const customerErrors = await validateSchema(ActivityValidationSchema, activity, {
      context: { timeEmpty: false },
      abortEarly: false
    });

    this.setState({ validationErrors: customerErrors });
    if (customerErrors) {
      return true;
    }

    return false;
  }

  @bind
  private async _onConfirm() {
    const { toProspect } = this.props;
    const { customerConvert, salesAgentId, activity } = this.state;

    if (toProspect && activity) {
      activity.createdOn = new Date();
      this.setState({
        salesAgentMissing: !salesAgentId,
        activity: activity
      });
    }
    
    const hasErrors = toProspect ? await this._onValidateActivity(activity) && salesAgentId : false;

    if (hasErrors) {
      return;
    }

    if (toProspect && this.props.onConfirmConvertToProspect && salesAgentId && activity) {
      this.props.onConfirmConvertToProspect(salesAgentId, activity);
    }

    if (!toProspect && this.props.onConfirmConvertToCustomer && customerConvert) {
      this.props.onConfirmConvertToCustomer(customerConvert);
    }
  }

  @bind
  private _onCancel(): void {
    if (this.props.onCancel) {
      this.setState({
        salesAgentId: undefined,
        salesAgentMissing: false,
        activity: {
          ...initialActivity,
          customerId: this.props.customer?.id
        }, 
        validationErrors: null
      });
      this.props.onCancel();
    }
  }

  render() {
    const {
      toProspect,
      prospectIsShipper,
      prospectIsConsignee,
      salesReps
    } = this.props;
    const {
      customerConvert,
      activity,
      salesAgentId,
      salesAgentMissing,
      validationErrors
    } = this.state;

    const dialogTitle = toProspect ? 'Enter Conversion Reason' : 'Convert Prospect to Customer'
    const dialogContentText = toProspect ?
      'Please specify why the customer is no longer doing business with Kaiser along with any other relevant account information.' :
      'The caller for this quote is a prospect. Confirm the applicable customer types to convert the prospect to a customer.';
    const validationsParser = toProspect ? new ValidationErrorParser<Activity>(validationErrors) : new ValidationErrorParser<CustomerConvertParams>(validationErrors);

    return (
      <Dialog
        open={this.props.isOpen}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogContentText}
          </DialogContentText>
          {toProspect ?
            <Grid container direction="column" spacing={2} style={{ marginTop: "0.5rem" }}>
              <Grid item>
                <AdvanceTextField
                  label="Notes"
                  value={activity?.noteText ?? ""}
                  onChange={this._onTextChange}
                  maxRows={3}
                  inputProps={{ maxLength: 300 }}
                  error={!validationsParser.isValidDeep("noteText")}
                  helperText={validationsParser.validationMessageDeep("noteText")}
                  multiline
                  fullWidth
                  required
                />
              </Grid>
              <Grid item>
                <FormControl error={salesAgentMissing} style={{minWidth: "12rem"}} >
                  <InputLabel>Sales Representative *</InputLabel>
                  <Select
                    value={salesAgentId ?? ""}
                    name="salesAgentId"
                    onChange={(event) => this._onSelectChange(event as React.ChangeEvent<HTMLInputElement>)}
                    displayEmpty
                    required={toProspect}
                  >
                    {salesReps?.map((s, idx) => (
                      <MenuItem value={s.id} key={idx}>{`${s.firstName} ${s.lastName}`}</MenuItem>
                    ))}
                  </Select>
                  {salesAgentMissing && <FormHelperText>Sales Representative is required</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
            :
            <Grid container direction="row" wrap="wrap" spacing={2} style={{ marginTop: "0.5rem" }}>
              <Grid item xs={4}>
                <FormControlLabel
                  label="Caller?"
                  control={(
                    <Checkbox
                      checked
                      name="isCaller"
                      disabled
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  label="Shipper?"
                  control={(
                    <Checkbox
                      checked={customerConvert?.isShipper ?? false}
                      onChange={this._onCheckboxChange}
                      name="isShipper"
                      disabled={prospectIsShipper}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  label="Consignee?"
                  control={(
                    <Checkbox
                      checked={customerConvert?.isConsignee ?? false}
                      onChange={this._onCheckboxChange}
                      name="isConsignee"
                      disabled={prospectIsConsignee}
                    />
                  )}
                />
              </Grid>
            </Grid>
          }
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this._onConfirm}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            onClick={this._onCancel}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
