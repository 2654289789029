import {
  React,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  PercentageInputField
} from "$Imports/CommonComponents";

import {
  LogisticsMarkup
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button
} from "$Imports/MaterialUIComponents";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

const styles: {
  modalContainer: string;
} = require("$Shared/styles/Modal.scss");

interface IAddLogisticsProps {
  isOpen: boolean;
  newRecord: LogisticsMarkup;
  validationErrors: ValidationError | null;
  onSave: () => void;
  onCancel: () => void;
  onChange: (value: Partial<LogisticsMarkup>) => void;
}

export class AddLogisticsMarkup extends React.PureComponent<IAddLogisticsProps> {

  @bind
  private _onSave() {
    this.props.onSave();
  }

  @bind
  private _onCancel() {
    this.props.onCancel();
  }

  @bind
  private _onPrimaryChange(value?: number) {
    this.props.onChange({ primaryRate: value });
  }

  @bind
  private _onSecondaryChange(value?: number) {
    this.props.onChange({ secondaryRate: value });
  }

  render() {
    const {
      isOpen,
      newRecord,
      validationErrors
    } = this.props;

    const validationsParser = new ValidationErrorParser<LogisticsMarkup>(validationErrors);  

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>
          Add Logistics Market Markup
        </DialogTitle>
        <DialogContent
          className={styles.modalContainer}
        >
          <PercentageInputField
            label="Primary Rate"
            required
            error={!validationsParser.isValid("primaryRate")}
            helperText={validationsParser.validationMessage("primaryRate")}
            decimalScale={1}
            fixedDecimalScale
            value={newRecord.primaryRate}
            onPercentValueChange={this._onPrimaryChange}
            style={{ maxWidth: "180px" }}
          />
          <PercentageInputField
            label="Secondary Rate"
            required
            error={!validationsParser.isValid("secondaryRate")}
            helperText={validationsParser.validationMessage("secondaryRate")}
            decimalScale={1}
            fixedDecimalScale
            value={newRecord.secondaryRate}
            onPercentValueChange={this._onSecondaryChange}
            style={{ maxWidth: "180px", marginTop: "8px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this._onSave}
          >
            Save
          </Button>
          <Button
            onClick={this._onCancel}
            variant="outlined"
          >
            Cancel
          </Button>
          </DialogActions>
      </Dialog>
    );
  }
}
