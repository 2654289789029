import {
  React,
  _,
  bind,
  moment
} from "$Imports/Imports";

import {
  LeadSource
} from "$Generated/api";

import {
  Edit
} from "$Imports/MaterialUIIcons";

import {
  Card,
  CardActions,
  CardHeader,
  Button,
  DataGridPro,
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
  IconButton,
  FormControlLabel,
  Switch
} from "$Imports/MaterialUIComponents";

import {
  AjaxActionIndicator,
  DisplayFormattedDatetime
} from "$Imports/CommonComponents";

import {
  ILeadSourceServiceInjectedProps,
  LeadSourceService
} from "$State/LeadSourceFreezerService";

import {
  DATE_ONLY_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  LeadSourceModal
} from "./LeadSourceModal";

const styles: {
  mainContainer: string;
  cardStyle: string;
  actionArea: string;
} = require("$Shared/styles/Modal.scss");

interface ILeadSourceViewPageBaseProps { }

type ILeadSourceViewPageProps = ILeadSourceViewPageBaseProps
  & ILeadSourceServiceInjectedProps;

class _LeadSourceViewPage extends React.Component<ILeadSourceViewPageProps> {
  private readonly columns: GridColDef[] = [
    {
      headerName: "Lead Source",
      field: "name",
      flex: 4
    },
    {
      headerName: "Lead Details Required?",
      field: "isDetailRequired",
      flex: 2,
      valueGetter: (params: GridValueGetterParams) => {
        return params.value ? "Yes" : "No"
      }
    },
    {
      headerName: "Start Date",
      field: "startDate",
      renderCell: (params: GridRenderCellParams) => <DisplayFormattedDatetime value={params.value} formatString={DATE_ONLY_FORMAT} />,
      flex: 2
    },
    {
      headerName: "End Date",
      field: "endDate",
      renderCell: (params: GridRenderCellParams) => <DisplayFormattedDatetime value={params.value} formatString={DATE_ONLY_FORMAT} />,
      flex: 2
    },
    {
      headerName: "Created By",
      field: "createdById",
      flex: 3,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.row.createdBy?.firstName ?? ""} {params.row.createdBy?.lastName ?? ""}
        </>
      )
    },
    {
      headerName: "",
      field: "actions",
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {

        return (
          params.row.name === "Other" ? undefined :
            <IconButton onClick={() => this._editLeadSource(params.row)}>
              <Edit />
            </IconButton>
        );
      }
    }
  ];

  componentDidMount() {
    this.props.leadSourceService.fetchLeadSources(true);
  }

  @bind
  private _onActiveFilterChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.leadSourceService.setFilterActive(checked);
  }

  @bind
  private _addLeadSource() {
    this.props.leadSourceService.openAddEditModal();
  }

  @bind
  private _editLeadSource(editLeadSource: LeadSource) {
    this.props.leadSourceService.openAddEditModal(editLeadSource);
  }

  @bind
  private _onChangeFromModal(leadSource: Partial<LeadSource>) {
    this.props.leadSourceService.onChangeLeadSource(leadSource);
  }

  @bind
  private async _saveModal() {
    await this.props.leadSourceService.saveLeadSource();
    await this.props.leadSourceService.fetchLeadSources(true);
    this.props.leadSourceService.closeAddEditModal();
  }

  @bind
  private _cancelModal() {
    this.props.leadSourceService.closeAddEditModal();
  }

  render() {
    const {
      fetchResult: fetchLeadSourcesResult,
      filterActive,
      modalIsOpen,
      addEditLeadSource
    } = this.props.leadSourceService.getState();

    const leadSources = fetchLeadSourcesResult.data ?? [];
    const now = moment();
    const filteredData = filterActive
      ? _.filter(leadSources, (x) => now.isAfter(moment.utc(x.startDate)) && (!x.endDate || now.isBefore(moment.utc(x.endDate))))
      : leadSources;

    return (
      <div
        className={styles.mainContainer}
      >
        <Card
          className={styles.cardStyle}
        >
          <CardHeader
            title="Sales Opportunity Lead Sources"
          />
          <CardActions
            disableSpacing={true}
            className={styles.actionArea}
          >
            <FormControlLabel
              control={
                (
                  <Switch
                    color="primary"
                    checked={filterActive}
                    onChange={this._onActiveFilterChange}
                  />
                )
              }
              label="Only show active"
            />
            <Button
              color="primary"
              onClick={this._addLeadSource}
            >
              Add
            </Button>
          </CardActions>
          <AjaxActionIndicator
            state={[]}
          />
          <div style={{ margin: "8px" }}>
            <DataGridPro
              rows={filteredData}
              columns={this.columns}
              density="compact"
              hideFooter
              disableSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: "id", sort: "asc" }]
                }
              }}
              autoHeight
            />
          </div>
        </Card>
        <LeadSourceModal
          isOpen={modalIsOpen}
          leadSource={addEditLeadSource}
          onSave={this._saveModal}
          onCancel={this._cancelModal}
          onChange={this._onChangeFromModal}
        />
      </div>
    );
  }
}

export const LeadSourceViewPage = LeadSourceService.inject(
  _LeadSourceViewPage
);
