import {
  React,
  bind,
  NumberFormat,
  NumberFormatValues,
  _
} from "$Imports/Imports";

import {
  AdvanceTextField,
  CardLinedHeader
} from "$Imports/CommonComponents";

import {
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps,
  NegotiatedQuoteDataEntry
} from "$State/QuoteEntryFreezerService";

import { 
  TextField
 } from "$Imports/MaterialUIComponents";

 import { 
  ValidationErrorParser 
} from "$Utilities/ValidationErrorParser";

import { 
  Quote 
} from "$Generated/api";

interface INegotiatedRateEntryCardBaseProps {
  quote: Quote;
  approvalReasons: string | undefined;
  hasQuoteChanged: boolean;
  editMode: boolean;
  createReminderButton: JSX.Element | undefined;
  actionButtons: JSX.Element;
}

type INegotiatedRateEntryCardProps = INegotiatedRateEntryCardBaseProps & IQuoteEntryServiceInjectedProps;

const styles: {
  quoteCard: string
  status: string;
  quoteButtons: string;
  quoteChangedError: string;
} = require('./RateInfoSection.scss');

class _NegotiatedRateEntryCard extends React.Component<INegotiatedRateEntryCardProps> {

  @bind
  private _onNegotiatedQuoteChange(value?: NumberFormatValues) {
    this.props.QuoteEntryService.updateNegotiatedQuoteDataEntry({ negotiatedRateValue: value?.floatValue });
  }

  @bind
  private _onNotesChange(value: string) {
    this.props.QuoteEntryService.updateNegotiatedQuoteDataEntry({ notes: value });
  }

  render() {
    const {
      negotiatedQuoteDataEntry,
      negotiatedRateDataEntryValidationErrors
    } = this.props.QuoteEntryService.getState();

    const {
      quote,
      approvalReasons,
      createReminderButton,
      actionButtons,
      hasQuoteChanged,
      editMode
    } = this.props;

    const validationsNegotiatedRates = new ValidationErrorParser<NegotiatedQuoteDataEntry>(negotiatedRateDataEntryValidationErrors);

    return (
      <CardLinedHeader
        className={styles.quoteCard}
        titleText={`Quote ${quote.quoteNumber ? `Q${quote.quoteNumber}` : ""}`}
        titleComponents={(
          <div>
            <span className={styles.status}>{approvalReasons}</span>
            {createReminderButton}
          </div>
        )}
      >
        {hasQuoteChanged &&
          <div className={styles.quoteChangedError}>
            Quote information has changed. Please rate again.
          </div>
        }
        {/** TODO: KT-1809 refactor out the table */}
        <table>
          <tbody>
            <tr style={{ width: "100%" }}>
              <td>
                <NumberFormat
                  allowNegative={false}
                  decimalSeparator="."
                  decimalScale={2}
                  thousandSeparator={true}
                  disabled={hasQuoteChanged || !quote.calculatedRates}
                  prefix="$"
                  label="Negotiated Rate"
                  value={negotiatedQuoteDataEntry.negotiatedRateValue?.toFixed(2) ?? ""}
                  customInput={TextField}
                  onValueChange={this._onNegotiatedQuoteChange}
                  error={!validationsNegotiatedRates?.isValid("negotiatedRateValue")}
                  helperText={validationsNegotiatedRates?.validationMessage("negotiatedRateValue")}
                />
              </td>
            </tr>
            <tr>
              <td>
                <AdvanceTextField
                  label="Additional Internal Notes"
                  onDebouncedChange={this._onNotesChange}
                  value={quote.notes ?? negotiatedQuoteDataEntry.notes ?? ""}
                  disabled={hasQuoteChanged  || !quote.calculatedRates || (!editMode && hasQuoteChanged)}
                  fullWidth
                />
              </td>
            </tr>
            <tr>
              <td className={styles.quoteButtons}>
                {actionButtons}
              </td>
            </tr>
          </tbody>
        </table>
      </CardLinedHeader>
    );
  }
}

export const NegotiatedRateEntryCard = QuoteEntryService.inject(_NegotiatedRateEntryCard);