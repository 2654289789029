import {
  React,
  bind,
  NumberFormatValues,
  NumberFormat,
  _
} from "$Imports/Imports";

import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Switch,
  TableCell,
  TableRow,
  TextField,
  Select,
  MenuItem,
  FormHelperText
} from "$Imports/MaterialUIComponents";

import {
  IRateModelTestServiceInjectedProps,
  RateModelTestService
} from "$State/RateModelTestFreezerService";

import {
  ICommodityServiceInjectedProps,
  CommodityService
} from "$State/CommodityFreezerService";

import {
  AjaxActionIndicator,
  DataTable,
  DisplayFormattedNumber,
  IDataTableColumn,
  DateTimeInputField,
  DatRateMessage
} from "$Imports/CommonComponents";

import {
  EditAddRateModelTest
} from "./EditAddRateModelTest";

import {
  ActionMenu
} from "./ActionMenu";

import {
  RateModelTest,
  QuickRateModelTestRequest
} from "$Generated/api";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import { RateTestTooltip } from "./RateTestTooltip";

import { ISelectedCompanyContext } from "$Providers/CompanyProvider";

import {
  getFormattedZipPostalCode,
  qualifyZipPostalCodeInput
} from "$Shared/utilities/helpers";

const styles: {
  borderLeft: string;
  datCell: string;
  centerText: string;
  sectionHeader: string;
  mileTable: string;
  rateTable: string;
  rateTableOverride: string;
  rightAlign: string;
  criteriaContainer: string;
  criteriaSubcontainer: string;
  spaceBetween: string;
  savedTestHeader: string;
} = require("./RateTesterView.scss");

const containerStyles: {
  mainContainer: string;
  cardStyle: string;
  actionArea: string;
} = require("$Shared/styles/Modal.scss");

interface IRateTesterViewBaseProp {
  companyContext: ISelectedCompanyContext;
}

type IRateTesterViewProp = IRateTesterViewBaseProp
  & IRateModelTestServiceInjectedProps
  & ICommodityServiceInjectedProps;

// tslint:disable-next-line: class-name
class _RateTesterView extends React.Component<IRateTesterViewProp> {
  private originRef = React.createRef<HTMLInputElement>();

  componentDidMount() {
    this.props.rateModelTestService.clearFreezer();
    this.props.rateModelTestService.fetchRateModelTests(this.props.companyContext.companyId, true);
    this.props.commodityService.fetchCommodities(this.props.companyContext.companyId, true);
  }

  componentDidUpdate(prevProps: IRateTesterViewBaseProp) {
    if (this.props.companyContext.companyId !== prevProps.companyContext.companyId) {
      this.props.rateModelTestService.clearFreezer();
      this.props.rateModelTestService.fetchRateModelTests(this.props.companyContext.companyId, true);
      this.props.commodityService.fetchCommodities(this.props.companyContext.companyId, true);
    }
  }

  @bind
  private focusOrigin() {
    this.originRef.current?.focus();
  }

  @bind
  private handleOriginChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = qualifyZipPostalCodeInput(e.target?.value).replace(' ', '');
    this.props.rateModelTestService.setQuickTestRequest({ originZipPostalCode: value });
  }

  @bind
  private handleDestinationChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = qualifyZipPostalCodeInput(e.target?.value).replace(' ', '');
    this.props.rateModelTestService.setQuickTestRequest({ destZipPostalCode: value });
  }

  @bind
  private handleCommodityChanged(e: any) {
    this.props.rateModelTestService.setQuickTestRequest({ commodityId: parseInt(e.target.value) });
  }

  @bind
  private handleModelLevelChanged(value?: NumberFormatValues) {
    this.props.rateModelTestService.setQuickTestRequest({ modelLevel: value?.floatValue });
  }

  @bind
  private handleFutureDateTimeChange(date: Date | undefined) {
    this.props.rateModelTestService.setQuickTestRequest({ futureDate: date });
  }

  @bind
  private sendRequest() {
    this.props.rateModelTestService.fetchQuickTestResult(this.props.companyContext.companyId);
    this.focusOrigin();
  }

  @bind
  private _onAddRateModelTest() {
    this.props.rateModelTestService.addRateModelTest(this.props.companyContext.companyId);
  }

  @bind
  private _onRateModelTestChanged(rateModelTest: Partial<RateModelTest>) {
    this.props.rateModelTestService.updateRateModelTest(rateModelTest);
  }

  @bind
  private _onSave() {
    this.props.rateModelTestService.saveRateModelTest(this.props.companyContext.companyId);
  }

  @bind
  private _onCancel() {
    this.props.rateModelTestService.cancel(this.props.companyContext.companyId);
  }

  @bind
  private _onSaveAndAdd() {
    this.props.rateModelTestService.saveAndAdd();
  }

  @bind
  private _onDeleteClick(data: RateModelTest) {
    this.props.rateModelTestService.confirmRateModelTestDeletion(data);
    this.props.rateModelTestService.deleteRateModelTest(this.props.companyContext.companyId);
  }

  @bind
  private _onUpdateMiles() {
    this.props.rateModelTestService.updateAllRateModelTestsMiles(this.props.companyContext.companyId);
  }

  @bind
  private _onSetIncludeFuture(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.rateModelTestService.setIncludeFuture(checked);
  }

  @bind
  private _onSetFutureDate(date: Date | undefined) {
    this.props.rateModelTestService.setFutureDateTime(date ?? new Date());
  }

  @bind
  private _onUpdate() {
    this.props.rateModelTestService.update(this.props.companyContext.companyId);
  }

  private readonly columns: Array<IDataTableColumn<RateModelTest>> = [
    {
      columnName: "originZipPostalCode",
      columnFieldData: (d) => getFormattedZipPostalCode(d.originZipPostalCode) ?? "",
      headerValue: "Origin",
    },
    {
      columnName: "destZipPostalCode",
      columnFieldData: (d) => getFormattedZipPostalCode(d.destZipPostalCode) ?? "",
      headerValue: "Destination",
    },
    {
      columnName: "miles",
      columnFieldData: (d) => d.miles ?? "",
      headerValue: "Miles",
    },
    {
      columnName: "commodityName",
      columnFieldData: (d) => d.commodity?.commodityName ?? "",
      headerValue: "Commodity",
    },
    {
      columnName: "modelLevelId",
      columnFieldData: (d) => d.modelLevelId ?? "",
      headerValue: "Length/Weight",
    },
    {
      columnName: "datRate",
      columnFieldData: (d) => d.datRate ?
        <div className={styles.datCell}>
          <span>{d.datRate}</span>
          <DatRateMessage
            datRate={d.datRateObject}
            showAsTooltip
          />
        </div>
        : "",
      headerValue: "DAT Rate",
    },
    {
      columnName: "minimumAmount",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.minimumAmount} formatString={"$0,0"} />,
      headerProps: {
        className: styles.rightAlign,
      },
      cellProps: {
        className: styles.rightAlign
      },
      headerValue: "Minimum Amount",
    },
    {
      columnName: "defaultHighLineHaulRate",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.defaultHighLineHaulRate} formatString={"$0,0"} />,
      headerProps: {
        className: `${styles.borderLeft} ${styles.rightAlign}`,
      },
      headerValue: "LHR",
      cellProps: {
        className: `${styles.borderLeft} ${styles.rightAlign}`,
      }
    },
    {
      columnName: "defaultLowLineHaulRate",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.defaultLowLineHaulRate} formatString={"$0,0"} />,
      headerProps: {
        className: styles.rightAlign,
      },
      cellProps: {
        className: styles.rightAlign
      },
      headerValue: "Low LHR",
    },
    {
      columnName: "activeHighLineHaulRate",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.activeHighLineHaulRate} formatString={"$0,0"} />,
      headerProps: {
        className: `${styles.borderLeft} ${styles.rightAlign}`,
      },
      headerValue: "LHR",
      cellProps: {
        className: `${styles.borderLeft} ${styles.rightAlign}`,
      }
    },
    {
      columnName: "activeLowLineHaulRate",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.activeLowLineHaulRate} formatString={"$0,0"} />,
      headerProps: {
        className: styles.rightAlign,
      },
      cellProps: {
        className: styles.rightAlign
      },
      headerValue: "Low LHR",
    },
    {
      columnName: "futureHighLineHaulRate",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.futureHighLineHaulRate} formatString={"$0,0"} />,
      headerProps: {
        className: `${styles.borderLeft} ${styles.rightAlign}`,
      },
      cellProps: {
        className: `${styles.borderLeft} ${styles.rightAlign}`,
      },
      headerValue: "LHR",
    },
    {
      columnName: "futureLowLineHaulRate",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.futureLowLineHaulRate} formatString={"$0,0"} />,
      headerProps: {
        className: styles.rightAlign,
      },
      cellProps: {
        className: styles.rightAlign
      },
      headerValue: "Low LHR",
    },
    {
      columnName: "sandboxHighLineHaulRate",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.sandboxHighLineHaulRate} formatString={"$0,0"} />,
      headerProps: {
        className: `${styles.borderLeft} ${styles.rightAlign}`,
      },
      headerValue: "LHR2",
      cellProps: {
        className: `${styles.borderLeft} ${styles.rightAlign}`,
      }
    },
    {
      columnName: "sandboxLowLineHaulRate",
      columnFieldData: (d) => <DisplayFormattedNumber value={d.sandboxLowLineHaulRate} formatString={"$0,0"} />,
      headerProps: {
        className: styles.rightAlign,
      },
      cellProps: {
        className: styles.rightAlign,
      },
      headerValue: "Low LHR",
    },
    {
      columnName: "action",
      columnFieldData: (d) => (
        <ActionMenu
          data={d}
          onDelete={this._onDeleteClick}
          includeFuture={this.props.rateModelTestService.getState().includeFuture}
        />
      ),
      headerValue: "",
      headerProps: {
        className: styles.borderLeft,
      },
      cellProps: {
        className: styles.borderLeft,
      }
    },
  ];

  render() {
    const {
      rateModelTestFetchResults,
      editAddRateModelTest,
      rateModelTestDeleteResults,
      rateModelTestUpdateResults,
      formMode,
      updateAllRateModelTestsMilesResult,
      rateModelTestAddResults,
      editAddValidationErrors,
      includeFuture,
      futureDateTime,
      futureDateValidationErrors,
      quickTestRequest,
      quickTestValidationErrors,
      quickTestResultFetchState
    } = this.props.rateModelTestService.getState();
    const {
      activeCommodities,
      commodityFetchResults
    } = this.props.commodityService.getState();

    const rateModelTestData = rateModelTestFetchResults.data ?? [];

    const isFetching: boolean = rateModelTestFetchResults.isFetching
      || rateModelTestDeleteResults.isFetching
      || rateModelTestAddResults.isFetching
      || rateModelTestUpdateResults.isFetching
      || commodityFetchResults.isFetching
      || updateAllRateModelTestsMilesResult.isFetching;

    const futureDateValidationParser = new ValidationErrorParser<{
      futureDateTime: Date
    }>(futureDateValidationErrors);

    const quickTestResult = quickTestResultFetchState.data;
    const quickTestRequestValidationsParser = new ValidationErrorParser<QuickRateModelTestRequest>(quickTestValidationErrors);

    // do not allow actions that cause grid refresh when future date is invalid.
    const allowRefresh = !includeFuture || (futureDateTime ? futureDateTime >= new Date() : false);

    return (
      <div
        className={containerStyles.mainContainer}
      >
        <Card
          className={containerStyles.cardStyle}
        >
          <CardHeader
            title="Tester"
          />
          <AjaxActionIndicator
            state={quickTestResultFetchState}
          />
          <Card
            className={containerStyles.cardStyle}
          >
            <Grid container>
              <Grid item xs={4}>
                <Container>
                  <span className={styles.sectionHeader}>Quick Test Criteria</span>
                  <div className={styles.criteriaContainer}>
                    <div className={styles.criteriaSubcontainer}>
                      <TextField
                        label="Origin"
                        value={quickTestRequest?.originZipPostalCode ?? ""}
                        onChange={this.handleOriginChange}
                        error={!quickTestRequestValidationsParser.isValid("originZipPostalCode")}
                        helperText={quickTestRequestValidationsParser.validationMessage("originZipPostalCode")}
                        inputRef={this.originRef}
                        style={{ marginTop: "8px" }}
                      />
                      <TextField
                        label="Destination"
                        value={quickTestRequest?.destZipPostalCode ?? ""}
                        onChange={this.handleDestinationChange}
                        error={!quickTestRequestValidationsParser.isValid("destZipPostalCode")}
                        helperText={quickTestRequestValidationsParser.validationMessage("destZipPostalCode")}
                        style={{ marginTop: "8px" }}
                      />
                      <FormControl style={{ marginBottom: "4px", minWidth: "160px" }} error={!quickTestRequestValidationsParser.isValid("commodityId")}>
                        <InputLabel>Commodity</InputLabel>
                        <Select
                          value={quickTestRequest?.commodityId ?? ""}
                          onChange={this.handleCommodityChanged}
                        >
                          {activeCommodities.map((x, i) =>
                            <MenuItem value={x.id} key={i}>
                              {x.commodityName}
                            </MenuItem>)}
                        </Select>
                        <FormHelperText>{quickTestRequestValidationsParser.validationMessage("commodityId")}</FormHelperText>
                      </FormControl>
                    </div>
                    <div>
                      <div className={styles.spaceBetween}>
                        <NumberFormat
                          allowNegative={false}
                          decimalScale={0}
                          label="Length/Weight"
                          value={quickTestRequest?.modelLevel ?? ""}
                          customInput={TextField}
                          onValueChange={this.handleModelLevelChanged}
                          error={!quickTestRequestValidationsParser.isValid("modelLevel")}
                          helperText={quickTestRequestValidationsParser.validationMessage("modelLevel")}
                          style={{ marginTop: "8px", maxWidth: "160px" }}
                        />
                        <Button
                          color="primary"
                          disabled={quickTestResultFetchState.isFetching}
                          onClick={this.sendRequest}
                          style={{ textTransform: "none", alignSelf: "center" }}
                        >
                          Rate
                        </Button>
                      </div>
                      <div>
                        <div style={{ marginTop: "8px" }}>
                          <DateTimeInputField
                            value={quickTestRequest?.futureDate ?? null}
                            onChange={this.handleFutureDateTimeChange}
                            dateLabel="Future Date"
                            error={!quickTestRequestValidationsParser.isValid("futureDate")}
                            helperText={quickTestRequestValidationsParser.validationMessage("futureDate")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              </Grid>
              <Grid item xs={2} className={styles.borderLeft}>
                <table className={styles.mileTable}>
                  <tbody>
                    <tr>
                      <td>Miles</td>
                      <td><DisplayFormattedNumber value={quickTestResult?.miles} formatString={'0,0'} /></td>
                    </tr>
                    <tr>
                      <td>Minimum Amount</td>
                      <td><DisplayFormattedNumber value={quickTestResult?.minimumAmount} formatString={'$0,0'} /></td>
                    </tr>
                    <tr>
                      <td>DAT Rate</td>
                      <td>
                        {quickTestResult?.datRateValue}
                        <DatRateMessage
                          datRate={quickTestResult?.datRate}
                          showAsTooltip
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={4} className={styles.borderLeft}>
                <table className={styles.rateTable}>
                  <tbody>
                    <tr>
                      <td style={{ "paddingLeft": "10px" }}>
                        <RateTestTooltip
                          defaultCalculationInfo={quickTestResult?.defaultCalculationInfo}
                          activeCalculationInfo={quickTestResult?.activeCalculationInfo}
                          futureCalculationInfo={quickTestResult?.futureCalculationInfo}
                          sandboxCalculationInfo={quickTestResult?.sandboxCalculationInfo}
                          includeFuture={quickTestResult?.futureHighLineHaulRate != undefined}
                        />
                      </td>
                      <td className={styles.rightAlign} style={{ "paddingLeft": "10px" }}>LHR</td>
                      <td className={styles.rightAlign} style={{ "paddingLeft": "10px" }}>Low LHR</td>
                    </tr>
                    <tr>
                      <td>Default</td>
                      <td className={styles.rightAlign}><DisplayFormattedNumber value={quickTestResult?.defaultHighLineHaulRate} formatString={'$0,0.00'} /></td>
                      <td className={styles.rightAlign}><DisplayFormattedNumber value={quickTestResult?.defaultLowLineHaulRate} formatString={'$0,0.00'} /></td>
                    </tr>
                    <tr>
                      <td>Active</td>
                      <td className={styles.rightAlign}><DisplayFormattedNumber value={quickTestResult?.activeHighLineHaulRate} formatString={'$0,0.00'} /></td>
                      <td className={styles.rightAlign}><DisplayFormattedNumber value={quickTestResult?.activeLowLineHaulRate} formatString={'$0,0.00'} /></td>
                    </tr>
                    <tr>
                      <td>Future</td>
                      <td className={styles.rightAlign}><DisplayFormattedNumber value={quickTestResult?.futureHighLineHaulRate} formatString={'$0,0.00'} /></td>
                      <td className={styles.rightAlign}><DisplayFormattedNumber value={quickTestResult?.futureLowLineHaulRate} formatString={'$0,0.00'} /></td>
                    </tr>
                    <tr>
                      <td>Sandbox</td>
                      <td className={styles.rightAlign}><DisplayFormattedNumber value={quickTestResult?.sandboxHighLineHaulRate} formatString={'$0,0.00'} /></td>
                      <td className={styles.rightAlign}><DisplayFormattedNumber value={quickTestResult?.sandboxLowLineHaulRate} formatString={'$0,0.00'} /></td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
          </Card>
          <Card
            className={containerStyles.cardStyle}
          >
            <AjaxActionIndicator
              state={rateModelTestFetchResults || commodityFetchResults}
            />
            <div className={styles.savedTestHeader}>
              <CardHeader
                title="Saved Tests"
              />
              {this.props.companyContext.companyId === 2 &&
                <span style={{ color: this.props.companyContext.themeColor }}>
                  ***All rates are for the secondary market
                </span>
              }
            </div>
            <CardActions className={containerStyles.actionArea}>
              <FormControlLabel
                control={
                  (
                    <Switch
                      color="primary"
                      checked={includeFuture}
                      onChange={this._onSetIncludeFuture}
                    />
                  )
                }
                label="Future Date"
              />
              {includeFuture && <DateTimeInputField
                value={futureDateTime}
                onChange={this._onSetFutureDate}
                error={!futureDateValidationParser.isValid("futureDateTime")}
                helperText={futureDateValidationParser.validationMessage("futureDateTime")}
              />}
              {includeFuture && <Button
                color="primary"
                onClick={this._onUpdate}
                disabled={isFetching}
              >
                Update
              </Button>}
              <Button
                color="primary"
                onClick={this._onUpdateMiles}
                disabled={isFetching || !allowRefresh}
                style={{ marginLeft: "auto", marginBottom: "10px" }}
              >
                Refresh
              </Button>
              <Button
                color="primary"
                onClick={this._onAddRateModelTest}
                disabled={isFetching || !allowRefresh}
                style={{ marginBottom: "10px" }}
              >
                ADD
              </Button>
            </CardActions>
            <div className={styles.rateTableOverride}>
              <DataTable
                tableHeaderComponent={
                  <TableRow>
                    <TableCell colSpan={7}>
                    </TableCell>
                    <TableCell className={`${styles.borderLeft} ${styles.centerText}`} colSpan={2}>
                      Default
                      </TableCell>
                    <TableCell className={`${styles.borderLeft} ${styles.centerText}`} colSpan={2}>
                      Active
                      </TableCell>
                    {includeFuture && <TableCell className={`${styles.borderLeft} ${styles.centerText}`} colSpan={2}>
                      Future
                    </TableCell>}
                    <TableCell className={`${styles.borderLeft} ${styles.centerText}`} colSpan={2}>
                      Sandbox
                    </TableCell>
                    {allowRefresh && <TableCell className={`${styles.borderLeft} ${styles.centerText}`} colSpan={2}>
                    </TableCell>}
                  </TableRow>
                }
                columns={this.columns}
                data={rateModelTestData}
                tableContextProps={{
                  hiddenColumns: includeFuture ? [] : ["futureHighLineHaulRate", "futureLowLineHaulRate"],
                  disableAction: !allowRefresh
                }}
              />
            </div>
          </Card>
        </Card>
        <EditAddRateModelTest
          formMode={formMode}
          rateModelTest={editAddRateModelTest}
          isFetching={isFetching}
          onRateModelTestChanged={this._onRateModelTestChanged}
          onSave={this._onSave}
          onCancel={this._onCancel}
          commodityOptions={activeCommodities}
          onSaveAndAdd={this._onSaveAndAdd}
          validationErrors={editAddValidationErrors}
        />
      </div >
    );
  }
}

export const RateTesterView = RateModelTestService.inject(
CommodityService.inject(
  _RateTesterView
)
);
