import {
  React,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  LoadingInstruction,
  LoadingInstructionAppliesToEnum
} from "$Generated/api";

import {
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControlLabel,
  Switch,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "$Imports/MaterialUIComponents";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  ILoadingInstructionServiceInjectedProps,
  LoadingInstructionService,
  EMPTY_LOADING_INSTRUCTION
} from "$State/LoadingInstructionFreezerService";

import {
  LoadingInstructionValidationSchema
} from "$State/LoadingInstructionValidationSchema";

const styles: {
  modalContainer: string;
} = require("$Shared/styles/Modal.scss");

interface IAddEditLoadingInstructionBaseProps {
  isOpen: boolean;
  filterActive: boolean;
  loadingInstruction: LoadingInstruction;
  onClose: (value?: LoadingInstruction) => void;
}

interface IOwnState {
  loadingInstruction: LoadingInstruction;
  validationErrors: ValidationError | null;
  disableSave: boolean;
}

type IAddEditLoadingInstructionProps = IAddEditLoadingInstructionBaseProps &
ILoadingInstructionServiceInjectedProps;

class _AddEditLoadingInstructionModal extends React.PureComponent<IAddEditLoadingInstructionProps, IOwnState> {
  state: IOwnState = {
    loadingInstruction: { ...EMPTY_LOADING_INSTRUCTION, isActive: true },
    validationErrors: null,
    disableSave: false
  };

  @bind
  private async _onClose(shouldSave: boolean): Promise<void> {
    if(!shouldSave) {
      this.props.onClose();
      return;
    }
    
    const loadingInstructionErrors = await validateSchema(LoadingInstructionValidationSchema, this.state.loadingInstruction);
    this.setState({ validationErrors: loadingInstructionErrors });

    if (loadingInstructionErrors) {
      return;
    }

    this.setState({
      disableSave: true
    });

    const { loadingInstruction } = this.state;
    
    try {
      await LoadingInstructionService.saveLoadingInstruction(loadingInstruction);
      this.props.loadingInstructionService.fetchLoadingInstructions(this.props.filterActive, true);
      this.props.onClose(loadingInstruction);
    } catch (ex) {
      if (ex) {
        this.setState({
          disableSave: false
        });
      }
    }
  }

  componentDidUpdate(prev: IAddEditLoadingInstructionBaseProps) {
    if(prev.isOpen !== this.props.isOpen) {
      this.setState({
        disableSave: false
      });
    }

    if (this.props.loadingInstruction !== prev.loadingInstruction) {
      this.setState({
        loadingInstruction: {
          ...this.props.loadingInstruction,
        },
        validationErrors: null
      });
    }
  }

  @bind
  private _onActiveChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.setState((prev) => ({
      loadingInstruction: {
        ...prev.loadingInstruction,
        isActive: checked
      }
    }));
  }

  @bind
  private _onAppliesToChange(event: SelectChangeEvent, child: React.ReactNode) {
    this.setState((prev) => ({
      loadingInstruction: {
        ...prev.loadingInstruction,
        appliesTo: event.target.value as LoadingInstructionAppliesToEnum
      }
    }));
  }

  @bind
  private _onChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState((prev) => ({
      loadingInstruction: {
        ...prev.loadingInstruction,
        [event.target.name]: event.target.value
      }
    }));
  }

  render() {
    const {
      isOpen
    } = this.props;

    const {
      loadingInstruction,
      disableSave,
      validationErrors
    } = this.state;

    const validationsParser = new ValidationErrorParser<LoadingInstruction>(validationErrors);

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>
        {loadingInstruction.id ? "Edit Instruction" : "Add Instruction"}
        </DialogTitle>
        <DialogContent
          className={styles.modalContainer}
        >
          <FormControlLabel
            control={
              (
                <Switch
                  color="primary"
                  checked={loadingInstruction.isActive ?? false}
                  onChange={this._onActiveChange}
                />
              )
            }
            label="Active"
          />
          <AdvanceTextField
            required
            label="Instruction"
            name="instruction"
            value={loadingInstruction.instruction ?? ""}
            onChange={this._onChange}
            inputProps={{ maxLength: 1000 }}
            error={!validationsParser.isValid("instruction")}
            helperText={validationsParser.validationMessage("instruction")}
            style={{ margin: "1rem 0"}}
          />
          <FormControl
            error={!validationsParser.isValid("appliesTo")}
          >
            <InputLabel>Applies To *</InputLabel>
            <Select
              required
              value={loadingInstruction.appliesTo !== undefined ? loadingInstruction.appliesTo : "BothShippersAndConsignees"}
              onChange={this._onAppliesToChange}
              style={{ width: "15rem"}}
            >
              <MenuItem value={"BothShippersAndConsignees"}>
                Both Shippers & Consignees
              </MenuItem>
              <MenuItem value={"ShippersOnly"}>
                Shippers Only
              </MenuItem>
              <MenuItem value={"ConsigneesOnly"}>
                Consignees Only
              </MenuItem>
            </Select>
            <FormHelperText>{validationsParser.validationMessage("appliesTo")}</FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => this._onClose(true)}
            disabled={disableSave}
          >
            Save
          </Button>
          <Button
            onClick={() => this._onClose(false)}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export const AddEditLoadingInstructionModal = LoadingInstructionService.inject(_AddEditLoadingInstructionModal);