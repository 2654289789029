import {
  React,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  AjaxActionIndicator,
  PercentageInputField
} from "$Imports/CommonComponents";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Grid
} from "$Imports/MaterialUIComponents";

import {
  FuelSurchargeModalState,
  formModeType
} from "$State/FuelSurchargeFreezerService"

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

const styles: {
  container: string;
  textField: string;
  labelLeft: string;
} = require("./AddFuelSurcharge.scss");

interface IAddFuelSurcharge {
  modalState: FuelSurchargeModalState;
  formMode: formModeType;
  isFetching: boolean;
  validationErrors: ValidationError | null;
  onSave: () => void;
  onCancel: () => void;
  onFuelSurchargeChange: (value: number | undefined) => void;
}

export class AddFuelSurcharge extends React.PureComponent<IAddFuelSurcharge> {

  @bind
  private _onFuelSurchargeChange(fuelSurchargeValue: number | undefined) {
    if(this.props.onFuelSurchargeChange) {
      this.props.onFuelSurchargeChange(fuelSurchargeValue);
    }
  }

  @bind
  private _onCancel() {
    if(this.props.onCancel) {
      this.props.onCancel();
    }
  }

  @bind 
  private _onSubmit() {
    if(this.props.onSave) {
      this.props.onSave();
    }
  }
  
  render() {
    const {
      modalState,
      formMode,
      isFetching,
      validationErrors
    } = this.props;

    const validationsParser = new ValidationErrorParser<FuelSurchargeModalState>(validationErrors);

    return (
      <Dialog
        open={formMode == "add"}
        fullWidth={true}
        maxWidth="xs"
      >
        <AjaxActionIndicator
          showProgress={isFetching}
        />
        <DialogTitle>
          Add Fuel Surcharge
        </DialogTitle>
        <DialogContent
          className={styles.container}
        >
          <Grid container direction={"column"} spacing={3}>
            <Grid item>
              <PercentageInputField
                label="Fuel Surcharge %"
                error={!validationsParser.isValid("surchargeValue")}
                helperText={validationsParser.validationMessage("surchargeValue")}
                decimalScale={2}
                value={modalState.surchargeValue}
                onPercentValueChange={this._onFuelSurchargeChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={isFetching}
            onClick={this._onSubmit}
          >
            Save
          </Button>
          <Button
            onClick={this._onCancel}
            disabled={isFetching}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}