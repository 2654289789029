import {
  FreezerService,
  _,
  bind,
  moment,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional
} from "$Imports/Imports";

import {
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import yup from "$Shared/utilities/yupExtension";

import {
  Customer,
  CustomerApiFactory,
  CustomerBillingStatusEnum,
  CustomerConvertParams,
  CustomerHour,
  CustomerHourDayOfWeekEnum,
  CustomerSearchCriteria,
  CustomerSearchCriteriaBillingStatusEnum,
  CustomerSearchCriteriaIndustryTypeEnum,
  CustomerSearchCriteriaPercentageToCloseEnum,
  CustomerSearchResult,
  ProspectDecisionMakingTypeEnum,
  ProspectIndustryTypeEnum,
  ProspectPercentageToCloseEnum,
  ProspectPricingTypeEnum,
  Region
} from "$Generated/api";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import AppConstants from "$Utilities/AppConstants";

const CustomerSearchValidationSchema: SchemaOf<NullableOptional<CustomerSearchCriteria>> = yup.object({
  customerName: yup.string()
    .notRequired()
    .allowEmpty(),
  regionAbbreviation: yup.string()
    .notRequired()
    .allowEmpty(),
  salesRepId: yup.number().notRequired(),
  isProspect: yup.boolean().notRequired(),
  isCaller: yup.boolean().notRequired(),
  isShipper: yup.boolean().notRequired(),
  isConsignee: yup.boolean().notRequired(),
  includeProspects: yup.boolean().notRequired(),
  isBillToSearch: yup.boolean().notRequired(),
  callerId: yup.number().notRequired(),
  industryType: yup.mixed<CustomerSearchCriteriaIndustryTypeEnum>().notRequired(),
  currentProvider: yup.string().notRequired(),
  percentageToClose: yup.mixed<CustomerSearchCriteriaPercentageToCloseEnum>().notRequired(),
  billingStatus: yup.mixed<CustomerSearchCriteriaBillingStatusEnum>().notRequired(),
  startIndex: yup.number().notRequired(),
  pageSize: yup.number().notRequired(),
  sortColumn: yup.string().notRequired(),
  sortAscending: yup.boolean().notRequired()
});

export const CustomerHourValidationSchema: SchemaOf<NullableOptional<CustomerHour>> = yup.object().shape({
  id: yup.number().notRequired(),
  customerId: yup.number().notRequired(),
  dayOfWeek: yup.mixed<CustomerHourDayOfWeekEnum>().oneOf(AppConstants.DayOfWeekEnumArray).notRequired(),
  openTime: yup.string().notRequired()
    .when('closeTime', (closeTime: string, schema: any) => {
      return closeTime ? schema.required("Time range cannot be open-ended") : schema.notRequired();
    })
    .test("closeTime", "${message}", (value: string | undefined, testContext: any) => {
      if (value === "Invalid date") {
        return testContext.createError({ message: "Invalid time or format" });
      }

      return true;
    })
    .transform((value: any) => value || undefined),
  closeTime: yup.string().notRequired()
    .when('openTime', (openTime: string, schema: any) => {
      return openTime ? schema.required("Time range cannot be open-ended") : schema.notRequired();
    })
    .test("closeTime", "${message}", (value: string | undefined, testContext: any) => {
      const m1 = moment(testContext.parent.openTime, "HH:mm");
      const m2 = moment(value, "HH:mm");

      if (value === "Invalid date") {
        return testContext.createError({ message: "Invalid time or format" });
      }
      if (m2.isBefore(m1)) {
        return testContext.createError({ message: "Close time must be after open time" });
      }

      return true;
    })
    .transform((value: any) => value || undefined),
  allDay: yup.boolean().notRequired(),
  closed: yup.boolean().notRequired(),
  hasHours: yup.boolean().notRequired(),
  auditDisplayValue: yup.string().notRequired()
}, [['openTime', 'closeTime']]); // the array param is so openTime/closeTime don't form a cyclic dependency

export const CustomerValidationSchema: SchemaOf<NullableOptional<Customer>> = yup.object({
  id: yup.number().notRequired(),
  customerName: yup.string().required("Customer Name is required").max(150, "Max length can not exceed 150 characters"),
  tmcustomerId: yup.string().notRequired().allowEmpty().nullable(),
  contactName: yup.string().notRequired().max(300, "Max length can not exceed 300 characters").nullable(),
  emailAddress: yup.string()
    .when('isCaller', {
      is: true,
      then: yup.string().required("Email is required").email("Invalid email").max(250, "Max length can not exceed 250 characters").nullable(),
      otherwise: yup.string().notRequired().allowEmpty().nullable()
    }),
  address1: yup.string().notRequired().allowEmpty().max(150, "Max length can not exceed 150 characters").nullable(),
  address2: yup.string().notRequired().allowEmpty().max(150, "Max length can not exceed 150 characters").nullable(),
  city: yup.string().notRequired().allowEmpty().max(150, "Max length can not exceed 150 characters").nullable(),
  regionId: yup.number().notRequired(),
  phoneNumber: yup.string().required("Phone Number is required").allowEmpty().phoneNumber("Invalid phone number").nullable(),
  cellNumber: yup.string().notRequired().allowEmpty().phoneNumber("Invalid phone number").nullable(),
  zipPostalCode: yup.string()
    .when("region", (region: Region | undefined | null, schema: any) => {
      return region?.countryId === 1 ? schema.notRequired().allowEmpty().usZipCode().nullable()
      : region?.countryId === 2 ? schema.notRequired().allowEmpty().canadianZipCode().nullable()
      : schema.notRequired().allowEmpty().zipCode().nullable()
    }),
  website: yup.string().notRequired().allowEmpty().nullable().max(150, "Max length can not exceed 150 characters").website("Website must start with http:// or https://"),
  isActive: yup.boolean().notRequired(),
  salesAgentId: yup.number().notRequired().allowNaN().nullable(),
  customerSince: yup.date().notRequired().nullable(),
  createdOn: yup.date().notRequired(),
  modifiedOn: yup.date().notRequired(),
  isCaller: yup.boolean().notRequired() // this test performs validation for all 3 checkboxes
    .test("isCaller", "${message}", (value: any, testContext: any) => {
      if (!(testContext.parent.isCaller || testContext.parent.isShipper || testContext.parent.isConsignee)) {
        return testContext.createError({ message: "At least one customer type must be selected" });
      }

      return true;
    }),
  isConsignee: yup.boolean().notRequired(),
  isShipper: yup.boolean().notRequired(),
  region: yup.object().notRequired().nullable(),
  salesAgent: yup.object().notRequired().nullable(),
  quotes: yup.array().notRequired(),
  addresses: yup.array().notRequired(),
  customerContacts: yup.array().notRequired(),
  customerQuotes: yup.array().notRequired(),
  commodityExclusions: yup.array().notRequired(),
  activities: yup.array().notRequired(),
  customerHours: yup.array().of(CustomerHourValidationSchema).notRequired().nullable(), // the array param is so openTime/closeTime don't form a cyclic dependency
  customerLoadingInstructions: yup.array().notRequired(),
  hasCustomerPortalAccess: yup.boolean().notRequired().nullable(),
  displayAlert: yup.boolean().notRequired(),
  alert: yup.string().when('displayAlert', {
    is: true,
    then: yup.string().required("Alert Text is required").max(500, "Max length can not exceed 500 characters").nullable(),
    otherwise: yup.string().notRequired().allowEmpty().nullable()
  }),
  customerSourceId: yup.number().notRequired().nullable(),
  customerSource: yup.object().notRequired().nullable(),
  isProspect: yup.boolean().notRequired(),
  prospectId: yup.number().notRequired().allowNaN().nullable(),
  prospect: yup.object().notRequired().nullable(),
  sourceDetails: yup.string().notRequired().allowEmpty().max(50, "Max length can not exceed 50 characters").nullable(),
  isBillTo: yup.boolean().notRequired(),
  billingStatus: yup.mixed<CustomerBillingStatusEnum>().oneOf(["CheckCredit", "CreditConfirmed", "CODOnly"]).required("Billing Status is required"),
  creditLimit: yup.number().notRequired().nullable(true),
  dueDays: yup.number().notRequired().nullable(true),
  hasBillingAddress: yup.boolean().notRequired(),
  billingAddress1: yup.string()
    .when('hasBillingAddress', {
      is: true,
      then: yup.string().typeError('Billing Address is required').required('Billing Address is required').max(150, 'Max length cannot exceed 150 characters'),
      otherwise: yup.string().notRequired().allowEmpty().max(150, 'Max length cannot exceed 150 characters').nullable(),
    }),
  billingAddress2: yup.string().notRequired().allowEmpty().max(150, "Max length can not exceed 150 characters").nullable(),
  billingCity: yup.string()
    .when('hasBillingAddress', {
      is: true,
      then: yup.string().typeError('Billing City is required').required('Billing City is required').max(150, 'Max length cannot exceed 150 characters'),
      otherwise: yup.string().notRequired().allowEmpty().max(150, 'Max length cannot exceed 150 characters').nullable(),
    }),
  billingRegionId: yup.number()
    .when('hasBillingAddress', {
      is: true,
      then: yup.number().typeError('Billing Region is required').required('Billing Region is required'),
      otherwise: yup.number().notRequired().nullable(),
    }),
  billingZipPostalCode: yup.string()
    .when('hasBillingAddress', {
      is: true,
      then: yup.string().when('billingRegion', (billingRegion: Region | undefined | null, schema: any) => {
        return billingRegion?.countryId === 1 ? schema.required('Billing Postal Code is required').usZipCode().nullable()
          : billingRegion?.countryId === 2 ? schema.required('Billing Postal Code is required').canadianZipCode().nullable()
          : schema.required('Billing Postal Code is required').zipCode().nullable();
      }),
      otherwise: yup.string().when('billingRegion', (billingRegion: Region | undefined | null, schema: any) => {
        return billingRegion?.countryId === 1 ? schema.notRequired().allowEmpty().usZipCode().nullable()
          : billingRegion?.countryId === 2 ? schema.notRequired().allowEmpty().canadianZipCode().nullable()
          : schema.notRequired().allowEmpty().zipCode().nullable();
      }),
    }),
  billingRegion: yup.object().notRequired().nullable()
});

export const CustomerAddressRequiredValidationSchema: SchemaOf<NullableOptional<Customer>> = CustomerValidationSchema.concat(
  yup.object({
    address1: yup.string().required("Address is required").max(150, "Max length can not exceed 150 characters"),
    city: yup.string().required("City is required").max(150, "Max length can not exceed 150 characters"),
    regionId: yup.number().required("Region is required"),
    region: yup.object().notRequired().nullable(),
    zipPostalCode: yup.string()
      .when("region", (region: Region | undefined | null, schema: any) => {
        return region?.countryId === 1 ? schema.required("Postal Code is required").usZipCode() 
          : region?.countryId === 2 ? schema.required("Postal Code is required").canadianZipCode()
          : schema.required("Postal Code is required").zipCode()
      })
  }));

export const CustomerProspectValidationSchema: SchemaOf<NullableOptional<Customer>> = yup.object({
  id: yup.number().notRequired(),
  customerName: yup.string().required("Customer Name is required").max(40, "Max length can not exceed 40 characters"),
  tmcustomerId: yup.string().notRequired().allowEmpty().nullable(),
  contactName: yup.string().notRequired().nullable(),
  isCaller: yup.boolean().notRequired().nullable(),
  isConsignee: yup.boolean().notRequired().nullable(),
  isShipper: yup.boolean().notRequired().nullable(),
  salesAgentId: yup.number().required("Sales Representative is required").transform((value: any) => value || undefined),
  salesAgent: yup.object().notRequired().nullable(),
  emailAddress: yup.string().notRequired().nullable(),
  address1: yup.string().required("Address is required").max(40, "Max length can not exceed 40 characters"),
  address2: yup.string().notRequired().allowEmpty().max(40, "Max length can not exceed 40 characters").nullable(),
  city: yup.string().required("City is required").max(30, "Max length can not exceed 30 characters"),
  regionId: yup.number().required("Region is required"),
  region: yup.object().notRequired().nullable(),
  zipPostalCode: yup.string()
    .when("region", (region: Region | undefined | null, schema: any) => {
      return region?.countryId === 1 ? schema.required("Postal Code is required").usZipCode() 
        : region?.countryId === 2 ? schema.required("Postal Code is required").canadianZipCode()
        : schema.required("Postal Code is required").zipCode()
    }),
  phoneNumber: yup.string().required("Phone Number is required").allowEmpty().phoneNumber("Invalid phone number").nullable(),
  cellNumber: yup.string().notRequired().allowEmpty().phoneNumber("Invalid phone number").nullable(),
  isActive: yup.boolean().notRequired(),
  website: yup.string().required("Website is required").max(150, "Max length can not exceed 150 characters").website("Website must start with http:// or https://"),
  customerSourceId: yup.number().required("Lead Source is required"),
  customerSource: yup.object().notRequired().nullable(),
  customerSince: yup.date().notRequired().nullable(),
  createdOn: yup.date().notRequired(),
  modifiedOn: yup.date().notRequired(),
  quotes: yup.array().notRequired(),
  addresses: yup.array().notRequired(),
  customerContacts: yup.array().notRequired(),
  customerQuotes: yup.array().notRequired(),
  commodityExclusions: yup.array().notRequired(),
  activities: yup.array().notRequired(),
  customerHours: yup.array().notRequired(),
  customerLoadingInstructions: yup.array().notRequired(),
  hasCustomerPortalAccess: yup.boolean().notRequired().nullable(),
  displayAlert: yup.boolean().notRequired(),
  alert: yup.string().notRequired().nullable(),
  isProspect: yup.boolean().notRequired(),
  prospectId: yup.number().notRequired(),
  prospect: yup.object({
    id: yup.number().notRequired(),
    currentProvider: yup.string().notRequired().max(100, "Max length can not exceed 100 characters").allowEmpty(),
    decisionMakingType: yup.mixed<ProspectDecisionMakingTypeEnum>().oneOf(["Customer", "DealerNetwork", "Local", "Other"]).notRequired().transform((value: any) => value || undefined),
    estAvgMonthlyFreightBills: yup.number().notRequired().max(2000000000, "Number cannot exceed 2 billion.").transform((value: any) => value || undefined),
    estAvgRevenuePerFreightBill: yup.number().notRequired().max(2000000000, "Number cannot exceed 2 billion.").transform((value: any) => value || undefined),
    industryType: yup.mixed<ProspectIndustryTypeEnum>().oneOf(["Aerospace", "Equipment", "GeneratorsTransformers", "MachineTools", "Steel", "Other"]).required("Industry Type is required"),
    percentageToClose: yup.mixed<ProspectPercentageToCloseEnum>().oneOf(["NoContactWithDM", "ContactedDMInterestExpressed", "Quoted", "VerballyAcceptedQuote", "OrderPlaced"]).required("Percentage to Close is required"),
    pricingType: yup.mixed<ProspectPricingTypeEnum>().oneOf(["Contracted", "SpotQuote"]).notRequired().transform((value: any) => value || undefined),
    startDate: yup.date().notRequired()
      .typeError("Invalid Date")
      .transform((value: any) => value && !isNaN(value.valueOf()) ? value : undefined)
      .test("startDate", "${message}", (value: Date | undefined, testContext: any) => {
        const currentDate = testContext.options.context.currentTime;
        const chosenDate = moment(value).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
        const startIsPast = chosenDate.isBefore(currentDate);

        if ( startIsPast) {
          return testContext.createError({ message: "Date cannot be in the past." });
        }

        return true;
      }),
    customer: yup.object().notRequired()
  }),
  sourceDetails: yup.string().notRequired().allowEmpty().max(50, "Max length can not exceed 50 characters").nullable(),
  isBillTo: yup.boolean().notRequired(),
  billingStatus: yup.mixed<CustomerBillingStatusEnum>().oneOf(["CheckCredit", "CreditConfirmed", "CODOnly"]).required("Billing Status is required"),
  creditLimit: yup.number().notRequired().nullable(true),
  dueDays: yup.number().notRequired(),
  hasBillingAddress: yup.boolean().notRequired(),
  billingAddress1: yup.string().notRequired().allowEmpty().max(150, "Max length can not exceed 150 characters").nullable(),
  billingAddress2: yup.string().notRequired().allowEmpty().max(150, "Max length can not exceed 150 characters").nullable(),
  billingCity: yup.string().notRequired().allowEmpty().max(150, "Max length can not exceed 150 characters").nullable(),
  billingRegionId: yup.number().notRequired().nullable(),
  billingZipPostalCode: yup.string()
  .when("billingRegion", (billingRegion: Region | undefined | null, schema: any) => {
    return billingRegion?.countryId === 1 ? schema.notRequired().allowEmpty().usZipCode().nullable()
    : billingRegion?.countryId === 2 ? schema.notRequired().allowEmpty().canadianZipCode().nullable()
    : schema.notRequired().allowEmpty().zipCode().nullable()
  }),
  billingRegion: yup.object().notRequired().nullable()
});

export const ProspectConvertValidationSchema: SchemaOf<NullableOptional<CustomerConvertParams>> = yup.object({
  isConsignee: yup.boolean().notRequired().nullable(),
  isShipper: yup.boolean().notRequired().nullable()
});

// note that this does not capture the usage of the AddEditCustomerModal on the CustomerDetailViewPage
// since that's handled in the CustomerDetailFreezerService
type AddEditCustomerModalUsageContext = "CustomersViewPage" | "CustomerCard" | "CustomerSearchModal" | "SalesRepHomeView" | "ShipperConsigneeEntry" | "";

interface ICustomerServiceState {
  // search
  searchCriteria: CustomerSearchCriteria;
  searchValidationErrors: ValidationError | null;
  searchResults: IAjaxState<CustomerSearchResult>;
  selectedRow: Customer | null;
  // add/edit
  customerModalIsOpen: AddEditCustomerModalUsageContext;
  customerValidationErrors: ValidationError | null;
  saveCustomerResults: IAjaxState<Customer>;
  addEditCustomer: Customer | null;
  customerExistsResults: IAjaxState<boolean>;
  // get customer
  customerFetchResults: IAjaxState<Customer>;
}

const InjectedPropName = "customerService";

const initialState = {
  // search / sort
  searchCriteria: {
    customerName: "",
    regionAbbreviation: "",
    sortColumn: "customerName",
    sortAscending: true,
    startIndex: 0,
    pageSize: 20
  },
  searchValidationErrors: null,
  searchResults: managedAjaxUtil.createInitialState(),
  selectedRow: null,
  // add/edit
  customerModalIsOpen: "",
  customerValidationErrors: null,
  saveCustomerResults: managedAjaxUtil.createInitialState(),
  customerFetchResults: managedAjaxUtil.createInitialState(),
  customerExistsResults: managedAjaxUtil.createInitialState()
} as ICustomerServiceState;

class CustomerFreezerService extends FreezerService<ICustomerServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public onSearchModelChanged(searchModel: Partial<CustomerSearchCriteria>) {
    this.freezer.get().searchCriteria.set(searchModel);
  }

  public clearSearchResults() {
    this.freezer.get().searchResults.set(managedAjaxUtil.createInitialState());
  }

  public setSelectedRow(selectedRow: Customer | null) {
    const currentSelected = this.freezer.get().selectedRow?.toJS();

    if (selectedRow && currentSelected?.id == selectedRow.id) {
      this.freezer.get().set({ selectedRow: null });
    } else {
      this.freezer.get().set({ selectedRow });
    }
  }

  public async customerIsActiveInTruckMate(tmCode: string | undefined): Promise<boolean> {
    if (tmCode) {
      const customerFactory = CustomerApiFactory(managedAjaxUtil.apiOptions.wrappedFetch, managedAjaxUtil.apiOptions.baseUrl);
      const isActive = await customerFactory.isCustomerActiveInTruckMate({ tmCustomerId: tmCode });

      if (!isActive) {
        ErrorService.pushErrorMessage("Customer is no longer in TruckMate and cannot be selected.");
      }

      return isActive;
    }

    return true;
  }

  public async onSearchClick() {
    var searchModel = this.freezer.get().searchCriteria.toJS();

    const errors = await validateSchema(CustomerSearchValidationSchema, searchModel, {
      abortEarly: false
    });

    this.freezer.get().set({ searchValidationErrors: errors });
    
    if (errors) {
      return;
    }

    this.setSelectedRow(null);

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "searchResults",
      params: {
        body: searchModel
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.searchCustomers(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch customer search results.");
      },
      onOk: (data: CustomerSearchResult) => {
        const firstCustomer = _.first(data.results);
        if (firstCustomer) {
          this.setSelectedRow(firstCustomer)
        }
      }
    });
  }

  public openAddEditCustomerModal(openedFrom: AddEditCustomerModalUsageContext, customer?: Customer) {
    var editCustomer = _.cloneDeep(customer);

    if (editCustomer?.customerHours) {
      editCustomer.customerHours = _.map(editCustomer.customerHours, (h) => ({
        ...h,
        openTime: h.openTime ? h.openTime.substring(0, 5) : h.openTime,
        closeTime: h.closeTime ? h.closeTime.substring(0, 5) : h.closeTime
      }));
    }
    else if (editCustomer && editCustomer.customerHours === undefined) {
      editCustomer.customerHours = [];
      editCustomer.customerLoadingInstructions = [];
    }

    this.freezer.get().set({
      customerModalIsOpen: openedFrom,
      addEditCustomer: editCustomer
    });
  }

  public closeAddEditModal() {
    this.freezer.get().set({
      customerModalIsOpen: "",
      customerValidationErrors: null,
      addEditCustomer: null
    });
  }

  public async checkCustomerExists(addEditCustomer: Customer) {
    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "customerExistsResults",
      params: {
        body: addEditCustomer
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.checkCustomerExists(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to check customer.");
      }
    });
  }

  public async addCustomer(addEditCustomer: Customer): Promise<Customer | void> {
    return await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "saveCustomerResults",
      params: {
        body: addEditCustomer
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.addCustomer(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to save customer.");
      }
    });
  }

  public async updateSelectedCustomer(addEditCustomer: Customer) {
    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "saveCustomerResults",
      params: {
        body: addEditCustomer
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.updateCustomer(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to save customer.");
      }
    });
  }

  public async getCustomerById(id: number | undefined) {
    if (!id) {
      return;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "customerFetchResults",
      params: {
        customerId: id
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getCustomerById(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch customer.");
      }
    });
  }
}

export const CustomerService = new CustomerFreezerService();
export type ICustomerServiceInjectedProps = ReturnType<CustomerFreezerService["getPropsForInjection"]>;
