import {
  React,
  bind,
  _,
  moment
} from "$Imports/Imports";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button
} from "$Imports/MaterialUIComponents";

import {
  formModeType
} from "$State/RateModelFreezerService";

import {
  AjaxActionIndicator,
  DateTimeInputField
} from "$Imports/CommonComponents";

const styles: {
  container: string;
} = require("./ApplySandboxRates.scss");

interface IApplySandboxRatesProps {
  isFetching: boolean;
  formMode: formModeType;
  resetToDefaultDate: Date | null;
  onApplySandbox: () => void;
  onApplySandboxCancel: () => void;
  onResetToDefaultDateChange: (datetime: Date) => void;
}

export class ApplySandboxRates extends React.PureComponent<IApplySandboxRatesProps> {

  @bind
  private _onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    this.props.onApplySandbox();
  }

  @bind
  private _onResetToDefaultDateChange(date: Date | undefined) {
    this.props.onResetToDefaultDateChange(date ?? new Date());
  }

  @bind
  private resetToDefaultDateInvalid() : boolean {
    const resetToDefaultDate = this.props.resetToDefaultDate;
    if (resetToDefaultDate == null || !moment(resetToDefaultDate).isValid()){
      return true;
    }
    return resetToDefaultDate <= new Date();
  }

  render() {
    const {
      isFetching,
      formMode,
      resetToDefaultDate,
      onApplySandboxCancel
    } = this.props;

    return <Dialog
      open={formMode === "apply sandbox"}
      maxWidth={"md"}
    >
      <form onSubmit={this._onSubmit}>
        <AjaxActionIndicator
          showProgress={isFetching}
        />
        <DialogTitle>
          Apply Sandbox
          </DialogTitle>
        <DialogContent className={styles.container}>
          <span>Do you want to activate the Sandbox values to Kaiser TSS %?</span>
          <br />
          <span>Reset active TSS % to default TSS % on</span>
          <DateTimeInputField
            value={resetToDefaultDate ?? null}
            onChange={this._onResetToDefaultDateChange}
            error={this.resetToDefaultDateInvalid()}
            helperText={this.resetToDefaultDateInvalid() && "Datetime cannot be empty and must be in the future."}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={isFetching || this.resetToDefaultDateInvalid()}
            type="submit"
          >
            Confirm
          </Button>
          <Button
            onClick={onApplySandboxCancel}
            disabled={isFetching}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>;
  }
}
