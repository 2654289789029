import {
  React,
  bind,
  NumberFormatValues,
  _,
  moment
} from "$Imports/Imports";

import {
  Customer,
  Quote,
  QuoteStatusEnum,
  QuoteStop
} from "$Generated/api";

import {
  AjaxActionIndicator,
  AdvanceTextField,
  ClearButton,
  QuestionAnswerButton,
  UserAccessControl,
  OperationTimeoutModal
} from "$Imports/CommonComponents";

import {
  Button,
  Checkbox,
  FormControlLabel
} from "$Imports/MaterialUIComponents";

import {
  Error
} from "$Imports/MaterialUIIcons";

import {
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps,
  AlertCustomerType
} from "$State/QuoteEntryFreezerService";

import {
  CommodityService,
  ICommodityServiceInjectedProps
} from "$State/CommodityFreezerService";

import {
  CompanySelectService,
  ICompanySelectServiceInjectedProps
} from "$State/CompanySelectFreezerService";

import {
  DeclaredValueService,
  IDeclaredValueServiceInjectedProps
} from "$State/DeclaredValueFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  QuoteStopDatesEntry
} from "./QuoteStopDatesEntry";

import {
  QuoteStopAddressesEntry
} from "./QuoteStopAddressesEntry";

import {
  QuoteStopFreightDimensionsEntry
} from "./QuoteStopFreightDimensionsEntry";

import {
  UpchargeEntry
} from "./UpchargeEntry";

import {
  TarpEntry
} from "./TarpEntry";

import {
  DeclaredValueEntry
} from "./DeclaredValueEntry";

import {
  StopFlagsDisplay
} from "./StopFlagsDisplay";

import {
  StopFlagErrorMessage
} from "./StopFlagErrorMessage";

import {
  ErrorService
} from "$State/ErrorFreezerService";

import {
  DEFAULT_TIMEOUT,
  TIMEOUT_BUFFER
} from "$Utilities/ratingUtil";

export type DateType = "Start" | "End";
export type DateEntity = "shipper" | "consignee";
export type UpchargeType = "Flat" | "Percent";

type DateEntityCapitilized = "Shipper" | "Consignee"

const styles: {
  subSection: string;
  quoteChangedError: string;
  label: string;
} = require("./QuoteStopEntry.scss");

interface IQuoteStopEntryBaseProps {
  companyId: number | undefined;
  themeColor: string;
  currentQuoteStopIndex: number;
  thisQuoteStopIndex: number;
  quoteStop: QuoteStop;
  onCustomerAlertClick: (alertCustomers: Customer[], alertCustomerType: AlertCustomerType) => void;
  onZipCodeChange: (zipcode: string) => void;
}

interface IQuoteStopEntryState {
  isOperationTimeoutModalOpen: boolean;
}

type IQuoteStopEntryProps = IQuoteEntryServiceInjectedProps
  & ICommodityServiceInjectedProps
  & IDeclaredValueServiceInjectedProps
  & ICompanySelectServiceInjectedProps
  & IQuoteStopEntryBaseProps;

class _QuoteStopEntry extends React.Component<IQuoteStopEntryProps, IQuoteStopEntryState> {
  state: IQuoteStopEntryState = {
    isOperationTimeoutModalOpen: false
  };

  @bind
  private _onDateChange(dateEntity: DateEntity, dateType: DateType, date: Date | null) {
    this.props.QuoteEntryService.updateQuoteStop(this.props.thisQuoteStopIndex, { [`${dateEntity}${dateType}Date`]: date ? moment(date).startOf('day').toDate() : null });
  }

  @bind
  private _onHardTimeChange(dateEntity: DateEntity, time: string | undefined) {
    this.props.QuoteEntryService.updateQuoteStop(this.props.thisQuoteStopIndex, { [`${dateEntity}HardTime`]: time });
  }

  @bind
  private _onApptRequiredChange(dateEntity: DateEntity, checked: boolean) {
    const dateEntityCapitilized: DateEntityCapitilized = dateEntity === "shipper" ? "Shipper" : "Consignee"

    if (checked) {
      this.props.QuoteEntryService.updateQuoteStop(this.props.thisQuoteStopIndex, { [`is${dateEntityCapitilized}AppointmentRequired`]: checked });
    }
    else {
      this.props.QuoteEntryService.updateQuoteStop(this.props.thisQuoteStopIndex, { [`is${dateEntityCapitilized}AppointmentRequired`]: checked, [`${dateEntity}HardTime`]: undefined });
    }

    this.props.QuoteEntryService.updateHardTimeShippingNotes(this.props.thisQuoteStopIndex, dateEntity);
  }

  @bind
  private _onUpchargeChanged(value?: NumberFormatValues) {
    this.props.QuoteEntryService.updateQuote({ flatUpcharge: value?.floatValue });
  }

  @bind
  private _onUpchargeBlur() {
    this.props.QuoteEntryService.openUpchargeQuestionModal(true);
  }

  @bind
  private _onFlatUpchargeFocus() {
    this.props.QuoteEntryService.updateFlatUpchargeFocus();
  }

  @bind
  private _onFlatUpchargeReopenQuestionClick() {
    this.props.QuoteEntryService.openUpchargeQuestionModal();
  }

  @bind
  private _onTarpChange(tarpId: string | undefined) {
    this.props.QuoteEntryService.updateQuoteStop(this.props.thisQuoteStopIndex, { tarpId: tarpId ? parseInt(tarpId) : undefined, tarp: undefined });
  }

  @bind
  private _onClearTarp() {
    this.props.QuoteEntryService.updateQuoteStop(this.props.thisQuoteStopIndex, { tarpId: undefined })
  }

  @bind
  private _onPercentUpchargeReopenQuestionClick() {
    this.props.QuoteEntryService.openPercentUpchargeModal();
  }

  @bind
  private _onPercentChanged(value?: NumberFormatValues) {
    this.props.QuoteEntryService.updateQuote({ upchargePercentage: value?.floatValue });
  }

  @bind
  private _onPercentUpchargeBlur() {
    this.props.QuoteEntryService.openPercentUpchargeModal(true);
  }

  @bind
  private _onPercentFocus() {
    this.props.QuoteEntryService.updatePercentUpchargeFocus();
  }

  @bind
  private _onDeclaredValChanged(value?: NumberFormatValues) {
    this.props.QuoteEntryService.updateQuoteStop(this.props.thisQuoteStopIndex, { declaredValue: value?.floatValue });
    this.props.QuoteEntryService.updateDeclaredValueApprovalNeeded(this.props.companyId, this.props.thisQuoteStopIndex);
  }

  @bind
  private async sendRequest(timeout: number = DEFAULT_TIMEOUT) {
    const { quote } = this.props.QuoteEntryService.getState();

    const isQuickQuote = quote.quoteType === "Quick";
    const action: QuoteStatusEnum = (isQuickQuote && quote.calculatedRates)
      ? "Pending"
      : "InProgress";
    const bufferTimer = this.setupTimeoutBuffer();

    try {
      await this.props.QuoteEntryService.save(action, false, timeout);
    } catch (ex) {
      // don't show timeout modal for non-timeout errors
      clearTimeout(bufferTimer);
      throw ex;
    }

    let error: string | undefined;

    if (isQuickQuote || (quote.status === "Convert")) {
      const { quickQuoteSaveResults } = this.props.QuoteEntryService.getState();
      error = (quickQuoteSaveResults.data?.state?.error as any)?.Message;
    }
    else {
      const { quoteSaveResults } = this.props.QuoteEntryService.getState();
      error = (quoteSaveResults.data?.state?.error as any)?.Message;

      if (!error && quoteSaveResults.data?.quote?.calculatedRates && quoteSaveResults.data?.quote?.calculatedRates?.length > 1) {
        this.props.QuoteEntryService.fetchDataChangeLogs(quoteSaveResults.data?.quote?.id);
      }
    }

    this.detectTimeout(bufferTimer, error);
  }

  @bind
  private async calculateRateInEditMode(timeout: number = DEFAULT_TIMEOUT) {
    const bufferTimer = this.setupTimeoutBuffer();
    try {
      await this.props.QuoteEntryService.calculateRate(timeout);
    } catch (ex) {
      // don't show timeout modal for non-timeout errors
      clearTimeout(bufferTimer);
      throw ex;
    }

    const { quoteCalculateRatingResult } = this.props.QuoteEntryService.getState();
    const error = quoteCalculateRatingResult.data?.error;

    this.detectTimeout(bufferTimer, error);
  }

  @bind
  private setupTimeoutBuffer(): number | undefined {
    const { isOperationTimeoutModalOpen } = this.state;

    let bufferTimer: number | undefined = undefined;

    if (!isOperationTimeoutModalOpen) {
      bufferTimer = setTimeout(() => {
        this.setState({ isOperationTimeoutModalOpen: true });
      }, TIMEOUT_BUFFER) as unknown as number; // timeout IDs in browser are _not_ NodeJS.Timeout
    }

    return bufferTimer;
  }

  @bind
  private detectTimeout(bufferTimer?: number, error?: string) {
    let keepTimeoutModal = false;

    if (error && error.indexOf("timed out") > -1) {
      keepTimeoutModal = true;
    }

    if (bufferTimer) {
      clearTimeout(bufferTimer);
    }

    this.setState({ isOperationTimeoutModalOpen: keepTimeoutModal });
  }

  @bind
  private _onCalculateRateTimeoutCancel() {
    this.setState({ isOperationTimeoutModalOpen: false });
  }

  @bind
  private _onDescriptionChanged(newValue: string) {
    this.props.QuoteEntryService.updateQuoteStop(this.props.thisQuoteStopIndex, { description: newValue });
  }

  @bind
  private _onNotesChange(value: string) {
    this.props.QuoteEntryService.updateNegotiatedQuoteDataEntry({ notes: value });
  }

  @bind
  private _getApprovalReasonsMessage(isOverdimensional: boolean | undefined, isApprovalNeededForDeclaredValue: boolean) {
    var reasons: string[] = [];
    if (isOverdimensional) {
      reasons.push("Over-Dimensional")
    }
    if (isApprovalNeededForDeclaredValue) {
      reasons.push("Declared Value");
    }
    if (reasons.length > 0) {
      var reasonString = reasons.join(", ");
      return `Cannot rate quote: ${reasonString}`;
    }
    return undefined;
  }

  @bind
  private _onAccessCheckboxChange(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.QuoteEntryService.updateQuoteStop(this.props.thisQuoteStopIndex, { [e.target.name]: checked });
  }

  @bind
  private _onZipCodeChange(zipCode: string) {
    this.props.onZipCodeChange(zipCode);
  }

  render() {
    const {
      currentQuoteStopIndex,
      thisQuoteStopIndex,
      quoteStop,
      onCustomerAlertClick
    } = this.props;

    const { isOperationTimeoutModalOpen } = this.state;

    const {
      quote,
      shipperPlace,
      consigneePlace,
      negotiatedQuoteDataEntry,
      quoteGetResults,
      quoteValidationErrors,
      quoteSaveResults,
      quickQuoteSaveResults,
      commodityQuestionsFetchResults,
      tripMileageResults,
      upchargeTarpsFetchResults,
      upchargeQuestionsFetchResults,
      calculateRatingVariableResults,
      quoteCalculateRatingResult,
      viewOnly,
      hasQuoteChanged,
      isZipValidationSuccessful,
      freightTotalData,
      currentQuoteFreight,
      editMode,
      isApprovalNeededForDeclaredValue
    } = this.props.QuoteEntryService.getState();
    const {
      commodityFetchResults
    } = this.props.commodityService.getState();
    const {
      companyContext
    } = this.props.companySelectService.getState();

    const validationsParserQuote = new ValidationErrorParser<Quote>(quoteValidationErrors);

    let tarps = upchargeTarpsFetchResults.data ?? [];
    let tarpOptions = _.filter(tarps, t => t.companyId === quote.companyId || !t.companyId);

    const now = moment();
    if (tarpOptions.length > 0) {
      tarpOptions = tarpOptions.filter(
        t => t.tarpValues?.find(
          v => v.startDateTime && moment(v.startDateTime).isBefore(now) && (v.endDateTime ? moment(v.endDateTime).isAfter(now) : true) || v.id === -1
        )
      );
    }

    const isFetching: boolean = commodityFetchResults.isFetching
      || commodityQuestionsFetchResults.isFetching
      || upchargeQuestionsFetchResults.isFetching
      || quickQuoteSaveResults.isFetching
      || quoteSaveResults.isFetching
      || quoteGetResults.isFetching
      || quoteCalculateRatingResult.isFetching
      || calculateRatingVariableResults.isFetching;

    let tarpInactive = false;
    if (quoteStop?.tarp && (!quoteStop?.tarp?.isActive || (!!quoteStop.tarp.companyId && quoteStop.tarp.companyId !== quote.companyId)) && !_.includes(tarpOptions, quoteStop?.tarp)) {
      tarpOptions.push(quoteStop?.tarp);
      tarpInactive = true;
    }
    const selectedTarp = tarpOptions?.find(t => t.id === quoteStop?.tarpId);
    var selectedTarpEndDate = undefined;
    if (selectedTarp && selectedTarp.tarpValues) {
      selectedTarpEndDate = selectedTarp.tarpValues.find(v => v.startDateTime && moment(v.startDateTime).isSameOrBefore(now) && (v.endDateTime ? moment(v.endDateTime).isAfter(now) : true))?.endDateTime;
    }
    tarpInactive = tarpInactive ? true : tarpOptions && selectedTarp ? !(selectedTarp.isActive) : false;
    const tarpExpired = selectedTarpEndDate ? moment(selectedTarpEndDate).isBefore(moment()) : false;
    if ((tarpInactive && !viewOnly && !hasQuoteChanged) || (tarpExpired && !viewOnly)) {
      this._onTarpChange(undefined)
      ErrorService.pushErrorMessage("Inactive tarp removed.");
    }

    const isFullQuoteType = quote.quoteType === "Full";

    const message = this._getApprovalReasonsMessage(freightTotalData.isOverdimensional, isApprovalNeededForDeclaredValue);

    const calculateRateOperation = editMode ? this.calculateRateInEditMode : this.sendRequest;
    const canUpdateCustomer = !quote.status || quote.status === "InProgress" || quote.status === "PendingNeedsCustomers" || quote.status === "Convert" || editMode;

    return (
      thisQuoteStopIndex === currentQuoteStopIndex ?
        <div>
          <AjaxActionIndicator
            state={[tripMileageResults, quoteSaveResults, quoteGetResults, quickQuoteSaveResults, calculateRatingVariableResults, quoteCalculateRatingResult]}
          />
          <QuoteStopAddressesEntry
            quote={quote}
            quoteStop={quoteStop}
            shipperPlace={shipperPlace}
            consigneePlace={consigneePlace}
            thisQuoteStopIndex={thisQuoteStopIndex}
            themeColor={this.props.themeColor}
            viewOnly={viewOnly || !canUpdateCustomer}
            onCustomerAlertClick={onCustomerAlertClick}
            onZipCodeChange={this._onZipCodeChange}
          />
          <QuoteStopFreightDimensionsEntry
            companyContext={companyContext}
            thisQuoteStopIndex={thisQuoteStopIndex}
            quote={quote}
            currentQuoteFreight={currentQuoteFreight}
            freightTotalData={freightTotalData}
            viewOnly={viewOnly}
            themeColor={this.props.themeColor}
          />
          {/** TODO: KT-1809 refactor out the remaining sections */}
          <div className={styles.subSection} style={{ backgroundColor: this.props.themeColor }}>Freight Details</div>
          <div style={{ display: "flex" }}>
            <AdvanceTextField
              label="Internal Freight Notes"
              onDebouncedChange={this._onDescriptionChanged}
              value={quoteStop?.description ?? ""}
              error={!validationsParserQuote.isValidDeep(`quoteStops[${thisQuoteStopIndex}].description`)}
              helperText={validationsParserQuote.validationMessageDeep(`quoteStops[${thisQuoteStopIndex}].description`)}
              disabled={viewOnly}
              style={isFullQuoteType ? { width: "66%", marginBottom: "5px", marginRight: "10px" } : { width: "33%", marginBottom: "5px", marginRight: "10px" }}
            />
            {!isFullQuoteType &&
              <AdvanceTextField
                label="Additional Internal Notes"
                onDebouncedChange={this._onNotesChange}
                value={quote.notes ?? negotiatedQuoteDataEntry.notes ?? ""}
                disabled={viewOnly}
                style={{ width: "33%", marginRight: "10px" }}
              />}
            <div style={{ display: "flex", width: "33%" }}>
              <TarpEntry
                thisQuoteStopIndex={thisQuoteStopIndex}
                tarpId={quoteStop?.tarpId}
                viewOnly={viewOnly}
                tarpOptions={tarpOptions}
                onTarpChange={this._onTarpChange}
                validationErrors={quoteValidationErrors}
              />
              <ClearButton
                onClick={this._onClearTarp}
                viewOnly={viewOnly}
              />
            </div>
          </div>
          <QuoteStopDatesEntry
            thisQuoteStopIndex={thisQuoteStopIndex}
            dateEntity="shipper"
            viewOnly={viewOnly}
            startDate={quoteStop?.shipperStartDate}
            endDate={quoteStop?.shipperEndDate}
            isAppointmentRequired={quoteStop?.isShipperAppointmentRequired}
            hardTime={quoteStop?.shipperHardTime}
            isFullQuoteType={quote.quoteType === "Full"}
            onDateChange={this._onDateChange}
            onHardTimeChange={this._onHardTimeChange}
            onApptRequiredChange={this._onApptRequiredChange}
            validationErrors={quoteValidationErrors}
          />
          <QuoteStopDatesEntry
            thisQuoteStopIndex={thisQuoteStopIndex}
            dateEntity="consignee"
            viewOnly={viewOnly}
            startDate={quoteStop?.consigneeStartDate}
            endDate={quoteStop?.consigneeEndDate}
            isAppointmentRequired={quoteStop?.isConsigneeAppointmentRequired}
            hardTime={quoteStop?.consigneeHardTime}
            isFullQuoteType={quote.quoteType === "Full"}
            onDateChange={this._onDateChange}
            onHardTimeChange={this._onHardTimeChange}
            onApptRequiredChange={this._onApptRequiredChange}
            validationErrors={quoteValidationErrors}
          />
          {quoteStop?.shipmentNotes && <>
            <Error fontSize="small" style={{ verticalAlign: "middle" }} />
            {quoteStop.shipmentNotes}
          </>}
          <StopFlagsDisplay
            quoteStops={quote.quoteStops ?? []}
          />
          {isFullQuoteType &&
            <>
              <div className={styles.label}>Shipper/Consignee Access Restrictions</div>
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  label="Military Base"
                  control={(
                    <Checkbox
                      checked={quoteStop?.isMilitaryBase ?? false}
                      name="isMilitaryBase"
                      onChange={this._onAccessCheckboxChange}
                      disabled={viewOnly}
                    />
                  )}
                />
                <FormControlLabel
                  label="TWIC Card Required"
                  control={(
                    <Checkbox
                      checked={quoteStop?.isTwicCardRequired ?? false}
                      name="isTwicCardRequired"
                      onChange={this._onAccessCheckboxChange}
                      disabled={viewOnly}
                    />
                  )}
                />
              </div>
            </>
          }
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", width: "33%", marginRight: "15px" }}>
              <UpchargeEntry
                thisQuoteStopIndex={thisQuoteStopIndex}
                upchargeType={"Flat"}
                upchargeValue={quote?.flatUpcharge}
                validationErrors={quoteValidationErrors}
                viewOnly={viewOnly}
                onUpchargeChange={this._onUpchargeChanged}
                onUpchargeBlur={this._onUpchargeBlur}
                onUpchargeFocus={this._onFlatUpchargeFocus}
              />
              <QuestionAnswerButton
                disabled={!quote?.flatUpcharge}
                onClick={this._onFlatUpchargeReopenQuestionClick}
              />
            </div>
            <div style={{ display: "flex", width: "33%", marginRight: "15px" }}>
              <UpchargeEntry
                thisQuoteStopIndex={thisQuoteStopIndex}
                upchargeType={"Percent"}
                upchargeValue={quote?.upchargePercentage}
                validationErrors={quoteValidationErrors}
                viewOnly={viewOnly}
                onUpchargeChange={this._onPercentChanged}
                onUpchargeBlur={this._onPercentUpchargeBlur}
                onUpchargeFocus={this._onPercentFocus}
              />
              <QuestionAnswerButton
                disabled={!quote?.upchargePercentage}
                onClick={this._onPercentUpchargeReopenQuestionClick}
              />
            </div>
            <div style={{ width: "33%" }}>
              <DeclaredValueEntry
                thisQuoteStopIndex={thisQuoteStopIndex}
                declaredValueEntry={quoteStop?.declaredValue}
                viewOnly={viewOnly}
                onValueChange={this._onDeclaredValChanged}
                validationErrors={quoteValidationErrors}
              />
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <UserAccessControl roles={["quote:create", "quote:edit"]}>
              {quote.quoteType === "Quick" && hasQuoteChanged && (quoteSaveResults.hasFetched || quoteGetResults.hasFetched || quickQuoteSaveResults.hasFetched) &&
                <div className={styles.quoteChangedError}>
                  Quote information has changed. Please rate again.
                </div>
              }
              <Button
                color="primary"
                disabled={isFetching || viewOnly || ((quoteSaveResults.hasFetched || quoteGetResults.hasFetched || quickQuoteSaveResults.hasFetched) && !hasQuoteChanged && !!quote.calculatedRates)
                  || !isZipValidationSuccessful || (quote.quoteType === "Quick" && (isApprovalNeededForDeclaredValue || freightTotalData.isOverdimensional))}
                onClick={() => calculateRateOperation()}
                style={{ marginTop: "20px" }}
              >
                Calculate Rate
              </Button>
            </UserAccessControl>
            {quote.quoteType === "Quick" && message &&
              <StopFlagErrorMessage
                message={message}
              />
            }
          </div>
          {thisQuoteStopIndex === currentQuoteStopIndex
            ? <OperationTimeoutModal
              isOpen={isOperationTimeoutModalOpen}
              isFetching={isFetching}
              onRetry={calculateRateOperation}
              onCancel={this._onCalculateRateTimeoutCancel}
              operationText={"calculate quote rate"}
            />
            : undefined
          }
        </div>
      : null
    );
  }
}

export const QuoteStopEntry = QuoteEntryService.inject(
  CommodityService.inject(
    CompanySelectService.inject(
      DeclaredValueService.inject(
        _QuoteStopEntry
      )
    )
  )
);