// extracted by mini-css-extract-plugin
var _1 = "CustomerContacts__contactCell__whfSc";
var _2 = "CustomerContacts__contactName__NVS9u";
var _3 = "CustomerContacts__contactNotes__SenXR";
var _4 = "CustomerContacts__contactTable__cQWl3";
var _5 = "CustomerContacts__fieldValue__Ufz9u";
var _6 = "CustomerContacts__hideShow__mNiCA";
var _7 = "CustomerContacts__inactive__m_7Zp";
var _8 = "CustomerContacts__tableRow__iKB4W";
export { _1 as "contactCell", _2 as "contactName", _3 as "contactNotes", _4 as "contactTable", _5 as "fieldValue", _6 as "hideShow", _7 as "inactive", _8 as "tableRow" }
