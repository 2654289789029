import {
  React
} from "$Imports/Imports";

import {
  Link
} from "$Imports/MaterialUIComponents";

import {
  NavigationService
} from "$State/NavigationFreezerService";

interface ICustomerLinkProps {
  customerId?: number;
  customerName?: string;
  isProspect?: boolean;
}

export class CustomerLink extends React.PureComponent<ICustomerLinkProps> {
  private _navigateTo(event: React.MouseEvent, url: string): void {
    NavigationService.navigateTo(url);

    // router performs navigation - prevent "href" from navigating + reloading app
    event.preventDefault();
  }

  render() {
    const {
      customerId,
      customerName,
      isProspect
    } = this.props;

    const url = `/${isProspect ? "prospect" : "customer"}/${customerId}`;

    return (
      <Link
        href={url}
        color="secondary"
        onClick={(event) => this._navigateTo(event, url)}
      >
        {customerName}
      </Link>
    );
  }
}