import {
  React,
  _
} from "$Imports/Imports";

import {
  Customer,
  CustomerBillingStatusEnum,
} from "$Generated/api";

import { CURRENCY_FORMAT } from "$Shared/utilities/formatUtil";
import { billingStatusEnumTextMap } from "$Utilities/enumUtil";
import { DisplayFormattedNumber } from "$Imports/CommonComponents";

const styles: {
  checkCredit: string
} = require("./QuoteStopEntry.scss");

interface IBillToDisplayBaseProps {
  customer?: Customer
}

export class BillToDisplay extends React.PureComponent<IBillToDisplayBaseProps> {

  render() {
    const {
      customer
    } = this.props;

    const statusClassName = customer?.billingStatus === "CheckCredit" ? styles.checkCredit : "";

    return (
      <>
        {
          customer ?
            <>
              <div><b>Bill To: </b>{`${customer.customerName}`}</div>
              <div><b>Status: </b><span className={statusClassName}>{`${billingStatusEnumTextMap[customer.billingStatus as CustomerBillingStatusEnum]}`}</span></div>
              <div><b>Credit Limit: </b>
                {customer?.billingStatus === "CODOnly" ?
                  "N/A" :
                  <DisplayFormattedNumber
                    value={customer?.creditLimit}
                    formatString={CURRENCY_FORMAT}
                  />}
              </div>
              <div><b>Due Days: </b>{`${customer.dueDays ?? ""}`}</div>
            </>
          : undefined
        }
      </>
    )
  }
}