import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  CustomerQuotesSearchCriteria,
  SimplifiedCustomerQuote
} from "$Generated/api";

import {
  DisplayFormattedDatetime,
  DisplayFormattedNumber,
  CustomerLink,
  QuoteLink,
  BinaryChoiceDialog
} from "$Imports/CommonComponents";

import {
  ICustomerQuoteServiceInjectedProps,
  CustomerQuoteService,
  DEFAULT_CUSTOMER_QUOTE_SEARCH
} from "$State/CustomerQuotesFreezerService";

import {
  ISalesRepHomeServiceInjectedProps,
  SalesRepHomeService
} from "$State/SalesRepHomeFreezerService";

import {
  CURRENCY_FORMAT,
  DATE_ONLY_FORMAT
} from "$Shared/utilities/formatUtil";

import { 
  getDisplayedQuoteNumber
} from "$Shared/utilities/helpers";

import {
  MyCustomerQuotesSearchForm
} from "./MyCustomerQuotesSearchForm";

import {
  Check
} from "$Imports/MaterialUIIcons";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  getDeliveryDateColumn
} from "$Utilities/quoteColConstants";

const styles: {
  gridContainer: string;
  searchFormContainer: string;
  resultsMessage: string;
  customerQuoteRow: string;
  customerQuoteActions: string;
  hideAction: string;
} = require("./MyQuotes.scss");

const getRowClassNames = (row: SimplifiedCustomerQuote): string => {
  return `${styles.customerQuoteRow}`;
};

interface IMyCustomerQuotesState {
  isReviewQuoteModalOpen: boolean;
  reviewQuoteId?: number;
  isReviewedMessage: string;
}

interface IMyCustomerQuotesBaseProps {
  companyId: number | undefined;
  salesRepId: number | undefined;
}

type IMyCustomerQuotesProps = IMyCustomerQuotesBaseProps
  & ICustomerQuoteServiceInjectedProps
  & ISalesRepHomeServiceInjectedProps;

class _MyCustomerQuotes extends React.Component<IMyCustomerQuotesProps, IMyCustomerQuotesState> {
  state: IMyCustomerQuotesState = {
    isReviewQuoteModalOpen: false,
    reviewQuoteId: undefined,
    isReviewedMessage: ""
  };

  componentDidMount() {
    if (SharedSecurityContext.hasRole(["customer-quote:view"])) {
      this.props.customerQuotesService.onResetSearchModel({
          salesAgentId: this.props.salesRepId,
          ...DEFAULT_CUSTOMER_QUOTE_SEARCH
        }, true);
      this.props.customerQuotesService.fetchQuotes(this.props.companyId, true);
    }
  }

  componentDidUpdate(prevProps: Readonly<IMyCustomerQuotesProps>) {
    if (SharedSecurityContext.hasRole(["customer-quote:view"]) && this.props.companyId !== prevProps.companyId){
      this.props.customerQuotesService.fetchQuotes(this.props.companyId, true);
      this.props.customerQuotesService.getRequestedQuotesCount(this.props.companyId, this.props.salesRepId);
    }
  }

  @bind
  private _getColumns(): GridColDef[] {
    return [{
      headerName: "Quote #",
      field: "quoteNumber",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return Number(params.row.quoteNumber);
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <QuoteLink
            quoteId={params.row.id}
            quoteNumber={getDisplayedQuoteNumber(params.value, undefined, params.row.quoteKind)}
            isCustomerQuote={true}
          />
        )
      },
    },
    {
      headerName: "Customer",
      field: "customerName",
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CustomerLink
            customerId={params.row.customerId}
            customerName={params.value ?? ""}
          />
        );
      }
    },
    {
      headerName: "Quote Date",
      field: "quoteDate",
      renderCell: (params: GridRenderCellParams) => <DisplayFormattedDatetime value={params.value} formatString={DATE_ONLY_FORMAT} />,
      flex: 2
    },
    getDeliveryDateColumn("Delivery Date"),
    {
      headerName: "Rate",
      field: "rate",
      renderCell: (params: GridRenderCellParams) => <DisplayFormattedNumber value={params.value} formatString={CURRENCY_FORMAT} />,
      flex: 1.5
    },
    {
      headerName: "Shipper Zip",
      field: "shipperZipCode",
      flex: 1.5
    },
    {
      headerName: "Consignee Zip",
      field: "consigneeZipCode",
      flex: 1.5
    },
    {
      headerName: "Rvw",
      field: "actions",
      renderCell: (params: GridRenderCellParams<number, SimplifiedCustomerQuote>) => (
        <div className={styles.customerQuoteActions}>
          <IconButton
            className={styles.hideAction}
            onClick={() => this._onOpenReviewQuoteModal(params.row.id!, params.row.quoteNumber)}
            size="small"
            title="Mark as reviewed"
          >
            <Check />
          </IconButton>
        </div>
      ),
      width: 50,
      disableColumnMenu: true,
      sortable: false
    }];
  }

  @bind _onOpenReviewQuoteModal(id: number, quoteNumber: string | undefined) {
    this.setState({
      isReviewQuoteModalOpen: true,
      isReviewedMessage: `Are you sure you want to mark Customer Portal quote EQ${quoteNumber} as reviewed?`,
      reviewQuoteId: id
    })
  }

  @bind
  private async _onMarkReviewedConfirm() {
    const { reviewQuoteId } = this.state;
    if (reviewQuoteId) {
      await this.props.customerQuotesService.reviewCustomerQuote(reviewQuoteId);
      await this.props.customerQuotesService.fetchQuotes(this.props.companyId, true);
      await this.props.customerQuotesService.getRequestedQuotesCount(this.props.companyId, this.props.salesRepId);
      this._onCloseReviewQuoteModal();
    }
  }

  @bind
  private _onCloseReviewQuoteModal() {
    this.setState({
      isReviewQuoteModalOpen: false
    });
  }

  @bind
  private async _onSearchSubmit(search: CustomerQuotesSearchCriteria): Promise<void> {
    if (SharedSecurityContext.hasRole(["customer-quote:view"])) {
      search.quoteStatuses = ["Requested"];
      search.isReviewed = false;
      search.salesAgentId = this.props.salesRepId;

      CustomerQuoteService.freezer.get().set({
        searchCriteria: {...search}
      });

      this.props.customerQuotesService.fetchQuotes(this.props.companyId, true);
    }
  }

  render() {
    const {
      isReviewQuoteModalOpen,
      isReviewedMessage
    } = this.state;
    
    const {
      quotesFetchResults
    } = this.props.customerQuotesService.getState();
    const customerQuotes = quotesFetchResults.data?.results ?? [];

    return (
      <div className={styles.gridContainer}>
        <div className={styles.searchFormContainer}>
          <MyCustomerQuotesSearchForm
            onSubmit={this._onSearchSubmit}
          />
        </div>
      
        {(quotesFetchResults.hasFetched && (quotesFetchResults.data?.totalRecords ?? 0) > (quotesFetchResults.data?.numberOfRecords ?? 0)) ? (
          <div className={styles.resultsMessage}>
            {quotesFetchResults.data?.totalRecords} results found, {quotesFetchResults.data?.numberOfRecords} shown - please refine your search.
          </div>
        ) : undefined}
        <DataGridPro
          rows={customerQuotes}
          columns={this._getColumns()}
          density="compact"
          hideFooter
          initialState={{
            sorting: {
              sortModel: [{ field: "deliveryDate", sort: "asc" }]
            }
          }}
          getRowClassName={(params) => getRowClassNames(params.row)}
        />

        <BinaryChoiceDialog
          title="Review Customer Portal Quote"
          isOpen={isReviewQuoteModalOpen}
          message={isReviewedMessage}
          falseText="Cancel"
          trueText="Confirm"
          onClick={(value) => {
            if (value) {
              this._onMarkReviewedConfirm();
            } else {
              this._onCloseReviewQuoteModal();
            }
          }}
        />
      </div>
    );
  }
}

export const MyCustomerQuotes = CustomerQuoteService.inject(
  SalesRepHomeService.inject(
    _MyCustomerQuotes
));
