import {
  React,
  bind,
  numeral,
  _,
  Helpers
} from "$Imports/Imports";

import {
  PercentageInputField,
  DateTimeInputField
} from "$Imports/CommonComponents";

import {
  Commodity,
  CommodityRate
} from "$Generated/api";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  FormControlLabel,
  Checkbox
} from "$Imports/MaterialUIComponents";

import {
  EditCommodityRateState
} from "$State/CommodityRateFreezerService";

const styles: {
  container: string;
  gridWrapper: string;
} = require("./EditCommodityRate.scss");

interface IEditCommodityRateProps {
  commodities: Commodity[];
  editState: EditCommodityRateState;
  isFetching: boolean;
  onSave: () => void;
  onCancel: () => void;
  copyCurrentRates: () => void;
  percentRateChanged: (commodityId: number, value?: number) => void;
  startDateChanged: (date: Date) => void;
  immediateStartChanged: (immediateStart: boolean) => void;
}

interface IEditCommodityRateState {
  disableActions: boolean;
}

export class EditCommodityRate extends React.PureComponent<IEditCommodityRateProps, IEditCommodityRateState> {
  
  state = {
    disableActions: false
  };
  
  @bind
  private _onSubmit() {
    this.setState({
      disableActions: true
    });

    this.props.onSave();
  }

  @bind
  private _onCancel() {
    this.setState({
      disableActions: true
    });

    this.props.onCancel();
  }

  @bind
  private _onPercentRateChange(commodityId: number, value?: number) {
    this.props.percentRateChanged(commodityId, value);
  }

  @bind
  private _onStartDateChanged(date: Date | undefined) {
    this.props.startDateChanged(date ?? new Date());
  }

  @bind
  private _onImmediateStartChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.immediateStartChanged(checked);
  }

  componentDidUpdate(prevProps: IEditCommodityRateProps) {
    if (prevProps.editState !== this.props.editState) {

      const isOpen = (this.props.editState.current !== null && this.props.editState.edited !== null);
      const isPreviousOpen = (prevProps.editState.current !== null && prevProps.editState.edited !== null);

      this.setState({
        disableActions: isOpen === isPreviousOpen && this.state.disableActions
      });
    }
  }

  render() {
    const {
      commodities,
      editState,
    } = this.props;

    const {
      disableActions
    } = this.state;

    const validationsParser = new ValidationErrorParser<CommodityRate>(editState.validationErrors);

    const isButtonDisabled = disableActions && !validationsParser.hasErrors();

    var rows: JSX.Element[] = [];
    if (editState.current != null && editState.edited != null) {

      commodities.forEach(c => {
        const commodityId = c.id || 0; // it's not ever going to be undefined

        var current = editState.current?.commodityValues ? editState.current.commodityValues[commodityId]?.percentRate : 0;
        var editable = editState.edited?.commodityValues && editState.edited.commodityValues[commodityId]?.percentRate;

        var hasError = false;
        var helperText: string | null = "";

        if (!validationsParser.isValid("commodityValues")) {
          // yup does not easily lend itself to validating objects with dynamic keys
          if (editable === undefined) {
            hasError = true;
            helperText = "All rates must be filled out.";
          } else if (editable >= 1) {
            hasError = true;
            helperText = "Rate cannot be greater than 100%";
          } else if (editable <= -1) {
            hasError = true;
            helperText = "Rate cannot be less than -100%";
          }
        }
  
        var gridItem = (
          <Grid container direction="row" item spacing={2} key={commodityId}>
            <Grid container item sm={3} alignItems="center">{c.commodityName}</Grid>
            <Grid container item sm={3} alignItems="center">{!Helpers.isNullOrUndefined(current) ? numeral(current).format("0.00%") : ""}</Grid>
            <Grid item sm={3}>
              <PercentageInputField
                label="% rate"
                error={hasError}
                helperText={helperText}
                decimalScale={2}
                fixedDecimalScale
                value={editable}
                onPercentValueChange={(value?: number) => this._onPercentRateChange(commodityId, value)}
              />
            </Grid>
          </Grid>
        );

        rows.push(gridItem);
      });
    }

    return (
      <Dialog
        open={editState.current !== null && editState.edited !== null}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          {`Edit Commodity Rates for Zone ${editState.current?.zoneName}`}
        </DialogTitle>
        <DialogActions>
            <Button
              onClick={this.props.copyCurrentRates}
              color="secondary"
            >
              Copy Current Rates
            </Button>
          </DialogActions>
        <DialogContent
          className={styles.container}
        >
          <div className={styles.gridWrapper}>
            <Grid container direction={"column"} spacing={1}>
              <Grid container direction={"row"} item spacing={2}>
                <Grid item sm={3}>Commodities</Grid>
                <Grid item sm={3}>Current Rate</Grid>
                <Grid item sm={3}>New Rate</Grid>
              </Grid>
              {rows}
              <Grid item>
                <FormControlLabel
                  label="Immediate start"
                  control={(
                    <Checkbox
                      checked={editState.immediateStart}
                      onChange={this._onImmediateStartChange}
                      name="immediateStart"
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <DateTimeInputField
                  value={editState.edited?.startDateTime ?? null}
                  onChange={this._onStartDateChanged}
                  dateLabel="Start Date Time"
                  error={!validationsParser.isValid("startDateTime")}
                  helperText={validationsParser.validationMessage("startDateTime")}
                  disabled={editState.immediateStart}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this._onSubmit}
            disabled={isButtonDisabled}
          >
            Save
          </Button>
          <Button
            onClick={this._onCancel}
            variant="outlined"
            disabled={isButtonDisabled}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}