import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState
} from "$Imports/Imports";

import {
  Tarp,
  TarpValue,
  TarpApiFactory
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

interface ITarpsServiceState {
  tarpFetchResult: IAjaxState<Tarp[]>;
  tarpSaveResult: IAjaxState<Tarp>;
  tarpRateSaveResult: IAjaxState<TarpValue>;
}

const InjectedPropName = "tarpsService";

const initialState = {
  tarpFetchResult: managedAjaxUtil.createInitialState(),
  tarpSaveResult: managedAjaxUtil.createInitialState(),
  tarpRateSaveResult: managedAjaxUtil.createInitialState()
} as ITarpsServiceState;

class TarpsFreezerService extends FreezerService<ITarpsServiceState, typeof InjectedPropName> {

  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchTarps(forceUpdate: boolean = false) {
    const {
      tarpFetchResult
    } = this.freezer.get();

    if (tarpFetchResult.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "tarpFetchResult",
      params: {
        activeOnly: false,
        companyId: 0
      },
      onExecute: (apiOptions, param, options) => {
        const factory = TarpApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet(param);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch tarps.");
      }
    });
  }

  public async saveTarp(formMode: string, tarp: Tarp | null) {
    if(!tarp) {
      return;
    }

    return await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "tarpSaveResult",
      params: {
        body: tarp,
      },
      onExecute: (apiOptions, param, options) => {
        const factory = TarpApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        if (formMode == "edit") {
          return factory.apiV1TarpUpdateTarpRatesPut(param); // update
        } else {
          return factory.apiV1TarpAddTarpPost(param); // create
        }
      },
      onOk: (data) => {
        this.fetchTarps(true);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? "Failed to save tarp.");
      }
    });
  }
}

export const TarpsService = new TarpsFreezerService();
export type ITarpsServiceInjectedProps = ReturnType<TarpsFreezerService["getPropsForInjection"]>;