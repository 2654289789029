import {
  React,
  bind
} from "$Imports/Imports";

import {
  CustomerContact
} from "$Generated/api";

import {
  Button,
  Checkbox,
  DataGridPro,
  FormControlLabel,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
  GridValueGetterParams,
  Link,
  Tooltip
} from "$Imports/MaterialUIComponents";

import {
  ISortState,
  directionType,
  UserAccessControl,
  CardLinedHeader,
  AjaxActionIndicator
} from "$Imports/CommonComponents";

import {
  ActionMenu
} from "./ActionMenu";

import {
  VisibilityOff
} from "$Imports/MaterialUIIcons";

const styles: {
  contactTable: string;
  tableRow: string;
  contactCell: string;
  fieldValue: string;
  contactName: string;
  contactNotes: string;
  inactive: string;
} = require("./CustomerContacts.scss");

interface IContactsCardProps {
  contacts: CustomerContact[];
  addEditContact: (contact?: CustomerContact) => void;
  setPrimaryContact: (contact: CustomerContact) => void;
  onShowInactive: (showInactive: boolean) => void;
  sortState: ISortState;
  onSortChange: (columnName: string | undefined, direction: directionType) => void;
  isFetching: boolean;
  isProspect?: boolean;
  showInactiveContacts: boolean;
}

export class ContactsCard extends React.Component<IContactsCardProps> {
  private readonly columns: GridColDef[] = [
    {
      headerName: "Contact Information",
      field: "information",
      flex: 2,
      cellClassName: styles.contactCell,
      renderCell: (params: GridRenderCellParams<string, CustomerContact>) => (
        <>
          <div className={styles.contactName}>{params.row.firstName} {params.row.lastName}{params.row.nameSuffix ? `, ${params.row.nameSuffix.suffixValue}` : ""}</div>
          { params.row.emailAddress &&
            <Link
              color="secondary"
              href={`mailto:${params.row.emailAddress}`}
            >
              {params.row.emailAddress}
            </Link>
          }
          <div className={styles.fieldValue}>{params.row.phoneNumber}</div>
        </>
      ),
      sortable: false
    },
    {
      headerName: "Type",
      field: "type",
      flex: 2,
      cellClassName: styles.contactCell,
      renderCell: (params: GridRenderCellParams<string, CustomerContact>) => (
        <>
          <div className={styles.fieldValue}>{params.row.contactType ? params.row.contactType.type : ""}</div>
          <div className={styles.fieldValue}>{params.row.title}</div>
          {params.row.isHidden &&
            <VisibilityOff fontSize="small" titleAccess="Hidden in Customer Portal" style={{ verticalAlign: "middle" }} />
          }
        </>
      ),
      sortable: false
    },
    {
      headerName: "Notes",
      field: "notes",
      flex: 2,
      cellClassName: styles.contactCell,
      renderCell: (params: GridRenderCellParams<string, CustomerContact>) =>
        params.row.notes && (
          <Tooltip
            className={styles.contactNotes}
            title={params.row.notes}
            placement="left"
          >
            <span>{ params.row.notes }</span>
          </Tooltip>
        ),
      sortable: false
    },
    {
      headerName: "",
      field: "actions",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, CustomerContact>) => (
        <ActionMenu
          contact={params.row}
          editContact={this._editContact}
          setPrimaryContact={this._setPrimaryContact}
        />
      ),
      sortable: false
    }
  ];

  private readonly prospectColumns: GridColDef[] = [
    {
      headerName: "Name",
      field: "name",
      flex: 2,
      valueGetter: (params: GridValueGetterParams<string, CustomerContact>) => {
        const suffix = params.row.nameSuffix ? `, ${params.row.nameSuffix.suffixValue}` : "";
        return `${params.row.firstName} ${params.row.lastName}${suffix}`;
      }
    },
    {
      headerName: "Type",
      field: "type",
      flex: 2,
      valueGetter: (params: GridValueGetterParams<any, CustomerContact>) => params.row.contactType?.type ?? "",
    },
    {
      headerName: "Title",
      field: "title",
      flex: 2,
    },
    {
      headerName: "Email",
      field: "emailAddress",
      flex: 2,
      renderCell: (params: GridRenderCellParams<string, CustomerContact>) => (
        <Link
          color="secondary"
          href={`mailto:${params.value}`}
        >
          {params.value}
        </Link>
      )
    },
    {
      headerName: "Phone Number",
      field: "phoneNumber",
      flex: 2,
    },
    {
      headerName: "",
      field: "actions",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, CustomerContact>) => (
        <ActionMenu
          contact={params.row}
          editContact={this._editContact}
          setPrimaryContact={this._setPrimaryContact}
        />
      ),
      sortable: false
    }
  ];

  @bind
  private _addContact() {
    this.props.addEditContact();
  }

  @bind
  private _editContact(contact: CustomerContact) {
    this.props.addEditContact(contact);
  }

  @bind
  private _setPrimaryContact(contact: CustomerContact) {
    this.props.setPrimaryContact(contact);
  }

  @bind
  private _onShowInactiveChange(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.onShowInactive(checked);
  }

  render() {
    const {
      contacts,
      isFetching,
      isProspect,
      showInactiveContacts
    } = this.props;

    const sortModel: GridSortModel = isProspect ? [
      { field: "type", sort: "asc"},
      { field: "name", sort: "asc" }
    ] : [
      { field: "isPrimary", sort: "desc" }
    ];

    return (
      <CardLinedHeader
        titleText="Contacts"
        titleComponents={(
          <div style={{ marginBottom: "5px" }}>
            <FormControlLabel
              label="Show inactive"
              control={(
                <Checkbox
                  checked={showInactiveContacts}
                  onChange={this._onShowInactiveChange}
                  name="isPrimary"
                />
              )}
            />
            <UserAccessControl roles={["quote:create", "quote:edit"]}>
              <Button onClick={this._addContact}>
                Add
              </Button>
            </UserAccessControl>
          </div>
        )}
      >
        <AjaxActionIndicator showProgress={isFetching} />
        <DataGridPro
          className={styles.contactTable}
          rows={contacts ?? []}
          columns={isProspect ? this.prospectColumns : this.columns}
          getRowClassName={(params: GridRowParams<CustomerContact>) => `${styles.tableRow} ${!params.row.isActive ? styles.inactive : ""}`}
          density="compact"
          getRowHeight={() => isProspect ? 36 : 64}
          initialState={{ sorting: { sortModel: sortModel } }}
          hideFooter
          disableColumnMenu
          disableColumnFilter
          disableSelectionOnClick
        />
      </CardLinedHeader>
    )
  }
}