// extracted by mini-css-extract-plugin
var _1 = "AddEditCustomerModal__actionsContainer__miOhK";
var _2 = "AddEditCustomerModal__alertContainer__flR2e";
var _3 = "AddEditCustomerModal__checkboxContainer__I_JVc";
var _4 = "AddEditCustomerModal__flexContainer__hdwLx";
var _5 = "AddEditCustomerModal__icon__OYZa6";
var _6 = "AddEditCustomerModal__infoIcon__FRFrX";
var _7 = "AddEditCustomerModal__inputContainer__QGxUb";
var _8 = "AddEditCustomerModal__normalActions__Q9CoU";
var _9 = "AddEditCustomerModal__phoneFlexRow__lVpud";
export { _1 as "actionsContainer", _2 as "alertContainer", _3 as "checkboxContainer", _4 as "flexContainer", _5 as "icon", _6 as "infoIcon", _7 as "inputContainer", _8 as "normalActions", _9 as "phoneFlexRow" }
