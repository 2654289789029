import { Region } from "$Generated/api";

import {
  React,
  bind,
  _,
  memoizeOne
} from "$Imports/Imports";

import {
  DialogActions,
  DialogContent,
  Dialog,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox
} from "$Imports/MaterialUIComponents";

interface IRegionSelectionModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onSave: (region: Region[] | undefined) => void;
  onSelectedChange: (selectedRegions: Region[]) => void;
  regions: Region[];
  selected: Region[];
}

const AllStates = "All States";
const AllProvinces = "All Provinces"

export class RegionSelectionModal extends React.PureComponent<IRegionSelectionModalProps> {

  @bind
  private isAllCountryRegionsSelected(selectedRegions: Region[], allRegions: Region[], countryId: number): boolean {
    let selectedCountryRegions = _.filter(selectedRegions, (r) => r.countryId === countryId);
    let countryRegions = _.filter(allRegions, (r) => r.countryId === countryId);
    return selectedCountryRegions.length === countryRegions.length;
  }

  @bind
  private isAllProvincesSelected(selectedRegions: Region[], allRegions: Region[]): boolean {
    return this.isAllCountryRegionsSelected(selectedRegions, allRegions, 2)
  }

  @bind
  private isAllStatesSelected(selectedRegions: Region[], allRegions: Region[]): boolean {
    return this.isAllCountryRegionsSelected(selectedRegions, allRegions, 1)
  }

  @bind
  private isChecked(selectedRegions: Region[]): number[] {
    return _.map(selectedRegions, (r) => r.id!);
  }

  private readonly isAllStatesSelected_Memoized = memoizeOne(this.isAllStatesSelected);
  private readonly isAllProvincesSelected_Memoized = memoizeOne(this.isAllProvincesSelected);
  private readonly isChecked_Memoized = memoizeOne(this.isChecked);

  @bind
  private _onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  @bind
  private _onSave() {
    if (this.props.onSave) {
      const selection = this.props.regions.filter(x => this.isChecked_Memoized(this.props.selected).includes(x.id!));
      this.props.onSave(selection.length > 0 ? selection : undefined);
    }
  }

  private handleItemClick = (value: number) => () => {
    const checked = this.isChecked_Memoized(this.props.selected);
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    const selectedRegions = this.props.regions.filter(x => newChecked.includes(x.id!));
    if (this.props.onSelectedChange) {
      this.props.onSelectedChange(selectedRegions);
    }
  }

  @bind
  private handleAllStatesClick() {
    const allStatesSelected = !this.isAllStatesSelected_Memoized(this.props.selected, this.props.regions);
    const usRegions = this.props.regions.filter(x => x.countryId === 1).map(x => x.id!);
    const checked = this.isChecked_Memoized(this.props.selected);
    var newChecked = [...checked];

    if (allStatesSelected) {
      newChecked = [...Array.from(new Set([...checked, ...usRegions]))];
    } else {
      newChecked = newChecked.filter(x => !usRegions.includes(x));
    }
    const selectedRegions = this.props.regions.filter(x => newChecked.includes(x.id!));

    if (this.props.onSelectedChange) {
      this.props.onSelectedChange(selectedRegions);
    }

  }

  @bind
  private handleAllProvinceClick() {
    const allProvincesSelected = !this.isAllProvincesSelected_Memoized(this.props.selected, this.props.regions);
    const canadaRegions = this.props.regions.filter(x => x.countryId === 2).map(x => x.id!);
    const checked = this.isChecked_Memoized(this.props.selected);
    var newChecked = [...checked];

    if (allProvincesSelected) {
      newChecked = [...Array.from(new Set([...checked, ...canadaRegions]))];
    } else {
      newChecked = newChecked.filter(x => !canadaRegions.includes(x));
    }
    const selectedRegions = this.props.regions.filter(x => newChecked.includes(x.id!));

    if (this.props.onSelectedChange) {
      this.props.onSelectedChange(selectedRegions);
    }

  }


  render() {
    const { regions, selected } = this.props;
    const usRegions = regions.filter(x => x.countryId == 1);
    const canadaRegions = regions.filter(x => x.countryId != 1);

    const allStateId = regions.length + 2;
    const allProvinceId = regions.length + 3;
    return (
      <Dialog open={this.props.isOpen}>
        <DialogContent >
          <List
            style={{
              width: "300px",
              overflowX: "hidden"
            }}
          >
            <ListItem key={allStateId} dense button onClick={this.handleAllStatesClick} style={{ paddingBottom: '0%' }} >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={this.isAllStatesSelected_Memoized(selected, regions)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': AllStates }}
                />
                <ListItemText id={AllStates} primary={AllStates} style={{ paddingTop: '8%' }} />
              </ListItemIcon>
            </ListItem>
            {usRegions.length && usRegions.map((value: Region) => (
              <ListItem key={value.id} dense button onClick={this.handleItemClick(value.id!)} style={{ paddingLeft: '20%', height: "35px" }}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={!!_.find(selected, sr => sr.id == value.id)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': value.regionName }}
                  />
                  <ListItemText id={value.regionName} primary={value.regionName} style={{ paddingTop: '8px' }} />
                </ListItemIcon>
              </ListItem>
            ))}
            <ListItem key={allProvinceId} dense button onClick={this.handleAllProvinceClick} style={{ paddingBottom: '0%' }} >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={this.isAllProvincesSelected_Memoized(selected, regions)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': AllProvinces }}
                />
                <ListItemText id={AllProvinces} primary={AllProvinces} style={{ paddingTop: '8%' }} />
              </ListItemIcon>
            </ListItem>
            {canadaRegions.length && canadaRegions.map((value: Region) => (
              <ListItem key={value.id} dense button onClick={this.handleItemClick(value.id!)} style={{ paddingLeft: '20%', height: "35px" }}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={!!_.find(selected, sr => sr.id == value.id)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': value.regionName }}
                  />
                  <ListItemText id={value.regionName} primary={value.regionName} style={{ paddingTop: '8px' }} />
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this._onSave}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={this._onCancel}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog >
    );
  }
}
