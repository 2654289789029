import {
  bind,
  React,
  moment
} from "$Imports/Imports";

import {
  BinaryChoiceDialog,
  CardLinedHeader,
  CreateReminderButton,
  CustomerLink,
  DueDateField,
  QuoteLink,
  ReminderEditModal,
  ToggleBox,
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  Checkbox,
  DataGridPro,
  FormControlLabel,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  Check,
  Delete,
  Edit
} from "$Imports/MaterialUIIcons";

import {
  CustomerEntityLink,
  Employee,
  Reminder,
  ReminderSearchCriteria,
  ReminderTypeEnum
} from "$Generated/api";

import {
  EMPTY_REMINDER,
  IReminderServiceInjectedProps,
  ReminderService
} from "$State/ReminderFreezerService";

import {
  ReminderSearchForm
} from "./ReminderSearchForm";

import { DATE_WITH_TIME_MERIDIAN_FORMAT } from "$Shared/utilities/formatUtil";

interface IOwnProps {
  salesReps: Employee[];
  companyId: number | undefined;
  style?: React.CSSProperties;
}

type OwnProps = IOwnProps
  & IReminderServiceInjectedProps;

interface IOwnState {
  isDeleteModalOpen: boolean;
  isEditModalOpen: boolean;
  selectedReminder: Reminder;
  selectedReminderListType: reminderListType;
}

const styles: {
  headerActions: string;
  isComplete: string;
  gridContainer: string;
  reminderRow: string;
  reminderActions: string;
  hideAction: string;
  showAction: string;
  showCompleted: string;
} = require("./ReminderListView.scss");

const reminderListTypes = ["Just Me", "Everyone"];
type reminderListType = typeof reminderListTypes[number];

const getRowClassNames = (row: Reminder): string => {
  return `${styles.reminderRow} ${row.isComplete ? styles.isComplete : ""}`;
};

class _ReminderListView extends React.Component<OwnProps, IOwnState> {
  state: IOwnState = {
    isDeleteModalOpen: false,
    isEditModalOpen: false,
    selectedReminder: {},
    selectedReminderListType: "Just Me"
  };

  private readonly _columns: GridColDef[] = [{
    headerName: "Due Date",
    field: "dueDate",
    renderCell: (params: GridRenderCellParams<Date>) =>
      <DueDateField
        soonCutoff={moment.utc(params.value).subtract(1, 'day').toDate()}
        value={params.value}
        formatString={DATE_WITH_TIME_MERIDIAN_FORMAT}
        includeTime
      />,
    width: 200,
    disableColumnMenu: true,
    sortable: true,
  }, {
    field: "createdByName",
    headerName: "Assigned To",
    disableColumnMenu: true,
    width: 150
  }, {
    headerName: "Type",
    field: "type",
    renderCell: (params: GridRenderCellParams<ReminderTypeEnum, Reminder>) =>
      params.value === "Quote" ? (
        <>
          Quote&nbsp; {params.row.quote && (
            <QuoteLink
              quoteNumber={`(#${params.row.quote?.linkName})`}
              quoteId={params.row.quote?.linkId}
              isCustomerQuote={false}
            />
          )}
        </>
      ) : params.value,
    maxWidth: 150,
    disableColumnMenu: true,
    sortable: false
  }, {
    headerName: "Customer Name",
    field: "customer",
    renderCell: (params: GridRenderCellParams<CustomerEntityLink | undefined, Reminder>) =>
      params.value && (
        <CustomerLink
          customerId={params.value.linkId}
          customerName={params.value.linkName}
          isProspect={params.value.isProspect}
        />
      ),
    flex: 1,
    disableColumnMenu: true,
    sortable: false
  }, {
    headerName: "Description",
    field: "text",
    flex: 2,
    disableColumnMenu: true,
    sortable: false
  }, {
    headerName: "",
    field: "actions",
    renderCell: (params: GridRenderCellParams<number, Reminder>) => (
      <div className={styles.reminderActions}>
        <IconButton
          className={params.row.isComplete ? styles.showAction : styles.hideAction}
          disabled={params.row.isComplete}
          onClick={() => this._completeReminder(params.row.id!)}
          size="small"
          title="Complete reminder"
        >
          <Check />
        </IconButton>
        {!params.row.isComplete && (
          <IconButton
            className={styles.hideAction}
            disabled={params.row.isComplete}
            onClick={() => this._toggleEditModal(params.row)}
            size="small"
            title="Edit reminder"
          >
            <Edit />
          </IconButton>
        )}
        <IconButton
          className={styles.hideAction}
          onClick={() => this._toggleDeleteModal(params.row)}
          size="small"
          title="Delete reminder"
        >
          <Delete />
        </IconButton>
      </div>
    ),
    width: 120,
    disableColumnMenu: true,
    sortable: false
  }];

  componentDidMount() {
    this.props.reminderService.update({
      searchCriteria: {
        reminderListType: this.state.selectedReminderListType
      },
      showCompleted: false
    });
    this.props.reminderService.fetchReminders(true);
  }

  @bind
  private async _onSearchSubmit(search: ReminderSearchCriteria): Promise<void> {
    this.props.reminderService.update({
      searchCriteria: {
        ...search,
        reminderListType: this.state.selectedReminderListType
      }
    });

    await this.props.reminderService.fetchReminders(true);
  }

  @bind
  private _toggleShowCompleted(_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    this.props.reminderService.update({ showCompleted: checked });
  }

  @bind
  private _updateSort(model: GridSortModel): void {
    const newSort = model[0];
    if (newSort) {
      this.props.reminderService.update({ sort: { sortColumnName: newSort.field, sortDirection: newSort.sort } });
    }
  }

  @bind
  private _toggleDeleteModal(model?: Reminder): void {
    this.setState((prev) => ({
      selectedReminder: model ? model : {},
      isDeleteModalOpen: !prev.isDeleteModalOpen
    }));
  }

  @bind
  private _toggleEditModal(model?: Reminder): void {
    this.setState((prev) => ({
      selectedReminder: model ? model : {},
      isEditModalOpen: !prev.isEditModalOpen
    }));
  }

  @bind
  private async _editReminder(model?: Reminder): Promise<void> {
    if (model) {
      await this.props.reminderService.fetchReminders(true);
    }

    this._toggleEditModal();
  }

  @bind
  private async _deleteReminder(id: number): Promise<void> {
    await this.props.reminderService.deleteReminder(id);
    await this.props.reminderService.fetchReminders(true);
  }

  @bind
  private async _completeReminder(id: number): Promise<void> {
    await this.props.reminderService.setReminderToComplete(id);
    await this.props.reminderService.fetchReminders(true);
  }

  @bind
  private _selectReminderListType(reminderListType: string) {
    if (reminderListType === this.state.selectedReminderListType) {
      return;
    }

    this.setState({
      selectedReminderListType: reminderListType as reminderListType
    });

    ReminderService.freezer.get().searchCriteria.set({
      reminderListType: reminderListType
    });

    this.props.reminderService.fetchReminders(true);
  }

  render() {
    const {
      salesReps,
      companyId
    } = this.props;

    const {
      fetchResult,
      sort,
      showCompleted
    } = this.props.reminderService.getState();

    const {
      isDeleteModalOpen,
      isEditModalOpen,
      selectedReminder,
      selectedReminderListType
    } = this.state;

    const style: React.CSSProperties = {
      display: "flex",
      flexDirection: "column",
      flex: "0 0 25rem",
      ...this.props.style,
    }

    // applying a dynamic filter via the grid's filterModel does not seem to work
    // entire dataset must be reloaded when updating, deleting, etc
    const reminders = (fetchResult.data ?? [])
      .filter(x => showCompleted || !x.isComplete);

    const sortModel: GridSortModel = [{ field: sort?.sortColumnName ?? "dueDate", sort: sort?.sortDirection ?? "asc" }];

    return (
      <CardLinedHeader
        titleText="My To Do List"
        titleComponents={(
          <div className={styles.headerActions}>
            <div className={styles.showCompleted}>
              <FormControlLabel
                label="Show completed"
                control={(
                  <Checkbox
                    checked={showCompleted}
                    onChange={this._toggleShowCompleted}
                  />
                )}
              />
            </div>
            <div>
              <UserAccessControl roles={["reminder:manage-others"]}>
                <ToggleBox
                  selectedValue={selectedReminderListType}
                  options={reminderListTypes}
                  toggleClick={this._selectReminderListType}
                  companyId={companyId}
                />
              </UserAccessControl>
              <UserAccessControl roles={["reminder:create"]}>
                <div style={{marginBottom:"5px"}}>
                  <CreateReminderButton
                    seed={{
                      ...EMPTY_REMINDER,
                      type: "General"
                    }}
                    onClick={this._toggleEditModal}
                  />
                </div>
              </UserAccessControl>
            </div>
          </div>
        )}
        style={style}
      >
        <ReminderSearchForm
          onSubmit={this._onSearchSubmit}
        />

        <DataGridPro
          rows={reminders}
          columns={this._columns}
          density="compact"
          hideFooter
          sortModel={sortModel}
          onSortModelChange={this._updateSort}
          sortingOrder={["asc", "desc"]} // null removed to disallow "no" sorting
          getRowClassName={(params) => getRowClassNames(params.row)}
          columnVisibilityModel={{
            createdByName: selectedReminderListType === "Everyone"
          }}
          className={styles.gridContainer}
        />

        <BinaryChoiceDialog
          isOpen={isDeleteModalOpen}
          message="Are you sure you want to delete this reminder?"
          onClick={(value) => {
            if (value) {
              this._deleteReminder(selectedReminder.id!);
            }
            this._toggleDeleteModal();
          }}
          trueText="Delete"
          trueColor="error"
          falseText="Cancel"
        />

        <ReminderEditModal
          isOpen={isEditModalOpen}
          reminder={selectedReminder}
          onClose={this._editReminder}
          canSelectType={!selectedReminder.quote?.linkId}
          salesReps={salesReps}
        />
      </CardLinedHeader>
    );
  }
}

export const ReminderListView = ReminderService.inject(_ReminderListView);
