import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  AddEditCustomerModal,
  CustomerSearchResults,
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  Button,
  Card,
  CardHeader
} from "$Imports/MaterialUIComponents";

import {
  ICustomerServiceInjectedProps,
  CustomerService
} from "$State/CustomerFreezerService";

import {
  StateService,
  IStateServiceInjectedProps
} from "$State/RegionFreezerService";

import {
  EmployeeService,
  IEmployeeServiceInjectedProps
} from "$State/EmployeeFreezerService";

import {
  CustomerSourceService,
  ICustomerSourceServiceInjectedProps
} from "$State/CustomerSourceFreezerService";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  NavigationService
} from "$State/NavigationFreezerService";

import { Customer } from "$Generated/api";

const styles: {
  mainContainer: string;
  cardStyle: string;
  header: string;
} = require("./ProspectsViewPage.scss");

interface IProspectsViewState {
  isOpen: boolean;
  addProspect: Customer | null;
}

interface IProspectsViewPageBaseProps { }

type IProspectsViewPageProps = IProspectsViewPageBaseProps
& ICustomerServiceInjectedProps
& IStateServiceInjectedProps
& IEmployeeServiceInjectedProps
& ICustomerSourceServiceInjectedProps;

class _ProspectsViewPage extends React.Component<IProspectsViewPageProps, IProspectsViewState> {
  state: IProspectsViewState = {
    isOpen: false,
    addProspect: null
  }

  componentDidMount() {
    this.props.regionService.fetchStates();
    this.props.employeeService.fetchSalesReps();
    this.props.customerSourceService.fetchCustomerSources(true, true);
  }

  @bind
  private _addCustomerClick() {
    const currentEmployee = _.find(this.props.employeeService.getState().activeSalesReps, (e) => e.userId?.toLowerCase() === SharedSecurityContext.getUserId()?.toLowerCase());
    const currentUserId = currentEmployee?.id;
    const blankCustomer: Customer = {
      isActive: true,
      isCaller: true,
      isProspect: true,
      salesAgentId: currentUserId,
      prospect: {} 
    };

    this.setState({
      isOpen: true,
      addProspect: blankCustomer
    });
  }

  @bind
  private _closeAdd() {
    this.setState({
      isOpen: false,
      addProspect: null
    });
  }

  @bind
  private async _saveCustomerProspect(customer: Customer | undefined) {
    if (!customer){
      return
    }

    await this.props.customerService.addCustomer(customer);

    const result = this.props.customerService.getState().saveCustomerResults;

    if (result?.data) {
      this._goToCustomerDetails(result.data);
    }
  }

  @bind
  private _goToCustomerDetails(customer: Customer) {
    if (customer.id) {
      NavigationService.navigateTo(`/prospect/${customer.id}`);
    }
  }

  render() {
    const {
      isOpen,
      addProspect
    } = this.state;

    const {
      saveCustomerResults
    } = this.props.customerService.getState();

    const {
      regionFetchResults
    } = this.props.regionService.getState();
    const regionData = regionFetchResults.data ?? [];

    const {
      activeSalesReps
    } = this.props.employeeService.getState();    
    const salesReps = _.orderBy(activeSalesReps ?? [], s => s.lastName);

    const {
      activeCustomerSources
    } = this.props.customerSourceService.getState();
    const customerSources = _.orderBy(activeCustomerSources ?? [], s => s.name);

    return (
      <div className={styles.mainContainer}>
        <Card className={styles.cardStyle}>
          <div className={styles.header}>
            <CardHeader
              title="Prospect Search"
            />
            <UserAccessControl roles={["quote:create"]}>
              <Button
                color="primary"
                onClick={this._addCustomerClick}
              >
                Add Prospect
              </Button>
            </UserAccessControl>
          </div>
          <CustomerSearchResults
            searchBySalesRep
            isProspect
            onDetailsClick={this._goToCustomerDetails}
          />
        </Card>
        <AddEditCustomerModal
          isOpen={isOpen}
          isFetching={saveCustomerResults.isFetching}
          customer={addProspect ?? {}}
          regions={regionData}
          salesReps={salesReps}
          customerSources={customerSources}
          onSave={this._saveCustomerProspect}
          onCancel={this._closeAdd}
        />
      </div>
    );
  }
}

export const ProspectsViewPage = CustomerService.inject(
  StateService.inject(
    EmployeeService.inject(
      CustomerSourceService.inject(
        _ProspectsViewPage
      )
    )
  )
);