import {
  React,
  bind
} from "$Imports/Imports";

import {
  Customer,
  CustomerSearchCriteria,
  CustomerSearchCriteriaIndustryTypeEnum,
  CustomerSearchCriteriaPercentageToCloseEnum,
  CustomerSource,
  Employee
} from "$Generated/api";

import {
  Search,
  Clear
} from "$Imports/MaterialUIIcons";

import {
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Link,
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
  GridValueFormatterParams,
  GridApiPro,
  MuiEvent,
  isEnterKey,
  GridValueGetterParams,
  Stack,
  CardActions,
  FormControlLabel,
  Checkbox
} from "$Imports/MaterialUIComponents";

import {
  AdvanceTextField,
  AjaxActionIndicator,
  TriStateSelector,
  TextCellTruncated,
  DisplayFormattedDatetime
} from "$Imports/CommonComponents";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  ICustomerServiceInjectedProps,
  CustomerService
} from "$State/CustomerFreezerService";

import {
  CustomerType
} from "$State/QuoteEntryFreezerService";

import {
  IStateServiceInjectedProps,
  StateService
} from "$State/RegionFreezerService";

import {
  IEmployeeServiceInjectedProps,
  EmployeeService
} from "$State/EmployeeFreezerService";

import {
  CustomerDetailsIcon
} from "./CustomerDetailsIcon";

import { getFormattedZipPostalCode } from "$Shared/utilities/helpers";

import {
  DATE_ONLY_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  industryTypeTextMap,
  percentageToCloseTextMap
} from "$Utilities/enumUtil";

const styles: {
  card: string;
  searchControls: string;
  customerName: string;
  clearIcon: string;
  regionSelector: string;
  typeSelector: string;
  alignToControl: string;
  tableHeaderComponents: string;
  resultsMessage: string;
  actions: string;
  resultsGrid: string;
  resultCell: string;
  noRows: string;
  addProspect: string;
} = require("./CustomerSearchResults.scss");

interface ICustomerSearchResultsBaseProps {
  onEnterPressWithSelectedRow?: () => void;
  actionButtons?: JSX.Element;
  hiddenColumns?: string[];
  hideGridIfNoResults?: boolean;
  limitGridHeight?: boolean;
  onDetailsClick?: (customer: Customer) => void;
  restrictToCustomerType?: CustomerType;
  canExpandSearch?: boolean;
  searchBySalesRep?: boolean;
  isProspect?: boolean;
  searchAll?: boolean;
  callerId?: number;
}

function getAddressCol(limitGridHeight?: boolean): GridColDef {
  return {
    headerName: "Address",
    field: "address",
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, Customer>) => {
      const addrElement = <>
        <div>{params.row.address1 ?? ""}</div>
        <div>{params.row.address2 ?? ""}</div>
        <div>{params.row.city ?? ""} {params.row.region?.regionAbbreviation ?? ""} {getFormattedZipPostalCode(params.row) ?? ""}</div>
      </>;

      // limited grid height means it's being used from the modal which is narrower
      return limitGridHeight ?
      <TextCellTruncated text={addrElement} />
      : addrElement;
    }
  };
}

type ICustomerSearchResultsProps = ICustomerSearchResultsBaseProps
  & ICustomerServiceInjectedProps
  & IStateServiceInjectedProps
  & IEmployeeServiceInjectedProps;

type ForcedSearchCriteria = Pick<CustomerSearchCriteria, "isCaller" | "isShipper" | "isConsignee">;

interface IOwnState {
  forceCriteria?: ForcedSearchCriteria;
  sortModel: GridSortModel;
}

const rowHeight = 64;
const pageHeight = 6 * rowHeight; // there are 7 rows per screen, paging 6 rows mimics DataGridPro's current paging functionality.
const sortModel: GridSortModel = [{
  field: "customerName",
  sort: "asc" 
}];

class _CustomerSearchResults extends React.Component<ICustomerSearchResultsProps, IOwnState> {
  state: IOwnState = {
    sortModel: sortModel
  };

  static defaultProps: Partial<ICustomerSearchResultsBaseProps> = {
    canExpandSearch: true
  };

  private _getColumns(customerTypeName: string): GridColDef[] {
    return [{
      headerName: `${customerTypeName !== "Customer" ? customerTypeName : ""} Code`,
      field: "tmcustomerId",
      valueGetter: (params: GridValueGetterParams<string, Customer>) => params.row.isProspect ? "Prospect" : params.value,
      width: 120
    }, {
      headerName: `${customerTypeName} Name`,
      field: "customerName",
      flex: 1
    },
    getAddressCol(this.props.limitGridHeight),
    {
      headerName: "Sales Rep",
      field: "salesAgent",
      renderCell: (params: GridRenderCellParams<Employee, Customer>) => {
        return params.row.isCaller ? params.value ? `${params.value.firstName} ${params.value.lastName}` : "Not Assigned" : "N/A";
      },
      width: 120
    }, {
      headerName: "Contact",
      field: "contactName",
      width: 120
    }, {
      headerName: "Phone Number",
      field: "phoneNumber",
      width: 120
    }, {
      headerName: "Cell",
      field: "cellNumber",
      width: 110
    }, {
      headerName: "Email",
      field: "emailAddress",
      width: 140
    }, {
      headerName: "Caller?",
      field: "isCaller",
      width: 65,
      valueFormatter: (params: GridValueFormatterParams) => params.value ? "Yes" : "No"
    }, {
      headerName: "Shipper?",
      field: "isShipper",
      width: 80,
      valueFormatter: (params: GridValueFormatterParams) => params.value ? "Yes" : "No"
    }, {
      headerName: "Consignee?",
      field: "isConsignee",
      width: 100,
      valueFormatter: (params: GridValueFormatterParams) => params.value ? "Yes" : "No"
    }, {
      headerName: "",
      field: "actions",
      width: 50,
      renderCell: (params: GridRenderCellParams<any, Customer>) => (
        <CustomerDetailsIcon
          customer={params.row}
          onClick={this.props.onDetailsClick}
        />
      ),
      sortable: false
    }];
  }

  private _getProspectColumns(): GridColDef[] {
    return [{
      headerName: "Prospect Name",
      field: "customerName",
      flex: 1
    },
    getAddressCol(this.props.limitGridHeight),
    {
      headerName: "Phone Number",
      field: "phoneNumber",
      minWidth: 120,
      maxWidth: 145,
      flex: 1
    }, {
      headerName: "Sales Rep",
      field: "salesAgent",
      renderCell: (params: GridRenderCellParams<Employee, Customer>) => params.value ? `${params.value.firstName} ${params.value.lastName}` : "",
      flex: 1
    }, {
      headerName: "Lead Source",
      field: "customerSource",
      valueGetter: (params: GridValueGetterParams<CustomerSource>) => params.value?.name ?? "",
      width: 101
    }, {
      headerName: "Industry Type",
      field: "industryType",
      valueGetter: (params: GridValueGetterParams<string, Customer>) => industryTypeTextMap[params.row.prospect?.industryType ?? ""],
      minWidth: 115,
      flex: 1
    }, {
      headerName: "Current Provider",
      field: "currentProvider",
      valueGetter: (params: GridValueGetterParams<string, Customer>) => params.row.prospect?.currentProvider ?? "",
      flex: 1
    }, {
      headerName: "Percent to Close",
      field: "percentToClose",
      minWidth: 125,
      flex: 1,
      valueGetter: (params: GridValueGetterParams<string, Customer>) => percentageToCloseTextMap[params.row.prospect?.percentageToClose ?? ""],
    }, {
      headerName: "Start Date",
      field: "startDate",
      width: 100,
      renderCell: (params: GridRenderCellParams<Date | undefined>) =>
        params.row.prospect?.startDate ? <DisplayFormattedDatetime value={params.row.prospect?.startDate} formatString={DATE_ONLY_FORMAT} /> : "",
    }, {
      headerName: "",
      field: "actions",
      width: 50,
      renderCell: (params: GridRenderCellParams<any, Customer>) => (
        <CustomerDetailsIcon
          customer={params.row}
          onClick={this.props.onDetailsClick}
        />
      ),

      sortable: false
    }];
  }

  componentDidMount() {
    this.props.regionService.fetchStates();
    this.props.employeeService.fetchSalesReps();
    this.props.customerService.onSearchModelChanged({isProspect: this.props.isProspect, callerId: this.props.callerId});
    
    if (this.props.restrictToCustomerType) {
      const forceCriteria: ForcedSearchCriteria = {};

      switch (this.props.restrictToCustomerType) {
        case "Caller":
          forceCriteria.isCaller = true;
          break;
        case "Shipper":
          forceCriteria.isShipper = true;
          break;
        case "Consignee":
          forceCriteria.isConsignee = true;
          break;
      }

      this.props.customerService.onSearchModelChanged(forceCriteria);
      this.setState({ forceCriteria: forceCriteria });
    }
  }

  componentWillUnmount() {
    this.props.customerService.clearFreezer();
  }

  private _gridApiRef: React.MutableRefObject<GridApiPro> = { current: {} as any };

  @bind
  private _onTextChanged(e: React.ChangeEvent<{ name: string; value: string; }>) {
    this.props.customerService.onSearchModelChanged({ [e.target.name]: e.target.value });
  }

  @bind
  private _clearCustomerName() {
    this.props.customerService.onSearchModelChanged({ customerName: "" });
  }

  @bind
  private _clearCurrentProvider() {
    this.props.customerService.onSearchModelChanged({ currentProvider: "" });
  }

  @bind
  private _onRegionChanged(e: SelectChangeEvent<string>) {
    this.props.customerService.onSearchModelChanged({ regionAbbreviation: e.target.value as string });
  }

  @bind
  private _onSalesRepChange(salesAgentId: number) {
    this.props.customerService.onSearchModelChanged({ salesRepId: salesAgentId || undefined });
  }

  @bind
  private _onIndustryTypeChange(e: SelectChangeEvent<CustomerSearchCriteriaIndustryTypeEnum>) {
    this.props.customerService.onSearchModelChanged({ industryType: e.target.value ? e.target.value as CustomerSearchCriteriaIndustryTypeEnum : undefined });
  }

  @bind
  private _onPercentageToCloseChange(e: SelectChangeEvent<CustomerSearchCriteriaPercentageToCloseEnum>) {
    this.props.customerService.onSearchModelChanged({ percentageToClose: e.target.value ? e.target.value as CustomerSearchCriteriaPercentageToCloseEnum : undefined });
  }

  @bind
  private _onCheckboxChange(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.customerService.onSearchModelChanged({ includeProspects: checked });
  }

  @bind
  private _onClearForcedCriteria(event: React.MouseEvent) {
    this.props.customerService.clearSearchResults();
    this.props.customerService.onSearchModelChanged({
      isCaller: undefined,
      isShipper: undefined,
      isConsignee: undefined
    });
    this.setState({ forceCriteria: undefined });

    event.preventDefault();
  }

  private _canSearch(criteria: CustomerSearchCriteria): boolean {
    return !!criteria.customerName
      || !!criteria.regionAbbreviation
      || !!criteria.salesRepId
      || (!criteria.isProspect && criteria.isCaller !== undefined)
      || (!criteria.isProspect && criteria.isShipper !== undefined)
      || (!criteria.isProspect && criteria.isConsignee !== undefined)
      || (!!criteria.isProspect && criteria.industryType !== undefined)
      || (!!criteria.isProspect && criteria.percentageToClose !== undefined)
      || (!!criteria.isProspect && !!criteria.currentProvider);
  }

  @bind
  private _onSearchClick() {
    const criteria = this.props.customerService.getState().searchCriteria;

    if (!this._canSearch(criteria)) {
      return;
    }

    this.props.customerService.onSearchClick();
  }

  @bind
  private _searchFieldOnKeyPress(e: React.KeyboardEvent) {
    if (isEnterKey(e.key)) {
      this._onSearchClick();
    }
  }

  @bind
  private _onTypeFilterChange(event: SelectChangeEvent<string | number>) {
    const type = event.target.name as "isCaller" | "isShipper" | "isConsignee";

    const newSearch: Partial<CustomerSearchCriteria> = {};
    newSearch[type] = (event.target.value === "" ? undefined : !!event.target.value);

    this.props.customerService.onSearchModelChanged(newSearch);
  }

  @bind
  private _onSortChange(sortModel: GridSortModel) {
    this.props.customerService.onSearchModelChanged({
      sortColumn: sortModel[0].field,
      sortAscending: sortModel[0].sort === "asc"
    });

    this._onSearchClick();
  }

  @bind
  private _selectCustomer(customer: Customer, shouldSubmit?: boolean) {
    this.props.customerService.setSelectedRow(customer);

    if (shouldSubmit && this.props.onEnterPressWithSelectedRow) {
      this.props.onEnterPressWithSelectedRow();
    }
  }

  @bind
  private _onRowClick(params: GridRowParams<Customer>, event: MuiEvent<React.MouseEvent>) {
    this._selectCustomer(params.row);
    event.defaultMuiPrevented = true;
  }

  @bind
  private _resultsOnKeyPress(params: GridCellParams<any, Customer>, event: MuiEvent<React.KeyboardEvent>) {
    if (isEnterKey(event.key)) {
      this._selectCustomer(params.row, true);
    }
    if (this.props.limitGridHeight && (event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'PageDown' || event.key === 'PageUp')) {
      const currentPos = this._gridApiRef.current.getScrollPosition();
      if (event.key === "ArrowDown") {
        currentPos.top += rowHeight;
      } else if (event.key === "ArrowUp") {
        currentPos.top -= rowHeight;
      } else if (event.key === "PageDown") {
        currentPos.top += pageHeight;
      } else if (event.key === "PageUp") {
        currentPos.top -= pageHeight;
      }
      
      this._gridApiRef.current.scroll(currentPos);
    } else {
      event.defaultMuiPrevented = true;
    }
  }

  render() {
    const {
      sortModel
    } = this.state;

    const {
      searchCriteria,
      searchValidationErrors,
      searchResults,
      selectedRow
    } = this.props.customerService.getState();

    const customerData = searchResults.data?.results ?? [];

    const {
      actionButtons,
      hiddenColumns,
      hideGridIfNoResults,
      canExpandSearch,
      searchBySalesRep,
      isProspect,
      searchAll
    } = this.props;

    const hideColumns: GridColumnVisibilityModel = {};
    if (hiddenColumns) {
      hiddenColumns.forEach((x) => {
        hideColumns[x] = false;
      });
    }

    const {
      regionFetchResults
    } = this.props.regionService.getState();
    const regionData = regionFetchResults.data ?? [];

    const {
      activeSalesReps
    } = this.props.employeeService.getState();
    const salesReps = activeSalesReps ?? [];

    const validationsParser = new ValidationErrorParser<CustomerSearchCriteria>(searchValidationErrors);

    const { forceCriteria } = this.state;

    const customerTypeName = isProspect ? "Prospect" 
      : forceCriteria?.isShipper ? "Shipper"
        : forceCriteria?.isConsignee ? "Consignee"
          : forceCriteria?.isCaller ? "Caller"
            : "Customer";

    const canSearch = this._canSearch(searchCriteria);

    const columns = isProspect ? this._getProspectColumns() : this._getColumns(customerTypeName);
    const gridOverlay = searchResults.hasFetched ?
      searchResults.data?.totalRecords === 0 ? `No ${customerTypeName.toLocaleLowerCase()}s found` : ""
      : `Enter criteria to search for ${customerTypeName.toLocaleLowerCase()}s`;

    return (
      <div className={styles.card}>
        <div className={styles.searchControls}>
          <div className={styles.customerName}>
            <AdvanceTextField
              label={isProspect ? "Prospect Name" : "Customer"}
              name="customerName"
              onChange={this._onTextChanged}
              onKeyPress={this._searchFieldOnKeyPress}
              value={searchCriteria.customerName ?? ""}
              error={!validationsParser.isValid("customerName")}
              helperText={validationsParser.validationMessage("customerName") || "Search customer name or code"}
              autoFocus
            />
            <IconButton
              className={styles.alignToControl}
              size="small"
              onClick={this._clearCustomerName}
            >
              <Clear className={styles.clearIcon} />
            </IconButton>
          </div>

          <FormControl className={styles.regionSelector}>
            <InputLabel>{isProspect ? "State" : "Origin State"}</InputLabel>
            <Select
              value={searchCriteria.regionAbbreviation}
              onChange={this._onRegionChanged}
            >
              <MenuItem value="">&nbsp;</MenuItem>
              {regionData.map((region, idx) =>
                <MenuItem value={region.regionAbbreviation} key={idx}>
                  {region.regionName}
                </MenuItem>
              )}
            </Select>
          </FormControl>

          {searchBySalesRep &&
            <FormControl style={{ flex: "0 1 12rem" }}>
              <InputLabel shrink>Sales Representative</InputLabel>
              <Select
                value={searchCriteria.salesRepId ?? ""}
                onChange={(event) => this._onSalesRepChange(event.target.value as number)}
                displayEmpty
              >
                <MenuItem value=""><i>All</i></MenuItem>
                {salesReps.map((agent, index) => (
                  <MenuItem key={index} value={agent.id}>
                    {`${agent.firstName} ${agent.lastName}`}
                  </MenuItem>
                ))}
                {!isProspect && 
                  <MenuItem value={-1}><i>Not Assigned</i></MenuItem>}
              </Select>
            </FormControl>
          }

          {isProspect ?
            <>
              <FormControl style={{ flex: "0 1 12rem" }}>
                <InputLabel>Industry Type</InputLabel>
                <Select
                  value={searchCriteria.industryType ?? ""}
                  onChange={(event) => this._onIndustryTypeChange(event)}
                >
                  <MenuItem value="">&nbsp;</MenuItem>
                  <MenuItem value="Aerospace">{industryTypeTextMap["Aerospace"]}</MenuItem>
                  <MenuItem value="Equipment">{industryTypeTextMap["Equipment"]}</MenuItem>
                  <MenuItem value="GeneratorsTransformers">{industryTypeTextMap["GeneratorsTransformers"]}</MenuItem>
                  <MenuItem value="MachineTools">{industryTypeTextMap["MachineTools"]}</MenuItem>
                  <MenuItem value="Steel">{industryTypeTextMap["Steel"]}</MenuItem>
                  <MenuItem value="Other">{industryTypeTextMap["Other"]}</MenuItem>
                </Select>
              </FormControl>

              <div className={styles.customerName}>
                <AdvanceTextField
                  label="Current Provider"
                  name="currentProvider"
                  onChange={this._onTextChanged}
                  onKeyPress={this._searchFieldOnKeyPress}
                  value={searchCriteria.currentProvider ?? ""}
                  error={!validationsParser.isValid("currentProvider")}
                  helperText={validationsParser.validationMessage("currentProvider")}
                />
                <IconButton
                  className={styles.alignToControl}
                  size="small"
                  onClick={this._clearCurrentProvider}
                >
                  <Clear className={styles.clearIcon} />
                </IconButton>
              </div>

              <FormControl style={{ flex: "0 1 12rem" }}>
                <InputLabel>Percent To Close</InputLabel>
                <Select
                  value={searchCriteria.percentageToClose ?? ""}
                  onChange={(event) => this._onPercentageToCloseChange(event)}
                >
                  <MenuItem value="">&nbsp;</MenuItem>
                  <MenuItem value="NoContactWithDM">{percentageToCloseTextMap["NoContactWithDM"]}</MenuItem>
                  <MenuItem value="ContactedDMInterestExpressed">{percentageToCloseTextMap["ContactedDMInterestExpressed"]}</MenuItem>
                  <MenuItem value="Quoted">{percentageToCloseTextMap["Quoted"]}</MenuItem>
                  <MenuItem value="VerballyAcceptedQuote">{percentageToCloseTextMap["VerballyAcceptedQuote"]}</MenuItem>
                  <MenuItem value="OrderPlaced">{percentageToCloseTextMap["OrderPlaced"]}</MenuItem>
                </Select>
              </FormControl>
            </> : <>
              {(!forceCriteria || forceCriteria?.isCaller) &&
                <div className={styles.typeSelector}>
                  <TriStateSelector
                    name="isCaller"
                    disabled={forceCriteria?.isCaller}
                    label="Caller?"
                    value={searchCriteria.isCaller}
                    trueLabel="Yes"
                    trueValue={1}
                    falseLabel="No"
                    falseValue={0}
                    onChange={this._onTypeFilterChange}
                  />
                </div>
              }

              {(!forceCriteria || forceCriteria?.isShipper) &&
                <div className={styles.typeSelector}>
                  <TriStateSelector
                    name="isShipper"
                    disabled={forceCriteria?.isShipper}
                    label="Shipper?"
                    value={searchCriteria.isShipper}
                    trueLabel="Yes"
                    trueValue={1}
                    falseLabel="No"
                    falseValue={0}
                    onChange={this._onTypeFilterChange}
                  />
                </div>
              }

              {(!forceCriteria || forceCriteria?.isConsignee) &&
                <div className={styles.typeSelector}>
                  <TriStateSelector
                    name="isConsignee"
                    disabled={forceCriteria?.isConsignee}
                    label="Consignee?"
                    value={searchCriteria.isConsignee}
                    trueLabel="Yes"
                    trueValue={1}
                    falseLabel="No"
                    falseValue={0}
                    onChange={this._onTypeFilterChange}
                  />
                </div>
              }
            </>
          }

          {searchAll &&
            <FormControlLabel
              label="Include prospects"
              control={(
                <Checkbox
                  checked={searchCriteria.includeProspects}
                  onChange={this._onCheckboxChange}
                  name="includeProspects"
                />
              )}
            />
          }

          <div className={styles.alignToControl}>
            <Button
              color="primary"
              disabled={searchResults.isFetching || !canSearch}
              onClick={this._onSearchClick}
            >
              <Search /> Search
            </Button>
          </div>

          {isProspect &&
            <CardActions
              disableSpacing={true}
              className={styles.addProspect}
            >
              {actionButtons}
            </CardActions>
          }
        </div>

        <AjaxActionIndicator
          state={[searchResults]}
        />

        <div className={styles.tableHeaderComponents}>
          <div className={styles.resultsMessage}>
            {(searchResults.hasFetched ?
              ((searchResults.data?.totalRecords ?? 0) > (searchResults.data?.numberOfRecords ?? 0)) ? (
                <>
                  {searchResults.data?.totalRecords} results found, {searchResults.data?.numberOfRecords} shown - please refine your search.
                </>
              ) : (!customerData.length && forceCriteria && canExpandSearch) ? (
                <>
                  No {customerTypeName}s found - <Link color="secondary" href="#" onClick={this._onClearForcedCriteria}>view all customer types?</Link>
                </>
              ) : (!customerData.length && !isProspect ? (
                <>No {customerTypeName}s found.</>
              ) : undefined)
              : undefined)}
          </div>

          {!isProspect && actionButtons && (
            <div className={styles.actions}>
              {actionButtons}
            </div>
          )}
        </div>

        {hideGridIfNoResults && !customerData.length ? null :
          <div className={styles.resultsGrid} style={!this.props.limitGridHeight ? undefined : { flex: "1 1 500px" }}>
            <DataGridPro
              columns={columns}
              rows={customerData}
              classes={{
                cell: styles.resultCell
              }}
              density="compact"
              initialState={{
                columns: { columnVisibilityModel: hideColumns },
                sorting: { sortModel: sortModel }
              }}
              selectionModel={[selectedRow?.id ?? 0]}
              sortingMode="server"
              sortingOrder={["asc", "desc"]}
              getRowId={(row: Customer) => row.id ?? 0}
              getRowHeight={() => 64}
              onCellKeyDown={this._resultsOnKeyPress}
              onRowClick={this._onRowClick}
              onSortModelChange={this._onSortChange}
              apiRef={this._gridApiRef}
              disableColumnFilter
              disableColumnMenu
              disableMultipleSelection
              hideFooter
              components={{
                NoRowsOverlay: () => 
                  <Stack className={styles.noRows}>
                    {gridOverlay}
                  </Stack>
              }}
            />
          </div>
        }
      </div>
    );
  }
}

export const CustomerSearchResults = CustomerService.inject(
  StateService.inject(
    EmployeeService.inject(
      _CustomerSearchResults
    )
  )
);
