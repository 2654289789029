import {
  _,
  React,
  bind,
  moment,
  NumberFormat,
  NumberFormatValues
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  AdvanceTextField,
  AjaxActionIndicator,
  PhoneNumberInputField,
  CustomerHours,
  InstructionsTable,
  BinaryChoiceDialog,
  ConvertCustomerModal,
  AddSourceDetailModal,
  BillingInformation
} from "$Imports/CommonComponents";

import {
  Customer,
  Region,
  Employee,
  CustomerSource,
  ProspectIndustryTypeEnum,
  ProspectDecisionMakingTypeEnum,
  ProspectPricingTypeEnum,
  ProspectPercentageToCloseEnum,
  CustomerHour,
  CustomerHourDayOfWeekEnum,
  Activity
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  SelectChangeEvent,
  KeyboardDatePicker,
  TextField,
  TextFieldProps,
  Grid,
  Checkbox,
  FormControlLabel,
  Icon
} from "$Imports/MaterialUIComponents";

import {
  ICustomerServiceInjectedProps,
  CustomerService,
  CustomerProspectValidationSchema,
  CustomerAddressRequiredValidationSchema
} from "$State/CustomerFreezerService";

import {
  LoadingInstructionService,
  ILoadingInstructionServiceInjectedProps
} from "$State/LoadingInstructionFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  ErrorService
} from "$State/ErrorFreezerService";

import {
  decisionMakingTypeTextMap,
  industryTypeTextMap,
  percentageToCloseTextMap,
  pricingTypeTextMap
} from "$Utilities/enumUtil";

import {
  qualifyZipPostalCodeInput
} from "$Shared/utilities/helpers";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import { Info } from "$Imports/MaterialUIIcons";

const styles: {
  inputContainer: string;
  phoneFlexRow: string;
  checkboxContainer: string;
  alertContainer: string;
  flexContainer: string;
  icon: string;
  infoIcon: string
  actionsContainer: string;
  normalActions: string;
} = require("./AddEditCustomerModal.scss");

interface IAddEditCustomerModalProps {
  isOpen: boolean;
  isFetching: boolean;
  customer: Customer;
  salesReps: Employee[];
  regions: Region[];
  customerSources: CustomerSource[];
  disableCallerCheckbox?: boolean;
  disableShipperCheckbox?: boolean;
  disableConsigneeCheckbox?: boolean;
  canConvertToProspect?: boolean;
  onSave: (value: Customer, activity?: Activity) => void;
  onCancel: () => void;
}

type AddEditCustomerModalProps = IAddEditCustomerModalProps
& ICustomerServiceInjectedProps
& ILoadingInstructionServiceInjectedProps;

interface IAddEditCustomerModalState {
  confirmModalOpen: boolean;
  isConvertCustomerModalOpen: boolean;
  templateDay: CustomerHourDayOfWeekEnum | "";
  customer: Customer;
  validationErrors: ValidationError | null;
  activityToSave: Activity | undefined;
}

export class _addEditCustomerModal extends React.Component<AddEditCustomerModalProps, IAddEditCustomerModalState> {
  state: IAddEditCustomerModalState = {
    confirmModalOpen: false,
    isConvertCustomerModalOpen: false,
    templateDay: "",
    customer: this.props.customer,
    validationErrors: null,
    activityToSave: undefined
  }

  componentDidUpdate(prev: AddEditCustomerModalProps) {
    const { customer, salesReps, customerSources } = this.props;
    if (customer !== prev.customer) {
      this.setState({
        customer: customer,
        validationErrors: null
      });
    }
    if (this.state.customer.salesAgentId && this.state.customer.salesAgentId === customer.salesAgentId) {
      const salesRepInactive = salesReps.length > 0 && customer.salesAgentId ? !(salesReps.find(s => s.id === customer.salesAgentId)?.isActive) : false;
      if (salesRepInactive) {
        this.removeInactive("salesAgentId", "sales representative")
      }
    }
    if (this.state.customer.customerSourceId && this.state.customer.customerSourceId === customer?.customerSourceId) {
      const sourceInactive = customerSources.length > 0 && customer?.customerSourceId ? !(customerSources.find(s => s.id === customer?.customerSourceId)?.isActive) : false;
      if (sourceInactive) {
        this.removeInactive("customerSourceId", "customer source")
      }
    }
  }

  @bind
  private _onCancel() {
    this.setState({
      templateDay: "",
      validationErrors: null
    });
    this.props.onCancel();
  }

  @bind
  private async _onValidateCustomer(): Promise<boolean> {
    const schema = this.state.customer.isProspect ?
      CustomerProspectValidationSchema :
      CustomerAddressRequiredValidationSchema;
    const customerErrors = await validateSchema(schema, this.state.customer, {
      context: {
        currentTime: moment(new Date()).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
      },
      abortEarly: false
    });

    this.setState({ validationErrors: customerErrors });
    if (customerErrors) {
      return true;
    }

    return false;
  }

  @bind
  private async _checkCustomerExists(): Promise<boolean> {
    await this.props.customerService.checkCustomerExists(this.state.customer);

    const result = this.props.customerService.getState().customerExistsResults;
    
    return result?.data ?? false;
  }

  @bind
  private async _onCheckCustomerExists() {
    var hasErrors = await this._onValidateCustomer();

    if (hasErrors) {
      return;
    }

    this._checkCustomerExists().then((exists) => {
      this.setState({ confirmModalOpen: exists});
      if (!exists) {
        this._onSave();
      }
    });
  }

  @bind
  private async _onSave() {
    const { customer, activityToSave } = this.state;
    var hasErrors = await this._onValidateCustomer();

    if (hasErrors) {
      return;
    }

    this.setState({ templateDay: "" });
    this.props.onSave(customer, activityToSave);
  }

  @bind
  private _onTextInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        [event.target.name]: event.target.value !== "" ? event.target.value : undefined
      }
    }));
  }

  @bind
  private _onSourceDetailsChange(sourceDetails: string): void {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        sourceDetails: sourceDetails !== "" ? sourceDetails : undefined
      }
    }));
  }

  @bind
  private _onPhoneNumberChange(newValue: string | undefined) {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        phoneNumber: newValue
      }
    }));
  }

  @bind
  private _onCellNumberChange(newValue: string | undefined) {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        cellNumber: newValue
      }
    }));
  }

  @bind
  private _onSelectChange(event: SelectChangeEvent): void {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        [event.target.name]: event.target.value !== "" ? Number(event.target.value) : undefined
      }
    }));
  }

  @bind
  private _onCustomerSourceChange(event: SelectChangeEvent): void {
    const value = event.target.value;
    const customerSource = _.find(this.props.customerSources, s => s.id === Number(value));

    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        customerSourceId: Number(value),
        customerSource: customerSource,
        sourceDetails: undefined
      }
    }));
  }

  @bind
  private _onRegionChange(event: SelectChangeEvent): void {
    const regionId = Number(event.target.value);
    const region = _.find(this.props.regions, r => r.id === regionId);
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        regionId: regionId,
        region: region
      }
    }));
  }

  @bind
  private _onTextInputChangeProspect(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        prospect: {
          ...prev.customer.prospect,
          [event.target.name]: event.target.value !== "" ? event.target.value : undefined
        }
      }
    }));
  }

  @bind
  private _onNumberInputChangeProspect(name: string, value: NumberFormatValues): void {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        prospect: {
          ...prev.customer.prospect,
          [name]: value?.floatValue
        }
      }
    }));
  }

  @bind
  private _onChangeIndustryType(event: SelectChangeEvent<ProspectIndustryTypeEnum >) {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        prospect: {
          ...prev.customer.prospect,
          industryType: event.target.value !== "" ? event.target.value as ProspectIndustryTypeEnum : undefined
        }
      }
    }));
  }

  @bind
  private _onChangeDecisionMakingType(event: SelectChangeEvent<ProspectDecisionMakingTypeEnum >) {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        prospect: {
          ...prev.customer.prospect,
          decisionMakingType: event.target.value !== "" ? event.target.value as ProspectDecisionMakingTypeEnum : undefined
        }
      }
    }));
  }

  @bind
  private _onChangePricingType(event: SelectChangeEvent<ProspectPricingTypeEnum >) {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        prospect: {
          ...prev.customer.prospect,
          pricingType: event.target.value !== "" ? event.target.value as ProspectPricingTypeEnum : undefined
        }
      }
    }));
  }

  @bind
  private _onChangePercentageToClose(event: SelectChangeEvent<ProspectPercentageToCloseEnum >) {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        prospect: {
          ...prev.customer.prospect,
          percentageToClose: event.target.value !== "" ? event.target.value as ProspectPercentageToCloseEnum : undefined
        }
      }
    }));
  }

  @bind
  private _onDateChange(date: Date | null) {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        prospect: {
          ...prev.customer.prospect,
          startDate: date ?? undefined
        }
      }
    }));
  }

  @bind
  private _onZipPostalCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const countryId = this.state.customer.region?.countryId;
    const isCanadian = countryId !== undefined ? countryId === 2 : undefined;
    const value = qualifyZipPostalCodeInput(e.target.value, isCanadian).replace(' ', '');
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        [e.target.name]: value
      }
    }));
  }

  @bind
  private _onWebsiteBlur(e: React.FocusEvent<HTMLInputElement>) {
    if (e.target.value && !e.target.value.startsWith("http://") && !e.target.value.startsWith("https://")) {
      this.setState((prev) => ({
        customer: {
          ...prev.customer,
          website: `https://${e.target.value}`
        }
      }));
    }
  }
  
  @bind
  private onChangeCustomerHours(day: CustomerHourDayOfWeekEnum, businessHours: Partial<CustomerHour>) {
    const { customer } = this.state;
    const idx = _.findIndex(customer?.customerHours, h => h.dayOfWeek === day);
    const customerHours = [...customer.customerHours ?? []];

    if (idx >= 0) {
      customerHours[idx] = {...customerHours[idx], ...businessHours};
    }
    else {
      customerHours.push({
        dayOfWeek: day,
        customerId: customer?.id,
        ...businessHours
      });
    }

    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        customerHours: customerHours
      }
    }));
  }

  @bind
  private onApplyTemplate(customerHours: Partial<CustomerHour[]>) {
    const { customer } = this.state;
    let currentCustomerHours = [...customer.customerHours ?? []];
    const updatedCustomerHours = _.map(currentCustomerHours, hour => {
      const replacementHour = _.find(customerHours, h => h?.dayOfWeek === hour.dayOfWeek);
      return replacementHour ? { ...hour, ...replacementHour } : hour;
    });
    const missingCustomerHours = _.differenceBy(customerHours, updatedCustomerHours, 'dayOfWeek') as CustomerHour[];
    const finalCustomerHours = [...updatedCustomerHours, ...missingCustomerHours];

    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        customerHours: finalCustomerHours
      }
    }));
  }

  @bind
  private removeInactive(propName: string, name: string) {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        [propName]: undefined
      }
    }));
    ErrorService.pushErrorMessage(`Inactive ${name} removed.`);
  }

  @bind
  private _onCheckboxChange(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    const { customer } = this.state;

    if (e.target.name === "isCaller" && !checked) {
      this.setState((prev) => ({
        customer: {
          ...prev.customer,
          salesAgentId: undefined
        }
      }));
    }

    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        [e.target.name]: checked
      }
    }));
  }

  @bind
  private _onBillToChange(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        isBillTo: checked,
        billingStatus: checked ? undefined : "CheckCredit",
      }
    }));
  }

  @bind
  async _onInstructionCheckboxChange(loadingInstructionId: number | undefined, checked: boolean) {
    if (!loadingInstructionId) {
      return;
    }

    const { customer } = this.state;
    const loadingInstructions = [...customer.customerLoadingInstructions ?? []];
    const idx = _.findIndex(loadingInstructions, i => i.loadingInstructionId === loadingInstructionId);
    
    if (idx >= 0) {
      loadingInstructions?.splice(idx, 1);
    }
    else {
      loadingInstructions?.push({
        loadingInstructionId: loadingInstructionId,
        customerId: customer.id
      });
    }

    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        customerLoadingInstructions: loadingInstructions
      }
    }));
  }

  @bind
  private _onCloseConvertToProspect() {
    this.setState({ isConvertCustomerModalOpen: false });
  }

  @bind
  private async _onConfirmConvertToProspect(salesAgentId: number, activity: Activity) {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        isProspect: true,
        salesAgentId: salesAgentId,
        customerSourceId: prev.customer.customerSourceId ?? undefined,
        website: prev.customer.website ?? undefined,
        prospectId: prev.customer.prospectId ?? 0,
        prospect: prev.customer.prospect ?? undefined
      },
      activityToSave: activity
    }));
    this._onCloseConvertToProspect();
  }

  @bind
  private _onConvertToProspect() {
    this.setState({ isConvertCustomerModalOpen: true });
  }

  @bind
  private _onBillingChange(billingInfo: Partial<Customer>): void {
    this.setState((prev) => ({
      customer: {
        ...prev.customer,
        ...billingInfo
      }
    }));
  }

  private getPhoneNumberInputField(customer: Customer, validationsParser: ValidationErrorParser<Customer>, isCell: boolean = false) {
    return (
      <PhoneNumberInputField
        label={`${isCell ? "Cell" : "Phone"} Number`}
        name={isCell ? "cellNumber" : "phoneNumber"}
        required={!isCell}
        onPhoneNumberChange={isCell ? this._onCellNumberChange : this._onPhoneNumberChange}
        captureExt={!isCell}
        value={isCell ? customer.cellNumber ?? "" : customer.phoneNumber ?? ""}
        error={!validationsParser.isValid(isCell ? "cellNumber" : "phoneNumber")}
        helperText={validationsParser.validationMessage(isCell ? "cellNumber" : "phoneNumber")}
        className={isCell ? "" : styles.phoneFlexRow}
      />
    );
  }

  private getEmailTextField(customer: Customer, validationsParser: ValidationErrorParser<Customer>) {
    return (
      <AdvanceTextField
        label="Email"
        name="emailAddress"
        required={customer.isCaller}
        onChange={this._onTextInputChange}
        value={customer.emailAddress ?? ""}
        error={!validationsParser.isValid("emailAddress")}
        helperText={validationsParser.validationMessage("emailAddress")}
        fullWidth
      />
    );
  }

  private getWebsiteTextField(customer: Customer, validationsParser: ValidationErrorParser<Customer>, isProspect: boolean = false) {
    return (
      <AdvanceTextField
        label="Website"
        name="website"
        onBlur={this._onWebsiteBlur}
        onChange={this._onTextInputChange}
        value={customer.website ?? ""}
        error={!validationsParser.isValid("website")}
        helperText={validationsParser.validationMessage("website")}
        inputProps={{ maxLength: 150 }}
        fullWidth
        required={isProspect}
      />
    );
  }

  private getSourceFormControl(customer: Customer, validationsParser: ValidationErrorParser<Customer>, isProspect: boolean = false) {
    return (
      <FormControl fullWidth error={!validationsParser.isValid("customerSourceId")}>
        <InputLabel>{isProspect ? "Lead Source *" : "Customer Source"}</InputLabel>
        <Select
          value={customer.customerSourceId ?? ""}
          name="customerSourceId"
          onChange={(event) => this._onCustomerSourceChange(event as React.ChangeEvent<HTMLInputElement>)}
          required={isProspect}
        >
          {this.props.customerSources.map((c, idx) => (
            <MenuItem value={c.id} key={idx}>{c.name}</MenuItem>
          ))}
        </Select>
        {!validationsParser.isValid("customerSourceId") && <FormHelperText>{validationsParser.validationMessage("customerSourceId")}</FormHelperText>}
      </FormControl>
    );
  }

  render() {
    const {
      isOpen,
      isFetching,
      regions,
      salesReps,
      disableCallerCheckbox,
      disableShipperCheckbox,
      disableConsigneeCheckbox,
      canConvertToProspect
    } = this.props;

    const {
      customer,
      validationErrors,
      isConvertCustomerModalOpen
    } = this.state;

    const {
      activeLoadingInstructions
    } = this.props.loadingInstructionService.getState();

    const isProspect = customer.isProspect;
    const showConvertToProspect = isProspect === true ? false : canConvertToProspect;

    const validationsParser = new ValidationErrorParser<Customer>(validationErrors);

    return (
      <>
        <Dialog
          open={isOpen}
          maxWidth="md"
        >
          <DialogTitle>{`${customer.id ? "Edit" : "Add"} ${isProspect ? "Prospect" : "Customer"}`}</DialogTitle>
          <AjaxActionIndicator
            showProgress={isFetching}
          />
          <DialogContent className={styles.inputContainer}>
            <Grid container direction="row" wrap="wrap" spacing={2}>
              <Grid item xs={isProspect ? 8 : 4}>{/* Line 1 */}
                <AdvanceTextField
                  label={`${isProspect ? "Prospect" : "Customer"} Name`}
                  name="customerName"
                  required
                  onChange={this._onTextInputChange}
                  value={customer.customerName ?? ""}
                  error={!validationsParser.isValid("customerName")}
                  helperText={validationsParser.validationMessage("customerName")}
                  inputProps={{ maxLength: 40 }}
                  fullWidth
                />
              </Grid>
              {!isProspect &&
                <Grid item xs={4}>
                  <AdvanceTextField
                    label="Contact Name"
                    name="contactName"
                    onChange={this._onTextInputChange}
                    value={customer.contactName ?? ""}
                    error={!validationsParser.isValid("contactName")}
                    helperText={validationsParser.validationMessage("contactName")}
                    fullWidth
                  />
                </Grid>}
              <Grid item xs={4}>
                <FormControl fullWidth error={!validationsParser.isValid("salesAgentId")}>
                  <InputLabel shrink>{`Sales Representative${isProspect ? " *" : ""}`}</InputLabel>
                  <Select
                    value={customer.salesAgentId ?? ""}
                    name="salesAgentId"
                    onChange={(event) => this._onSelectChange(event as React.ChangeEvent<HTMLInputElement>)}
                    displayEmpty
                    disabled={!isProspect && !(customer.isCaller ?? false)}
                    required={isProspect}
                  >
                    {!isProspect && <MenuItem value="">Not Assigned</MenuItem>}
                    {salesReps.map((s, idx) => (
                      <MenuItem value={s.id} key={idx}>{`${s.firstName} ${s.lastName}`}</MenuItem>
                    ))}
                  </Select>
                  {!validationsParser.isValid("salesAgentId") && <FormHelperText>{validationsParser.validationMessage("salesAgentId")}</FormHelperText>}
                </FormControl>
              </Grid>

              <Grid item xs={8}>{/* Line 2 */}
                <AdvanceTextField
                  label="Address"
                  name="address1"
                  required
                  onChange={this._onTextInputChange}
                  value={customer.address1 ?? ""}
                  error={!validationsParser.isValid("address1")}
                  helperText={validationsParser.validationMessage("address1")}
                  inputProps={{ maxLength: 40 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <AdvanceTextField
                  label="Address line 2"
                  name="address2"
                  onChange={this._onTextInputChange}
                  value={customer.address2 ?? ""}
                  error={!validationsParser.isValid("address2")}
                  helperText={validationsParser.validationMessage("address2")}
                  inputProps={{ maxLength: 40 }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>{/* Line 3 */}
                <AdvanceTextField
                  label="City"
                  name="city"
                  required
                  onChange={this._onTextInputChange}
                  value={customer.city ?? ""}
                  error={!validationsParser.isValid("city")}
                  helperText={validationsParser.validationMessage("city")}
                  inputProps={{ maxLength: 30 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth error={!validationsParser.isValid("regionId")}>
                  <InputLabel>Region *</InputLabel>
                  <Select
                    value={customer.regionId ?? ""}
                    name="regionId"
                    required
                    onChange={(event) => this._onRegionChange(event as React.ChangeEvent<HTMLInputElement>)}
                  >
                    {_.map(regions, (r, idx) =>
                      <MenuItem value={r.id} key={idx}>{r.regionName}</MenuItem>
                    )}
                  </Select>
                  {!validationsParser.isValid("regionId") && <FormHelperText>{validationsParser.validationMessage("regionId")}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Postal Code"
                  name="zipPostalCode"
                  required
                  onChange={this._onZipPostalCodeChange}
                  value={customer.zipPostalCode ?? ""}
                  error={!validationsParser.isValid("zipPostalCode")}
                  helperText={validationsParser.validationMessage("zipPostalCode")}
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>{/* Line 4 */}
                {isProspect ?
                  this.getPhoneNumberInputField(customer, validationsParser) : 
                  this.getEmailTextField(customer, validationsParser)}
              </Grid>
              <Grid item xs={isProspect ? 8 : 4}>
                {isProspect ? 
                  this.getWebsiteTextField(customer, validationsParser, isProspect) :
                  this.getPhoneNumberInputField(customer, validationsParser)}
              </Grid>
              {!isProspect &&
                <Grid item xs={4}>
                  {this.getPhoneNumberInputField(customer, validationsParser, true)}
                </Grid>}

              <Grid item xs={4}>{/* Line 5 */}
                {isProspect ?
                  this.getSourceFormControl(customer, validationsParser, isProspect) :
                  this.getWebsiteTextField(customer, validationsParser, isProspect)}
              </Grid>
              <Grid item xs={4}>
                {isProspect ?
                  <AdvanceTextField
                    label="Current Provider"
                    name="currentProvider"
                    onChange={this._onTextInputChangeProspect}
                    value={customer.prospect?.currentProvider ?? ""}
                    error={!validationsParser.isValidDeep("prospect.currentProvider")}
                    helperText={validationsParser.validationMessageDeep("prospect.currentProvider")}
                    inputProps={{ maxLength: 100 }}
                    fullWidth
                  /> :
                  this.getSourceFormControl(customer, validationsParser, isProspect)}
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth error={!validationsParser.isValidDeep("prospect.industryType")}>
                  <InputLabel>{`Industry Type${isProspect ? " *" : ""}`}</InputLabel>
                  <Select
                    name="industryType"
                    value={customer.prospect?.industryType ?? ""}
                    onChange={(event) => this._onChangeIndustryType(event)}
                    required={isProspect}
                  >
                    <MenuItem value="">&nbsp;</MenuItem>
                    <MenuItem value="Aerospace">{industryTypeTextMap["Aerospace"]}</MenuItem>
                    <MenuItem value="Equipment">{industryTypeTextMap["Equipment"]}</MenuItem>
                    <MenuItem value="GeneratorsTransformers">{industryTypeTextMap["GeneratorsTransformers"]}</MenuItem>
                    <MenuItem value="MachineTools">{industryTypeTextMap["MachineTools"]}</MenuItem>
                    <MenuItem value="Steel">{industryTypeTextMap["Steel"]}</MenuItem>
                    <MenuItem value="Other">{industryTypeTextMap["Other"]}</MenuItem>
                  </Select>
                  {!validationsParser.isValidDeep("prospect.industryType") && <FormHelperText>{validationsParser.validationMessageDeep("prospect.industryType")}</FormHelperText>}
                </FormControl>
              </Grid>

              <Grid item xs={4}>{/* Line 6 */}
                <FormControl fullWidth error={!validationsParser.isValidDeep("prospect.decisionMakingType")}>
                  <InputLabel>Decision-Making Type</InputLabel>
                  <Select
                    name="decisionMakingType"
                    value={customer.prospect?.decisionMakingType ?? ""}
                    onChange={(event) => this._onChangeDecisionMakingType(event)}
                    required
                  >
                    <MenuItem value="">&nbsp;</MenuItem>
                    <MenuItem value="Customer">{decisionMakingTypeTextMap["Customer"]}</MenuItem>
                    <MenuItem value="DealerNetwork">{decisionMakingTypeTextMap["DealerNetwork"]}</MenuItem>
                    <MenuItem value="Local">{decisionMakingTypeTextMap["Local"]}</MenuItem>
                    <MenuItem value="Other">{decisionMakingTypeTextMap["Other"]}</MenuItem>
                  </Select>
                  {!validationsParser.isValidDeep("prospect.decisionMakingType") && <FormHelperText>{validationsParser.validationMessageDeep("prospect.decisionMakingType")}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth error={!validationsParser.isValidDeep("prospect.pricingType")}>
                  <InputLabel>Pricing Type</InputLabel>
                  <Select
                    name="pricingType"
                    value={customer.prospect?.pricingType ?? ""}
                    onChange={(event) => this._onChangePricingType(event)}
                    required
                  >
                    <MenuItem value="">&nbsp;</MenuItem>
                    <MenuItem value="Contracted">Contracted</MenuItem>
                    <MenuItem value="SpotQuote">{pricingTypeTextMap["SpotQuote"]}</MenuItem>
                  </Select>
                  {!validationsParser.isValidDeep("prospect.pricingType") && <FormHelperText>{validationsParser.validationMessageDeep("prospect.pricingType")}</FormHelperText>}
                </FormControl>
              </Grid>
              {isProspect &&
                <>
                  <Grid item xs={4}>
                    <FormControl fullWidth error={!validationsParser.isValidDeep("prospect.percentageToClose")}>
                      <InputLabel>Percent To Close *</InputLabel>
                      <Select
                        value={customer.prospect?.percentageToClose ?? ""}
                        onChange={(event) => this._onChangePercentageToClose(event)}
                        required
                      >
                        <MenuItem value="">&nbsp;</MenuItem>
                        <MenuItem value="NoContactWithDM">{percentageToCloseTextMap["NoContactWithDM"]}</MenuItem>
                        <MenuItem value="ContactedDMInterestExpressed">{percentageToCloseTextMap["ContactedDMInterestExpressed"]}</MenuItem>
                        <MenuItem value="Quoted">{percentageToCloseTextMap["Quoted"]}</MenuItem>
                        <MenuItem value="VerballyAcceptedQuote">{percentageToCloseTextMap["VerballyAcceptedQuote"]}</MenuItem>
                        <MenuItem value="OrderPlaced">{percentageToCloseTextMap["OrderPlaced"]}</MenuItem>
                      </Select>
                      {!validationsParser.isValidDeep("prospect.percentageToClose") && <FormHelperText>{validationsParser.validationMessageDeep("prospect.percentageToClose")}</FormHelperText>}
                    </FormControl>
                  </Grid>

                  <Grid item xs={4} className={styles.flexContainer}>{/* Line 7 */}
                    <KeyboardDatePicker
                      value={customer.prospect?.startDate ?? null}
                      onChange={(date: Date | null, keyboard?: string | undefined) => this._onDateChange(date)}
                      inputFormat="MM/DD/YYYY"
                      disablePast={true}
                      renderInput={(params: TextFieldProps) => <TextField 
                        {...params}
                        label="Start Date"
                        error={!validationsParser.isValidDeep("prospect.startDate")} 
                        helperText={validationsParser.validationMessageDeep("prospect.startDate")}
                        fullWidth
                      />}
                    />
                    <Icon title="Start date is the estimated date when they will have their first shipment with Kaiser" className={styles.icon}>
                      <Info className={styles.infoIcon} />
                    </Icon>
                  </Grid>
                  <Grid item xs={4}>
                    <NumberFormat              
                      name="estAvgMonthlyFreightBills"
                      allowNegative={false}
                      thousandSeparator={true}
                      decimalScale={0}
                      label="Number of Monthly Freight Bills"
                      value={customer.prospect?.estAvgMonthlyFreightBills ?? ""}
                      customInput={TextField}
                      onValueChange={(value) => this._onNumberInputChangeProspect("estAvgMonthlyFreightBills", value)}
                      error={!validationsParser.isValidDeep("prospect.estAvgMonthlyFreightBills")}
                      helperText={validationsParser.validationMessageDeep("prospect.estAvgMonthlyFreightBills")}
                      inputProps={{ maxLength: 12 }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumberFormat
                      name="estAvgRevenuePerFreightBill"
                      allowNegative={false}
                      thousandSeparator={true}
                      decimalScale={0}
                      prefix="$"
                      label="Average Revenue per Freight Bill"
                      value={customer.prospect?.estAvgRevenuePerFreightBill ?? ""}
                      customInput={TextField}
                      onValueChange={(value) => this._onNumberInputChangeProspect("estAvgRevenuePerFreightBill", value)}
                      error={!validationsParser.isValidDeep("prospect.estAvgRevenuePerFreightBill")}
                      helperText={validationsParser.validationMessageDeep("prospect.estAvgRevenuePerFreightBill")}
                      inputProps={{ maxLength: 13 }}
                      fullWidth
                    />
                  </Grid>
                </>}
              {!isProspect &&
                <>
                  <Grid item xs={10}>
                    <div className={styles.checkboxContainer}>
                      <FormControlLabel
                        label="Caller?"
                        control={(
                          <Checkbox
                            checked={customer.isCaller ?? false}
                            onChange={this._onCheckboxChange}
                            name="isCaller"
                            disabled={disableCallerCheckbox}
                          />
                        )}
                      />
                      <FormControlLabel
                        label="Shipper?"
                        disabled={disableShipperCheckbox}
                        control={(
                          <Checkbox
                            checked={customer.isShipper ?? false}
                            onChange={this._onCheckboxChange}
                            name="isShipper"
                          />
                        )}
                      />
                      <FormControlLabel
                        label="Consignee?"
                        disabled={disableConsigneeCheckbox}
                        control={(
                          <Checkbox
                            checked={customer.isConsignee ?? false}
                            onChange={this._onCheckboxChange}
                            name="isConsignee"
                          />
                        )}
                      />
                      <FormControlLabel
                        label="Bill To?"
                        disabled={!SharedSecurityContext.hasRole(["customer-credit-information:edit"])}
                        control={(
                          <Checkbox
                            checked={customer.isBillTo}
                            onChange={this._onBillToChange}
                            name="isBillTo"
                          />
                        )}
                      />
                    </div>
                    <FormHelperText error={!validationsParser.isValid("isCaller")}>{validationsParser.validationMessage("isCaller")}</FormHelperText>
                  </Grid>
                  
                  <Grid item xs={12}>
                    <div className={styles.alertContainer}>
                      <FormControlLabel
                        label="Display alert when customer is added to a quote?"
                        disabled={false}
                        control={(
                          <Checkbox
                            checked={customer.displayAlert ?? false}
                            onChange={this._onCheckboxChange}
                            name="displayAlert"
                          />
                        )}
                      />
                      <AdvanceTextField
                        label="Alert Text"
                        name="alert"
                        disabled={!customer.displayAlert}
                        onChange={this._onTextInputChange}
                        value={customer.alert ?? ""}
                        error={!validationsParser.isValid("alert")}
                        helperText={validationsParser.validationMessage("alert")}
                        multiline
                        inputProps={{ maxLength: 500 }}
                      />
                    </div>
                  </Grid>
                </>}
              </Grid>
            
            {!isProspect &&
              <>
                <BillingInformation
                  customer={customer ?? {}}
                  regions={regions}
                  validationErrors={validationErrors}
                  onBillingChange={this._onBillingChange}
                />
                <CustomerHours
                  customer={customer ?? {}}
                  onHoursChange={this.onChangeCustomerHours}
                  onApplyTemplate={this.onApplyTemplate}
                  validationErrors={validationErrors}
                />
                <InstructionsTable
                  customer={customer ?? {}}
                  loadingInstructions={activeLoadingInstructions}
                  onInstructionsChange={this._onInstructionCheckboxChange}
                />
              </>}
          </DialogContent>
          <DialogActions className={styles.actionsContainer}>
            <div>
              {showConvertToProspect && (
                <Button
                  onClick={this._onConvertToProspect}
                  color="primary"
                >
                  Convert to Prospect
                </Button>
              )}
            </div>

            <div className={styles.normalActions}>
              <Button
                color="primary"
                onClick={isProspect || customer.id ? this._onSave : this._onCheckCustomerExists}
                disabled={isFetching}
              >
                Save
              </Button>
              <Button
                onClick={this._onCancel}
                disabled={isFetching}
                variant="outlined"
              >
                Cancel
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        <BinaryChoiceDialog
          isOpen={this.state.confirmModalOpen}
          message="Customer already exists. Click Continue to create a duplicate Customer."
          trueText={"Continue"}
          falseText={"Cancel"}
          onClick={(value) => {
            if (value) {
              this._onSave();
            } else {
              this.setState({ confirmModalOpen: false });
            }
          }}
        />
        <ConvertCustomerModal
          isOpen={isConvertCustomerModalOpen}
          customer={customer}
          salesReps={salesReps}
          onConfirmConvertToProspect={this._onConfirmConvertToProspect}
          onCancel={this._onCloseConvertToProspect}
          toProspect
        />
        <AddSourceDetailModal
          isOpen={customer.customerSource?.requiresAdditionalDetail === true && !customer.sourceDetails}
          sourceDetails={customer.sourceDetails}
          customerSource={customer.customerSource}
          onSave={this._onSourceDetailsChange}
        />
      </>
    );
  }
}

export const AddEditCustomerModal = CustomerService.inject(
  LoadingInstructionService.inject(
    _addEditCustomerModal
));