import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  CustomerSource
} from "$Generated/api";

import {
  Card,
  CardActions,
  CardHeader,
  Button,
  FormControlLabel,
  Switch,
  DataGridPro,
  GridColDef,
  GridValueFormatterParams,
  GridRenderCellParams
} from "$Imports/MaterialUIComponents";

import {
  AjaxActionIndicator
} from "$Imports/CommonComponents";

import {
  ICustomerSourceServiceInjectedProps,
  CustomerSourceService,
  EMPTY_CUSTOMER_SOURCE
} from "$State/CustomerSourceFreezerService";

import {
  AddEditCustomerSourceModal
} from "./AddEditCustomerSourceModal";

import {
  ActionMenu
} from "./ActionMenu";

const styles: {
  mainContainer: string;
  actionArea: string;
  cardStyle: string;
} = require("$Shared/styles/Modal.scss");

interface ICustomerSourceViewPageBaseProps { }

interface ICustomerSourceViewState {
  isCustomerSourceOpen: boolean;
  filterActive: boolean;
  customerSource: CustomerSource;
}

type ICustomerSourceViewPageProps = ICustomerSourceViewPageBaseProps
  & ICustomerSourceServiceInjectedProps;

class _CustomerSourceViewPage extends React.Component<ICustomerSourceViewPageProps, ICustomerSourceViewState> {
  state: ICustomerSourceViewState = {
    isCustomerSourceOpen: false,
    filterActive: true,
    customerSource: { ...EMPTY_CUSTOMER_SOURCE }
  }

  private readonly columns: GridColDef[] = [
    {
      headerName: "Source",
      field: "name",
      flex: 4
    },
    {
      headerName: "Details Required?",
      field: "requiresAdditionalDetail",
      valueFormatter: (params: GridValueFormatterParams<boolean>) => params.value ? "Yes" : "No",
      flex: 1
    },
    {
      headerName: "Active",
      field: "isActive",
      valueFormatter: (params: GridValueFormatterParams<boolean>) => params.value ? "Yes" : "No",
      flex: 1
    },
    {
      headerName: "",
      field: "actions",
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any, CustomerSource>) => (
        <ActionMenu
          customerSource={params.row}
          onEdit={this._toggleCustomerSourceModal}
        />
      )
    }
  ];

  componentDidMount() {
    this.props.customerSourceService.fetchCustomerSources(true);
  }

  @bind
  private _onActiveFilterChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.setState({ filterActive: checked });
    this.props.customerSourceService.fetchCustomerSources(checked, true);
  }

  @bind
  private async _toggleCustomerSourceModal(seed?: CustomerSource) {
    const { isCustomerSourceOpen } = this.state;
    if (isCustomerSourceOpen) {
      this.setState({
        isCustomerSourceOpen: false,
        customerSource: { ...EMPTY_CUSTOMER_SOURCE }
      });
    }
    else {
      let editCustomerSource: CustomerSource = { ...EMPTY_CUSTOMER_SOURCE, isActive: true };
      if (seed) {
        editCustomerSource = _.cloneDeep(seed);
      }
      this.setState({
        isCustomerSourceOpen: true,
        customerSource: editCustomerSource
      });
    }
  }

  render() {
    const {
      customerSourceFetchResults,
      customerSourceSaveResult
    } = this.props.customerSourceService.getState();
    const {
      isCustomerSourceOpen,
      filterActive,
      customerSource
    } = this.state;
    let customerSourceData = customerSourceFetchResults.data ?? [];

    return (
      <div
        className={styles.mainContainer}
      >
        <Card
          className={styles.cardStyle}
        >
          <CardHeader title="Customer Sources" />
          <CardActions
            disableSpacing={true}
            className={styles.actionArea}
          >
            <FormControlLabel
              control={
                (
                  <Switch
                    color="primary"
                    checked={filterActive}
                    onChange={this._onActiveFilterChange}
                  />
                )
              }
              label="Only show active"
            />
            <Button
              color="primary"
              onClick={() => this._toggleCustomerSourceModal()}
            >
              Add
            </Button>
          </CardActions>
          <AjaxActionIndicator
            state={[customerSourceFetchResults, customerSourceSaveResult]}
          />
          <div style={{ margin: "8px" }}>
            <DataGridPro
              rows={customerSourceData}
              columns={this.columns}
              density="compact"
              hideFooter
              disableSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: "name", sort: "asc" }]
                }
              }}
              autoHeight
            />
          </div>
        </Card>
        <AddEditCustomerSourceModal
          isOpen={isCustomerSourceOpen}
          filterActive={filterActive}
          customerSource={customerSource}
          onClose={() => this._toggleCustomerSourceModal()}
        />
      </div>
    );
  }
}

export const CustomerSourceViewPage = CustomerSourceService.inject(
  _CustomerSourceViewPage
);