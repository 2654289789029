import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  TextField,
  TextFieldProps
} from "$Imports/MaterialUIComponents";

interface IPhoneNumberInputFieldState {
  value?: string | undefined;
  phoneNumber?: string | undefined;
  ext?: string | undefined;
  phoneNumberError: boolean;
}

interface IPhoneNumberInputFieldBaseProps {
  onPhoneNumberChange: (newValue?: string) => void;
  value?: string | undefined;
  captureExt?: boolean | undefined;
  className?: string | undefined;
}

type IPhoneNumberInputFieldProps = IPhoneNumberInputFieldBaseProps & TextFieldProps;

export class PhoneNumberInputField extends React.Component<IPhoneNumberInputFieldProps, IPhoneNumberInputFieldState> {
  state: IPhoneNumberInputFieldState = {
    value: undefined,
    phoneNumber: undefined,
    ext: undefined,
    phoneNumberError: false
  }

  componentDidMount() {
    const parts = this.props.value?.split(' x');
    let phoneNumber: string | undefined;
    let ext: string | undefined;

    [phoneNumber, ext] = parts && parts.length > 0 ? parts : [undefined, undefined];

    this.setState({
      value: this.props.value,
      phoneNumber: phoneNumber === "" ? undefined : phoneNumber,
      ext: ext === "" ? undefined : ext
    });
  }

  componentDidUpdate(prev: IPhoneNumberInputFieldProps) {
    const {phoneNumber, ext} = this.state;
    if (prev.value !== this.props.value && this.props.value !== `${phoneNumber} x${ext}`) {
      if (this.props.name === "phoneNumber"){
        this.setState({
          value: ext ? `${this.props.value} x${ext}` : this.props.value,
          phoneNumber: this.props.value
        });
      }
      if (this.props.name === "ext") {
        this.setState({
          value: phoneNumber ? `${phoneNumber} x${this.props.value}` : `x${this.props.value}`,
          ext: this.props.value
        });
      }
    }
  }

  @bind
  private _onPhoneNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { ext } = this.state;

    let phoneNumber = event.target.value;
    if (phoneNumber !== undefined) {
      // Remove non-numeric characters
      phoneNumber = phoneNumber.replace(/[^0-9]/g, '');

      if (phoneNumber.length > 10) {
        phoneNumber = phoneNumber.slice(0, 10);
      }
    }

    this.setState({
      value: ext ? `${phoneNumber} x${ext}` : phoneNumber,
      phoneNumber: phoneNumber
    });
  }

  @bind
  private _onExtChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { phoneNumber } = this.state;
    let rawValue = event.target.value.substring(0, 5);
    const numericOnly = rawValue.replace(/\D/g, "")
    this.setState({
      value: phoneNumber ? `${phoneNumber} x${rawValue}` : "",
      ext: numericOnly
    });
  }

  @bind
  private _onPhoneNumberBlur(event: React.FocusEvent<HTMLInputElement>) {
    const { ext } = this.state;
    const rawValue = event.target.value;
    const numericOnly = rawValue.replace(/\D/g, "");

    if (numericOnly.length === 10) {
      const phoneNo = `${numericOnly.substring(0, 3)}-${numericOnly.substring(3, 6)}-${numericOnly.substring(6)}`;
      const fullPhoneNumber = ext ? `${phoneNo} x${ext}` : phoneNo;
      this.setState({ value: fullPhoneNumber, phoneNumber: phoneNo }, () => this.props.onPhoneNumberChange(fullPhoneNumber));
    }
    else {
      this.props.onPhoneNumberChange(rawValue); // let the parent component's validation handle it
    }
  }

  @bind
  private _onExtBlur(event: React.FocusEvent<HTMLInputElement>) {
    const { phoneNumber } = this.state;
    const ext = event.target.value;

    const fullPhoneNumber = ext && ext !== "" ? `${phoneNumber} x${ext}` : phoneNumber;
    this.setState({
      value: fullPhoneNumber,
      ext: ext
    }, () => this.props.onPhoneNumberChange(fullPhoneNumber));
  }

  render() {
    const {
      value: stateValue,
      phoneNumber,
      ext,
      phoneNumberError
    } = this.state;

    const {
      value,
      onPhoneNumberChange,
      ref,
      captureExt,
      className,
      ...passthroughProps
    } = this.props;

    if (passthroughProps.error && phoneNumberError === false) {
      this.setState({ phoneNumberError: true });
    }
    
    return (
      <div className={className ?? ""}>
        <TextField
          {...passthroughProps}
          style={{ width:"100%" }}
          onChange={this._onPhoneNumberChange}
          onBlur={this._onPhoneNumberBlur}
          value={phoneNumber ?? ""}
          error={phoneNumberError}
        />
        {captureExt && 
          <TextField 
            label="Ext"
            name="ext"
            style={{ width: "80px" }}
            onChange={this._onExtChange}
            onBlur={this._onExtBlur}
            value={ext ?? ""}
          />}
      </div>
    );
  }
}
