import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  ContactPage,
  Edit
} from "$Imports/MaterialUIIcons";

import {
  Customer,
  Employee,
  Opportunity,
  Reminder
} from "$Generated/api";

import {
  DisplayFormattedNumber,
  CustomerLink,
  UserAccessControl,
  TextCellTruncated,
  CreateReminderButton,
  ReminderEditModal,
  CompanyIcon,
  DueDateField,
  OpportunityEditModal,
  AddCustomerNoteModal
} from "$Imports/CommonComponents";

import {
  ISalesRepHomeServiceInjectedProps,
  SalesRepHomeService
} from "$State/SalesRepHomeFreezerService";

import {
  CustomerDetailService
} from "$State/CustomerDetailFreezerService";

import {
  EMPTY_OPPORTUNITY,
  IOpportunityServiceInjectedProps,
  OpportunityService
} from "$State/OpportunityFreezerService";

import {
  EMPTY_REMINDER,
  IReminderServiceInjectedProps,
  ReminderService
} from "$State/ReminderFreezerService";

import {
  CURRENCY_NO_DECIMAL_FORMAT
} from "$Shared/utilities/formatUtil";

const styles: {
  gridContainer: string;
  searchFormContainer: string;
  companyIconCompact: string;
  filterCardContainer: string;
  filterCard: string;
  filterTitle: string;
} = require("./MyQuotes.scss");

type OpportunityFilterType = "Discovery" | "Interested" | ""; // not included: Converted, Abandoned

interface IMySalesPipelineState {
  reminder: Reminder;
  isReminderModalOpen: boolean;
  isOpportunityModalOpen: boolean;
  opportunity: Opportunity;
  opportunityFilter: OpportunityFilterType;
  isCustomerNoteModalOpen: boolean;
  customerNoteText: string;
  noteCustomer?: Customer;
}

interface IMySalesPipelineBaseProps {
  salesReps: Employee[];
  companyHighlightColor: string;
}

type IMySalesPipelineProps = IMySalesPipelineBaseProps
  & ISalesRepHomeServiceInjectedProps
  & IReminderServiceInjectedProps
  & IOpportunityServiceInjectedProps;

class _MySalesPipeline extends React.Component<IMySalesPipelineProps, IMySalesPipelineState> {
  state: IMySalesPipelineState = {
    reminder: { ...EMPTY_REMINDER },
    isReminderModalOpen: false,
    isOpportunityModalOpen: false,
    opportunity: { ...EMPTY_OPPORTUNITY },
    opportunityFilter: "",
    isCustomerNoteModalOpen: false,
    customerNoteText: ""
  };

  private readonly columns: GridColDef[] = [
    {
      headerName: "",
      field: "companyId",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any, Opportunity>) => <CompanyIcon companyKey={params.row.company?.companyKey ?? ""} />,
    },
    {
      headerName: "Opp. ID",
      flex: 2,
      field: "opportunityId"
    },
    {
      headerName: "Customer Name",
      field: "customerId",
      flex: 3,
      renderCell: (params: GridRenderCellParams<any, Opportunity>) => {
        return (
          <CustomerLink
            customerId={params.row.customer?.id}
            customerName={params.row.customer?.customerName}
            isProspect={params.row.customer?.isProspect}
          />
        );
      }
    },
    {
      headerName: "",
      field: "customer",
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      minWidth: 24,
      width: 24,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <IconButton onClick={() => this.openCustomerNoteModal(params.row.customer)}>
            <ContactPage />
          </IconButton>
        )
      }
    },
    {
      headerName: "Opportunity Description",
      field: "description",
      flex: 4,
      renderCell: (params: GridRenderCellParams) => {
        return <TextCellTruncated text={params.row.description} />
      }
    },
    {
      headerName: "Status",
      field: "status",
      flex: 2
    },
    {
      headerName: "Confidence %",
      field: "confidence",
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return <DisplayFormattedNumber value={params.row.confidence / 100} formatString="0%" /> // % format auto multiplies by 100, but we store in whole numbers already
      }
    },
    {
      headerName: "Target Revenue",
      field: "targetRevenue",
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return <DisplayFormattedNumber value={params.row.targetRevenue} formatString={CURRENCY_NO_DECIMAL_FORMAT} />
      }
    },
    {
      headerName: "Close Date",
      field: "closeDate",
      width: 110,
      renderCell: (params: GridRenderCellParams<Date | undefined>) => params.value && <DueDateField value={params.value} />
    },
    {
      headerName: "",
      field: "actions",
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      renderCell: (params: GridRenderCellParams<any, Opportunity>) =>
        <>
          <UserAccessControl roles={["opportunity:edit"]}>
            <IconButton
              onClick={() => this._editOpportunity(params.row)}
              size="small"
              title="Edit opportunity"
            >
              <Edit />
            </IconButton>
          </UserAccessControl>
          <UserAccessControl roles={["reminder:create"]}>
            <CreateReminderButton
              seed={{
                ...EMPTY_REMINDER,
                type: "Sales"
              }}
              onClick={(seed) => this.onCreateReminder(seed, params.row.customer)}
            />
          </UserAccessControl>
        </>
    }
  ];

  componentDidMount() {
    this.props.salesRepHomeService.fetchOpportunities(true);
  }

  @bind
  onCreateReminder(seed: Reminder, customer: Customer | undefined) {
    this.setState({
      isReminderModalOpen: true,
      reminder: {
        ...seed,
        customer: {
          linkId: customer?.id,
          linkName: customer?.customerName
        }
      }
    });
  }

  @bind
  onCloseReminderModal(reminder: Reminder | undefined) {
    this.setState({
      isReminderModalOpen: false,
      reminder: { ...EMPTY_REMINDER }
    });

    // reload if a reminder was added or edited
    if (reminder) {
      this.props.reminderService.fetchReminders(true);
    }
  }

  @bind
  filterOpportunities(filter: OpportunityFilterType) {
    if (this.state.opportunityFilter === filter) {
      this.setState({ opportunityFilter: "" });
    }
    else {
      this.setState({ opportunityFilter: filter });
    }
  }

  @bind
  openCustomerNoteModal(selectedCustomer: Customer) {
    this.setState({
      isCustomerNoteModalOpen: true,
      customerNoteText: "",
      noteCustomer: selectedCustomer
    });
  }

  @bind
  onCustomerNoteTextChange(noteText: string) {
    this.setState({ customerNoteText: noteText });
  }

  @bind
  closeCustomerNoteModal() {
    const contactNoteText = this.state.customerNoteText.trim();

    if (this.state.noteCustomer?.id && contactNoteText !== "") {
      CustomerDetailService.createNoteWithText(this.state.noteCustomer.id, contactNoteText);
    }

    this.setState({
      isCustomerNoteModalOpen: false,
      customerNoteText: "",
      noteCustomer: undefined
    });
  }

  @bind
  private _editOpportunity(model: Opportunity) {
    this.setState({
      opportunity: model,
      isOpportunityModalOpen: true
    });
  }

  @bind
  private async _saveOpportunity(model?: Opportunity) {
    if (model) {
      await this.props.opportunityService.updateOpportunity(model.id!, model);

      await this.props.salesRepHomeService.fetchOpportunities(true);
    }

    this.setState({
      opportunity: { ...EMPTY_OPPORTUNITY },
      isOpportunityModalOpen: false
    });
  }

  render() {
    const {
      opportunityFetchResults
    } = this.props.salesRepHomeService.getState();
    const opportunityData = opportunityFetchResults.data ?? [];

    const {
      companyHighlightColor
    } = this.props;

    const {
      isReminderModalOpen,
      reminder,
      isCustomerNoteModalOpen,
      customerNoteText,
      isOpportunityModalOpen,
      opportunity,
      noteCustomer
    } = this.state;

    const discoveryOpportunities = _.filter(opportunityData, (o) => o.status === "Discovery");
    const interestedOpportunities = _.filter(opportunityData, (o) => o.status === "Interested");
    const opportunitiesToDisplay = _.filter(opportunityData, (o) => o.status === "Discovery" || o.status === "Interested");

    return (
      <>
        <div className={styles.gridContainer}>
          <div className={styles.filterCardContainer}>
            <div
              className={styles.filterCard}
              style={this.state.opportunityFilter === "Discovery" ? { backgroundColor: companyHighlightColor } : undefined}
              onClick={() => this.filterOpportunities("Discovery")}
            >
              <div className={styles.filterTitle}>Discovery Opportunities</div>
              <div>{discoveryOpportunities.length}</div>
            </div>
            <div
              className={styles.filterCard}
              style={this.state.opportunityFilter === "Interested" ? { backgroundColor: companyHighlightColor } : undefined}
              onClick={() => this.filterOpportunities("Interested")}
            >
              <div className={styles.filterTitle}>Interested Opportunities</div>
              <div>{interestedOpportunities.length}</div>
            </div>
          </div>

          <DataGridPro
            rows={this.state.opportunityFilter === "Discovery" ? discoveryOpportunities
              : this.state.opportunityFilter === "Interested" ? interestedOpportunities
                : opportunitiesToDisplay}
            columns={this.columns}
            density="compact"
            hideFooter
            initialState={{
              sorting: {
                sortModel: [{ field: "closeDate", sort: "asc" }]
              }
            }}
          />
        </div>
        <ReminderEditModal
          isOpen={isReminderModalOpen}
          reminder={reminder}
          onClose={this.onCloseReminderModal}
          salesReps={this.props.salesReps}
          canSelectType
        />
        <AddCustomerNoteModal
          isOpen={isCustomerNoteModalOpen}
          customer={noteCustomer}
          noteText={customerNoteText}
          onChange={this.onCustomerNoteTextChange}
          onClose={this.closeCustomerNoteModal}
        />
        <OpportunityEditModal
          isOpen={isOpportunityModalOpen}
          opportunity={opportunity}
          onClose={this._saveOpportunity}
        />
      </>
    );
  }
}

export const MySalesPipeline = SalesRepHomeService.inject(
  OpportunityService.inject(
    ReminderService.inject(
      _MySalesPipeline
    )
  )
);
