import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
} from "$Imports/Imports";

import {
  CustomerSource,
  CustomerSourceApiFactory
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

// prevent controlled/uncontrolled input warnings
export const EMPTY_CUSTOMER_SOURCE: CustomerSource = {
  id: 0,
  name: "",
  requiresAdditionalDetail: false
};

interface ICustomerSourceServiceState {
  customerSourceFetchResults: IAjaxState<CustomerSource[]>;
  activeCustomerSources: CustomerSource[];
  customerSourceSaveResult: IAjaxState<CustomerSource>;
}

const InjectedPropName = "customerSourceService";

const initialState = {
  customerSourceFetchResults: managedAjaxUtil.createInitialState(),
  activeCustomerSources: [],
  customerSourceSaveResult: managedAjaxUtil.createInitialState()
} as ICustomerSourceServiceState;

class CustomerSourceFreezerService extends FreezerService<ICustomerSourceServiceState, typeof InjectedPropName> {

  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchCustomerSources(filterActive: boolean = false, forceUpdate: boolean = false) {
    const {
      customerSourceFetchResults: customerSourceFetchResults,
    } = this.freezer.get();

    if (customerSourceFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "customerSourceFetchResults",
      params: {
        activeOnly: filterActive
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerSourceApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getCustomerSources(params);
      },
      onOk: (data: CustomerSource[]) => {
        const activeCustomerSources = data.filter(i => i.isActive);
        this.freezer.get().set({ activeCustomerSources });
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch customer sources.");
      }
    });
  }

  @bind
  public saveCustomerSource(customerSource: CustomerSource): Promise<CustomerSource | void> {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "customerSourceSaveResult",
      params: {
        body: customerSource
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerSourceApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);

        if (!!customerSource.id && customerSource.id > 0) {
          return factory.updateCustomerSource(params);
        } else {
          return factory.addCustomerSource(params);
        }
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body.message ?? "Failed to save customer source.");
      }
    });
  }
}

export const CustomerSourceService = new CustomerSourceFreezerService();
export type ICustomerSourceServiceInjectedProps = ReturnType<CustomerSourceFreezerService["getPropsForInjection"]>;