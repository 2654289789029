import { Zone } from "$Generated/api";

import {
  React,
  bind,
  _,
  memoizeOne
} from "$Imports/Imports";

import {
  DialogActions,
  DialogContent,
  Dialog,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox
} from "$Imports/MaterialUIComponents";

interface IZoneSelectionModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onSave: (zone: Zone[] | undefined) => void;
  onSelectedChange: (selectedZones: Zone[]) => void;
  zones: Zone[];
  selected: Zone[];
}

const AllZones = "All Zones";

export class ZoneSelectionModal extends React.PureComponent<IZoneSelectionModalProps> {
  @bind
  private isAllZonesSelected(selectedZones: Zone[], allZones: Zone[]): boolean {
    return selectedZones.length === allZones.length;
  }

  @bind
  private isChecked(selectedZones: Zone[]): number[] {
    return _.map(selectedZones, (z) => z.id!);
  }

  private readonly isAllStatesSelected_Memoized = memoizeOne(this.isAllZonesSelected);
  private readonly isChecked_Memoized = memoizeOne(this.isChecked);

  @bind
  private _onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  @bind
  private _onSave() {
    if (this.props.onSave) {
      const selection = this.props.zones.filter(x => this.isChecked_Memoized(this.props.selected).includes(x.id!));
      this.props.onSave(selection.length > 0 ? selection : undefined);
    }
  }

  private handleItemClick = (value: number) => () => {
    const checked = this.isChecked_Memoized(this.props.selected);
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    const selectedZones = this.props.zones.filter(x => newChecked.includes(x.id!));
    if (this.props.onSelectedChange) {
      this.props.onSelectedChange(selectedZones);
    }
  }

  @bind
  private handleAllZonesClick() {
    const allZonesSelected = !this.isAllStatesSelected_Memoized(this.props.selected, this.props.zones);
    const allZones = this.props.zones.map(x => x.id!);
    const checked = this.isChecked_Memoized(this.props.selected);
    var newChecked = [...checked];

    if (allZonesSelected) {
      newChecked = [...Array.from(new Set([...checked, ...allZones]))];
    } else {
      newChecked = [];
    }
    const selectedZones = this.props.zones.filter(x => newChecked.includes(x.id!));

    if (this.props.onSelectedChange) {
      this.props.onSelectedChange(selectedZones);
    }
  }

  render() {
    const { zones, selected } = this.props;

    const allZoneId = zones.length + 2;
    return (
      <Dialog open={this.props.isOpen}>
        <DialogContent >
          <List
            style={{
              width: "300px",
              overflowX: "hidden"
            }}
          >
            <ListItem key={allZoneId} dense button onClick={this.handleAllZonesClick} style={{ paddingBottom: '0%' }} >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={this.isAllStatesSelected_Memoized(selected, zones)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': AllZones }}
                />
                <ListItemText id={AllZones} primary={AllZones} style={{ paddingTop: '8%' }} />
              </ListItemIcon>
            </ListItem>
            {zones.length && zones.map((value: Zone) => (
              <ListItem key={value.id} dense button onClick={this.handleItemClick(value.id!)} style={{ paddingLeft: '20%', height: "35px" }}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={!!_.find(selected, sr => sr.id == value.id)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': value.zoneName }}
                  />
                  <ListItemText id={value.zoneName} primary={value.zoneName} style={{ paddingTop: '8px' }} />
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this._onSave}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={this._onCancel}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog >
    );
  }
}
