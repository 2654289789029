import {
  bind,
  React
} from "$Imports/Imports";

import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  Stack
} from "$Imports/MaterialUIComponents";

import {
  Customer, CustomerSearchCriteria
} from "$Generated/api";

import {
  DisplayFormattedDatetime,
  CustomerLink,
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import {
  DEFAULT_CUSTOMER_SEARCH,
  ISalesRepHomeServiceInjectedProps,
  SalesRepHomeService
} from "$State/SalesRepHomeFreezerService";

import {
  DATE_ONLY_FORMAT,
  CURRENCY_NO_DECIMAL_FORMAT
} from "$Shared/utilities/formatUtil";

import {
  getPercentageToCloseNumber,
  industryTypeTextMap
} from "$Utilities/enumUtil";

import {
  MyCustomersSearchForm
} from "./MyCustomersSearchForm";

const styles: {
  gridContainer: string;
  searchFormContainer: string;
  noRows: string;
} = require("./MyQuotes.scss");

interface IMyProspectsState {}

interface IMyProspectsBaseProps {}

type IMyQuotesProps = IMyProspectsBaseProps
  & ISalesRepHomeServiceInjectedProps;

class _MyProspects extends React.Component<IMyQuotesProps, IMyProspectsState> {

  private readonly columns: GridColDef[] = [
    {
      headerName: "Prospect Name",
      field: "customerName",
      flex: 2,
      valueGetter: (params: GridValueGetterParams<string, Customer>) => {
        return params.value;
      },
      renderCell: (params: GridRenderCellParams<string, Customer>) => {
        return (
          <CustomerLink
            customerId={params.row.id}
            customerName={params.value}
            isProspect
          />
        );
      }
    }, {
      headerName: "City",
      field: "city",
      flex: 1
    }, {
      headerName: "State",
      field: "regionId",
      width: 50,
      valueGetter: (params: GridValueGetterParams<number, Customer>) => {
        return params.row.region?.regionAbbreviation;
      }
    }, {
      headerName: "Phone Number",
      field: "phoneNumber",
      minWidth: 120,
      flex: 1
    }, {
      headerName: "Industry Type",
      field: "industryType",
      valueGetter: (params: GridValueGetterParams<any, Customer>) => industryTypeTextMap[params.row.prospect?.industryType ?? ""],
      minWidth: 115,
      flex: 2
    }, {
      headerName: "Current Provider",
      field: "currentProvider",
      valueGetter: (params: GridValueGetterParams<any, Customer>) => params.row.prospect?.currentProvider ?? "",
      flex: 1
    }, {
      headerName: "Start Date",
      field: "startDate",
      width: 100,
      valueGetter: (params: GridValueGetterParams<any, Customer>) =>
        params.row.prospect?.startDate,
      renderCell: (params: GridRenderCellParams<Date | undefined>) =>
        params.row.prospect?.startDate ? <DisplayFormattedDatetime value={params.row.prospect?.startDate} formatString={DATE_ONLY_FORMAT} /> : "",
    }, {
      headerName: "% to Close",
      field: "percentToClose",
      width: 85,
      valueGetter: (params: GridValueGetterParams<any, Customer>) => getPercentageToCloseNumber(params.row.prospect?.percentageToClose),
      renderCell: (params: GridRenderCellParams<number>) => `${params.value}%`,
    }, {
      headerName: "Est Revenue",
      field: "estAvgMonthlyFreightBills",
      minWidth: 125,
      maxWidth: 280,
      flex: 1,
      valueGetter: (params: GridValueGetterParams<any, Customer>) => {
        const monthlyBills = params.row.prospect?.estAvgMonthlyFreightBills;
        const revPerBill = params.row.prospect?.estAvgRevenuePerFreightBill;
        return monthlyBills && revPerBill ? monthlyBills * revPerBill : undefined;
      },
      renderCell: (params: GridRenderCellParams<number | undefined>) => {
        return params.value ? <DisplayFormattedNumber value={params.value} formatString={CURRENCY_NO_DECIMAL_FORMAT} /> : "";
      },
    }
  ];

  componentDidMount() {    
    this.props.salesRepHomeService.update({
      customerSearchCriteria: { ...DEFAULT_CUSTOMER_SEARCH, isProspect: true }
    });
    this.props.salesRepHomeService.fetchCustomers(true);
  }

  @bind
  private async _onSearchSubmit(search: CustomerSearchCriteria): Promise<void> {
    this.props.salesRepHomeService.update({
      customerSearchCriteria: { ...search }
    });

    await this.props.salesRepHomeService.fetchCustomers(true);
  }

  render() {
    const {
      customerFetchResults
    } = this.props.salesRepHomeService.getState();

    const customerData = customerFetchResults.data ?? [];
    const gridOverlay = customerFetchResults.hasFetched ?
    customerFetchResults.data?.length === 0 ? `No prospects found` : ""
      : "No prospects assigned";

    return (
      <div className={styles.gridContainer}>
        <div className={styles.searchFormContainer}>
          <MyCustomersSearchForm
            isProspect
            onSubmit={this._onSearchSubmit}
          />
        </div>

        <DataGridPro
          rows={customerData}
          columns={this.columns}
          density="compact"
          hideFooter
          initialState={{
            sorting: {
              sortModel: [{ field: "startDate", sort: "asc" }]
            }
          }}
          components={{
            NoRowsOverlay: () => 
              <Stack className={styles.noRows}>
                {gridOverlay}
              </Stack>
          }}
        />
      </div>
    )
  }
}

export const MyProspects = SalesRepHomeService.inject(
  _MyProspects
);
