import {
  React,
  Helpers,
  bind,
  _
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  AjaxActionIndicator,
  ConfirmDeleteModal,
  ThreeDigitZipEntry
} from "$Imports/CommonComponents";

import {
  Zone,
  Region,
  ZoneRegion
} from "$Generated/api";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  Typography,
  TextField
} from "$Imports/MaterialUIComponents";

import {
  formModeType,
  ZoneModalState
} from "$State/ZoneFreezerService";

import {
  qualifyZipPostalCodeInput
} from "$Shared/utilities/helpers";

const styles: {
  container: string;
} = require("./AddEditZone.scss");

interface IAddEditZoneProps {
  formMode: formModeType;
  zone: Zone | null;
  zoneModalState: ZoneModalState;
  zoneModalValidationErrors: ValidationError | null;
  isFetching?: boolean;
  zoneRegionsWithDuplicateZips?: ZoneRegion[] | null | undefined;
  onSave?: () => void;
  onCancel?: () => void;
  onZoneChange?: (zoneChange: Partial<Zone>) => void;
  onZipCodeDelete?: (zipCode: string) => void;
  onZipCodeEnter?: () => void;
  onZipCodeChange?: (zipcode: string) => void;
  onPrimaryZipCodeChange?: (zipCode: string) => void;
  onSecondaryZipCodeChange?: (zipCode: string) => void;
  onDeleteConfirm?: () => void;
  onDeleteCancel?: () => void;
  regionData: Region[];
  duplicateZipsFound: boolean;
}

//TODO: implement add zone in future story.

export class AddEditZone extends React.PureComponent<IAddEditZoneProps> {
  static defaultProps: Partial<IAddEditZoneProps> = {
    isFetching: false,
  };

  @bind
  private _onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  @bind
  private _onZipCodeChange(value: string) {
    const zip = qualifyZipPostalCodeInput(value, this.props.zoneModalState.isCanadianZone, true);
    if (this.props.onZipCodeChange) {
      this.props.onZipCodeChange(zip);
    }
  }

  @bind
  private _onZipCodeEnter() {
    if (this.props.onZipCodeEnter) {
      this.props.onZipCodeEnter();
    }
  }

  @bind
  private _onZipCodeDelete(zipCode: string) {
    if (this.props.onZipCodeDelete) {
      this.props.onZipCodeDelete(zipCode);
    }
  }

  @bind
  private _onPrimaryZipCodeChange(event: React.ChangeEvent<HTMLInputElement>) {
    const zip = qualifyZipPostalCodeInput(event.target.value, this.props.zoneModalState.isCanadianZone);
    if (this.props.onPrimaryZipCodeChange) {
      this.props.onPrimaryZipCodeChange(zip);
    }
  }

  @bind
  private _onSecondaryZipCodeChange(event: React.ChangeEvent<HTMLInputElement>) {
    const zip = qualifyZipPostalCodeInput(event.target.value, this.props.zoneModalState.isCanadianZone);
    
    if (this.props.onSecondaryZipCodeChange) {
      this.props.onSecondaryZipCodeChange(zip);
    }
  }

  @bind
  private _onDeleteConfirm() {
    if (this.props.onDeleteConfirm) {
      this.props.onDeleteConfirm();
    }
  }

  @bind
  private _onDeleteCancel() {
    if (this.props.onDeleteCancel) {
      this.props.onDeleteCancel();
    }
  }

  @bind
  private _onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (this.props.onSave) {
      this.props.onSave();
    }
  }

  render() {
    const {
      formMode,
      zone,
      isFetching,
      zoneModalState,
      zoneModalValidationErrors,
      zoneRegionsWithDuplicateZips,
      duplicateZipsFound,
      regionData: stateData
    } = this.props;

    let states: string = "";
    const threeDigitZips: string[] = zoneModalState.editedZipCodes?.filter(zc => zc !== undefined).sort() as string[];

    const validationsParser = new ValidationErrorParser<ZoneModalState>(zoneModalValidationErrors);

    if(zone && zone.zoneRegions) {
      states = zone.zoneRegions
        .filter((r) => r.zipPostalCode === null)
        .map((r) => { if(r.region) return r.region.regionName })
        .join(', ');
    }

    const deleteMessage = 
      <Typography variant="body1">
        <div>The following zip codes were found in other zones: </div>
          {zoneRegionsWithDuplicateZips?.map((zR) => { return <div style={{textAlign:"center"}}>{zR.zipPostalCode}</div>})}
        <div>Do you wish to delete these zip codes from the zones they currently are in?</div>
      </Typography>

    return !Helpers.isNullOrUndefined(zone) && !Helpers.isNullOrUndefined(stateData) ? (
    <>
      <Dialog
        open={formMode === "add" || formMode === "edit"}
        fullWidth={true}
        maxWidth="sm"
      >
        <form onSubmit={this._onSubmit}>
          <AjaxActionIndicator
            showProgress={isFetching}
          />
          <DialogTitle>
            {formMode === "add" ? "Add Zone" : `Edit Zone`}
          </DialogTitle>
          <DialogContent>
            <Typography variant="h6">
              <div>Zone: {zone?.zoneName ?? ""}</div>
              <div>States: {states}</div>
            </Typography>
          </DialogContent>
          <DialogContent
            className={styles.container}
          >
            <Grid container direction={"column"} spacing={3}>
              <Grid item>
                <ThreeDigitZipEntry
                  threeDigitZips={threeDigitZips}
                  newZipCode={zoneModalState.newZipCode ?? ""}
                  textFieldError={!validationsParser.isValid("newZipCode")}
                  helperText={validationsParser.validationMessage("newZipCode")}
                  onZipCodeChange={this._onZipCodeChange}
                  onZipCodeEnter={this._onZipCodeEnter}
                  onZipCodeDelete={this._onZipCodeDelete}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Primary Zipcode"
                  value={zoneModalState.primaryZipCode ?? zone?.primaryZipPostalCode ?? ""}
                  onChange={this._onPrimaryZipCodeChange}
                  error={!validationsParser.isValid("primaryZipCode")}
                  helperText={validationsParser.validationMessage("primaryZipCode")}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Secondary Zipcode"
                  value={zoneModalState.secondaryZipCode ?? zone?.secondaryZipPostalCode ?? ""}
                  onChange={this._onSecondaryZipCodeChange}
                  error={!validationsParser.isValid("secondaryZipCode")}
                  helperText={validationsParser.validationMessage("secondaryZipCode")}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={isFetching}
              type="submit"
            >
              {formMode === "add" ? "Add" : "Save"}
            </Button>
            <Button
              variant="outlined"
              onClick={this._onCancel}
              disabled={isFetching}
            >
              Cancel
          </Button>
          </DialogActions>
        </form>
      </Dialog >
      <ConfirmDeleteModal
        isOpen={duplicateZipsFound}
        onDelete={this._onDeleteConfirm}
        onDeleteCancel={this._onDeleteCancel}
        deleteMessage={""}
        deleteTypography={deleteMessage}
      />
    </>
    ) : null;
  }
}


