import {
  React,
  bind,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  LeadSource
} from "$Generated/api";

import {
  AdvanceTextField,
  DateRangePicker
} from "$Imports/CommonComponents";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox
} from "$Imports/MaterialUIComponents";

import {
  LeadSourceSchema
} from "$State/LeadSourceFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

const styles: {
  dialogContent: string;
  nameField: string;
  requiredBox: string;
} = require("./LeadSourceViewPage.scss");

interface ILeadSourceModalProps {
  isOpen: boolean;
  leadSource: LeadSource | undefined;
  onSave: () => void;
  onCancel: () => void;
  onChange: (leadSource: Partial<LeadSource>) => void;
}

interface IOwnState {
  validationErrors: ValidationError | null;
}

export class LeadSourceModal extends React.PureComponent<ILeadSourceModalProps, IOwnState> {
  state: IOwnState = {
    validationErrors: null
  };

  @bind
  private async _onSave() {
    const errors = await validateSchema(LeadSourceSchema, this.props.leadSource);
    this.setState({ validationErrors: errors });

    if (!errors) {
      this.props.onSave();
    }
  }

  @bind
  private _onCancel() {
    this.setState({ validationErrors: null });

    this.props.onCancel();
  }

  @bind
  private _onNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.onChange({ name: event.target.value });
  }

  @bind
  private _onDetailsRequiredChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.onChange({ isDetailRequired: checked });
  }

  @bind
  private _onDateRangeChange(start: Date | null, end: Date | null) {
    this.props.onChange({
      startDate: start ? moment(start).startOf('day').toDate() : undefined,
      endDate: end ? moment(end).endOf('day').toDate() : undefined
    });
  }

  render() {
    const {
      isOpen,
      leadSource
    } = this.props;

    const { validationErrors } = this.state;

    const validationsParser = new ValidationErrorParser<LeadSource>(validationErrors);

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>
          {leadSource?.id ? "Edit" : "Add"} Lead Source
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <AdvanceTextField
            label="Source Name"
            name="name"
            required
            value={leadSource?.name ?? ""}
            onChange={this._onNameChange}
            error={!validationsParser.isValid("name")}
            helperText={validationsParser.validationMessage("name")}
            className={styles.nameField}
          />
          <FormControlLabel
            label="Lead Details Required?"
            className={styles.requiredBox}
            control={(
              <Checkbox
                checked={leadSource?.isDetailRequired ?? false}
                onChange={this._onDetailsRequiredChange}
                name="isDetailRequired"
              />
            )}
          />
          <div style={{ display: "inline-flex", gap: "0.5rem" }}>
            <DateRangePicker
              startDate={leadSource?.startDate}
              startLabel="Start Date"
              endDate={leadSource?.endDate}
              endLabel={"End Date"}
              onChange={this._onDateRangeChange}
              startError={validationsParser.validationMessage("startDate")}
              endError={validationsParser.validationMessage("endDate")}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this._onSave}
          >
            Save
          </Button>
          <Button
            onClick={this._onCancel}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}