import {
  React,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  EquipmentType,
  OverdimensionalRuleset
} from "$Generated/api";

import {
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  FormHelperText
} from "$Imports/MaterialUIComponents";

import {
  EMPTY_EQUIPMENT_TYPE,
  EquipmentTypeValidationSchema
} from "$State/EquipmentTypeFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

const styles: {
  modalContainer: string;
} = require("$Shared/styles/Modal.scss");

interface IEditEquipmentTypeProps {
  isOpen: boolean;
  canSave: boolean;
  equipmentType?: EquipmentType;
  overdimensionalRulesets: OverdimensionalRuleset[];
  onClose: (value?: EquipmentType) => void;
}

interface IOwnState {
  equipmentType: EquipmentType;
  validationErrors: ValidationError | null;
}

export class EditEquipmentTypeModal extends React.PureComponent<IEditEquipmentTypeProps, IOwnState> {
  state: IOwnState = {
    equipmentType: { ...EMPTY_EQUIPMENT_TYPE },
    validationErrors: null
  };

  componentDidUpdate(prev: IEditEquipmentTypeProps) {
    if (this.props.equipmentType !== prev.equipmentType) {
      this.setState({
        equipmentType: {
          ...this.props.equipmentType,
        },
        validationErrors: null
      });
    }
  }

  @bind
  private _onActiveChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.setState((prev) => ({
      equipmentType: {
        ...prev.equipmentType,
        isActive: checked
      }
    }));
  }

  @bind
  private _onTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState((prev) => ({
      equipmentType: {
        ...prev.equipmentType,
        [event.target.name]: event.target.value
      }
    }));
  }

  @bind
  private _onOverdimensionalRulesetChange(event: SelectChangeEvent<number>): void {
    this.setState((prev) => ({
      equipmentType: {
        ...prev.equipmentType,
        overdimensionalRulesetId: Number(event.target.value)
      }
    }));
  }

  @bind
  private async _onClose(shouldSave: boolean) {
    const { onClose } = this.props;
    const { equipmentType } = this.state;

    if (!shouldSave) {
      onClose();
      return;
    }

    const errors = await validateSchema(EquipmentTypeValidationSchema, equipmentType);
    this.setState({
      validationErrors: errors
    });

    if (errors) {
      return;
    }

    onClose(equipmentType);
  }

  render() {
    const {
      canSave,
      isOpen,
      overdimensionalRulesets
    } = this.props;

    const {
      equipmentType,
      validationErrors
    } = this.state;

    const validationsParser = new ValidationErrorParser<EquipmentType>(validationErrors);

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>
          {equipmentType.id ? "Edit Equipment" : "Add Equipment"}
        </DialogTitle>
        <DialogContent
          className={styles.modalContainer}
        >
          <FormControlLabel
            control={
              (
                <Switch
                  color="primary"
                  checked={equipmentType.isActive ?? false}
                  onChange={this._onActiveChange}
                />
              )
            }
            label="Active"
          />
          <AdvanceTextField
            label="Name *"
            name="name"
            value={equipmentType.name ?? ""}
            onChange={this._onTextChange}
            error={!validationsParser.isValid("name")}
            helperText={validationsParser.validationMessage("name")}
            style={{ marginTop: "0.5rem" }}
            fullWidth
          />
          <AdvanceTextField
            label="TruckMate Equipment ID *"
            name="tmEquipmentCode"
            value={equipmentType.tmEquipmentCode ?? ""}
            onChange={this._onTextChange}
            error={!validationsParser.isValid("tmEquipmentCode")}
            helperText={validationsParser.validationMessage("tmEquipmentCode")}
            inputProps={{ maxLength: 10 }}
            style={{ marginTop: "0.5rem", width: "12rem" }}
          />
          <FormControl
            error={!validationsParser.isValid("overdimensionalRulesetId")}
            style={{ marginTop: "0.5rem", width: "16rem" }}
            fullWidth
          >
            <InputLabel>Over-Dimensional Ruleset *</InputLabel>
            <Select
              value={equipmentType.overdimensionalRulesetId ?? ""}
              name="overdimensionalRulesetId"
              onChange={this._onOverdimensionalRulesetChange}
              required
            >
              {overdimensionalRulesets.map((x, idx) => (
                <MenuItem value={x.id} key={idx}>{x.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{validationsParser.validationMessage("overdimensionalRulesetId")}</FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => this._onClose(true)}
            disabled={!canSave}
          >
            Save
          </Button>
          <Button
            onClick={() => this._onClose(false)}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}