import {
  React,
  bind
} from "$Imports/Imports";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button
} from "$Imports/MaterialUIComponents";

import {
  ButtonColorThemeType,
  ButtonVariantType,
  DialogMaxWidthType
} from "$Imports/MaterialUIStyles";

interface IBinaryChoiceDialogProps {
  isOpen: boolean;
  maxWidth?: DialogMaxWidthType;
  title?: string;
  message: string;
  trueVariant?: ButtonVariantType;
  trueColor?: ButtonColorThemeType;
  trueText: string;
  falseVariant?: ButtonVariantType;
  falseColor?: ButtonColorThemeType;
  falseText: string;
  onClick: (answer: boolean) => void;
}

export class BinaryChoiceDialog extends React.PureComponent<IBinaryChoiceDialogProps> {
  @bind
  private _onClickTrue() {
    this.props.onClick(true);
  }

  @bind
  private _onClickFalse() {
    this.props.onClick(false);
  }

  render() {
    const {
      isOpen,
      maxWidth = "xs",
      title,
      message,
      trueVariant = "contained",
      trueColor = "primary",
      trueText,
      falseVariant = "outlined",
      falseColor,
      falseText
    } = this.props;

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={maxWidth}
      >
        {title &&
        <DialogTitle>
          {title}
        </DialogTitle>}
        <DialogContent>
          {message}
        </DialogContent>
        <DialogActions>
          <Button
            variant={trueVariant}
            onClick={this._onClickTrue}
            color={trueColor}
          >
            {trueText}
          </Button>
          <Button
            variant={falseVariant}
            onClick={this._onClickFalse}
            color={falseColor}
          >
            {falseText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
