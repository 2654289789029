import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  AdvanceTextField,
  AjaxActionIndicator
} from "$Imports/CommonComponents";

import {
  CityStateSearch,
  CityStateService,
  ICityStateFreezerServiceInjectedProps,
  IndexedPlace
} from "$State/CityStateFreezerService";

import { 
  ValidationErrorParser 
} from "$Utilities/ValidationErrorParser";

import {
  IconButton,
  Button,
  DataGridPro,
  GridColDef,
  GridValueGetterParams,
  GridRowParams,
  MuiEvent,
  isEnterKey,
  GridApiPro,
  GridCellParams
} from "$Imports/MaterialUIComponents";

import {
  Clear,
  Search
} from "$Imports/MaterialUIIcons";

import {
  getFormattedZipPostalCode,
  getTrimmedZipPostalCode
} from "$Shared/utilities/helpers";

const styles: {
  clearButton: string,
  clearIcon: string,
  searchButton: string,
  noResults: string,
  resultsTable: string,
  headerRow: string
} = require("./CityStateSearchResults.scss"); 

interface ICityStateSearchResultsBaseProps {
  onEnterPressWithSelectedRow: () => void;
}

type ICityStateSearchResultsProps = ICityStateSearchResultsBaseProps & ICityStateFreezerServiceInjectedProps;

const headerHeight = 41;
const rowHeight = 40;
const pageRowCount = 9;

class _CityStateSearchResults extends React.Component<ICityStateSearchResultsProps> {

  componentWillUnmount() {
    this.props.CityStateService.clearFreezer();
  }

  private readonly _columns: GridColDef[] = [
    {
      headerName: "City",
      field: "city",
      flex: 1,
      sortable: false
    },
    {
      headerName: "State",
      field: "stateProvince",
      flex: 1,
      sortable: false
    },
    {
      headerName: "Postal Code",
      field: "zipPostalCode",
      flex: 1,
      minWidth: 100,
      valueGetter: (params: GridValueGetterParams) => {
        return getFormattedZipPostalCode(params.row) ?? params.value;
      },
      sortable: false
    },
    {
      headerName: "County",
      field: "county",
      flex: 1,
      sortable: false
    }
  ];

  @bind
  private _onCityStateChanged(e: React.ChangeEvent<{ name: string; value: string; }>) {
    this.props.CityStateService.updateSearchCriteria(e.target.value);
  }

  @bind
  private _clearCityState() {
    this.props.CityStateService.updateSearchCriteria("");
  }

  @bind
  private async _onSearchClick() {
    await this.props.CityStateService.onSearchClick();
  }

  @bind
  private _searchFieldOnKeyPress(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === "Enter") {
      this._onSearchClick();
    }
  }
  
  @bind
  private _selectPlace(row: IndexedPlace, shouldSubmit?: boolean) {
    const selectedRow = {
          ...row,
          zipPostalCode: getTrimmedZipPostalCode(row.zipPostalCode)
        };
        this.props.CityStateService.setSelectedRow(selectedRow);

    if (shouldSubmit && this.props.onEnterPressWithSelectedRow) {
      this.props.onEnterPressWithSelectedRow();
    }
  }

  @bind
  private _onRowClick(params: GridRowParams<IndexedPlace>, event: MuiEvent<React.MouseEvent>) {
    this._selectPlace(params.row);
    event.defaultMuiPrevented = true;
  }

  @bind
  private _onRowDoubleClick(params: GridRowParams<IndexedPlace>, event: MuiEvent<React.MouseEvent>) {
    this._selectPlace(params.row, true);
    event.defaultMuiPrevented = true;
  }

  @bind
  private _resultsOnKeyPress(params: GridCellParams<any, IndexedPlace>, event: MuiEvent<React.KeyboardEvent>) {
    if (isEnterKey(event.key)) {
      this._selectPlace(params.row, true);
    }
    if ((event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'PageDown' || event.key === 'PageUp')) {
      const { indexedPlaces } = this.props.CityStateService.getState();
      const rowIdx = params.row.rowIdx ?? 0;
      const maxIdx = indexedPlaces.length - 1;
      let nextIdx: number | undefined;
      if (event.key === "ArrowDown") {
        nextIdx = rowIdx + 1 > maxIdx ? maxIdx : rowIdx + 1;
      } else if (event.key === "ArrowUp") {
        nextIdx = rowIdx - 1 < 0 ? 0 : rowIdx - 1;
      } else if (event.key === "PageDown") {
        nextIdx = rowIdx + pageRowCount > maxIdx ? maxIdx : (params?.row?.rowIdx ?? 0) + pageRowCount;
      } else if (event.key === "PageUp") {
        nextIdx = rowIdx - pageRowCount < 0 ? 0 : (params?.row?.rowIdx ?? 0) - pageRowCount;
      }
      
      if (nextIdx !== undefined) {
        this._selectPlace(indexedPlaces[nextIdx]);
      }
    } else {
      event.defaultMuiPrevented = true;
    }
  }

  render() {
    const {
      searchCriteria,
      searchValidationErrors,
      searchResults,
      indexedPlaces,
      selectedRow
    } = this.props.CityStateService.getState();

    const validationsParser = new ValidationErrorParser<CityStateSearch>(searchValidationErrors);
    const gridHeight = indexedPlaces.length * rowHeight + headerHeight;

    return (
      <>
        <div style={{display: "flex"}}>
          <AdvanceTextField
            label="City, State (abbr.)" 
            onChange={this._onCityStateChanged}
            onKeyPress={this._searchFieldOnKeyPress}
            value={searchCriteria ?? ""}
            autoFocus
            error={!validationsParser.isValid("cityStateSearchCriteria")} 
            helperText={validationsParser.validationMessage("cityStateSearchCriteria") || "Search by City, ST"}
          />
          <div className={styles.clearButton}>
            <IconButton
              size="small"
              onClick={this._clearCityState}     
            >
              <Clear className={styles.clearIcon} />
            </IconButton>
          </div>
          <div className={styles.searchButton}>
            <Button
              color="primary"
              disabled={searchResults.isFetching}
              onClick={this._onSearchClick}
            >
              <Search /> Search
            </Button>
          </div>
        </div>
        <AjaxActionIndicator
          state={[searchResults]}
        />
        <div className={styles.resultsTable} style={{height: `${gridHeight}px`}}>
          {(searchResults.hasFetched && indexedPlaces.length !== 0) &&
            <DataGridPro
              columns={this._columns}
              rows={indexedPlaces}
              density="compact"
              selectionModel={[selectedRow?.rowIdx ?? 1]}
              getRowId={(row: IndexedPlace) => row.rowIdx ?? 1}
              getRowHeight={() => 40}
              onCellKeyDown={this._resultsOnKeyPress}
              onRowClick={this._onRowClick}
              onRowDoubleClick={this._onRowDoubleClick}
              disableColumnFilter
              disableColumnMenu
              disableMultipleSelection
              hideFooter
              rowBuffer={10}
            />}
          {searchResults.hasFetched && indexedPlaces.length === 0 && <div className={styles.noResults}>No locations found</div>}
        </div>
      </>
    )
  }

}

export const CityStateSearchResults = CityStateService.inject (
  _CityStateSearchResults
);