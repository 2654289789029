import {
  _,
  moment,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf
} from "$Shared/imports/Yup";

import {
  Reminder,
  ReminderTypeEnum
} from "$Generated/api";

export const ReminderValidationSchema: SchemaOf<NullableOptional<Reminder>> = yup.object({
  id: yup.number().required(),
  customer: yup.object().nullable().notRequired(),
  quote: yup.object().nullable().notRequired(),
  dueDate: yup.date()
    .typeError("Invalid date")
    .required("Due Date is required")
    .min(moment().startOf('day').toDate(), "Due Date must be today or later")
    .test("dueDate", "${message}", (value: any, testContext: any) => {
      const timeEmpty = testContext.options.context.timeEmpty;
      return timeEmpty ? testContext.createError({ message: "Due time is required" }) : true;
    }),
  type: yup.mixed<ReminderTypeEnum>()
    .required("Type is required"),
  text: yup.string()
    .required("Description is required")
    .max(300, "Description can not exceed 300 characters"),
  createdById: yup.number().required("Sales Representative is required"),
  createdOn: yup.date().notRequired(),
  isComplete: yup.boolean().required(),
  isActive: yup.boolean().required(),
  createdByName: yup.string().nullable().notRequired()
});