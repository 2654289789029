import {
  moment,
  NullableOptional,
  _
} from "$Imports/Imports";

import {
  SchemaOf
} from "$Shared/imports/Yup";

import yup from "$Shared/utilities/yupExtension";

import {
  OtherFreightInfo
} from "./QuoteEntryFreezerService";

import {
  Quote,
  QuoteFreight,
  QuoteStatusEnum,
  QuoteStopFreightQuestionAnswerEnum,
  QuoteQuestionAnswerEnum,
  ZipCodePair,
  ZipCodeValidationResult,
  EquipmentType,
  QuoteCalculatedRateRateLevelFactorEnum,
  QuoteQuoteTypeEnum,
  QuoteCancellationReasonEnum,
  QuoteBillingStatusEnum
} from "$Generated/api";

interface FreightTotalData {
  totalNumOfPieces?: number;
  totalWeight?: number;
  totalLength?: number;
  ratingVariable?: QuoteCalculatedRateRateLevelFactorEnum;
  ratingError?: string;
  ratingVariableCalculatedAmount?: number;
  ratingVariableOverriddenAmount?: number;
  canRatingVariableBeOverriden?: boolean;
  isOverdimensional?: boolean;
  market?: QuoteMarket;
  equipmentType?: EquipmentType;
  isWeightOverdimensional?: boolean;
  overriddenRatingLength?: number;
}

type ResponseType = QuoteStopFreightQuestionAnswerEnum | QuoteQuestionAnswerEnum;
type QuoteMarket = "Primary" | "Secondary" | "None";

interface NegotiatedQuoteDataEntry {
  negotiatedRateValue?: number;
  notes?: string;
}

const QuoteSchema: SchemaOf<NullableOptional<Quote>> = yup.object({
  id: yup.number().notRequired(),
  quoteNumber: yup.number().notRequired(),
  companyId: yup.number().notRequired(),
  customerQuoteId: yup.number().notRequired().nullable(true),
  customerQuote: yup.object().notRequired().nullable(),
  customerId: yup.number().notRequired().nullable(true),
  billToId: yup.number().notRequired().nullable(true),
  createdById: yup.number().notRequired().nullable(true),
  reviewedById: yup.number().notRequired().nullable(true),
  finalizedById: yup.number().notRequired().nullable(true),
  contactName: yup.string().notRequired().max(50, "Max length can not exceed 50 characters").allowEmpty().nullable(),
  contactPhoneNumber: yup.string().notRequired().allowEmpty().phoneNumber("Invalid phone number").max(20, "Phone number cannot exceed 20 characters").nullable(),
  quoteDate: yup.date().notRequired(),
  deliveryDate: yup.date().notRequired(),
  isMarketPrimary: yup.boolean().notRequired().nullable(true),
  paymentCollected: yup.boolean().notRequired().nullable(true),
  negotiatedRate: yup.number().notRequired().nullable(true),
  upchargePercentage: yup.number().notRequired().transform((value: any) => value || undefined),
  flatUpcharge: yup.number().notRequired().transform((value: any) => value || undefined),
  otherFlatUpchargeReason: yup.boolean().notRequired().transform((value: any) => value || undefined),
  flatUpchargeReason: yup.string().notRequired().transform((value: any) => value || undefined),
  percentUpchargeReason: yup.string().notRequired().transform((value: any) => value || undefined),
  miles: yup.number().notRequired(),
  totalFreightLength: yup.number().notRequired().nullable(true),
  notes: yup.string().notRequired().nullable(true),
  acceptedMileage: yup.number().notRequired().nullable(true),
  acceptedRateEngineResults: yup.string().notRequired().nullable(true),
  status: yup.mixed<QuoteStatusEnum>().oneOf(["Accepted", "ApprovalNeeded", "Declined", "InProgress", "Pending", "Expired", "PendingNeedsCustomers", "AcceptanceRejected", "PendingResave"]).notRequired().nullable(true),
  billingStatus: yup.mixed<QuoteBillingStatusEnum>().oneOf(["CheckCredit", "CreditConfirmed", "CODOnly", null]).notRequired().nullable(true),
  quoteStatusHistories: yup.array().notRequired().nullable(true),
  expirationDate: yup.date().notRequired().nullable(true),
  equipmentTypeId: yup.number().notRequired().nullable(true),
  createdOn: yup.date().notRequired(),
  modifiedOn: yup.date().notRequired(),
  customer: yup.mixed().notRequired(),
  billTo: yup.mixed().notRequired(),
  createdBy: yup.mixed().notRequired(),
  carrierId: yup.number().notRequired().nullable(true),
  customerContactId: yup.number().notRequired().nullable(true), // validation is contingent on the status being set; handled in QuoteEntryFreezerService.save
  carrier: yup.mixed().notRequired().nullable(true),
  finalizedBy: yup.mixed().notRequired().nullable(true),
  reviewedBy: yup.mixed().notRequired().nullable(true),
  company: yup.mixed().notRequired(),
  equipmentType: yup.mixed().notRequired(),
  workflowState: yup.string().notRequired(),
  customerContact: yup.mixed().notRequired().nullable(true),
  declineReasonText: yup.string().notRequired().nullable(true),
  calculatedRates: yup.array().notRequired(),
  approvalReasons: yup.array().notRequired(),
  editHistory: yup.array().notRequired(),
  quoteType: yup.mixed<QuoteQuoteTypeEnum>().oneOf(["Full", "Quick", "Contract"]).required(),
  cancellationReason: yup.mixed<QuoteCancellationReasonEnum>().oneOf(["Customer", "Kaiser", "Other", null]).notRequired(),
  cancellationDetails: yup.string()
    .when("cancellationReason", {
      is: "Other",
      then: yup.string().required("Cancellation details are required.").max(200, "Max length can not exceed 200 characters").nullable(true),
      otherwise: yup.string().notRequired().max(200, "Max length can not exceed 200 characters").nullable(true)
    }),
  quoteQuestions: yup.array(yup.object({
    id: yup.number().notRequired(),
    quoteId: yup.number().notRequired(),
    questionId: yup.number().notRequired(),
    answer: yup.mixed<ResponseType>().oneOf(["Yes", "No", "NA"]).required("Answer must be provided")
  })),
  quoteFreights: yup.array().notRequired()
    .test("quoteFreights", "${message}", (value: QuoteFreight[] | undefined, testContext: any) => {
      const numberOfStops: number = testContext.options.context.numberOfStops;

      for (let stopNum = 1; stopNum <= numberOfStops; stopNum += 1) {
        const stopHasFreight = _.findIndex(value, f => f.quoteStop?.stopNumber === stopNum) >= 0;
        if (!stopHasFreight) {
          return testContext.createError({ message: "**There must be at least one commodity included in each stop's freight.**" });
        }
      }

      for (let freight of (value ?? [])) {
        if (freight.quoteStop?.stopNumber! > numberOfStops) {
          return testContext.createError({ message: "**A freight item has an invalid stop number associated to it.**" });
        }

        if (!freight.commodity?.isActive) {
          return testContext.createError({ message: "**A freight item has an inactive commodity.**" })
        }
      }

      return true;
    })
    .nullable(true),
  quoteStops: yup.array(yup.object({
    id: yup.number().notRequired(),
    quoteId: yup.number().notRequired(),
    ponumber: yup.string().notRequired().max(40, "PO Number cannot exceed 40 characters").allowEmpty(), // .nullable(true) does not work for nested fields
    externalNotes: yup.string().notRequired().allowEmpty(),
    siteId: yup.string().notRequired().allowEmpty(),
    opsCode: yup.string().notRequired().allowEmpty(),
    shipperStartDate: yup.date().required("Date is required")
      .test("shipperStartDate", "${message}", (value: Date | undefined, testContext: any) => {
        const currentDate = testContext.options.context.currentTime;
        const chosenDate = moment(value).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
        const chosenEndDate = moment(testContext.parent.shipperEndDate).set({ hours: 0, minutes: 0, seconds: 0 });
        const status = testContext.options.context.status;
        const startIsPast = chosenDate.isBefore(currentDate);
        const endIsPast = chosenEndDate.isBefore(currentDate);

        if ((!["Accepted", "Declined"].includes(status) && startIsPast)
          || (testContext.parent.shipperEndDate && endIsPast)
          || (!testContext.parent.shipperEndDate && startIsPast)) {
          return testContext.createError({ message: "Date cannot be in the past." });
        }

        return true;
      })
      .typeError("Invalid Date")
      .transform((value: any) => value && !isNaN(value.valueOf()) ? value : undefined),
    shipperEndDate: yup.date()
      .test("shipperEndDate", "${message}", (value: Date | undefined, testContext: any) => {
        const currentDate = testContext.options.context.currentTime;
        const chosenDate = moment(value).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

        if (chosenDate.isBefore(currentDate)) {
          return testContext.createError({ message: "Date cannot be in the past." });
        }

        return true;
      })
      .when('shipperStartDate', (shipperStartDate: Date, schema: any) => {
        return shipperStartDate ? schema.min(shipperStartDate, "The Shipping End Date must be after the Shipper Start Date")
          : schema.notRequired();
      })
      .typeError("Invalid Date")
      .transform((value: any) => value && !isNaN(value.valueOf()) ? value : undefined),
    isShipperAppointmentRequired: yup.boolean().notRequired(),
    consigneeStartDate: yup.date().required("Date is required")
      .test("consigneeStartDate", "${message}", (value: Date | undefined, testContext: any) => {
        const currentDate = testContext.options.context.currentTime;
        const chosenDate = moment(value).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

        if (chosenDate.isBefore(currentDate)) {
          return testContext.createError({ message: "Date cannot be in the past." });
        }

        return true;
      })
      .typeError("Invalid Date")
      .when('shipperStartDate', (shipperStartDate: Date, schema: any) => {
        return shipperStartDate && !isNaN(shipperStartDate.valueOf()) ? schema.min(yup.ref('shipperStartDate'), "The Consignee dates must begin after the shipper dates") 
        : schema.notRequired();
      })
      .transform((value: any) => value && !isNaN(value.valueOf()) ? value : undefined),
    consigneeEndDate: yup.date()
      .test("consigneeEndDate", "${message}", (value: Date | undefined, testContext: any) => {
        const currentDate = testContext.options.context.currentTime;
        const chosenDate = moment(value).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

        if (chosenDate.isBefore(currentDate)) {
          return testContext.createError({ message: "Date cannot be in the past." });
        }

        if (testContext.parent.consigneeEndDate && chosenDate.isBefore(testContext.parent.consigneeStartDate)) {
          return testContext.createError({ message: "The Consignee End Date must be after the Consignee Start Date" });
        }

        return true;
      })
      .when('shipperEndDate', (shipperEndDate: Date, schema: any) => {
        return shipperEndDate ? schema.min(shipperEndDate, "The Consignee End Date must be after the Shipper End Date")
          : schema.notRequired();
      })
      .typeError("Invalid Date")
      .transform((value: any) => value && !isNaN(value.valueOf()) ? value : undefined),
    isConsigneeAppointmentRequired: yup.boolean().notRequired(),
    shipperHardTime: yup.string()
      .when('isShipperAppointmentRequired', (isShipperAppointmentRequired: boolean, schema: any) => {
        return isShipperAppointmentRequired ? schema.required("Time is required if an appointment is required").timeHHmm()
          : schema.notRequired();
      })
      .transform((value: any) => value || undefined),
    consigneeHardTime: yup.string()
      .when('isConsigneeAppointmentRequired', (isConsigneeAppointmentRequired: boolean, schema: any) => {
        return isConsigneeAppointmentRequired ? schema.required("Time is required if an appointment is required").timeHHmm()
          : schema.notRequired();
      })
      .transform((value: any) => value || undefined),
    shipmentNotes: yup.string().notRequired().transform((value: any) => value || undefined),
    description: yup.string().notRequired().max(250, "Description cannot exceed 250 characters.").transform((value: any) => value || undefined),
    tarpId: yup.number().required("Tarp selection is required").transform((value: any) => value || undefined),
    declaredValue: yup.number().required("Declared value is required"),
    createdOn: yup.date().notRequired(),
    modifiedOn: yup.date().notRequired(),
    quote: yup.mixed().notRequired(),
    tarp: yup.mixed().notRequired(),
    addresses: yup.array(yup.object({
      id: yup.number().notRequired(),
      quoteStopId: yup.number().notRequired(),
      address1: yup.string().notRequired().allowEmpty(),
      address2: yup.string().notRequired().allowEmpty(),
      city: yup.string().notRequired().allowEmpty(),
      regionId: yup.number().notRequired().transform((value: any) => value || undefined),
      zipPostalCode: yup.string().required("Zip code is required").zipCode(),
      addressType: yup.mixed().notRequired(),
      createdOn: yup.date().notRequired(),
      modifiedOn: yup.date().notRequired(),
      quoteStop: yup.mixed().notRequired(),
      region: yup.mixed().notRequired()
    })),
    shipperContactId: yup.number().notRequired().transform((value: any) => value || undefined),
    consigneeContactId: yup.number().notRequired().transform((value: any) => value || undefined),
    quoteFreights: yup.array(yup.object({
      id: yup.number().notRequired(),
      quoteStopId: yup.number().notRequired(),
      width: yup.number()
        .typeError("Width is required.")
        .required("Width is required.")
        .min(1, "Width must be greater than 0."),
      height: yup.number()
        .typeError("Height is required.")
        .required("Height is required.")
        .min(1, "Height must be greater than 0."),
      length: yup.number()
        .typeError("Length is required.")
        .required("Length is required.")
        .min(1, "Length must be greater than 0."),
      weight: yup.number()
        .typeError("Weight is required.")
        .required("Weight is required.")
        .min(1, "Weight must be greater than 0."),
      commodityId: yup.number().required("Commodity is required.")
    }))
  })),
  accessorialChargeValues: yup.array().notRequired()
});

const PercentUpchargeResponseSchema: SchemaOf<string> = yup.string()
  .required("% upcharge reason is required")
  .max(250, "Upcharge reason cannot exceed 250 characters.");

const FlatUpchargeOtherReasonSchema: SchemaOf<string> = yup.string()
  .required("If Other Reason for Upcharge is Yes, you must provide a reason")
  .max(250, "Upcharge reason cannot exceed 250 characters.");

const FlatUpchargeResponsesSchema: SchemaOf<string[]> = yup.array().min(1, 'Must have one question responded Yes to')
  .required("Must have one question responded Yes to")
  .defined("Must have one question responded Yes to");

const ZipCodePairSchema: SchemaOf<ZipCodePair[]> = yup.array(yup.object({
  originZipPostalCode: yup.string()
    .required("Shipper zip code is required")
    .zipCode()
    .test("originZipPostalCode", "${message}", (value: string | undefined, testContext: any) => {
      const validateZips = testContext.options.context.validateZips;
      const pcMilerResults = (testContext.options.context.pcMilerResults ?? []) as ZipCodeValidationResult[];

      if (validateZips) {
        if (_.some(pcMilerResults, pc => !pc.isValid && pc.zipCode === value)) {
          const results = _.find(pcMilerResults, pc => pc.zipCode === value)
          return testContext.createError({ message: results?.error || "Failed to valid the shipper zip/postal code." })
        }
      }
      return true;
    }),
  destZipPostalCode: yup.string()
    .required("Consignee zip code is required")
    .zipCode()
    .test("destZipPostalCode", "${message}", (value: string | undefined, testContext: any) => {
      const validateZips = testContext.options.context.validateZips;
      const pcMilerResults = (testContext.options.context.pcMilerResults ?? []) as ZipCodeValidationResult[];

      if (validateZips) {
        if (_.some(pcMilerResults, pc => !pc.isValid && pc.zipCode === value)) {
          const results = _.find(pcMilerResults, pc => pc.zipCode === value)
          return testContext.createError({ message: results?.error || "Failed to valid the consignee zip/postal code." })
        }
      }
      return true;
    }),
}));

const NegotiatedQuoteDataEntrySchema: SchemaOf<NullableOptional<NegotiatedQuoteDataEntry>> = yup.object({
  negotiatedRateValue: yup.number()
    .test("negotiatedRateValue", "${message}", (value: number | undefined, testContext: any) => {
      const isOverdimensional: boolean = testContext.options.context.isOverdimensional;
      const quoteResponse: QuoteStatusEnum = testContext.options.context.quoteResponse;

      if (quoteResponse === "Declined" || (isOverdimensional && quoteResponse === "ApprovalNeeded")) {
        return true;
      }
      else if(quoteResponse === "InProgress" || quoteResponse === "AdminRerate") {
        return true;
      }
      else if (typeof value === "number" && value < 1) {
        return testContext.createError({ message: "The negotiated rate must be greater than 0" });
      }
      else if (!value) {
        return testContext.createError({ message: "A negotiated rate is required" });
      }

      return true;
    })
    .nullable(true),
  notes: yup.string().notRequired().nullable(true)
});

const DeclinedReasonSchema: SchemaOf<string> = yup.string().required("Declined reason is required").max(100, "Declined reason can not exceed 100 characters");

const QuoteFreightSchema: SchemaOf<QuoteFreight> = yup.object({
  id: yup.number().notRequired(),
  quoteStopId: yup.number().notRequired(),
  quoteId: yup.number().notRequired(),
  commodityId: yup.number().required("Commodity is required."),
  width: yup.number()
    .typeError("Width is required.")
    .required("Width is required.")
    .max(1200, "Width cannot exceed 100'")
    .min(1, "Width must be greater than 0."),
  height: yup.number()
    .typeError("Height is required.")
    .required("Height is required.")
    .max(1200, "Height cannot exceed 100'")
    .min(1, "Height must be greater than 0."),
  length: yup.number()
    .typeError("Length is required.")
    .required("Length is required.")
    .max(1200, "Length cannot exceed 100'")
    .min(1, "Length must be greater than 0."),
  weight: yup.number()
    .typeError("Weight is required.")
    .required("Weight is required.")
    .min(1, "Weight must be greater than 0."),
  description: yup.string().notRequired().max(250, "Freight description cannot exceed 250 characters.").transform((value: any) => value || undefined),
  isGrouped: yup.boolean().notRequired(),
  isStackable: yup.boolean().notRequired(),
  isSideBySide: yup.boolean().notRequired(),
  rotated: yup.boolean().notRequired(),
  numberOfPieces: yup.number().required("Number of pieces is required")
    .min(1, "There must be at least one piece of each commodity entered.").transform((value: any) => value && !isNaN(value.valueOf()) ? value : undefined),
  serialRefNumber: yup.string().notRequired().max(40, "Serial/ref # cannot exceed 40 characters.").transform((value: any) => value || undefined),
  createdOn: yup.date().notRequired(),
  modifiedOn: yup.date().notRequired(),
  commodity: yup.object().notRequired(),
  quoteStop: yup.object().notRequired(),
  quote: yup.mixed().notRequired().nullable(true),
  quoteStopFreightQuestions: yup.array(yup.object({
    id: yup.number().notRequired(),
    quoteStopFreightId: yup.number().notRequired(),
    questionId: yup.number().notRequired(),
    answer: yup.mixed<QuoteStopFreightQuestionAnswerEnum>().oneOf(["NA", "No", "Yes"]).required("Answer is required."),
    question: yup.object().notRequired(),
    quoteStopFreight: yup.object().notRequired()
  }))
});

const QuoteFreightArraySchema: SchemaOf<QuoteFreight[]> = yup.array(QuoteFreightSchema);

const RateVariableValidationSchema: SchemaOf<NullableOptional<FreightTotalData>> = yup.object({
  ratingVariable: yup.mixed<QuoteCalculatedRateRateLevelFactorEnum>().notRequired(),
  totalLength: yup.number().notRequired().transform((value: any) => value || undefined),
  totalNumOfPieces: yup.number().notRequired(),
  totalWeight: yup.number().notRequired(),
  ratingError: yup.string().notRequired().nullable(),
  ratingVariableCalculatedAmount: yup.number().notRequired(),
  ratingVariableOverriddenAmount: yup.number().notRequired().nullable().min(3, "Rate Variable value must be greater than 3."),
  canRatingVariableBeOverriden: yup.boolean().notRequired(),
  isOverdimensional: yup.boolean().notRequired(),
  isWeightOverdimensional: yup.boolean().notRequired(),
  market: yup.mixed<QuoteMarket>().oneOf(["Primary", "Secondary", "None"])
    .test("market", "${message}", (value: QuoteMarket | undefined, testContext: any) => {
      const companyKey: string = testContext.options.context.currentCompany;

      if (companyKey === "KT") {
        return true;
      } else if (companyKey === "KL" && value === "None") {
        return testContext.createError({ message: "Market type must be either Primary or Secondary" });
      }

      return true;
    })
    .nullable(true),
  equipmentType: yup.mixed<EquipmentType>().required("Equipment type is required"),
  overriddenRatingLength: yup.number().notRequired().allowNaN()
});

const OtherFreightInfoValidationSchema: SchemaOf<OtherFreightInfo[]> = yup.array(yup.object({
  id: yup.number().notRequired(),
  poNumber: yup.string().notRequired().max(40, "The PO Number must be less than or equal to 40 characters.").allowEmpty(),
  siteId: yup.string().notRequired().allowEmpty(),
  opsCode: yup.string().notRequired().allowEmpty(),
  externalNotes: yup.string().notRequired().max(500, "The External Notes must be less than or equal to 500 characters.").allowEmpty()
}));

export {
  DeclinedReasonSchema,
  FlatUpchargeResponsesSchema,
  FlatUpchargeOtherReasonSchema,
  NegotiatedQuoteDataEntrySchema,
  PercentUpchargeResponseSchema,
  QuoteFreightSchema,
  QuoteFreightArraySchema,
  QuoteSchema,
  RateVariableValidationSchema,
  ZipCodePairSchema,
  OtherFreightInfoValidationSchema,
  ResponseType,
  QuoteMarket,
  NegotiatedQuoteDataEntry,
  FreightTotalData
}
