import {
  _,
  NullableOptional,
  moment
} from "$Imports/Imports";

import {
  yup,
  SchemaOf
} from "$Shared/imports/Yup";

import {
  Tarp,
  TarpValue
} from "$Generated/api";

const TarpValidationSchema: SchemaOf<NullableOptional<Tarp>> = yup.object({
  id: yup.number().notRequired(),
  tarpName: yup.string()
    .required("Name is required")
    .max(100, "Name cannot be longer than 100 characters")
    .test("tarpName", "${message}", (value: any, testContext: any) => {
      const tarpId = testContext.options.context.tarpId;
      const existingNames = _.map(_.filter(testContext.options.context.tarps, t => t.id !== tarpId), "tarpName");

      if (_.includes(existingNames, value)) {
        return testContext.createError({ message: "Name already exists"});
      }
      return true;
    }),
  tarpDescription: yup.string().notRequired().nullable(),
  isActive: yup.boolean().notRequired(), // technically it is required but it's a boolean
  companyId: yup.number().notRequired().nullable(true),
  createdOn: yup.date().notRequired(),
  modifiedOn: yup.date().notRequired(),
  company: yup.mixed().notRequired(),
  tarpValues: yup.array()
});

const TarpRateValidationSchema: SchemaOf<NullableOptional<TarpValue>> = yup.object({
  id: yup.number().notRequired(),
  tarpId: yup.number().notRequired(),
  companyId: yup.number().notRequired(),
  startDateTime: yup.date()
    .typeError("Invalid date.")
    .required("Start date cannot be blank")
    .test("startDateTime", "${message}", (value: any, testContext: any) => {
      const openedAt = testContext.options.context.openedAt;

      if (moment(value).isBefore(openedAt)) {
        return testContext.createError({ message: "Start date must be in the future" });
      }

      return true;
    }),
  endDateTime: yup.date().nullable().notRequired(),
  createdOn: yup.date().notRequired(),
  modifiedOn: yup.date().notRequired(),
  company: yup.object().notRequired().nullable(),
  tarp: yup.mixed().notRequired().nullable(),
  rate: yup.number().when(['tarp', 'isActive'], {
    is: true,
    then: yup.number().required("Rate cannot be blank").moreThan(0, "Rate must be greater than $0.00."),
    otherwise: yup.number().required("Rate cannot be blank")
  })
});

const futureStartTarpRateValidationSchema: SchemaOf<NullableOptional<TarpValue>> = TarpRateValidationSchema.shape ({
  startDateTime: yup.date()
    .typeError("Invalid date.")
    .required("Start date cannot be blank")
    .test("startDateTime", "${message}", (value: any, testContext: any) => {
      const openedAt = testContext.options.context.openedAt;

      if (moment(value).isBefore(openedAt)) {
        return testContext.createError({ message: "Start date must be in the future" });
      }

      return true;
    })
});

const startNowTarpRateValidationSchema: SchemaOf<NullableOptional<TarpValue>> = TarpRateValidationSchema.shape ({
  startDateTime: yup.date().notRequired() // don't validate it at all
});

export {
  TarpValidationSchema,
  TarpRateValidationSchema,
  futureStartTarpRateValidationSchema,
  startNowTarpRateValidationSchema
}
