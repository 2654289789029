import {
  React,
  _,
  bind,
  NumberFormat,
  memoizeOne
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  AdvanceTextField,
  AjaxActionIndicator,
  FeetInputField,
  ConfirmCancelModal,
  DisplayFormattedFeet,
  DisplayFormattedNumber,
  OperationTimeoutModal
} from "$Imports/CommonComponents";

import {
  Commodity,
  EquipmentType,
  Question,
  Quote,
  QuoteFreight,
  QuoteStopFreightQuestion,
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  FormControlLabel,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Box,
  Card,
  CardActions,
  FormHelperText,
  SelectChangeEvent,
  DataGridPro,
  GridColDef,
  GridRowParams,
  GridValueGetterParams,
  GridRenderCellParams,
  Checkbox,
  Icon,
  Tooltip
} from "$Imports/MaterialUIComponents";

import {
  QuestionAnswerIcon,
  Delete,
  Edit,
  Error,
  ThreeSixty,
  AutoAwesomeMotion
} from "$Imports/MaterialUIIcons";

import {
  SecurityContext
} from "$Utilities/Security/ApplicationSecuritySettings";

import {
  FreightTotalData,
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps,
  QuoteMarket,
  Response,
} from "$State/QuoteEntryFreezerService";

import {
  ICommodityServiceInjectedProps,
  CommodityService
} from "$State/CommodityFreezerService";

import {
  QuoteFreightSchema, 
  RateVariableValidationSchema
} from "$State/QuoteEntryValidationSchema";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  DEFAULT_TIMEOUT,
  getLengthContent,
  getWeightContent,
  TIMEOUT_BUFFER
} from "$Utilities/ratingUtil";

import {
  IQFRow,
  addRowIndexes
} from "../QuoteView";

import {
  CommodityQuestionModal
} from "../CommodityQuestionModal";

import SharedConstants from "$Shared/utilities/SharedConstants";

const styles: {
  commodityTable: string;
  ratingVariableBox: string;
  updatedRow: string;
  warningMessage: string;
  parentAddEditDiv: string;
  addEditCard: string;
  formLabel: string;
  addEditFreightTopDiv: string;
  addEditFreightBottomDiv: string;
  bottomRowContainerDiv: string;
  bottomRowFlexContainerDiv: string;
  containerDiv: string;
  infoDiv: string;
} = require("./AddEditQuoteCommodity.scss");

const freightStyles: {
  gridSummaryRow: string;
  summaryCell: string;
  summaryDivider: string;
  overdimensional: string;
} = require("./QuoteStopEntry.scss");

interface IAddEditQuoteCommodityProps {
  isOpen: boolean;
  freightTotalData: FreightTotalData;
  currentQuoteStopIndex: number;
  currentQuoteFreight: QuoteFreight[];
  activeCommodities: Commodity[];
  activeEquipmentTypes: EquipmentType[];
  isFetching?: boolean;
  quote: Quote;
  currentCompany?: string;
}

type IAddEditQuoteCommodityBaseProps = IAddEditQuoteCommodityProps
  & ICommodityServiceInjectedProps
  & IQuoteEntryServiceInjectedProps;

interface IAddEditQuoteCommodityState {
  isCancelModalOpen: boolean;
  addEditQuoteFreight: IQFRow;
  isCommodityQuestionModalOpen: boolean;
  commodityFreightIndex?: number;
  commodityQuestions: Question[];
  commodityResponses: Response[];
  commodityName: string | undefined;
  quoteFreightValidationErrors: ValidationError | null;
  rateVariableValidationErrors: ValidationError | null;
  requireRetotal: boolean;
  isOperationTimeoutModalOpen: boolean;
  isMetricUnits: boolean;
}

class _AddEditQuoteCommodity extends React.PureComponent<IAddEditQuoteCommodityBaseProps, IAddEditQuoteCommodityState> {
  private readonly _addRowIndexes = memoizeOne(addRowIndexes);

  private stopsRef = React.createRef<HTMLDivElement>();

  state: IAddEditQuoteCommodityState = {
    isCancelModalOpen: false,
    addEditQuoteFreight: {
      rowIndex: -1,
      quoteStop: {
        stopNumber: this.props.currentQuoteStopIndex + 1
      }
    },
    commodityQuestions: [],
    commodityResponses: [],
    commodityName: undefined,
    isCommodityQuestionModalOpen: false,
    quoteFreightValidationErrors: null,
    rateVariableValidationErrors: null,
    requireRetotal: false,
    isOperationTimeoutModalOpen: false,
    isMetricUnits: false
  };

  static defaultProps: Partial<IAddEditQuoteCommodityProps> = {
    isFetching: false
  };

  componentDidUpdate(prev: IAddEditQuoteCommodityProps) {
    if (this.props.currentQuoteStopIndex !== prev.currentQuoteStopIndex) {
      this._clearAddEditFreight();
    }

    const ratingVariable = this.props.freightTotalData?.ratingVariable;
    if ((ratingVariable !== prev.freightTotalData?.ratingVariable) && (ratingVariable === "Error") && !this.state.requireRetotal) {
      this.setState({ requireRetotal: true });
    }
  }

  @bind
  private _clearAddEditFreight() {
    this.setState({
      addEditQuoteFreight: {
        rowIndex: -1,
        quoteStop: {
          stopNumber: this.props.currentQuoteStopIndex + 1
        },
        quoteStopFreightQuestions: undefined
      },
      commodityQuestions: [],
      commodityResponses: [],
      commodityName: undefined,
      commodityFreightIndex: undefined,
      quoteFreightValidationErrors: null,
      rateVariableValidationErrors: null,
      isMetricUnits: false
    });
  }

  @bind
  private async _onSave() {
    const {
      rowIndex,
      quoteStop,
      quoteStopId,
      ...addEditQuoteFreight
    } = this.state.addEditQuoteFreight;

    // filter out falsey values caused by onBlur functionality from some components
    if (!_.isEmpty(_.pickBy(addEditQuoteFreight))) {
      this.setState({
        isCancelModalOpen: true
      });
      return;
    }

    const rateVariableErrors = await this._validateFreightTotalData();

    if (rateVariableErrors) {
      return;
    }

    this._onFreightSave();
  }

  @bind
  private async _validateFreightTotalData() {
    // validate rate variable (override, market)
    const { freightTotalData } = this.props.QuoteEntryService.getState();

    const rateVariableErrors = await validateSchema(RateVariableValidationSchema, freightTotalData, {
      abortEarly: false,
      context: {
        currentCompany: this.props.currentCompany
      }
    });

    this.setState({
      rateVariableValidationErrors: rateVariableErrors
    });

    return rateVariableErrors;
  }

  @bind
  private _focusStopInput() {
    this.stopsRef.current?.focus();
  }

  @bind
  private async _onCancelContinue() {
    const rateVariableErrors = await this._validateFreightTotalData();
    if (rateVariableErrors) {
      this.setState({
        isCancelModalOpen: false
      });
      return;
    }
    this._onFreightSave();
    this.setState({
      isCancelModalOpen: false
    });
  }

  @bind
  private _onCancelNo() {
    this.setState({
      isCancelModalOpen: false
    });
  }

  @bind
  private async _onTotalsExecute(timeout: number = DEFAULT_TIMEOUT) {
    // always use up-to-date quote freight - automatic re-total unlikely to wait for a full prop cycle
    const { currentQuoteFreight } = this.props.QuoteEntryService.getState();
    const { isOperationTimeoutModalOpen } = this.state;

    let bufferTimer: number | undefined = undefined;

    if (!isOperationTimeoutModalOpen) {
      bufferTimer = setTimeout(() => {
        this.setState({ isOperationTimeoutModalOpen: true });
      }, TIMEOUT_BUFFER) as unknown as number; // timeout IDs in browser are _not_ NodeJS.Timeout
    }

    let keepTimeoutModal: boolean = false;

    let newTotals: Partial<FreightTotalData>;
    try {
      newTotals = await this.props.QuoteEntryService.calculateFreightTotalResults(currentQuoteFreight, undefined, timeout);
    } catch (ex) {
      // don't show timeout modal for non-timeout errors
      clearTimeout(bufferTimer);
      throw ex;
    }
    
    if (!newTotals.ratingError) {
      this.setState({ requireRetotal: false });
    }
    else if (newTotals.ratingError.indexOf("timed out") > -1) {
      keepTimeoutModal = true;
    }

    if (bufferTimer) {
      clearTimeout(bufferTimer);
    }

    this.setState({ isOperationTimeoutModalOpen: keepTimeoutModal });
  }

  @bind
  private _onMarketChange(newMarket: QuoteMarket) {
    this.props.QuoteEntryService.onMarketChange(newMarket);
  }

  @bind
  private _onEquipmentTypeChange(event: SelectChangeEvent<number>) {
    const {
      activeEquipmentTypes
    } = this.props;
    const newEquipmentType = activeEquipmentTypes.find(e => e.id === event.target.value);

    this.props.QuoteEntryService.onEquipmentTypeChange(newEquipmentType);

    // equipment type affects overdimensional rules
    this._applyRetotalCheck();
  }

  @bind
  private _onOverriddenRatingVariableChange(override: number | undefined) {
    const {
      canRatingVariableBeOverriden,
      ratingVariableCalculatedAmount,
      ratingVariableOverriddenAmount
    } = this.props.freightTotalData;

    let value: number | undefined = override;

    if (!canRatingVariableBeOverriden
      || (override === ratingVariableCalculatedAmount)) {
      value = undefined;
    }

    this.props.QuoteEntryService.updateFreightTotalData({
      ratingVariableOverriddenAmount: value
    });

    if (value && (ratingVariableOverriddenAmount !== value)) {
      this.props.QuoteEntryService.updateHasOverrideRateVariableChanged(true);
    }
  }

  @bind
  private _onQuoteFreightChange(quoteFreight: Partial<QuoteFreight>) {
    this.setState((prev) => ({
      addEditQuoteFreight: {
        ...prev.addEditQuoteFreight,
        ...quoteFreight
      }
    }));
  }

  @bind
  private _onQuoteFreightRotate(qfRow: IQFRow) {
    if (SecurityContext.isInGroup("/Admin") || SecurityContext.isInGroup("/Manager")) {
      const {
        rowIndex,
        ...quoteFreight
      } = qfRow;

      const length = quoteFreight.width;
      const width = quoteFreight.length;

      quoteFreight.width = width;
      quoteFreight.length = length;
      quoteFreight.rotated = !quoteFreight.rotated;

      this.props.QuoteEntryService.onUpdateQuoteFreightRow(quoteFreight, rowIndex);

      this._applyRetotalCheck();
    }
  }

  @bind
  private _onQuoteFreightEdit(quoteFreight: IQFRow) {
    this.setState({
      addEditQuoteFreight: { ...quoteFreight },
      quoteFreightValidationErrors: null,
      isMetricUnits: false
    });

    this._focusStopInput();
  }

  @bind
  private _onFreightRemove(quoteFreightIndex: number) {
    this.props.QuoteEntryService.removeQuoteFreight(quoteFreightIndex);

    this._applyRetotalCheck();

    this._clearAddEditFreight();
  }

  @bind
  private async _onAddFreightCommodity() {
    const {
      rowIndex,
      ...quoteFreight
    } = this.state.addEditQuoteFreight;

    this._roundMeasurements(quoteFreight);

    const quoteFreightValidationErrors = await validateSchema(QuoteFreightSchema, quoteFreight, {
      abortEarly: false
    });

    this.setState({ quoteFreightValidationErrors: quoteFreightValidationErrors });

    if (quoteFreightValidationErrors) {
      return;
    }

    this.props.QuoteEntryService.addQuoteFreight(quoteFreight);

    this._applyRetotalCheck();

    this._clearAddEditFreight();
  }

  @bind
  private _onFreightCancel() {
    this.props.QuoteEntryService.cancelFreightModal();

    this.setState({ requireRetotal: false });

    this._clearAddEditFreight();
  }

  @bind
  private _onFreightSave() {
    this.props.QuoteEntryService.saveFreightModal();

    this.setState({ requireRetotal: false });

    this._clearAddEditFreight();
  }

  @bind
  private _onCommodityChanged(commodityItem: string) {
    const { activeCommodities } = this.props;
    const foundCommodity = _.find(activeCommodities, co => co.commodityName === commodityItem)!;

    this._onQuoteFreightChange({
      commodityId: foundCommodity.id,
      isStackable: foundCommodity.isStackable,
      isSideBySide: foundCommodity.isSideBySide,
      commodity: {
        commodityName: foundCommodity.commodityName,
        isActive: foundCommodity.isActive
      }
    });

    // now open the dialog for commodity questions
    this._openCommodityQuestionsModal(foundCommodity.id!, [], undefined, foundCommodity.commodityName, false);
  }

  @bind
  private _onMeasurementBasisChange(measurementBasisItem: string) {
    const isGrouped = measurementBasisItem === "Totals";

    this._onQuoteFreightChange({
      isGrouped: isGrouped
    });
  } 

  @bind
  private _onWeightChange(e: any) {
    this._onQuoteFreightChange({ weight: this.state.isMetricUnits ? parseInt(e.currentTarget.value) * SharedConstants.lbsKgConversion : parseInt(e.currentTarget.value) });
  }

  @bind
  private _onUnitsChange(unit: string) {
    this.setState({
      isMetricUnits: unit === "Metric"
    });
  }

  @bind
  private async _roundMeasurements(quoteFreight: Partial<QuoteFreight>) {
    if (!quoteFreight.length || !quoteFreight.width || !quoteFreight.height || !quoteFreight.weight) {
      return;
    }

    quoteFreight.length = Math.ceil(quoteFreight.length);
    quoteFreight.width = Math.ceil(quoteFreight.width);
    quoteFreight.height = Math.ceil(quoteFreight.height);
    quoteFreight.weight = Math.ceil(quoteFreight.weight);
  }

  @bind
  private async _onUpdateQuoteFreight() {
    const {
      rowIndex,
      ...quoteFreight
    } = this.state.addEditQuoteFreight;

    this._roundMeasurements(quoteFreight);

    const quoteFreightValidationErrors = await validateSchema(QuoteFreightSchema, quoteFreight, {
      abortEarly: false
    });

    this.setState({ quoteFreightValidationErrors: quoteFreightValidationErrors });

    if (quoteFreightValidationErrors) {
      return;
    }

    const requireRetotal = this.props.QuoteEntryService.onUpdateQuoteFreightRow(quoteFreight, rowIndex);
    if (requireRetotal) {
      this._applyRetotalCheck();
    }

    this._clearAddEditFreight();
  }

  @bind
  private _selectAllOnFocus(event: React.FocusEvent<HTMLInputElement>) {
    event.target.select();
  }

  @bind
  private _openCommodityQuestionsModal(commodityId: number, existingResponses: QuoteStopFreightQuestion[], quoteFreightIndex: number | undefined, commodityName: string | undefined, userOpen: boolean) {
    const { commodityQuestionsFetchResults } = this.props.QuoteEntryService.getState();

    const questions = _.filter(commodityQuestionsFetchResults.data ?? [],
      q => _.findIndex(q.commodityQuestions, cQ => cQ.commodityId === commodityId) !== -1
    );

    this.setState({
      isCommodityQuestionModalOpen: questions.length > 0 || userOpen,
      commodityFreightIndex: quoteFreightIndex,
      commodityQuestions: questions,
      commodityResponses: existingResponses,
      commodityName: commodityName
    });
  }

  @bind
  private _onUpdateCommodityQuestions(responses: QuoteStopFreightQuestion[]) {
    const rowIndex = this.state.commodityFreightIndex

    if (rowIndex !== undefined) {
      // update existing quote freight "inline" with new responses
      const newQuoteFreight = _.cloneDeep(this.props.currentQuoteFreight[rowIndex]);

      if (newQuoteFreight) {
        newQuoteFreight.quoteStopFreightQuestions = _.cloneDeep(responses);

        this.props.QuoteEntryService.onUpdateQuoteFreightRow(newQuoteFreight, rowIndex);
      }
    }
    else {
      // save new responses to add/edit freight model
      this.setState((prev) => ({
        addEditQuoteFreight: {
          ..._.cloneDeep(prev.addEditQuoteFreight),
          quoteStopFreightQuestions: _.cloneDeep(responses)
        }
      }));
    }

    this.setState({
      isCommodityQuestionModalOpen: false,
      commodityFreightIndex: undefined,
      commodityQuestions: [],
      commodityResponses: [],
      commodityName: undefined
    });
  }

  @bind
  private async _onRatingTimeoutRetry(timeout: number) {
    await this._onTotalsExecute(timeout);
  }

  @bind
  private _onRatingTimeoutCancel() {
    this.setState({ isOperationTimeoutModalOpen: false });
  }

  @bind
  private _applyRetotalCheck() {
    // always use up-to-date quote freight for automatic re-total check
    const {
      currentQuoteFreight,
      freightTotalData: { equipmentType }
    } = this.props.QuoteEntryService.getState();

    // prevent (re-)calculating rating variable when no equipment type selected
    if (!equipmentType?.id) {
      return;
    }

    if (currentQuoteFreight.length === 1) {
      this._onTotalsExecute();
    }
    else if (currentQuoteFreight.length > 1) {
      this.setState({ requireRetotal: true });
    }
  }

  render() {
    const {
      isOpen,
      currentQuoteStopIndex,
      isFetching,
      activeCommodities,
      activeEquipmentTypes,
      currentCompany,
      currentQuoteFreight,
      freightTotalData
    } = this.props;

    const {
      quote
    } = this.props.QuoteEntryService.getState();

    const {
      isCancelModalOpen,
      addEditQuoteFreight,
      quoteFreightValidationErrors,
      rateVariableValidationErrors,
      isCommodityQuestionModalOpen,
      commodityQuestions,
      commodityResponses,
      commodityName,
      requireRetotal,
      isOperationTimeoutModalOpen,
      isMetricUnits
    } = this.state;

    const quoteFreightParser = new ValidationErrorParser<QuoteFreight>(quoteFreightValidationErrors);
    const rateVariableParser = new ValidationErrorParser<FreightTotalData>(rateVariableValidationErrors);

    const isAdminUser = SecurityContext.isInGroup("/Admin");
    const isManagerUser = SecurityContext.isInGroup("/Manager");

    const gridColumns: GridColDef[] = [
      {
        headerName: "Stop",
        description: "Stop",
        field: "quoteStop",
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.quoteStop.stopNumber;
        }
      },
      {
        headerName: "Commodity",
        description: "Commodity",
        field: "commodity",
        flex: 2,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<any, QuoteFreight>) => {
          const commodity = params.row.commodity;

          return (
            <>
              {!commodity?.isActive
                ? <Icon title="Commodity is inactive">
                  <Error />
                </Icon>
                : undefined
              }
              {commodity?.commodityShortName ?? commodity?.commodityName ?? ""}
            </>
          );
        }
      },
      {
        headerName: "# of Pieces",
        description: "# of Pieces",
        field: "numberOfPieces",
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<any, QuoteFreight>) => {
          if (params.row.isGrouped) {
            return (
              <>
                <div>{params.value}</div>
                <Tooltip title="Grouped freight">
                  <IconButton
                    size="small"
                  >
                    <AutoAwesomeMotion />
                  </IconButton>
                </Tooltip>
              </>
            );
          }

          return params.value;
        }
      },
      {
        headerName: "Length",
        description: "Length",
        field: "length",
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => <DisplayFormattedFeet value={params.value} />
      },
      {
        headerName: "Width",
        description: "Width",
        field: "width",
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => <DisplayFormattedFeet value={params.value} />
      },
      {
        headerName: "Height",
        description: "Height",
        field: "height",
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => <DisplayFormattedFeet value={params.value} />
      },
      {
        headerName: "Weight",
        description: "Weight",
        field: "weight",
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => <DisplayFormattedNumber value={params.value} formatString={"0"} postfix={" lbs"} />
      },
      {
        headerName: "Description",
        description: "Description",
        field: "description",
        flex: 2,
        sortable: false,
        disableColumnMenu: true
      },
      {
        headerName: "Serial/Ref #",
        description: "Serial/Ref #",
        field: "serialRefNumber",
        flex: 2,
        sortable: false,
        disableColumnMenu: true
      },
      {
        headerName: "Stackable",
        description: "Stackable",
        field: "isStackable",
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => params.value ? "Yes" : "No"
      },
      {
        headerName: "Side by Side",
        description: "Side by Side",
        field: "isSideBySide",
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => params.value ? "Yes" : "No"
      },
      {
        headerName: "Rotated",
        description: "Rotated",
        field: "rotated",
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => params.value ? "Yes" : "No"
      },
      {
        headerName: "",
        field: "action",
        width: isAdminUser || isManagerUser ? 156 : 122, // each button is 34, cell padding adds 20
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any, IQFRow>) => {
          return (
            <>
              <IconButton
                color={params.row.commodityId ? "secondary" : "primary"}
                disabled={!params.row.commodityId}
                size="small"
                onClick={() => this._openCommodityQuestionsModal(params.row.commodityId!, params.row.quoteStopFreightQuestions ?? [], params.row.rowIndex, params.row.commodity?.commodityName, false)}
              >
                <QuestionAnswerIcon />
              </IconButton>
              {
                (isAdminUser || isManagerUser) &&
                <IconButton
                  size="small"
                  onClick={() => this._onQuoteFreightRotate(params.row)}
                >
                  <ThreeSixty />
                </IconButton>
              }
              <IconButton
                size="small"
                onClick={() => this._onQuoteFreightEdit(params.row)}
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => this._onFreightRemove(params.row.rowIndex)}
                size="small"
              >
                <Delete />
              </IconButton>
            </>
          )
        }
      }
    ];

    const quoteFreightRows = this._addRowIndexes(currentQuoteFreight ?? []);
    const lengthContent = getLengthContent(freightTotalData, requireRetotal, freightStyles.overdimensional);
    const weightContent = requireRetotal && ((freightTotalData?.totalNumOfPieces ?? 0) > 0)
      ? undefined
      : getWeightContent(freightTotalData, freightStyles.overdimensional);

    return (
      <>
        <Dialog
          open={isOpen}
          maxWidth="xl"
        >
          <AjaxActionIndicator
            showProgress={isFetching}
          />
          <DialogTitle style={{ fontSize: "1.5rem" }}>
            Add/Edit Freight
          </DialogTitle>
          <DialogContent className={styles.commodityTable}>
            {currentCompany === "KL" &&
              <FormControl style={{ marginLeft: "9px", width: "150px", marginBottom: "10px" }}>
                <InputLabel>Market</InputLabel>
                <Select
                  value={freightTotalData.market ?? "Primary"}
                  onChange={(event) => this._onMarketChange(event.target.value as QuoteMarket)}
                >
                  <MenuItem value={"None"}>&nbsp;</MenuItem>
                  <MenuItem value={"Primary"}>Primary</MenuItem>
                  <MenuItem value={"Secondary"}>Secondary</MenuItem>
                </Select>
                <FormHelperText
                  error={!rateVariableParser.isValidDeep("market")}
                  children={rateVariableParser.validationMessageDeep("market")}
                />
              </FormControl>
            }
            <FormControl style={{ marginLeft: "10px", width: "180px", marginBottom: "10px" }}>
              <InputLabel>Equipment Type</InputLabel>
              <Select
                value={freightTotalData.equipmentType?.id ?? 0}
                onChange={this._onEquipmentTypeChange}
              >
                <MenuItem value={0}>&nbsp;</MenuItem>
                {activeEquipmentTypes.map((e, idx) => <MenuItem value={e.id} key={idx}>{e.name}</MenuItem>)}
              </Select>
              <FormHelperText
                error={!rateVariableParser.isValidDeep("equipmentType")}
                children={rateVariableParser.validationMessageDeep("equipmentType")}
              />
            </FormControl>
            <div>
              <DataGridPro
                columns={gridColumns}
                rows={quoteFreightRows}
                density="compact"
                autoHeight
                disableSelectionOnClick
                hideFooter
                initialState={{
                  sorting: {
                    sortModel: [{ field: "quoteStop", sort: "asc" }]
                  }
                }}
                columnVisibilityModel={{
                  quoteStop: quote.quoteType === "Full"
                }}
                getRowId={(row) => row.rowIndex}
                getRowClassName={(row: GridRowParams) => {
                  return row.row.rowIndex === addEditQuoteFreight.rowIndex ? styles.updatedRow : "";
                }}
              />
            </div>
            <div className={styles.infoDiv}>
              <div className={freightStyles.gridSummaryRow}>
                <div className={freightStyles.summaryCell}><b>Total Pieces</b>: {freightTotalData.totalNumOfPieces ?? 0}</div>
                <div className={freightStyles.summaryDivider}>|</div>
                <div className={freightStyles.summaryCell}>{lengthContent}</div>
                {weightContent &&
                  <>
                    <div className={freightStyles.summaryDivider}>|</div>
                    <div className={freightStyles.summaryCell}>{weightContent}</div>
                  </>
                }
                {requireRetotal &&
                  <div className={freightStyles.summaryDivider}>
                    <Button
                      color="primary"
                      size="small"
                      onClick={() => this._onTotalsExecute()}
                      disabled={isFetching}
                    >
                      Get Freight Totals
                    </Button>
                  </div>
                }
              </div>
              <div>
                <Box className={styles.ratingVariableBox}>
                  <div className={styles.warningMessage}>
                    {(freightTotalData.ratingVariableOverriddenAmount && (freightTotalData.ratingVariableCalculatedAmount !== freightTotalData.ratingVariableOverriddenAmount)) ?
                      `Rating Variable modified from ${freightTotalData.ratingVariableCalculatedAmount}`
                      : ""}
                  </div>
                  <span style={{ paddingRight: "7px", whiteSpace: "nowrap", fontWeight: "bold" }}>Rating Variable: </span>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <NumberFormat
                      label=""
                      decimalScale={0}
                      style={{ width: "40px" }}
                      customInput={TextField}
                      disabled={isFetching || !freightTotalData.canRatingVariableBeOverriden}
                      onValueChange={(value) => this._onOverriddenRatingVariableChange(value.floatValue)}
                      onBlur={this._applyRetotalCheck}
                      value={freightTotalData.ratingVariableOverriddenAmount ? freightTotalData.ratingVariableOverriddenAmount : freightTotalData.ratingVariableCalculatedAmount}
                      error={!rateVariableParser.isValid("ratingVariableOverriddenAmount")}
                      helperText={rateVariableParser.validationMessage("ratingVariableOverriddenAmount")}
                    />
                    <span>{freightTotalData.ratingVariable === "Weight" ? " K" : freightTotalData.ratingVariable === "Length" ? " ft" : ""}</span>
                  </div>
                </Box>
              </div>
            </div>
            <div className={styles.parentAddEditDiv}>
              <Card className={styles.addEditCard}>
                <div style={{ padding: "16px", fontSize: "1.25rem" }}>Commodity Entry</div>
                <CardActions style={{ flexWrap: "wrap" }}>
                  <div className={styles.addEditFreightTopDiv}>
                    {quote.quoteType === "Full" &&
                      <div className={styles.containerDiv}>
                        <div className={styles.formLabel}>Stop</div>
                        <FormControl style={{ marginLeft: "15px", width: "50px", marginTop: "-5px" }}>
                          <Select
                            value={addEditQuoteFreight?.quoteStop?.stopNumber ?? currentQuoteStopIndex + 1}
                            inputRef={this.stopsRef}
                            onChange={(event) => this._onQuoteFreightChange({ quoteStop: { stopNumber: event.target.value as number } })}
                          >
                            {_.map(quote.quoteStops, (_, idx) => (
                              <MenuItem value={idx + 1} key={idx}>{idx + 1}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    }
                    <div className={styles.containerDiv}>
                      <div>Commodity</div>
                      <Autocomplete<string | null, false, true, false>
                        options={activeCommodities.map(co => co.commodityName!)}
                        getOptionLabel={(commodityOption: string | null) => commodityOption ?? ""}
                        autoHighlight
                        style={{ width: "200px", paddingLeft: "10px", paddingRight: "10px" }}
                        value={_.find(activeCommodities, co => co.id === addEditQuoteFreight?.commodityId)?.commodityName ?? addEditQuoteFreight?.commodity?.commodityName ?? ""}
                        onChange={(event, value) => { this._onCommodityChanged(value) }}
                        isOptionEqualToValue={(option, value) => {
                          if (value === "") {
                            return false
                          }
                          return value === option
                        }}
                        disableClearable={true}
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            style={{ marginTop: "-5px" }}
                            label=""
                            autoFocus
                            margin="normal"
                            error={!quoteFreightParser.isValid("commodityId")}
                            helperText={quoteFreightParser.validationMessage("commodityId")}
                          />
                        }
                      />
                      <IconButton
                        color={addEditQuoteFreight?.commodityId ? "secondary" : "primary"}
                        disabled={!addEditQuoteFreight?.commodityId}
                        size="small"
                        onClick={() => this._openCommodityQuestionsModal(addEditQuoteFreight?.commodityId!, addEditQuoteFreight?.quoteStopFreightQuestions ?? [], undefined, addEditQuoteFreight?.commodity?.commodityName, true)}
                      >
                        <QuestionAnswerIcon />
                      </IconButton>
                    </div>
                    <div className={styles.containerDiv}>
                      <div>Description</div>
                      <AdvanceTextField
                        label=""
                        onFocus={this._selectAllOnFocus}
                        style={{ marginTop: "-5px", paddingLeft: "10px" }}
                        onDebouncedChange={(value) => this._onQuoteFreightChange({ description: value })}
                        value={addEditQuoteFreight?.description ?? ""}
                        error={!quoteFreightParser.isValid("description")}
                        helperText={quoteFreightParser.validationMessage("description")}
                        fullWidth
                      />
                    </div>
                    <div className={styles.containerDiv}>
                      <div style={{ whiteSpace: "nowrap" }}>Serial/Ref #</div>
                      <AdvanceTextField
                        label=""
                        onFocus={this._selectAllOnFocus}
                        inputProps={{ maxLength: 40 }}
                        style={{ marginTop: "-5px", paddingLeft: "10px" }}
                        onDebouncedChange={(value) => this._onQuoteFreightChange({ serialRefNumber: value })}
                        value={addEditQuoteFreight?.serialRefNumber ?? ""}
                        error={!quoteFreightParser.isValid("serialRefNumber")}
                        helperText={quoteFreightParser.validationMessage("serialRefNumber")}
                        fullWidth
                      />
                    </div>
                    <div className={styles.containerDiv}>
                      <div>Units</div>
                      <Autocomplete<string | null, false, true, false>
                        options={["Standard", "Metric"]}
                        getOptionLabel={(measurementBasisOption: string | null) => measurementBasisOption ?? ""}
                        autoHighlight
                        style={{ flex: "100%", paddingLeft: "10px", paddingRight: "10px" }}
                        value={!isMetricUnits ? "Standard" : "Metric"}
                        onChange={(event, value) => { this._onUnitsChange(value) }}
                        isOptionEqualToValue={(option, value) => {
                          if (value === "") {
                            return false
                          }
                          return value === option
                        }}
                        disableClearable={true}
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            style={{ marginTop: "-5px" }}
                            label=""
                            autoFocus
                            margin="normal"
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.addEditFreightBottomDiv}>
                    <div className={styles.bottomRowFlexContainerDiv}>
                      <div style={{ width: "85px" }}>Measurement Basis</div>
                      <Autocomplete<string | null, false, true, false>
                        options={["Per Piece", "Totals"]}
                        getOptionLabel={(measurementBasisOption: string | null) => measurementBasisOption ?? ""}
                        autoHighlight
                        style={{ width: "125px", paddingLeft: "10px", paddingRight: "10px" }}
                        value={addEditQuoteFreight?.isGrouped ? "Totals" : "Per Piece"}
                        onChange={(event, value) => { this._onMeasurementBasisChange(value) }}
                        isOptionEqualToValue={(option, value) => {
                          if (value === "") {
                            return false
                          }
                          return value === option
                        }}
                        disableClearable={true}
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            style={{ marginTop: "-5px" }}
                            label=""
                            autoFocus
                            margin="normal"
                          />
                        }
                      />
                    </div>
                    <div className={styles.bottomRowContainerDiv} style={{ display: "flex" }}>
                      <div style={{ whiteSpace: "nowrap" }}># of Pieces</div>
                      <NumberFormat
                        allowNegative={false}
                        decimalScale={0}
                        onFocus={this._selectAllOnFocus}
                        label=""
                        value={addEditQuoteFreight?.numberOfPieces ?? ""}
                        isAllowed={(value) => (value?.floatValue ?? 0) <= 999}
                        style={{ width: "50px", marginTop: "-5px", paddingLeft: "5px" }}
                        customInput={TextField}
                        onValueChange={(value) => this._onQuoteFreightChange({ numberOfPieces: value.floatValue })}
                        error={!quoteFreightParser.isValid("numberOfPieces")}
                        helperText={quoteFreightParser.validationMessage("numberOfPieces")}
                      />
                    </div>
                    <div className={styles.bottomRowContainerDiv}>
                      <div>Length</div>
                      <FeetInputField
                        label={""}
                        style={{ marginTop: "-10px" }}
                        error={!quoteFreightParser.isValid("length")}
                        helperText={quoteFreightParser.validationMessage("length")}
                        widthOverride={"35px"}
                        inches={addEditQuoteFreight?.length}
                        onFeetValueChange={(value) => this._onQuoteFreightChange({ length: value })}
                        isMetricUnits={isMetricUnits}
                      />
                    </div>
                    <div className={styles.bottomRowContainerDiv}>
                      <div>Width</div>
                      <FeetInputField
                        label={""}
                        error={!quoteFreightParser.isValid("width")}
                        helperText={quoteFreightParser.validationMessage("width")}
                        widthOverride={"35px"}
                        inches={addEditQuoteFreight?.width}
                        onFeetValueChange={(value) => this._onQuoteFreightChange({ width: value })}
                        isMetricUnits={isMetricUnits}
                      />
                    </div>
                    <div className={styles.bottomRowContainerDiv}>
                      <div>Height</div>
                      <FeetInputField
                        label={""}
                        error={!quoteFreightParser.isValid("height")}
                        helperText={quoteFreightParser.validationMessage("height")}
                        widthOverride={"35px"}
                        inches={addEditQuoteFreight?.height}
                        onFeetValueChange={(value) => this._onQuoteFreightChange({ height: value })}
                        isMetricUnits={isMetricUnits}
                      />
                    </div>
                    <div className={styles.bottomRowContainerDiv} style={{ display: "flex" }}>
                      <div style={{ whiteSpace: "nowrap" }}>Weight</div>
                      <div style={{ display: "inline-flex" }}>
                        <NumberFormat
                          allowNegative={false}
                          decimalScale={0}
                          label=""
                          value={
                            addEditQuoteFreight.weight === undefined ? "" : 
                            !isMetricUnits ? addEditQuoteFreight.weight === 0 ? "" : Math.ceil(addEditQuoteFreight.weight) :
                            addEditQuoteFreight.weight / SharedConstants.lbsKgConversion === 0 ? "" : Math.ceil(addEditQuoteFreight.weight / SharedConstants.lbsKgConversion)
                          }
                          customInput={TextField}
                          onFocus={this._selectAllOnFocus}
                          style={{ width: "80px", paddingLeft: "5px" }}
                          onBlur={this._onWeightChange}
                          error={!quoteFreightParser.isValidDeep("weight")}
                          helperText={quoteFreightParser.validationMessage("weight")}
                        />
                        <div style={{ marginTop: "8px" }}>
                          {!isMetricUnits ? "lbs" : "kg"}
                        </div>
                      </div>
                    </div>
                    <div className={styles.bottomRowContainerDiv} style={{ width: "160px" }}>
                      <div className={styles.addEditFreightTopDiv}>
                        <FormControlLabel
                          label="Stackable"
                          control={(
                            <Checkbox
                              checked={addEditQuoteFreight?.isStackable ?? false}
                              onChange={(event, checked) => this._onQuoteFreightChange({ isStackable: checked })}
                              name="isStackable"
                            />
                          )}
                        />
                      </div>
                      <div className={styles.addEditFreightBottomDiv} style={{ margin: "0px" }}>
                        <FormControlLabel
                          label="Side by Side"
                          control={(
                            <Checkbox
                              checked={addEditQuoteFreight?.isSideBySide ?? false}
                              onChange={(event, checked) => this._onQuoteFreightChange({ isSideBySide: checked })}
                              name="isSideBySide"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div>
                      {addEditQuoteFreight.rowIndex > -1 ?
                        <Button
                          color="primary"
                          size="small"
                          onClick={() => this._onUpdateQuoteFreight()}
                          disabled={isFetching}
                        >
                          Update
                        </Button>
                        : <Button
                          color="primary"
                          size="small"
                          onClick={() => this._onAddFreightCommodity()}
                          disabled={isFetching}
                        >
                          Add
                        </Button>}
                    </div>
                  </div>
                </CardActions>
              </Card>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => this._onSave()}
              disabled={isFetching || requireRetotal}
            >
              Save
            </Button>
            <Button
              onClick={() => this._onFreightCancel()}
              disabled={isFetching}
              variant="outlined"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <CommodityQuestionModal
          isOpen={isCommodityQuestionModalOpen}
          commodityName={commodityName}
          questions={commodityQuestions}
          responses={commodityResponses}
          onDone={this._onUpdateCommodityQuestions}
        />

        <ConfirmCancelModal
          isOpen={isCancelModalOpen}
          onCancelYes={this._onCancelContinue}
          onCancelNo={this._onCancelNo}
          message={"You have unsaved input that will be deleted - are you sure you want to continue?"}
        />

        <OperationTimeoutModal
          isOpen={isOperationTimeoutModalOpen}
          isFetching={isFetching}
          onRetry={this._onRatingTimeoutRetry}
          onCancel={this._onRatingTimeoutCancel}
          operationText={"calculate freight totals"}
        />
      </>
    );
  }
}

export const AddEditQuoteCommodity = QuoteEntryService.inject(
  CommodityService.inject(
    _AddEditQuoteCommodity
  )
);


