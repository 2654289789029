import {
  React,
  bind,
  moment,
  _,
  numeral,
} from "$Imports/Imports";

import {
  DataTable,
  IDataTableColumn,
  AjaxActionIndicator,
  directionType,
  DisplayFormattedDatetime
} from "$Imports/CommonComponents";

import {
  Card,
  CardActions,
  CardHeader,
  FormControlLabel,
  Switch
} from "$Imports/MaterialUIComponents";

import {
  TarpValue
} from "$Generated/api";

import {
  ITarpRatesServiceInjectedProps,
  TarpRatesService
} from "$State/TarpRatesFreezerService";

import {
  AddEditTarpRate
} from "./AddEditTarpRate";

import {
  ActionMenu
} from "./ActionMenu";

import { DATE_WITH_TIME_MERIDIAN_FORMAT } from "$Shared/utilities/formatUtil";

const styles: {
  mainContainer: string;
  actionArea: string;
  cardStyle: string;
} = require("$Shared/styles/Modal.scss");

interface ITarpRatesViewState {
  cancelDialogOpen: boolean;
}

interface ITarpRatesViewBaseProps {
  companyId: number | undefined;
}

type ITarpRatesViewProps = ITarpRatesViewBaseProps
  & ITarpRatesServiceInjectedProps;

class _TarpRatesView extends React.Component<ITarpRatesViewProps, ITarpRatesViewState> {
  state = {
    cancelDialogOpen: false
  }

  private readonly columns: Array<IDataTableColumn<TarpValue>> = [
    {
      columnName: "tarpName",
      columnFieldData: (d) => d.tarp?.tarpName ?? "",
      headerValue: "Tarp Name",
      sortMethod: (d) => d.tarp?.tarpName?.toLowerCase(),
    },
    {
      columnName: "tarpDescription",
      columnFieldData: (d) => d.tarp?.tarpDescription ?? "",
      headerValue: "Description",
      sortMethod: (d) => d.tarp?.tarpDescription?.toLowerCase(),
    },
    {
      columnName: "rate",
      columnFieldData: (d) => d.rate ? numeral(d.rate).format("$0,0.00") : "$0.00",
      headerValue: "Rate",
      sortMethod: (d) => d.rate ?? "$0.00",
    },
    {
      columnName: "startDateTime",
      columnFieldData: (d) => d.startDateTime ?
        <DisplayFormattedDatetime
          value={d.startDateTime}
          formatString={DATE_WITH_TIME_MERIDIAN_FORMAT}
          showTimeZone
        /> : "",
      headerValue: "Start Date",
      sortMethod: (d) => d.startDateTime ?? ""
    },
    {
      columnName: "endDateTime",
      columnFieldData: (d) => d.endDateTime ?
        <DisplayFormattedDatetime
          value={d.endDateTime}
          formatString={DATE_WITH_TIME_MERIDIAN_FORMAT}
          showTimeZone
        /> : "",
      headerValue: "End Date",
      sortMethod: (d) => d.endDateTime ?? ""
    },
    {
      columnName: "action",
      columnFieldData: (d) => (
        <ActionMenu
          tarpValue={d}
          showUndo={this.showUndo(d)}
          onMenuItemClick={this._onMenuClick}
        />
      ),
      headerValue: "",
    }
  ]

  componentDidMount() {
    this.props.tarpRatesService.clearFreezer();
    this.props.tarpRatesService.fetchTarpRates(this.props.companyId, true);
    this.props.tarpRatesService.fetchTarps(this.props.companyId, true);
  }

  componentDidUpdate(prevProps: ITarpRatesViewBaseProps) {
    if (this.props.companyId !== prevProps.companyId) {
      this.props.tarpRatesService.clearFreezer();
      this.props.tarpRatesService.fetchTarpRates(this.props.companyId, true);
      this.props.tarpRatesService.fetchTarps(this.props.companyId, true);      
    }
  }

  @bind
  private _onShowHistoryChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.tarpRatesService.setShowHistory(this.props.companyId, checked);
  }

  @bind
  private _onSortChange(event: React.MouseEvent<HTMLElement>, columnName: string | undefined, direction: directionType) {
    this.props.tarpRatesService.setSortState({
      sortColumnName: columnName,
      sortDirection: direction,
    });
  }

  @bind
  private _onMenuClick(taskName: string, tarpRate: TarpValue) {
    if (taskName == "edit") {
      this.props.tarpRatesService.openEditTarpRate(tarpRate);
    }
    else if (taskName == "undo") {
      this.props.tarpRatesService.undoTarpRate(this.props.companyId, tarpRate);
    }
  }

  @bind
  private _onTarpValueChange(tarpValue: Partial<TarpValue>) {
    this.props.tarpRatesService.onTarpValueChange(tarpValue);
  }

  @bind
  private _onImmediateStartChange(immediateStart: boolean) {
    this.props.tarpRatesService.onChangeImmediateStart(immediateStart);
  }

  @bind
  private _onSave() {
    this.props.tarpRatesService.saveTarpRate(this.props.companyId);
  }

  @bind
  private _onCancel() {
    if (this.props.tarpRatesService.hasUnsavedChanges()) {
      this.setState({ cancelDialogOpen: true });
    } else {
      this.props.tarpRatesService.clearEditAddForm();
    }
  }

  @bind
  private _cancelDialogResponse(response: boolean) {
    if (response) {
      this.props.tarpRatesService.clearEditAddForm();
    }
    this.setState({ cancelDialogOpen: false });
  }

  @bind
  private showUndo(current: TarpValue): boolean {
    const {
      tarpRatesFetchResults,
    } = this.props.tarpRatesService.getState();
    const tarpRates = tarpRatesFetchResults.data ?? [];

    let showUndo = current.endDateTime === null && moment().utc().isBefore(current.startDateTime);
    // this doesn't necessarily find the previous row
    // but all we need to know is that *a* previous row exists
    const hasPrevious = _.find(tarpRates, t => {
      return current.tarpId === t.tarpId && moment(t.startDateTime).isBefore(current.startDateTime);
    });
    showUndo = showUndo && hasPrevious !== undefined;

    return showUndo;
  }

  render() {
    const {
      tarpRatesFetchResults,
      tarpRateSaveResult,
      showHistory,
      sortState,
      modalState
    } = this.props.tarpRatesService.getState();

    const isFetching: boolean = tarpRatesFetchResults.isFetching || tarpRateSaveResult.isFetching;; 

    var tarpRatesData = tarpRatesFetchResults.data ? tarpRatesFetchResults.data : [];
    
    if (!showHistory) {
      tarpRatesData = tarpRatesData.filter(d => d.tarp?.isActive);
    }
    tarpRatesData = _.sortBy(tarpRatesData, ["tarpName"]);

    return (
      <div
        className={styles.mainContainer}
      >
        <Card
          className={styles.cardStyle}
        >
          <CardHeader
            title="Tarp Rates"
          />
          <CardActions
            disableSpacing={true}
            className={styles.actionArea}
          >
            <FormControlLabel
              control={
                (
                  <Switch
                    color="primary"
                    checked={showHistory}
                    onChange={this._onShowHistoryChange}
                  />
                )
              }
              label="Show History"
            />
          </CardActions>
          <AjaxActionIndicator
            state={[tarpRatesFetchResults]}
          />
          <DataTable
            columns={this.columns}
            data={tarpRatesData}
            defaultSortColumnName={sortState.sortColumnName}
            defaultSortDirection={sortState.sortDirection}
            onSortChange={this._onSortChange}
          />
        </Card>
        <AddEditTarpRate
          modalState={modalState}
          onCancel={this._onCancel}
          onSave={this._onSave}
          isFetching={isFetching}
          onTarpValueChange={this._onTarpValueChange}
          onImmediateStartChange={this._onImmediateStartChange}
          cancelOpen={this.state.cancelDialogOpen}
          cancelResponse={this._cancelDialogResponse}
        />
      </div>
      
    );
  }
}

export const TarpRatesView =
  TarpRatesService.inject(
    _TarpRatesView
  );