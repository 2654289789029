import {
  React,
  bind,
  _,
  moment
} from "$Imports/Imports";

import {
  Activity,
  Reminder
} from "$Generated/api";

import {
  TextField,
  TextFieldProps,
  Button,
  DataGridPro,
  GridColDef,
  GridRowClassNameParams,
  GridCellParams,
  GridRenderCellParams,
  KeyboardDatePicker,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem
} from "$Imports/MaterialUIComponents";

import {
  Search,
  Clear,
  PushPin,
  PushPinOutlined
} from "$Imports/MaterialUIIcons";

import {
  AjaxActionIndicator,
  CardLinedHeader,
  DisplayFormattedDatetime,
  UserAccessControl,
  AdvanceTextField,
  TextCellTruncated,
  EditNoteModal
} from "$Imports/CommonComponents";

import {
  ICustomerDetailServiceInjectedProps,
  CustomerDetailService,
  INoteSearchState
} from "$State/CustomerDetailFreezerService";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  ActionMenu
} from "./ActionMenu";

import { DATE_WITH_TIME_SECONDS_MERIDIAN_FORMAT } from "$Shared/utilities/formatUtil";


const styles: {
  searchContainer: string;
  addNoteContainer: string;
  pinnedNotesContainer: string;
  notesContainer: string;
  tableRow: string;
  hideShow: string;
  gridContainer: string;
  truncateCell: string;
} = require("./CustomerNotes.scss");

interface INotesCardBaseProps {
  customerId: number | undefined;
  onCreateReminder: (seed: Reminder) => void;
}

type INotesCardProps = INotesCardBaseProps
  & ICustomerDetailServiceInjectedProps
  ;

class _NotesCard extends React.Component<INotesCardProps> {

  private readonly columns: GridColDef[] = [
    {
      headerName: "",
      field: "isPinned",
      renderCell: (params: GridRenderCellParams<boolean>) => params.value
        ? <PushPin />
        : (<UserAccessControl roles={["note:edit"]}>
          <PushPinOutlined />
        </UserAccessControl>)
      ,
      resizable: false,
      maxWidth: 50,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false
    },
    {
      headerName: "Date / Time",
      field: "createdOn",
      renderCell: (params: GridRenderCellParams) => <DisplayFormattedDatetime value={params.value} formatString={DATE_WITH_TIME_SECONDS_MERIDIAN_FORMAT} />,
      flex: 2,
      disableColumnMenu: true
    },
    {
      headerName: "Author",
      field: "createdById",
      renderCell: (params: GridRenderCellParams) => params.row?.createdBy?.firstName + " " + params.row?.createdBy?.lastName,
      flex: 2,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false
    },
    {
      headerName: "Note",
      field: "noteText",
      renderCell: (params: GridRenderCellParams) => {
        let noteText = params.row?.noteText ?? "";

        return (
          <TextCellTruncated text={noteText} />
        );
      },
      flex: 6.5,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false
    },
    {
      headerName: "",
      field: "actions",
      renderCell: (params: GridRenderCellParams) => (
        <ActionMenu
          note={params.row}
          onEditClick={this._openEditModal}
          onDeleteClick={this._onDeleteClick}
        />
      ),
      maxWidth: 88,
      resizable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
      cellClassName: styles.hideShow
    }
  ];

  @bind
  private _onAddNoteTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.customerDetailService.addNoteTextChange(e.target.value);
  }

  @bind
  private _onAddNoteClick() {
    if (this.props.customerId) {
      this.props.customerDetailService.createNote(this.props.customerId);
    }
  }

  @bind
  private _onSearchTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.customerDetailService.setNoteSearchCriteria({ searchString: e.target.value });
  }

  @bind
  private _onSearchAuthorChange(event: SelectChangeEvent<number>) {
    this.props.customerDetailService.setNoteSearchCriteria({
      authorId: event.target.value as number
    });
  }

  @bind
  private _onSearchDateRangeChange(date: moment.Moment | null, dateType: "from" | "to") {
    if (dateType === "from") {
      this.props.customerDetailService.setNoteSearchCriteria({ fromDate: date !== null ? date.toDate() : undefined });
    } else {
      this.props.customerDetailService.setNoteSearchCriteria({ toDate: date !== null ? date.toDate() : undefined });
    }
  }

  @bind
  private _clearNoteSearch() {
    this.props.customerDetailService.clearNoteSearch();
  }

  @bind
  private _onNoteSearch() {
    this.props.customerDetailService.filterNotes();
  }

  @bind
  private _onPinClick(params: GridCellParams) {
    const canPin = SharedSecurityContext.hasRole(["note:edit"]);

    let row: Activity = params.row;
    if (params.field === "isPinned" && canPin) {
      this.props.customerDetailService.togglePinOnNote(row);
    }
  }

  @bind
  private _onDeleteClick(note: Activity) {
    this.props.customerDetailService.deleteNote(note);
  }

  @bind
  private _openEditModal(note: Activity) {
    this.props.customerDetailService.openEditNoteModal(note);
  }

  @bind
  private _onEditNoteChange(note: Partial<Activity>) {
    this.props.customerDetailService.editNoteOnChange(note);
  }

  @bind
  private _saveEditNote() {
    this.props.customerDetailService.saveEditNote();
  }

  @bind
  private _cancelEditModal() {
    this.props.customerDetailService.cancelEditNoteModal();
  }

  render() {
    const {
      noteSearchState,
      notesFetchResults,
      noteSearchValidationErrors,
      allNotes,
      filteredNotes,
      noteAuthors,
      addNoteText,
      noteSaveResults,
      editNote
    } = this.props.customerDetailService.getState();

    const validationsParser = new ValidationErrorParser<INoteSearchState>(noteSearchValidationErrors);

    let notes = allNotes ?? [];
    const pinnedNotes = _.filter(notes, n => !!n.isPinned) ?? [];

    // https://mui.com/components/data-grid/layout/
    // you can _not_ have a dynamic height grid (unless you're way better at CSS than I am)
    let pinnedHeight = Math.min(327, 41 + 36 * pinnedNotes.length);

    return (
      <>
        <CardLinedHeader
          titleText="Notes"
        >
          <AjaxActionIndicator state={[notesFetchResults, noteSaveResults]} />
          {/* label has to go outside the grid container because of grid sizing calculations */}
          {pinnedNotes.length > 0 && <span style={{ fontSize: "12px" }}>Pinned Notes</span>}
          <div className={styles.pinnedNotesContainer} style={{ maxHeight: `${pinnedHeight}px` }}>
            {
              pinnedNotes.length > 0
                ?
                <DataGridPro
                  rows={pinnedNotes}
                  columns={this.columns}
                  density="compact"
                  onCellClick={this._onPinClick}
                  getRowClassName={(params: GridRowClassNameParams) => styles.tableRow}
                  hideFooter
                  disableSelectionOnClick
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "createdOn", sort: "desc" }]
                    }
                  }}
                />
                :
                <i>No pinned notes.</i>
            }
          </div>

          <UserAccessControl roles={["note:create"]}>
            <div className={styles.addNoteContainer}>
              <AdvanceTextField
                value={addNoteText}
                onChange={this._onAddNoteTextChange}
                error={addNoteText.length > 300 || false}
                helperText={addNoteText.length > 300 ? "Maximum of 300 characters" : ""}
                placeholder="Write a new note"
                multiline
                fullWidth
                style={{ marginRight: "5px" }}
              />
              <Button
                color="primary"
                onClick={this._onAddNoteClick}
                disabled={!addNoteText || addNoteText.length > 300}
              >
                Add
              </Button>
            </div>
          </UserAccessControl>

          <div className={styles.searchContainer}>
            <AdvanceTextField
              label="Search"
              value={noteSearchState.searchString}
              onChange={this._onSearchTextChange}
              error={!validationsParser.isValid("searchString")}
              helperText={validationsParser.validationMessage("searchString")}
              style={{ flex: "1 1 5rem" }}
            />
            <FormControl style={{ width: "180px", marginRight: "10px" }}>
              <InputLabel>Sales Representative</InputLabel>
              <Select
                value={noteSearchState.authorId}
                name="authorId"
                onChange={this._onSearchAuthorChange}
              >
                <MenuItem value={0}>
                  All
                </MenuItem>
                {noteAuthors.map((e, idx) =>
                  <MenuItem value={e.id} key={idx}>
                    {`${e.firstName} ${e.lastName}`}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <KeyboardDatePicker
              value={noteSearchState.fromDate ?? null}
              onChange={(date: moment.Moment | null) => this._onSearchDateRangeChange(date, "from")}
              inputFormat="MM/DD/YYYY"
              label="From"
              renderInput={(props: TextFieldProps) => (
                <TextField
                  {...props}
                  error={!validationsParser.isValid("fromDate")}
                  helperText={validationsParser.validationMessage("fromDate")}
                  style={{ width: "150px" }}
                />
              )}
            />
            <KeyboardDatePicker
              value={noteSearchState.toDate ?? null}
              onChange={(date: moment.Moment | null) => this._onSearchDateRangeChange(date, "to")}
              inputFormat="MM/DD/YYYY"
              label="To"
              renderInput={(props: TextFieldProps) => (
                <TextField
                  {...props}
                  error={!validationsParser.isValid("toDate")}
                  helperText={validationsParser.validationMessage("toDate")}
                  style={{ width: "150px" }}
                />
              )}
            />
            <div style={{ alignSelf: "end", display: "inline-flex", gap: "0.25rem" }}>
              <Button onClick={this._onNoteSearch} className="iconAsButton">
                <Search />
              </Button>
              <Button onClick={this._clearNoteSearch} className="iconAsButton">
                <Clear />
              </Button>
            </div>
          </div>
          <div className={styles.notesContainer}>
            <DataGridPro
              rows={filteredNotes}
              columns={this.columns}
              density="compact"
              onCellClick={this._onPinClick}
              getRowClassName={(params: GridRowClassNameParams) => styles.tableRow}
              hideFooter
              disableSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: "createdOn", sort: "desc" }]
                }
              }}
            />
          </div>
        </CardLinedHeader>
        <EditNoteModal
          note={editNote}
          onChange={this._onEditNoteChange}
          onSave={this._saveEditNote}
          onCancel={this._cancelEditModal}
        />
      </>
    );
  }
}

export const NotesCard = CustomerDetailService.inject(
  _NotesCard
);
