import { 
  _,
  User
} from "$Imports/Imports";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings"

// Global Settings and User Manager

export type SalesPortalGroup = "/Admin" | "/Manager" | "/SalesRep" | "/ViewOnly" | "/CarrierRep" | "/Biller";

class ApplicationSecuritySettings {
  public isInGroup(group: SalesPortalGroup): boolean {
    const user = SharedSecurityContext.getUser();

    if(user === null) {
      false;
    }

    const groupValues: Array<string> = _.castArray(user?.profile?.groups) as [] ?? [];

    return _.indexOf(groupValues, group as string) > -1;
  }
  
  public getCompanies(user: User | null): string[] {
    if (!user) {
      user = SharedSecurityContext.getUser();
    }
    return _.castArray(user?.profile?.companies) as [] ?? [];
  }
  
  public getTMSalesAgent(): string | undefined {
    const user = SharedSecurityContext.getUser();

    return user?.profile?.TMSalesAgent as string | undefined;
  }
}

// Create a singleton for securiry settings.
export const SecurityContext = new ApplicationSecuritySettings();