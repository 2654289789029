import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  EquipmentType
} from "$Generated/api";

import {
  Card,
  CardActions,
  CardHeader,
  Button,
  FormControlLabel,
  Switch,
  GridColDef,
  GridValueFormatterParams,
  GridValueGetterParams,
  GridRenderCellParams,
  DataGridPro  
} from "$Imports/MaterialUIComponents";

import {
  AjaxActionIndicator
} from "$Imports/CommonComponents";

import {
  IEquipmentTypeServiceInjectedProps,
  EquipmentTypeService,
  EMPTY_EQUIPMENT_TYPE
} from "$State/EquipmentTypeFreezerService";

import {
  EditEquipmentTypeModal
} from "./EditEquipmentTypeModal";

import {
  ActionMenu
} from "./ActionMenu";

const styles: {
  mainContainer: string;
  actionArea: string;
  cardStyle: string;
} = require("$Shared/styles/Modal.scss");

interface IEquipmentTypeViewPageBaseProps { }

interface IOwnState {
  canSaveModal: boolean;
  isEquipmentTypeModalOpen: boolean;
  filterActive: boolean;
  addEditEquipmentType?: EquipmentType;
}

type IEquipmentTypeViewPageProps = IEquipmentTypeViewPageBaseProps
  & IEquipmentTypeServiceInjectedProps;

class _EquipmentTypeViewPage extends React.Component<IEquipmentTypeViewPageProps, IOwnState> {
  state: IOwnState = {
    canSaveModal: true,
    isEquipmentTypeModalOpen: false,
    filterActive: true,
    addEditEquipmentType: undefined,
  };

  private readonly columns: GridColDef[] = [{
    headerName: "Name",
    field: "name",
    flex: 1
  }, {
    headerName: "TM Equipment ID",
    field: "tmEquipmentCode",
    width: 180
  }, {
    headerName: "Over-Dimensional Ruleset",
    field: "overdimensionalRulesetId",
    width: 240,
    valueGetter: (params: GridValueGetterParams<number, EquipmentType>) => {
      const { overdimensionalRulesetFetchResults } = this.props.equipmentTypeService.getState();
      const ruleset = overdimensionalRulesetFetchResults.data?.find(x => x.id == params.value);

      return ruleset?.name ?? "--";
    }
  }, {
    headerName: "Active",
    field: "isActive",
    valueFormatter: (params: GridValueFormatterParams<boolean>) => params.value ? "Yes" : "No",
    width: 100
  }, {
    headerName: "",
    field: "actions",
    width: 60,
    renderCell: (params: GridRenderCellParams<any, EquipmentType>) => (
      <ActionMenu
        equipmentType={params.row}
        onEdit={this._onEdit}
      />
    ),
    disableColumnMenu: true,
    sortable: false
  }];

  componentDidMount() {
    this.props.equipmentTypeService.fetchEquipmentTypes();
    this.props.equipmentTypeService.fetchOverdimensionalRulesets();
  }

  @bind
  private _onActiveFilterChange(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.setState({
      filterActive: checked
    });

    this.props.equipmentTypeService.fetchEquipmentTypes(!checked, true);
  }

  @bind
  private _onAddClick() {
    this.setState({
      isEquipmentTypeModalOpen: true,
      addEditEquipmentType: { ...EMPTY_EQUIPMENT_TYPE }
    });
  }

  @bind
  private _onEdit(equipmentType: EquipmentType) {
    this.setState({
      isEquipmentTypeModalOpen: true,
      addEditEquipmentType: equipmentType
    });
  }

  @bind
  private async _onSave(value?: EquipmentType) {
    if (!value) {
      this.setState({
        canSaveModal: true,
        isEquipmentTypeModalOpen: false,
        addEditEquipmentType: undefined
      });
      return;
    }

    const { filterActive } = this.state;

    try {
      this.setState({
        canSaveModal: false
      });

      await this.props.equipmentTypeService.saveEquipmentType(value);
      this.props.equipmentTypeService.fetchEquipmentTypes(!filterActive, true);

      this.setState({
        isEquipmentTypeModalOpen: false,
        addEditEquipmentType: undefined
      });
    }
    catch { }
    finally {
      this.setState({
        canSaveModal: true
      });
    }
  }

  render() {
    const {
      equipmentTypeFetchResults,
      equipmentTypeSaveResults,
      overdimensionalRulesetFetchResults
    } = this.props.equipmentTypeService.getState();

    const {
      canSaveModal,
      isEquipmentTypeModalOpen,
      filterActive,
      addEditEquipmentType
    } = this.state;

    const equipmentTypeData = equipmentTypeFetchResults.data ?? [];
    const overdimensionalRulesets = overdimensionalRulesetFetchResults.data ?? [];

    return (
      <div
        className={styles.mainContainer}
      >
        <Card
          className={styles.cardStyle}
        >
          <CardHeader title="Equipment" />
          <CardActions
            disableSpacing={true}
            className={styles.actionArea}
          >
            <FormControlLabel
              control={
                (
                  <Switch
                    color="primary"
                    checked={filterActive}
                    onChange={this._onActiveFilterChange}
                  />
                )
              }
              label="Only show active"
            />
            <Button
              color="primary"
              onClick={this._onAddClick}
            >
              Add
            </Button>
          </CardActions>
          <AjaxActionIndicator
            state={[equipmentTypeFetchResults, equipmentTypeSaveResults, overdimensionalRulesetFetchResults]}
          />
          <DataGridPro
            rows={equipmentTypeData}
            columns={this.columns}
            density="compact"
            initialState={{
              sorting: {
                sortModel: [{ field: "name", sort: "asc" }]
              }
            }}
            style={{ margin: "0.5rem" }}
            autoHeight
            disableSelectionOnClick
            hideFooter
          />
        </Card>
        <EditEquipmentTypeModal
          canSave={canSaveModal}
          isOpen={isEquipmentTypeModalOpen}
          equipmentType={addEditEquipmentType}
          overdimensionalRulesets={overdimensionalRulesets}
          onClose={this._onSave}
        />
      </div>
    );
  }
}

export const EquipmentTypeViewPage = EquipmentTypeService.inject(
  _EquipmentTypeViewPage
);
