import {
  React,
  bind
} from "$Imports/Imports";

import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams
} from "$Imports/MaterialUIComponents";

import {
  CustomerSearchCriteria,
  Quote
} from "$Generated/api";

import {
  DisplayFormattedDatetime,
  CustomerLink
} from "$Imports/CommonComponents";

import {
  DEFAULT_CUSTOMER_SEARCH,
  ISalesRepHomeServiceInjectedProps,
  SalesRepHomeService
} from "$State/SalesRepHomeFreezerService";

import {
  IStateServiceInjectedProps,
  StateService
} from "$State/RegionFreezerService";

import {
  MyCustomersSearchForm
} from "./MyCustomersSearchForm";

import { DATE_WITH_TIME_SECONDS_MERIDIAN_FORMAT } from "$Shared/utilities/formatUtil";
import { getFormattedZipPostalCode } from "$Shared/utilities/helpers";

const styles: {
  gridContainer: string;
  searchFormContainer: string;
  expiry: string;
} = require("./MyQuotes.scss");

interface IMyCustomersState {

}

interface IMyCustomersBaseProps {

}

type IMyQuotesProps = IMyCustomersBaseProps
  & ISalesRepHomeServiceInjectedProps
  & IStateServiceInjectedProps;

class _MyCustomers extends React.Component<IMyQuotesProps, IMyCustomersState> {

  private readonly columns: GridColDef[] = [
    {
      headerName: "Code",
      field: "tmcustomerId",
      flex: 2
    },
    {
      headerName: "Customer Name",
      field: "customer",
      flex: 2,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.customerName;
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CustomerLink
            customerId={params.row.id}
            customerName={params.row.customerName}
          />
        );
      }
    },
    {
      headerName: "City",
      field: "city",
      flex: 2
    },
    {
      headerName: "State",
      field: "regionId",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.region?.regionAbbreviation;
      }
    },
    {
      headerName: "Zip",
      field: "zipPostalCode",
      renderCell: (params: GridRenderCellParams) => {
        return getFormattedZipPostalCode(params.row) ?? params.value;
      },
      flex: 1.5
    },
    {
      headerName: "Last Quote Date",
      field: "quoteDate",
      flex: 3,
      renderCell: (params: GridRenderCellParams) => {
        let quote: Quote = params.row.quotes[0];
        if (quote) {
          return <DisplayFormattedDatetime value={quote.quoteDate} formatString={DATE_WITH_TIME_SECONDS_MERIDIAN_FORMAT} />;
        }
        return "";
      }
    }
  ];

  @bind
  private async _onSearchSubmit(search: CustomerSearchCriteria): Promise<void> {
    this.props.salesRepHomeService.update({
      customerSearchCriteria: { ...search }
    });

    await this.props.salesRepHomeService.fetchCustomers(true);
  }

  componentDidMount() {    
    this.props.salesRepHomeService.update({
      customerSearchCriteria: { ...DEFAULT_CUSTOMER_SEARCH }
    });
    this.props.salesRepHomeService.fetchCustomers(true);
    this.props.regionService.fetchStates();
  }

  render() {
    const {
      customerFetchResults
    } = this.props.salesRepHomeService.getState();

    const {
      regionFetchResults
    } = this.props.regionService.getState();

    const customerData = customerFetchResults.data ?? [];

    const stateData = regionFetchResults.data ?? [];

    return (
      <div className={styles.gridContainer}>
        <div className={styles.searchFormContainer}>
          <MyCustomersSearchForm
            regions={stateData}
            onSubmit={this._onSearchSubmit}
          />
        </div>

        <DataGridPro
          rows={customerData}
          columns={this.columns}
          density="compact"
          hideFooter
          initialState={{
            sorting: {
              sortModel: [{ field: "customer", sort: "asc" }]
            }
          }}
        />
      </div>
    )
  }
}

export const MyCustomers = SalesRepHomeService.inject(
  StateService.inject(
    _MyCustomers
  )
);
