import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  DataTable,
  IDataTableColumn,
  AjaxActionIndicator,
  directionType,
  DisplayFormattedDatetime,
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import {
  Card,
  CardActions,
  CardHeader,
  FormControlLabel,
  Switch
} from "$Imports/MaterialUIComponents";

import {
  EquipmentTypeValue
} from "$Generated/api";

import {
  IEquipmentRateServiceInjectedProps,
  EquipmentRateService
} from "$State/EquipmentRateFreezerService";

import {
  ActionMenu
} from "./ActionMenu";

import {
  EditEquipmentRateModal
} from "./EditEquipmentRateModal";

import { DATE_WITH_TIME_MERIDIAN_FORMAT } from "$Shared/utilities/formatUtil";

const styles: {
  mainContainer: string;
  actionArea: string;
  cardStyle: string;
} = require("$Shared/styles/Modal.scss");

interface IEquipmentRatesViewState { }

interface IEquipmentRatesViewBaseProps {
  companyId: number | undefined;
}

type IEquipmentRatesViewProps = IEquipmentRatesViewBaseProps
  & IEquipmentRateServiceInjectedProps;

class _EquipmentRatesView extends React.Component<IEquipmentRatesViewProps, IEquipmentRatesViewState> {
  private readonly columns: Array<IDataTableColumn<EquipmentTypeValue>> = [
    {
      columnName: "equipmentType",
      columnFieldData: (x) => x.equipmentType?.name ?? "",
      sortMethod: (x) => x.equipmentType?.name ?? "",
      headerValue: "Equipment Type"
    },
    {
      columnName: "rate",
      columnFieldData: (x) => <DisplayFormattedNumber value={x.percentRate} formatString="0.0%" />,
      sortMethod: (x) => x.percentRate,
      headerValue: "Rate"
    },
    {
      columnName: "startDate",
      columnFieldData: (x) => <DisplayFormattedDatetime value={x.startDateTime} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} />,
      sortMethod: (x) => x.startDateTime,
      headerValue: "Start Date"
    },
    {
      columnName: "endDate",
      columnFieldData: (x) => <DisplayFormattedDatetime value={x.endDateTime} formatString={DATE_WITH_TIME_MERIDIAN_FORMAT} />,
      sortMethod: (x) => x.startDateTime,
      headerValue: "End Date"
    },
    {
      columnName: "createdBy",
      columnFieldData: (x) => x.createdBy ? x.createdBy.firstName + " " + x.createdBy.lastName : "",
      sortMethod: (x) => x.createdBy ? x.createdBy.firstName + " " + x.createdBy.lastName : "",
      headerValue: "Created By"
    },
    {
      columnName: "action",
      columnFieldData: (x) => (
        <ActionMenu
          equipmentRate={x}
          onEdit={this._onEdit}
        />
      ),
      headerValue: ""
    }
  ];

  componentDidMount() {
    this.props.equipmentRateService.fetchEquipmentRates(this.props.companyId);
  }

  componentDidUpdate(prevProps: IEquipmentRatesViewProps) {
    if (this.props.companyId !== prevProps.companyId) {
      this.props.equipmentRateService.clearFreezer();
      this.props.equipmentRateService.fetchEquipmentRates(this.props.companyId, true);
    }
  }

  @bind
  private _onActiveFilterChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.equipmentRateService.setFilterActive(this.props.companyId, checked);
  }

  @bind
  private _onSortChange(event: React.MouseEvent<HTMLElement>, columnName: string | undefined, direction: directionType) {
    this.props.equipmentRateService.setSortState({
      sortColumnName: columnName,
      sortDirection: direction,
    });
  }

  @bind
  private _onEdit(equipmentRate: EquipmentTypeValue) {
    this.props.equipmentRateService.openEditModal(equipmentRate, this.props.companyId);
  }

  @bind
  private _onChange(value: Partial<EquipmentTypeValue>) {
    this.props.equipmentRateService.onChangeFromModal(value);
  }

  @bind
  private _onSave() {
    this.props.equipmentRateService.saveEquipmentRate();
  }

  @bind
  private _closeModal() {
    this.props.equipmentRateService.closeEditModal();
  }

  render() {
    const {
      equipmentRateFetchResults,
      equipmentRateData,
      filterActive,
      sortState,
      equipmentRateModalState
    } = this.props.equipmentRateService.getState();

    return (
      <div
        className={styles.mainContainer}
      >
        <Card
          className={styles.cardStyle}
        >
          <CardHeader title="Equipment Rates" />
          <CardActions
            disableSpacing={true}
            className={styles.actionArea}
          >
            <FormControlLabel
              control={
                (
                  <Switch
                    color="primary"
                    checked={filterActive}
                    onChange={this._onActiveFilterChange}
                  />
                )
              }
              label="Only show active"
            />
          </CardActions>
          <AjaxActionIndicator
            state={[equipmentRateFetchResults]}
          />
          <DataTable
            columns={this.columns}
            data={equipmentRateData}
            defaultSortColumnName={sortState.sortColumnName}
            defaultSortDirection={sortState.sortDirection}
            onSortChange={this._onSortChange}
          />
        </Card>
        <EditEquipmentRateModal
          isOpen={equipmentRateModalState.isOpen}
          equipmentRate={equipmentRateModalState.equipmentRate}
          validationErrors={equipmentRateModalState.validationErrors}
          onSave={this._onSave}
          onCancel={this._closeModal}
          onChange={this._onChange}
        />
      </div>
    );
  }
}

export const EquipmentRatesView =
  EquipmentRateService.inject(
    _EquipmentRatesView
  );
