import {
  React,
  Helpers,
  bind,
  numeral,
  NumberFormat,
  NumberFormatValues
} from "$Imports/Imports";

import {
  AjaxActionIndicator,
  ConfirmCancelModal,
  DateTimeInputField
} from "$Imports/CommonComponents";

import {
  TarpValue
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox
} from "$Imports/MaterialUIComponents";

import {
  ModalState
} from "$State/TarpRatesFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

const styles: {
  container: string;
} = require("./AddEditTarpRate.scss");

interface IAddEditTarpRateProps {
  modalState: ModalState;
  isFetching: boolean;
  onSave: () => void;
  onCancel: () => void;
  onTarpValueChange: (tarpRateChange: Partial<TarpValue>) => void;
  onImmediateStartChange: (futureStart: boolean) => void;
  cancelOpen: boolean;
  cancelResponse: (response: boolean) => void;
}

export class AddEditTarpRate extends React.PureComponent<IAddEditTarpRateProps> {
  @bind
  private _onSubmit() {
    if (this.props.onSave) {
      this.props.onSave();
    }
  }

  @bind
  private _onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  @bind
  private _onFlatRateChange(value: NumberFormatValues) {
    this.props.onTarpValueChange({ rate: !Helpers.isNullOrUndefined(value.floatValue) ? value.floatValue : undefined });
  }

  @bind
  private _onStartDateChange(date: Date | undefined) {
    this.props.onTarpValueChange({ startDateTime: date });
  }

  @bind
  private _onFutureStartChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.onImmediateStartChange(checked);
  }

  render() {
    const {
      modalState,
      isFetching
    } = this.props;

    const validationsParser = new ValidationErrorParser<TarpValue>(modalState.validationErrors);  
    const isTarpActive = modalState.editTarpValue?.tarp?.isActive ?? true;

    return !Helpers.isNullOrUndefined(modalState.editTarpValue) && !Helpers.isNullOrUndefined(modalState.editTarpValue) ? (
      <>
        <Dialog
          open={modalState.formMode === "edit"}
          fullWidth={true}
          maxWidth="sm"
        >
          <AjaxActionIndicator
            showProgress={isFetching}
          />
          <DialogTitle>
            Edit Tarp Rate
          </DialogTitle>
          <DialogContent
            className={styles.container}
          >
            <Grid container direction={"column"} spacing={3}>
              <Grid item>
                <div>Tarp Name: {modalState.originalTarpValue?.tarp?.tarpName ?? ""}</div>
              </Grid>
              <Grid item>
                <div>Description: {modalState.originalTarpValue?.tarp?.tarpDescription ?? ""}</div>
              </Grid>
              <Grid item>
                <div>Current Rate: {numeral(modalState.originalTarpValue?.rate).format("$0,0.00") ?? ""}</div>
              </Grid>
              <Grid item>
                <NumberFormat
                  required
                  label="New Rate"
                  name="flatRate"
                  value={modalState.editTarpValue?.rate ?? ""}
                  error={!validationsParser.isValid("rate")}
                  helperText={validationsParser.validationMessage("rate")}
                  prefix={"$"}
                  thousandSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale
                  allowNegative={false}
                  onValueChange={this._onFlatRateChange}
                  customInput={TextField}
                  disabled={!isTarpActive}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label="Future start date"
                  control={(
                    <Checkbox
                      checked={modalState.futureStart}
                      onChange={this._onFutureStartChange}
                      name="futureStart"
                      disabled={!isTarpActive}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <DateTimeInputField
                  required
                  disablePast
                  dateLabel="Start Date Time"
                  disabled={!modalState.futureStart || !isTarpActive}
                  value={modalState.editTarpValue?.startDateTime ?? null}
                  onChange={this._onStartDateChange}
                  error={!validationsParser.isValid("startDateTime")}
                  helperText={validationsParser.validationMessage("startDateTime")}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={isFetching}
              onClick={this._onSubmit}
            >
              Save
            </Button>
            <Button
              onClick={this._onCancel}
              disabled={isFetching}
              variant="outlined"
            >
              Cancel
            </Button>
            </DialogActions>
        </Dialog>
        <ConfirmCancelModal
          isOpen={this.props.cancelOpen}
          onCancelNo={() => this.props.cancelResponse(false)}
          onCancelYes={() => this.props.cancelResponse(true)}
        />
      </>
    ) : null;
  }
}