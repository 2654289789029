import {
  bind,
  React,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  DateRangePicker,
  SearchControlsContainer
} from "$Imports/CommonComponents";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from "$Imports/MaterialUIComponents";

import {
  Region,
  SalesRepCustomerSearchCriteria,
  SalesRepCustomerSearchCriteriaIndustryTypeEnum,
  SalesRepCustomerSearchCriteriaPercentageToCloseEnum
} from "$Generated/api";

import {
  DEFAULT_CUSTOMER_SEARCH,
  ISalesRepHomeServiceInjectedProps,
  SalesRepHomeService,
  CustomerSearchValidationSchema
} from "$State/SalesRepHomeFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  industryTypeTextMap,
  percentageToCloseTextMap
} from "$Utilities/enumUtil";

import { ValidationErrorParser } from "$Utilities/ValidationErrorParser";
import { getTrimmedZipPostalCode } from "$Shared/utilities/helpers";

interface IOwnProps {
  isProspect?: boolean;
  regions?: Region[];
  onSubmit: (search: SalesRepCustomerSearchCriteria) => void;
}

type OwnProps = IOwnProps
  & ISalesRepHomeServiceInjectedProps;

interface IOwnState {
  searchCriteria: SalesRepCustomerSearchCriteria;
  errors: ValidationError | null;
}

const styles: {
  container: string;
} = require("./SalesRepSearchForm.scss");

class _MyCustomersSearchForm extends React.PureComponent<OwnProps, IOwnState> {
  state: IOwnState = {
    searchCriteria: { ...DEFAULT_CUSTOMER_SEARCH },
    errors: null
  };

  @bind
  private _onTextChange(e: React.ChangeEvent<{ name: string; value: string; }>): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        [e.target.name]: e.target.value ?? ""
      }
    }));
  }

  @bind
  private _onIndustryTypeChange(e: SelectChangeEvent<SalesRepCustomerSearchCriteriaIndustryTypeEnum>) {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        industryType: e.target.value ? e.target.value as SalesRepCustomerSearchCriteriaIndustryTypeEnum : undefined
      }
    }));
  }

  @bind
  private _onPercentageToCloseChange(e: SelectChangeEvent<SalesRepCustomerSearchCriteriaPercentageToCloseEnum>) {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        percentageToClose: e.target.value ? e.target.value as SalesRepCustomerSearchCriteriaPercentageToCloseEnum : undefined
      }
    }));
  }

  @bind
  private _onStateChange(e: SelectChangeEvent<string>): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        regionAbbreviation: e.target.value ?? ""
      }
    }));
  }

  @bind
  private _onDateRangeChange(start: Date | null, end: Date | null): void {
    const startName = this.props.isProspect ? "startDateFrom" : "latestQuoteStartDate";
    const endName = this.props.isProspect ? "startDateTo" : "latestQuoteEndDate";
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        [startName]: start ? moment(start).startOf('day').toDate() : undefined,
        [endName]: end ? moment(end).endOf('day').toDate() : undefined
      }
    }));
  }

  @bind
  private _onZipChange(zip: string): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        zipPostalCode: getTrimmedZipPostalCode(zip)
      }
    }));
  }

  @bind
  private async _onSubmit(): Promise<void> {
    const { searchCriteria } = this.state;
    const errors = await validateSchema(CustomerSearchValidationSchema, searchCriteria, {
      abortEarly: false
    });
    this.setState({ errors: errors });
    if(errors) {
      return;
    }

    this.props.onSubmit(searchCriteria);
  }

  @bind
  private _onClear(): void {
    const criteria = { ...DEFAULT_CUSTOMER_SEARCH, isProspect: this.props.isProspect };
    this.setState({
      searchCriteria: criteria
    });

    this.props.onSubmit(criteria);

    this.setState({ errors: null });
  }

  componentDidMount() {
    this.setState({
      searchCriteria: { ...DEFAULT_CUSTOMER_SEARCH, isProspect: this.props.isProspect }
    });
  }

  render() {
    const {
      isProspect,
      regions
    } = this.props;

    const {
      searchCriteria,
      errors
    } = this.state;

    const stateData = regions ?? [];

    const validationParser = new ValidationErrorParser<SalesRepCustomerSearchCriteria>(errors);
    
    return (
      <SearchControlsContainer
        className={styles.container}
        onSubmit={this._onSubmit}
        onClear={this._onClear}
      >
        <TextField
          style={{ flex: "1" }}
          label={`${isProspect ? "Prospect" : "Code / Customer"} Name`}
          name="customerName"
          error={!validationParser.isValid("customerName")}
          helperText={validationParser.validationMessage("customerName")}
          value={searchCriteria.customerName}
          onChange={this._onTextChange}
        />

        {!isProspect ?
          <>
            <FormControl style={{ flex: "0 0 4rem" }}>
              <InputLabel shrink>State</InputLabel>
              <Select
                value={searchCriteria.regionAbbreviation ?? ""}
                name="regionAbbreviation"
                onChange={this._onStateChange}
                displayEmpty
              >
                <MenuItem value="">All</MenuItem>
                {stateData.map((x, idx) => (
                  <MenuItem value={x.regionAbbreviation} key={idx}>
                    {x.regionAbbreviation}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              style={{ flex: "0 0 6rem" }}
              label="Zip Code"
              value={searchCriteria.zipPostalCode}
              onChange={(event) => this._onZipChange(event.target.value)}
            />

            <div style={{ display: "inline-flex", flex: "0 0 17rem", gap: "0.5rem" }}>
              <DateRangePicker
                startDate={searchCriteria.latestQuoteStartDate}
                startError={validationParser.validationMessage("latestQuoteStartDate")}
                endDate={searchCriteria.latestQuoteEndDate}
                endError={validationParser.validationMessage("latestQuoteEndDate")}
                onChange={this._onDateRangeChange}
              />
            </div>
          </>
          : <>
            <FormControl style={{ flex: "1" }}>
              <InputLabel>Industry Type</InputLabel>
              <Select
                value={searchCriteria.industryType ?? ""}
                onChange={this._onIndustryTypeChange}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                <MenuItem value="Aerospace">{industryTypeTextMap["Aerospace"]}</MenuItem>
                <MenuItem value="Equipment">{industryTypeTextMap["Equipment"]}</MenuItem>
                <MenuItem value="GeneratorsTransformers">{industryTypeTextMap["GeneratorsTransformers"]}</MenuItem>
                <MenuItem value="MachineTools">{industryTypeTextMap["MachineTools"]}</MenuItem>
                <MenuItem value="Steel">{industryTypeTextMap["Steel"]}</MenuItem>
                <MenuItem value="Other">{industryTypeTextMap["Other"]}</MenuItem>
              </Select>
            </FormControl>

            <TextField
              style={{ flex: "1" }}
              label="Current Provider"
              name="currentProvider"
              onChange={this._onTextChange}
              value={searchCriteria.currentProvider ?? ""}
              error={!validationParser.isValid("currentProvider")}
              helperText={validationParser.validationMessage("currentProvider")}
            />

            <FormControl style={{ flex: "1" }}>
              <InputLabel>Percent To Close</InputLabel>
              <Select
                value={searchCriteria.percentageToClose ?? ""}
                onChange={this._onPercentageToCloseChange}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                <MenuItem value="NoContactWithDM">{percentageToCloseTextMap["NoContactWithDM"]}</MenuItem>
                <MenuItem value="ContactedDMInterestExpressed">{percentageToCloseTextMap["ContactedDMInterestExpressed"]}</MenuItem>
                <MenuItem value="Quoted">{percentageToCloseTextMap["Quoted"]}</MenuItem>
                <MenuItem value="VerballyAcceptedQuote">{percentageToCloseTextMap["VerballyAcceptedQuote"]}</MenuItem>
                <MenuItem value="OrderPlaced">{percentageToCloseTextMap["OrderPlaced"]}</MenuItem>
              </Select>
            </FormControl>

            <div style={{ display: "inline-flex", flex: "0 0 17rem", gap: "0.5rem" }}>
              <DateRangePicker
                startLabel="Start From"
                startDate={searchCriteria.startDateFrom}
                startError={validationParser.validationMessage("startDateFrom")}
                endLabel="Start To"
                endDate={searchCriteria.startDateTo}
                endError={validationParser.validationMessage("startDateTo")}
                onChange={this._onDateRangeChange}
              />
            </div>
          </>
        }
      </SearchControlsContainer>
    );
  }
}

export const MyCustomersSearchForm = SalesRepHomeService.inject(_MyCustomersSearchForm);
